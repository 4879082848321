import { React, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import EditOrDeleteUserProfilePictureForm from "./EditOrDeleteUserProfilePictureForm";

const EditOrDeleteUserProfilePictureModal = (props) => {
  const [modal, setModal] = useState(false);
  const [typeOfAction, setTypeOfAction] = useState("");

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <div className="py-1">
      <span className="py-2">
        <Button
          color="warning"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=" Modifier la photo de profil"
          onClick={() => selectTypeOfAction("update")}
        >
          <i className="bx bx-edit-alt" /> <span>Modifier</span>
        </Button>
      </span>
      &nbsp;
      <span>
        <Button
          className="my-2"
          color="danger"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=" Supprimer la photo de profil"
          onClick={() => selectTypeOfAction("delete")}
        >
          <i className="bx bx-trash" />
          <span>Supprimer</span>
        </Button>
      </span>
    </div>
  );

  const selectTypeOfAction = (action) => {
    setTypeOfAction(action);
    toggle();
  };

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {typeOfAction === "delete" ? "Suppression de la photo de l'utilisateur" : "Modification de la photo de l'utilisateur"}
        </ModalHeader>
        <ModalBody>
          <EditOrDeleteUserProfilePictureForm
            modalConfig={setModal}
            typeOfAction={typeOfAction}
            addOrUpdateUserProfilePicture={props.addOrUpdateUserProfilePicture}
            deleteUserProfilePicture={props.deleteUserProfilePicture}
          ></EditOrDeleteUserProfilePictureForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditOrDeleteUserProfilePictureModal;
