import http from "./http-common";

class MaintenanceService {
  getMaintenance() {
    return http.get("/maintenance");
  }
  getMaintenanceHistory() {
    return http.get("/maintenance/history");
  }

  startMaintenance(data) {
    return http.post("/maintenance/start", data);
  }

  endMaintenance(data) {
    return http.put("/maintenance/end", data);
  }
}

export default new MaintenanceService();
