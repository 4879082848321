import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSession } from "../../redux/slices/UserSlice";
import { getHourFromUtcDate, convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";
import TablePagination from "../../components/table/table-pagination/TablePagination";

const UserSessions = () => {
  const { loading_userSessions, userSessions, currentUserInfos } = useSelector((state) => ({ ...state.userInfos }));
  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Date début ",
      accessor: "startDate",
    },
    {
      Header: "Heure début",
      accessor: "startHour",
    },
    {
      Header: "Date fin",
      accessor: "endDate",
    },
    {
      Header: "Heure fin",
      accessor: "endHour",
    },
    {
      Header: "Durée",
      accessor: "duration",
    },
  ];
  const tableHooks = () => {
    "";
  };
  const handleRowClicked = () => {
    "";
  };

  const getAllData = () => {
    if (userSessions && userSessions.data) {
      return [
        ...userSessions.data.sessions?.map((session) => ({
          startDate: convertDateToLocalTime(session.startTimestamp),
          startHour: getHourFromUtcDate(session.startTimestamp),
          endDate: convertDateToLocalTime(session.endTimestamp),
          endHour: getHourFromUtcDate(session.endTimestamp),
          duration: `${session?.durationHours}h : ${session?.durationMinutes}m : ${session?.durationSeconds}s  `,
        })),
      ];
    }

    return [];
  };

  useEffect(() => {
    dispatch(getUserSession(currentUserInfos?.userId));
  }, [currentUserInfos]);

  return (
    <>
      {loading_userSessions ? (
        "chargement ..."
      ) : userSessions && userSessions.data ? (
        <div className="test-table-pagination text-center">
          <TablePagination
            data={getAllData()}
            columns={columns}
            tableHooks={tableHooks}
            refresh={getAllData()}
            handleRowClicked={handleRowClicked}
            type={"default"}
            enableFilter={true}
          >
            <element
              key="startDate"
              rangeStartDate="startDate"
              rangeEndDate="endDate"
              className="cell"
              casesensitive={"true"}
              showsearch={"true"}
              isDateRange={true}
            >
              Date:
            </element>
          </TablePagination>
        </div>
      ) : (
        "erreur"
      )}
    </>
  );
};

export default UserSessions;
