import React from "react";
import { Table } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const MissionSheetVersionDetailsTab = ({ missionVersion }) => {
  return (
    <div>
      {missionVersion && (
        <Table size="sm">
          <thead></thead>
          <tbody>
            <tr>
              <td className="text-muted">Rôle du consultant</td>
              <td className="text-break">{missionVersion.consultantRole}</td>
            </tr>
            <tr>
              <td className="text-muted">Responsable mission</td>
              <td className="text-break">{missionVersion.clientMissionManager}</td>
            </tr>
            <tr>
              <td className="text-muted"> Responsable suivi</td>
              <td className="text-break">{missionVersion.clientMonitoringManager}</td>
            </tr>
            <tr>
              <td className="text-muted">Equipe</td>
              <td className="text-break">{missionVersion.teamProjectName}</td>
            </tr>
            <tr>
              <td className="text-muted"> Contexte business</td>
              <td className="text-break">{missionVersion.businessContext}</td>
            </tr>
            <tr>
              <td className="text-muted"> Description du rôle</td>
              <td className="text-break">{missionVersion.consultantRoleDescription}</td>
            </tr>
            <tr>
              <td className="text-muted"> Rôle de la mission</td>
              <td className="text-break">{missionVersion.missionRole}</td>
            </tr>
            <tr>
              <td className="text-muted"> Objectif de la mission </td>
              <td className="text-break">{missionVersion.missionGoal}</td>
            </tr>
            <tr>
              <td className="text-muted">Détails de la période </td>
              <td className="text-break">{missionVersion.missionPeriodDetails.periodDetails}</td>
            </tr>
            <tr>
              <td className="text-muted"> Environnement technique</td>
              <td className="text-break">{missionVersion.technicalEnvironement}</td>
            </tr>
            <tr>
              <td className="text-muted"> Environnement fonctionnel </td>
              <td className="text-break">{missionVersion.functionalEnvironement}</td>
            </tr>
            <tr>
              <td className="text-muted"> Environnement méthodologique </td>
              <td className="text-break">{missionVersion.methodologicalEnvironement}</td>
            </tr>
            <tr>
              <td className="text-muted">Piste d'amélioration de mission </td>
              <td className="text-break">{missionVersion.missionImprovements}</td>
            </tr>
            <tr>
              <td className="text-muted"> Piste d'amélioration personnelle</td>
              <td className="text-break">{missionVersion.personalImprovements}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default MissionSheetVersionDetailsTab;
