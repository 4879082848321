import React, { useState, useMemo } from "react";
import { FormGroup, Label, Col, Button, Input, ButtonGroup, FormText } from "reactstrap";
import { useFormik } from "formik";
import AddEditRessourceComp from "../ressources/AddEditRessourceComp";
import ToggleButton from "../../buttons/toggleButton/ToggleButton";

const AddNewActionConfigForm = ({ handleCloseModal, handleAddNewActionConfig, axeParent, domainParent, skillParent }) => {
  const [isloading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      pepAxe: axeParent,
      pepDomain: domainParent,
      pepSkill: skillParent,
      title: "",
      instruction: "",
      level: undefined,
      isEnable: false,
    },
    onSubmit: (values) => {
      setIsLoading(true);
      handleAddNewActionConfig(values);
    },
  });

  const isFormCorrect = useMemo(() => {
    return (
      formik.values.pepSkill && formik.values.pepSkill?.skillId && formik.values.title && formik.values.title?.length > 1 && formik.values.level !== undefined
    );
  }, [formik.values]);

  const handleRadioButtonChange = (e) => {
    formik.setValues({ ...formik.values, ["level"]: e });
  };

  const handleToggleButtonChange = () => {
    formik.setValues({ ...formik.values, isEnable: !formik.values.isEnable });
  };

  const handleAddEditRessource = (ressources) => {
    formik.setValues({ ...formik.values, ["ressources"]: ressources });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup row>
          <Label htmlFor="axe" sm={3}>
            Axe
          </Label>
          <Col sm={9}>
            <Input value={axeParent?.title} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="domaine" sm={3}>
            Domaine
          </Label>
          <Col sm={9}>
            <Input value={domainParent?.title} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="pepSkill" sm={3}>
            Compétence
          </Label>
          <Col sm={9}>
            <Input value={skillParent?.title} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="title" sm={3}>
            Titre
          </Label>
          <Col sm={9}>
            <Input id="title" name="title" onChange={formik.handleChange} value={formik.values.title} placeholder=" -- Ajouter un titre" maxLength="30" />
            <FormText>30 caractères max.</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="title" sm={3}>
            Actif
          </Label>
          <Col sm={8}>
            <ToggleButton state={formik.values.isEnable} onClickHandler={handleToggleButtonChange} />
          </Col>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="level" sm={10} className="mb-1 p-0">
            Niveau (*)
          </Label>
          <Col sm={10}>
            <ButtonGroup style={{ zIndex: "0" }}>
              <Button className="px-3" color="primary" outline onClick={() => handleRadioButtonChange(1)} active={formik.values.level === 1}>
                Débutant
              </Button>
              <Button className="px-3" color="primary" outline onClick={() => handleRadioButtonChange(2)} active={formik.values.level === 2}>
                Intermédiaire
              </Button>
              <Button className="px-3" color="primary" outline onClick={() => handleRadioButtonChange(3)} active={formik.values.level === 3}>
                Confirmé
              </Button>
            </ButtonGroup>
          </Col>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="urls" sm={2} className="p-0">
            Ressources
          </Label>
          <Col sm={10}>
            <AddEditRessourceComp handleAddEditRessource={handleAddEditRessource} creation={true} />
          </Col>
        </FormGroup>
        &nbsp;
        <FormGroup>
          <Label htmlFor="instructions" sm={4} className="mb-1 p-0">
            Instructions
          </Label>
          <Col sm={12}>
            <Input
              rows="4"
              type="textarea"
              id="instruction"
              name="instruction"
              onChange={formik.handleChange}
              value={formik.values.instruction}
              placeholder="  Ajouter les  instructions"
            />
          </Col>
        </FormGroup>
        <div className="d-flex justify-content-center">
          <Button className="m-2" size="md" color="danger" role="button" onClick={handleCloseModal}>
            Annuler
          </Button>
          &nbsp;
          <Button className="m-2" size="md" color="primary" type="submit" disabled={!isFormCorrect || isloading}>
            {isloading && <i className="bx bx-loader-alt bx-spin"></i>} Ajouter
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewActionConfigForm;
