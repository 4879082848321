import { React } from "react";
import QuestSerialValidationModal from "./QuestSerialValidationModal";

import _ from "lodash";

import { Row, Col } from "reactstrap";

const QuestValidationFilterBar = ({ allQuests, openedByDefault, refreshMainPage, refreshPage }) => {
  let quests = _.cloneDeep(allQuests);

  return (
    <Row>
      {/* <Col md="4">
        <div className="d-flex justify-content-end">
          <h6 className="lh-lg mx-2"> Statut &nbsp;</h6>
          <div className="w-100">
            <Select
              name="questState"
              id="questState"
              autoComplete="off"
              isMulti
              defaultValue={[{ value: 1, label: "A valider" }]}
              isClearable
              isSearchable
              onChange={handleStateSelectChange}
              noOptionsMessage={() => "Pas d'options"}
              placeholder="Filtre par statut"
              options={questStateOptionsList}
            />
          </div>
        </div>
      </Col>
      <Col md="4" className="d-flex justify-content-end">
        <h6 className="lh-lg mx-2"> Collaborateur &nbsp;</h6>
        <div className="w-100">
          <Select
            name="questState"
            id="questState"
            autoComplete="off"
            isClearable
            isSearchable
            onChange={handleUserIdSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            placeholder="... par nom"
            options={userOptionsList}
          />
        </div>
      </Col> */}

      <Col className="d-flex justify-content-end sequential-validation-loop">
        <QuestSerialValidationModal
          quests={quests}
          openedByDefault={openedByDefault}
          refreshMainPag
          refreshMainPage={refreshMainPage}
          refreshPage={refreshPage}
        ></QuestSerialValidationModal>
      </Col>
    </Row>
  );
};

export default QuestValidationFilterBar;
