import React from "react";
import { Row, FormGroup, Label, Input, Col, CloseButton } from "reactstrap";

const QuizResponseProposalComponent = ({
  proposalNumber,
  handleDeleteQuestion,
  handleAnswerChange,
  handleIsCorrectChange,
  proposal,
  questionType,
  disableButton,
}) => {
  return (
    <div>
      <Row className="mb-3">
        <Col md={9}>
          <div className="d-flex justify-content-between">
            <div>Proposition #{proposalNumber}</div>
            <div>
              <FormGroup switch>
                <Label check>Bonne réponse</Label>
                <Input
                  type="switch"
                  role="switch"
                  className="fs-5"
                  checked={proposal.isCorrect}
                  onChange={() => handleIsCorrectChange(proposalNumber)}
                  readOnly={disableButton}
                />
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={9}>
          <FormGroup row>
            <Label for="response" sm={2}>
              Réponse
            </Label>
            <Col sm={10}>
              <Input
                id="response"
                name="response"
                placeholder="proposition de réponse"
                type={questionType === 2 ? "number" : "text"}
                required
                value={proposal.text}
                onChange={(e) => handleAnswerChange(proposalNumber, e)}
                readOnly={disableButton}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col>
          <CloseButton onClick={() => handleDeleteQuestion(proposalNumber)} readOnly={disableButton} />
        </Col>
      </Row>
    </div>
  );
};

export default QuizResponseProposalComponent;
