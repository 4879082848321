import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedQuiz } from "../../redux/slices/QuizSlice";
import { useNavigate } from "react-router-dom";
import QuizDraftActionsControl from "./QuizDraftActionsControl";
import { formatDateUtcToLocalTime } from "../../assets/utils/UtilsFunctons";
import SortAndSearchTable from "../table/sortAndSearchTable/SortAndSearchTable";
import sortBy from "lodash/sortBy";

const QuizConfigTable = ({ data, handleDeleteQuiz }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const quizActionsBasedOnState = (quiz) => {
    if (quiz.state === 3) {
      // brouillon
      return <QuizDraftActionsControl handleDeleteQuiz={handleDeleteQuiz} quiz={quiz}></QuizDraftActionsControl>;
    }
  };

  const columns = [
    {
      Header: "Nom",
      accessor: "title",
    },
    {
      Header: "Thème",
      accessor: "theme",
    },
    {
      Header: "Questions",
      accessor: "questionCount",
    },
    {
      Header: "Modèle gain",
      accessor: "rewardTemplateName",
    },

    {
      Header: "Début",
      accessor: "startDate",
      Cell: ({ value }) => formatDateUtcToLocalTime(value),
    },
    {
      Header: "Fin",
      accessor: "endDate",
      Cell: ({ value }) => formatDateUtcToLocalTime(value),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => <div className="d-flex justify-content-evenly">{quizActionsBasedOnState(row.original)}</div>,
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off", @typescript-eslint/no-unused-vars: "off" */
  const tableHooks = (hooks) => {};
  const handleRowClicked = (row) => {
    navigate("/QuizDetailledPage/" + row.original.quizConfigId, { state: { quizConfigId: row.original.quizConfigId } });
    dispatch(setSelectedQuiz(row.original.quizConfigId));
    navigate("/QuizDetailledPage/" + row.original.quizConfigId);
  };

  let sortedDate = sortBy(data, "endDate").reverse();

  /*  filter */

  return (
    <div>
      <div>
        <SortAndSearchTable data={sortedDate} columns={columns} tableHooks={tableHooks} refresh={data} handleRowClicked={handleRowClicked}></SortAndSearchTable>
      </div>
    </div>
  );
};

export default QuizConfigTable;
