import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SeasonDataService from "../../services/SeasonDataService";
import toast from "react-hot-toast";

const notify = (notif, state) => {
  switch (state) {
    case 1:
      toast.success(notif, { position: "top-right" });
      break;
    case 0:
      toast.error(notif, { position: "top-right" });
      break;
    default:
      toast.error("La requête a échoué !");
      break;
  }
};

export const getSeasonInfos = createAsyncThunk("seasonConfigs/getSeasonInfos", async (data) => {
  try {
    const response = await SeasonDataService.getSeasonInfos(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const getSeasonList = createAsyncThunk("seasonConfigs/getSeasonList", async () => {
  try {
    const response = await SeasonDataService.getSeasonList();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const editSeason = createAsyncThunk("seasonConfigs/editSeason", async (data) => {
  try {
    const response = await SeasonDataService.editSeason(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const launchNewSeason = createAsyncThunk("seasonConfigs/launchNewSeason", async () => {
  try {
    const response = await SeasonDataService.launchNewSeason();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const closeCurrentSeason = createAsyncThunk("seasonConfigs/closeCurrentSeason", async () => {
  try {
    const response = await SeasonDataService.closeCurrentSeason();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const updateTeamName = createAsyncThunk("seasonConfigs/updateTeamName", async (data) => {
  try {
    const response = await SeasonDataService.updateTeamName(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const postTeamPicture = createAsyncThunk("seasonConfigs/postTeamPicture", async (data) => {
  try {
    const response = await SeasonDataService.postTeamPicture(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

const seasonSlice = createSlice({
  name: "season",
  initialState: {
    seasonInfos: [],
    seasonList: null,
    currentSeason: undefined,
    loading_getSeasonInfos: true,
    loading_getSeasonList: true,
    loading_editFutureSeason: true,
    loading_launchNewSeason: true,
    dataPost: [],
    selectedSeasonIdForRank: undefined,
    refreshSeasonPageAfterUpdate: false,
    refreshRankingPageAfterUpdate: false,
  },
  reducers: {
    changeSelectedSeasonIdForRank: (state, action) => {
      state.selectedSeasonIdForRank = action.payload;
    },
  },
  extraReducers: {
    [getSeasonInfos.pending]: (state) => {
      state.loading_getSeasonInfos = true;
    },
    [getSeasonInfos.fulfilled]: (state, { payload }) => {
      state.loading_getSeasonInfos = false;
      state.seasonInfos = payload;
      if (state.seasonInfos.status !== 200 && state.dataPost.title) {
        /* No action if status === 200, so we check for errors only */
        notify(state.dataPost.title, 0);
      }
    },

    [getSeasonList.pending]: (state) => {
      state.loading_getSeasonList = true;
    },
    [getSeasonList.fulfilled]: (state, { payload }) => {
      state.loading_getSeasonList = false;
      state.seasonList = payload;
      if (payload?.status === 200) {
        state.currentSeason = state.seasonList.data !== undefined ? state.seasonList.data.find((item) => item.seasonType === 1) : undefined;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [editSeason.fulfilled]: (state, { payload }) => {
      state.loading_editFutureSeason = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Infos modifiées avec succes !", 1);
        state.refreshSeasonPageAfterUpdate = !state.refreshSeasonPageAfterUpdate;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [launchNewSeason.fulfilled]: (state, { payload }) => {
      state.loading_launchNewSeason = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Expédition correctement lancée !", 1);
        state.refreshSeasonPageAfterUpdate = !state.refreshSeasonPageAfterUpdate;
      }
    },
    [closeCurrentSeason.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Expédition correctement clôturée !", 1);
        state.refreshSeasonPageAfterUpdate = !state.refreshSeasonPageAfterUpdate;
      }
    },
    [updateTeamName.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Nom d'équipe modifié avec succès !", 1);
        state.refreshRankingPageAfterUpdate = !state.refreshRankingPageAfterUpdate;
      }
    },
    [postTeamPicture.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Photo d'équipe modifiée avec succès !", 1);
        state.refreshRankingPageAfterUpdate = !state.refreshRankingPageAfterUpdate;
      }
    },
  },
});
export const { changeSelectedSeasonIdForRank } = seasonSlice.actions;
export default seasonSlice.reducer;
