import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getQuestListById } from "../../redux/slices/QuestSlice";
import { useNavigate } from "react-router-dom";
import { getQuestListSimpleFormatFromApiData } from "../questValidation/functions";
import PageLoadingFailNotification from "../PageLoadingFailNotification/PageLoadingFailNotification";
import { convertDateToLocalTimeWithHour } from "../../assets/utils/UtilsFunctons";
import { orderBy } from "lodash";

const UserQuests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { questListById } = useSelector((state) => ({ ...state.questInfos }));
  const { currentUserInfos } = useSelector((state) => ({ ...state.userInfos }));

  const handleSeeAllQuests = () => {
    navigate("/QuestToValidate/?userId=" + currentUserInfos?.userId);
  };

  useEffect(() => {
    if (currentUserInfos?.userId) {
      dispatch(getQuestListById(currentUserInfos?.userId));
    }
  }, [currentUserInfos]);

  const filterQuestsByUser = useCallback(
    (state) => {
      const newList = getQuestListSimpleFormatFromApiData(questListById?.data?.quests)?.concat(
        getQuestListSimpleFormatFromApiData(questListById?.data?.blocs.flatMap((item) => item.subQuests))
      );
      const filteredQuestByState = newList?.filter((quest) => quest.questState === state);
      return orderBy(filteredQuestByState, ["lastStateTransitionDate"]);
    },
    [questListById]
  );

  const renderQuests = useCallback((size = 5) => {
    let quests = [];
    let userQuests = filterQuestsByUser(1);

    for (let questTypes in userQuests) {
      quests.push(
        <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
          {userQuests[questTypes]?.title}
          <span className="d-block small opacity-50">{convertDateToLocalTimeWithHour(userQuests[questTypes]?.lastStateTransitionDate)}</span>
        </label>
      );
    }

    if (quests.length > size) {
      quests = quests.slice(0, size);
      quests.push(<span className="d-block small opacity-50">Et {quests.length - (size - 1)} autre(s)</span>);
    } else if (quests.length === 0) {
      quests.push(<span className="d-block">Aucune quête en attente de validation</span>);
    }
    return quests;
  }, []);

  const getNumberOfQuestInState = useCallback((state) => {
    const filteredQuests = filterQuestsByUser(state);

    return filteredQuests?.length;
  }, []);

  /* eslint react/no-unescaped-entities: off */
  return (
    <div>
      {questListById && currentUserInfos && questListById ? (
        <div className=" py-5">
          <div className="row align-items-md-stretch">
            <div className="col-md-6 col-sm-12">
              <div className="h-100 p-4 bg-light border rounded-3">
                <h5>Quêtes à valider</h5>
                <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">{renderQuests()}</div>
                <br />
                <button className="btn btn-primary" type="button" onClick={handleSeeAllQuests}>
                  Voir l'ensemble des quêtes
                </button>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="h-100 p-4 bg-light border rounded-3">
                <h5>Avancement des quêtes de l'expédition</h5>
                <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                    Quêtes terminées <span className="d-block small opacity-50">{getNumberOfQuestInState(3)}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                    Quêtes en attente de validation <span className="d-block small opacity-50">{getNumberOfQuestInState(1)}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                    Quêtes en réajustement :<span className="d-block small opacity-50">{getNumberOfQuestInState(2)}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                    Quêtes en cours :<span className="d-block small opacity-50">{getNumberOfQuestInState(0)}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                    Quêtes rejetées :<span className="d-block small opacity-50">{getNumberOfQuestInState(4)}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoadingFailNotification text={"La récupération des données a échoué"} />
      )}
    </div>
  );
};

export default UserQuests;
