import React from "react";
import coins from "../../assets/images/coins.png";
import cristaux from "../../assets/images/cristaux.png";
import gift_reward from "../../assets/images/gift_reward.png";

const RewardItem = ({ reward, displayRow }) => {
  return (
    <div className={displayRow ? "d-flex justify-content-between" : "d-flex flex-column"}>
      <span>
        <img src={coins} alt="coins" width={25} /> {reward?.coins}
      </span>
      <span>
        <img src={cristaux} alt="cristaux" width={25} /> {reward?.score}
      </span>
      <span>{reward?.gift && <img src={gift_reward} alt="gift" width={25} />}</span>
    </div>
  );
};

export default RewardItem;
