import { React, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { generateTextAvatar, getFileTypeFromFileName } from "../../assets/utils/UtilsFunctons";
import { postLogByParentId } from "../../redux/slices/LogSlice";
import { useDispatch, useSelector } from "react-redux";
import LogDataService from "../../services/LogDataService";
import { refreshPageAfterPost } from "../../redux/slices/LogSlice";
import { notify } from "../../assets/utils/UtilsFunctons";
import { v4 as uuid } from "uuid";
import { CloseButton, Input } from "reactstrap";
import "./messageSender.css";

const MessageSender = ({ parentId, parentType }) => {
  const [img, setImg] = useState(null);
  const [file, setFile] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [message, setMessage] = useState("");
  const [postingMedia, setPostingMedia] = useState(false);
  const { loading_postLogByParentId } = useSelector((state) => ({ ...state.logs }));
  const [preview, setPreview] = useState(null);
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");

    const payloadText = {
      messageId: uuid(),
      parentId: parentId,
      userId: accounts[0]?.idTokenClaims?.oid,
      text: message,
      parentType: parentType,
    };

    if (img || file) {
      let payloadImg = {
        mediaUrl: img || file,
        userId: accounts[0]?.idTokenClaims?.oid,
        parentId: parentId,
        parentType: parentType,
      };
      setPostingMedia(true);

      try {
        await LogDataService.postLogFile(payloadImg);
        notify(1, img ? "image uploadée avec succès" : "document uploadé avec succès");
        setImg(null);
        setPreview(null);
        setFile(null);
        dispatch(refreshPageAfterPost());
        setPostingMedia(false);
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
        setPostingMedia(false);
      }
    }

    if (message?.length >= 2) {
      dispatch(postLogByParentId(payloadText));
    }
    if (!img && !file && message?.length < 2) {
      setWarningMessage("pas de contenu à envoyer !");
    }
  };

  const handleChange = (e) => {
    setImg(null);
    setFile(null);
    setWarningMessage(null);
    const file = e.target.files[0];
    const fileFormat = getFileTypeFromFileName(file);
    if (fileFormat !== "Inconnu") {
      if (file.type.match("image/*")) {
        setImg(e.target.files[0]);
        const reader = new FileReader();

        reader.onload = (e) => {
          setPreview(e.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        setFile(file);
      }
    } else {
      setWarningMessage("format de fichier non accepté !");
    }
  };

  const getConnectedUserInitials = () => {
    let mots = accounts[0]?.name?.split(" ");

    if (mots.length > 0) {
      let premiereLettrePremierMot = mots[0][0];

      let dernierMot = mots[mots.length - 1];
      let premiereLettreDernierMot = dernierMot[0];

      return premiereLettrePremierMot + premiereLettreDernierMot;
    } else {
      // La chaîne est vide ou ne contient aucun mot
      return "";
    }
  };

  return (
    <div className=" mt-3 pb-3  ">
      <form onSubmit={handleSubmit}>
        <br />
        <div className="d-flex flex-column align-items-center">
          <div className="justify-content-center align-content-center message-sender-container row">
            <div className="col-1">
              <img src={generateTextAvatar(getConnectedUserInitials())} alt="user-icon" className="rounded-circle user_img_msg" />
            </div>
            <div className="msgInput col-6">
              <div className="d-flex justify-content-between ">
                <div className="div1">
                  <Input
                    type="textarea"
                    rows={2}
                    block
                    placeholder="Ecrire un nouveau message"
                    onChange={(e) => {
                      setMessage(e?.target?.value);
                      setWarningMessage(null);
                    }}
                    value={message}
                    className="input-text"
                  ></Input>
                </div>
                <div className="send div2">
                  <input
                    type="file"
                    name=""
                    id="imgAttachment"
                    style={{ display: "none" }}
                    onChange={(e) => handleChange(e)}
                    accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .mp4, .avi, .mov, .xlsx, .csv, .mp3 .webp"
                  />
                  <label htmlFor="imgAttachment" className="imgAttachment">
                    <i className="bx bx-paperclip bx-rotate-90 fs-4"></i>
                  </label>
                </div>
              </div>
            </div>
            {preview && img && (
              <div className="mx-1 col-1 image-sender-preview">
                <img src={preview} alt="Aperçu du fichier" className="img-preview" />
                <div className="img-preview-close-button">
                  <CloseButton
                    onClick={() => {
                      setImg(null);
                      setPreview(null);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="mx-2 col-2">
              <button onClick={handleSubmit} className="message-sender-button" disabled={postingMedia || loading_postLogByParentId}>
                {postingMedia || loading_postLogByParentId ? (
                  <i className="bx bx-loader-alt bx-spin fs-3 send-message-icon"></i>
                ) : (
                  <i className="bx bx-paper-plane fs-3 send-message-icon"></i>
                )}
              </button>
            </div>
          </div>
          {file && (
            <div className="d-flex justify-content-center mt-2">
              <i className="bx bx-file fs-5"></i> &nbsp;{file?.name}
              <CloseButton
                className="mx-2"
                onClick={() => {
                  setFile(null);
                }}
              />
            </div>
          )}
          {warningMessage && <div className="  text-danger">{warningMessage}</div>}
        </div>
      </form>
    </div>
  );
};

export default MessageSender;
