import { React, useState } from "react";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { compareDates, formatDateUtcToLocalTimeUtcFormat, formatToISOString } from "../../assets/utils/UtilsFunctons";
import useHolidaysCalendar from "../../hooks/useHolidaysCalendar";
import { Alert, FormGroup, Label, Input, Button } from "reactstrap";

const PlanifyQuizConfigComponent = ({ quiz, handlePlanifyQuizConfig }) => {
  const { isConfirmed } = useConfirm();
  const { isHoliday } = useHolidaysCalendar();
  const [alertTextContent, setAlertTextContent] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [quizPlanificationDate, setQuizPlanificationDate] = useState({
    quizConfigId: quiz.quizConfigId,
    startDate: formatDateUtcToLocalTimeUtcFormat(quiz.startDate),
    endDate: formatDateUtcToLocalTimeUtcFormat(quiz.endDate),
  });

  const onDismiss = () => setIsAlertVisible(!isAlertVisible);

  const handleClick = async () => {
    if (areQuizPlanificationDateCorrect()) {
      let confirmed = await isConfirmed(" Souhaitez vous planifier ce quiz !");
      if (confirmed) {
        let payload = {
          quizConfigId: quizPlanificationDate.quizConfigId,
          startDate: formatToISOString(quizPlanificationDate.startDate),
          endDate: formatToISOString(quizPlanificationDate.endDate),
        };
        handlePlanifyQuizConfig(payload);
      }
    }
  };

  const handleQuizPlanificationDateChange = (event) => {
    setQuizPlanificationDate({
      ...quizPlanificationDate,
      [event.target.name]: event.target.value,
    });
  };

  const areQuizPlanificationDateCorrect = () => {
    let dateNow = new Date();
    let startDate = quizPlanificationDate.startDate;
    let endDate = quizPlanificationDate.endDate;
    // verifier si les dates sont non nulles
    if (startDate === null || endDate === null) {
      setIsAlertVisible(true);
      setAlertTextContent("Les dates ne doivent pas être nulles !");
      return 0;
    }

    // les dates doivent être supérieur à la date du jour
    if (compareDates(dateNow, startDate) || compareDates(dateNow, endDate)) {
      setIsAlertVisible(true);
      setAlertTextContent("Les dates doivent être postérieures à aujourd'hui !");
      return 0;
    }

    // startDate de but doit antérieure à endDate
    if (compareDates(startDate, endDate)) {
      setIsAlertVisible(true);
      setAlertTextContent("La date de début doit être antérieure à la date de fin !");
      return 0;
    }
    // verifjour férié
    if (isHoliday(startDate)) {
      setIsAlertVisible(true);
      setAlertTextContent("Le jour de début est férié !");
      return 0;
    }

    // sinon
    setIsAlertVisible(false);
    setAlertTextContent("");
    return 1;
  };

  return (
    <>
      <Alert color="danger" isOpen={isAlertVisible} toggle={onDismiss}>
        {alertTextContent}
      </Alert>
      <FormGroup>
        <Label for="position">
          Début <sup>*</sup>
        </Label>
        <Input
          type="datetime-local"
          name="startDate"
          id="startDate"
          onChange={handleQuizPlanificationDateChange}
          required
          autoComplete="off"
          defaultValue={quizPlanificationDate.startDate?.slice(0, 16)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="timeLimit">
          Fin <sup>*</sup>
        </Label>
        <Input
          type="datetime-local"
          name="endDate"
          id="endDate"
          onChange={handleQuizPlanificationDateChange}
          required
          autoComplete="off"
          defaultValue={quizPlanificationDate.endDate?.slice(0, 16)}
        />
      </FormGroup>

      <FormGroup>
        <Button color="success" onClick={handleClick} className="mt-3" type="submit">
          <span>
            <i className="bx bx-calendar fs-4"></i> &nbsp;Planifier
          </span>
        </Button>
      </FormGroup>
    </>
  );
};

export default PlanifyQuizConfigComponent;
