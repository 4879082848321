import http from "./http-common";

class QuestConfigDataService {
  getAllQuestConfigs() {
    return http.get("/questConfig");
  }

  addNewQuestConfigs(data) {
    return http.post("/questConfig", data);
  }

  updateQuestConfigs(data) {
    return http.put("/questConfig", data);
  }

  deleteQuestConfigs(data) {
    return http.delete("/questConfig", data);
  }

  addQuestSeasonconfig(data) {
    return http.post("/questConfig/seasonConfig", data);
  }

  updateQuestSeasonconfig(data) {
    return http.put("/questConfig/seasonConfig", data);
  }

  ActivateOrDesactivateQuestSeasonconfig(data) {
    return http.put("/questConfig/seasonConfig/enable", data);
  }

  deleteQuestConfig(questConfigId) {
    return http.delete("/questConfig?guid=" + questConfigId);
  }
  deleteQuestSeasonconfig(data) {
    return http.delete("/questConfig/seasonConfig?questConfigId=" + data.questConfigId + "&questSeasonConfigId=" + data.questSeasonConfigId + "");
  }
}
export default new QuestConfigDataService();
