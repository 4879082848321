import http from "./http-common";

class MissionSheetDataService {
  getMissionsList() {
    return http.get("/mission/list?fromBo=true");
  }

  getMissionDetailById(data) {
    return http.get("/mission?missionId=" + data + "&fromBo=true");
  }
  getMissionDetailByUserId(data) {
    return http.get("/mission?userId=" + data);
  }

  addNewMission(data) {
    return http.post("/mission", data);
  }

  updateMission(data) {
    return http.put("/mission", data);
  }

  setMissionSheetDecision(data) {
    return http.put("/mission/sheet/decision", data);
  }

  closeMission(data) {
    return http.put("/mission/close", data);
  }
}

export default new MissionSheetDataService();
