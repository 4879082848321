import React from "react";

import { formatDateUtcToLocalTime } from "../assets/utils/UtilsFunctons";

const MaintenancePage = () => {
  const endDate = localStorage.getItem("estimatedEndMaintenance");

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center" style={{ height: "60vh" }}>
        <div className="d-flex flex-column align-items-center">
          <div>
            <i className="bx bx-error-alt  text-danger" style={{ fontSize: "5em" }}></i>
          </div>
          <br />
          <div>
            <strong className="fs-4">Maintenance en cours ....</strong>
          </div>
          <div className="pt-1">
            {endDate !== null && endDate !== "null" && (
              <strong className="fs-6 text-muted ">La reprise du service est estimée vers : {formatDateUtcToLocalTime(endDate)}</strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
