import React from "react";
import { Alert } from "reactstrap";

const FormError = ({ errorText }) => {
  return (
    <Alert color="danger">
      <i className="bx bx-error text-danger mx-2 fs-5"></i>
      {errorText}
    </Alert>
  );
};

export default FormError;
