export const PepAxes = ["TECHNIQUE", "FONCTIONNEL", "METHODOLOGIE", "SOFT SKILLS"];
export const RessourcesStatus = [
  { label: "En validation", status: 0 },
  { label: "Public", status: 1 },
  { label: "Certifiée", status: 2 },
  { label: "Privée", status: 3 },
  { label: "Désactivé", status: 4 },
];

export const ACTION_PRIORITIES = [
  { label: "Basse", value: 0 },
  { label: "Moyenne", value: 1 },
  { label: "Haute", value: 2 },
];

export const ACTION_STATUS = [
  { label: "En cours", value: 0 },
  { label: "Terminée", value: 1 },
  { label: "Annulée", value: 2 },
];
