import React, { useEffect } from "react";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import "../FormConFirmDialog/formConfirmDialog.css";

const ConfirmModal = ({ desactivateConfirmBtn }) => {
  const { prompt = "", isOpen = true, proceed, cancel } = useConfirm();

  useEffect(() => {
    /* eslint @typescript-eslint/no-unused-vars: "off" */
    const handleKeydown = (e) => {
      if (proceed && isOpen && e.key === "Enter") {
        proceed();
      }
    };
  }, [proceed, isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={cancel} fade>
      <ModalBody className="confirmForm-content">
        <div className="text-align-center">
          <i className="bx bx-error-circle bx-tada confirmForm-icon"></i>
        </div>
        <div className="confirmForm-title">{prompt}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={proceed} className="ml-4" disabled={desactivateConfirmBtn}>
          Confirmer
        </Button>
        <Button color="secondary" onClick={cancel}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
