import { React, useState } from "react";
import AddNewMissonForm from "./AddNewMissonForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const AddNewMissionModal = ({ handleAddNewMission }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <Button color="success" onClick={toggle} data-bs-toggle="tooltip" data-bs-placement="top" title=" Ajout d'une nouvelle mission">
      <i className="bx bx-add-to-queue" style={{ fontSize: "1.5em" }}></i>&nbsp;Ajouter une mission
    </Button>
  );

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Ajout mission
        </ModalHeader>
        <ModalBody>
          <AddNewMissonForm handleAddNewMission={handleAddNewMission} modalVisibility={setModal}></AddNewMissonForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewMissionModal;
