import { React, useState, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import DigDayInfosCard from "./DigDayInfosCard";
import Select from "react-select";
import { Label, Col, FormGroup } from "reactstrap";

const UserDigDaysHistory = ({ digDays, userId }) => {
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const [filteredYear, setFilteredYear] = useState(currentYear);

  const digDayHistory = useCallback(() => {
    let history = digDays?.filter((digDay) => new Date(digDay?.date)?.getFullYear() == filteredYear);
    return history?.sort((a, b) => new Date(b?.date) - new Date(a?.date));
  }, [digDays, filteredYear]);

  const getFilterOptions = () => {
    let data = Array.from(digDays, (item) => new Date(item?.date)?.getFullYear());
    data.push(currentYear);
    data.sort((a, b) => b - a);
    let optionsList = [];
    if (!data) {
      return optionsList;
    } else {
      [...new Set(data)]?.map((item) => optionsList.push({ value: item, label: item }));

      return optionsList;
    }
  };

  const handleSeeAllUserDigDays = () => {
    navigate("/DigdaysPage/?userId=" + userId);
  };

  return (
    <div>
      <div>
        <FormGroup row className="align-item-center " style={{ minWidth: "350px" }}>
          <Label sm={3}>historique</Label>
          <Col sm={5}>
            <Select
              value={getFilterOptions().find((option) => option.value === filteredYear)}
              onChange={(e) => setFilteredYear(e?.value)}
              placeholder="Année"
              options={getFilterOptions()}
            />
          </Col>
          <Col>
            <div style={{ cursor: "pointer" }} onClick={handleSeeAllUserDigDays}>
              <i className="bx bx-link-external"></i>
            </div>
          </Col>
        </FormGroup>
        <div className="list-group d-grid gap-2 border-0 w-auto p-2 " style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}>
          {digDayHistory()?.map((digday, index) => (
            <DigDayInfosCard digDay={digday} key={index} />
          ))}
          {digDayHistory()?.length === 0 && <em className="text-center">Pas de digDay posé</em>}
        </div>
      </div>
    </div>
  );
};

export default UserDigDaysHistory;
