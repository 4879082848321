import { React, useState } from "react";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { formatDateUtcToLocalTimeUtcFormat, convertDateToLocalTimeWithHour, formatToISOString, compareDates } from "../../assets/utils/UtilsFunctons";
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, Row, Col, ButtonGroup, Badge, Alert } from "reactstrap";

const DisEnableQuestSeasonConfig = ({ item, handleActivateOrDesactivateQuestSeasonconfig }) => {
  const { isConfirmed } = useConfirm();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [newActivationDate, setNewActivationDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [activationType, setActivationType] = useState(item?.activationDate ? 2 : 1);

  const toggle = () => {
    setModal(!modal);
  };

  const onDismiss = () => {
    setIsAlertVisible(false);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn =
    item.isEnabled === true ? (
      <div className="toggleBtn">
        <button
          className="questSeasonItem-content statutToggle statutToggleActif "
          onClick={() => handleSubmit()}
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Rendre inactive cette expédition"
        >
          <i className="bx bxs-toggle-right"></i>
        </button>
      </div>
    ) : item.activationDate ? (
      <div className="toggleBtn">
        <button
          className="questSeasonItem-content statutToggle"
          onClick={() => toggle()}
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Rendre active cette expédition"
        >
          <i className="bx bx-stopwatch"></i>
          <Badge>{convertDateToLocalTimeWithHour(item.activationDate)}</Badge>
        </button>
      </div>
    ) : (
      <div className="toggleBtn">
        <button
          className="questSeasonItem-content statutToggle"
          onClick={() => toggle()}
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Rendre active cette expédition"
        >
          <i className="bx bxs-toggle-left"></i>
        </button>
      </div>
    );
  const handleSubmit = async (e) => {
    if (!item.isEnabled) {
      e.preventDefault();
    }

    if (isInputValid()) {
      const text = item.isEnabled === true ? "Souhaitez vous desactivez cette quête d'expédition ?" : "Souhaitez vous activez cette quête d'expédition ?";
      const confirmed = await isConfirmed(text);
      if (confirmed) {
        const payload = {
          questSeasonConfigId: item.questSeasonConfigId,
          isActivated: activationType === 1 ? !item.isEnabled : item.isEnabled,
          activationDate: activationType === 2 ? formatToISOString(newActivationDate) : null,
        };
        handleActivateOrDesactivateQuestSeasonconfig(payload);
      }
    }
  };

  const handleReset = () => {
    toggle();
  };
  const isInputValid = () => {
    // in case of delayed activation check if an activation date is given
    if (activationType === 2 && !newActivationDate) {
      setAlertText("Veuillez donner une date d'activation.");
      setIsAlertVisible(true);
      return 0;
    }
    // in case of delayed activation check if the given activation date is greater than today
    if (activationType === 2 && item.expirationDate && compareDates(newActivationDate, item.expirationDate)) {
      setAlertText("Veuillez donner une date d'activation antérieure à la date d'expiration.");
      setIsAlertVisible(true);
      return 0;
    }
    // in case of delayed activation check if the given activation date is lower than the expiration date
    if (activationType === 2 && compareDates(new Date(), newActivationDate)) {
      setAlertText("Veuillez donner une date d'activation postérieure à la date du jour.");
      setIsAlertVisible(true);
      return 0;
    }

    return 1;
  };

  return (
    <div>
      <ConfirmModal></ConfirmModal>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {item.isEnabled ? "Désactivation de la quête" : "Activation de la quête"}
        </ModalHeader>
        <ModalBody>
          <ButtonGroup className="d-flex justify-content-center mt-3 ">
            <Button color="primary" block outline onClick={() => setActivationType(1)} active={activationType === 1}>
              activation immédiate
            </Button>
            <Button color="primary" block outline onClick={() => setActivationType(2)} active={activationType === 2}>
              activation différée
            </Button>
            <Button
              color="primary"
              block
              outline
              onClick={() => setActivationType(3)}
              active={activationType === 3}
              disabled={!item.isEnabled && !item.activationDate}
            >
              désactivation
            </Button>
          </ButtonGroup>
          <small id="emailHelp" className="form-text text-muted ">
            {activationType === 1
              ? "La quête devient active immédiatement."
              : activationType === 2
              ? "La quête devient active une fois que la date spécifiée est atteinte."
              : "La quête sera désactivée immédiatement."}
          </small>
          <Alert isOpen={isAlertVisible} toggle={onDismiss} color="danger">
            {alertText}
          </Alert>
          <form onSubmit={handleSubmit} onReset={handleReset} className="mt-3">
            {item.expirationDate ? (
              <FormGroup>
                <Label for="expirationDate">Date d&apos;expiration</Label>
                <Input
                  type="datetime-local"
                  name="expirationDate"
                  id="expirationDate"
                  required
                  readOnly
                  disabled
                  defaultValue={formatDateUtcToLocalTimeUtcFormat(item.expirationDate)}
                />
              </FormGroup>
            ) : (
              <br />
            )}
            {activationType === 2 ? (
              <FormGroup>
                <Label for="expirationDate">Date d&apos;activation</Label>
                <Input
                  type="datetime-local"
                  name="expirationDate"
                  id="expirationDate"
                  required
                  onChange={(e) => setNewActivationDate(e.target.value)}
                  defaultValue={formatDateUtcToLocalTimeUtcFormat(item.activationDate)}
                />
              </FormGroup>
            ) : (
              <br />
            )}
            <Row>
              <Col md={6}>
                <Button color="danger" type="reset" block>
                  Annuler
                </Button>
              </Col>
              <Col md={6}>
                <Button color={activationType === 3 ? "warning" : "success"} type="submit" block>
                  {activationType === 3 ? "Désactiver" : "Valider"}
                </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DisEnableQuestSeasonConfig;
