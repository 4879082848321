import React, { useState } from "react";
import QuizRanksContents from "./QuizRanksContents";
import QuizQuestionAccordion from "./QuizQuestionAccordion";
import { Button, ButtonGroup, Row } from "reactstrap";

const DoneAndPastQuizContentComponent = ({ quiz, handleQuizConfigContent, disableButton }) => {
  const [rSelected, setRSelected] = useState(1);
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-center">
        <ButtonGroup>
          <Button color="primary" outline onClick={() => setRSelected(1)} active={rSelected === 1}>
            Voir les questions
          </Button>
          <Button color="primary" outline onClick={() => setRSelected(2)} active={rSelected === 2}>
            Voir le classement
          </Button>
        </ButtonGroup>
      </div>
      <div>
        <div className="myCard mt-3">
          <Row>
            {rSelected === 1 ? (
              <QuizQuestionAccordion quiz={quiz} handleQuizConfigContent={handleQuizConfigContent} disableButton={disableButton} />
            ) : (
              <QuizRanksContents />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DoneAndPastQuizContentComponent;
