import { React, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const EditBlocConfigForm = (props) => {
  const [formValue, setformValue] = useState({
    blocId: props.item.blocId,
    name: props.item.name,
    isBlocVisibleByUser: props.item.isBlocVisibleByUser,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (props.label === "Edit") {
      props.updateOrDeleteBlocConfig(formValue);
      props.modalConfig(false);
    } else if (props.label === "Delete") {
      props.updateOrDeleteBlocConfig(formValue.blocId);
      props.modalConfig(false);
    }
  };

  const handleChange = (e) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleReset = () => {
    props.modalConfig(false);
  };

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <Row>
        <FormGroup>
          <Label for="boost">Nom Bloc</Label>
          <Input
            type="text"
            name="name"
            id="name"
            autoComplete="off"
            placeholder="name"
            required
            onChange={handleChange}
            defaultValue={formValue.name}
            disabled={props.label !== "Edit"}
          />
        </FormGroup>
      </Row>
      <br />
      <Row>
        <FormGroup>
          <Label for="isBlocVisibleByUser">Visible pour les users</Label>
          <Input
            type="select"
            name="isBlocVisibleByUser"
            id="isBlocVisibleByUser"
            onChange={handleChange}
            required
            autoComplete="off"
            disabled={props.label !== "Edit"}
            defaultValue={formValue.isBlocVisibleByUser}
          >
            <option value="" disabled={true} selected="selected">
              Choisir une catégorie
            </option>
            <option value={true}> Oui</option>
            <option value={false}> Non</option>
          </Input>
        </FormGroup>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          {props.label === "Edit" ? (
            <Button color="warning" type="submit" block>
              Modifier
            </Button>
          ) : (
            <Button color="success" type="submit" block>
              Supprimer
            </Button>
          )}
        </Col>
        <Col md={6}>
          <Button color="danger" type="reset" block>
            Annuler
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditBlocConfigForm;
