import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getShopTransactions } from "../redux/slices/ShopSlice";
import Loader from "../components/loader/Loader";
import { deliverShopTransaction, cancelShopTransaction, indeliveryShopTransaction, intreatmentShopTransaction } from "../redux/slices/ShopSlice";
import { Toaster } from "react-hot-toast";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import ShopTransactionsTable from "../components/Shop/shopTransactions/ShopTransactionsTable";

const ShopValidation = () => {
  let { loading_getshopTransactions, refreshAfterTransactionStateUpdate, shopTransactions } = useSelector((state) => ({ ...state.shopConfig }));
  const location = useLocation();
  const [currentUserId, setCurrentUserId] = useState(location.state ? location.state?.userId : null);
  const dispatch = useDispatch();

  useEffect(() => {
    /* eslint @typescript-eslint/no-unused-vars: "off" */
    let isMounted = true;
    dispatch(getShopTransactions());
    if (location.state) {
      const { userId } = location?.state;
      setCurrentUserId(userId);
    } else {
      setCurrentUserId(null);
    }
    // Clean-up:
    return () => {
      isMounted = false;
    };
  }, [refreshAfterTransactionStateUpdate]);

  const handleDeliverShopTransaction = (data) => {
    dispatch(deliverShopTransaction(data));
  };
  const handleCancelShopTransaction = (data) => {
    dispatch(cancelShopTransaction(data));
  };
  const handleindeliveryShopTransaction = (data) => {
    dispatch(indeliveryShopTransaction(data));
  };
  const handleIntreatmentShopTransaction = (data) => {
    dispatch(intreatmentShopTransaction(data));
  };
  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="page-header">Liste des transactions de la boutique</h2>
        </div>
      </div>
      <ConfirmContextProvider>
        <div className="row">
          {loading_getshopTransactions ? (
            <Loader></Loader>
          ) : shopTransactions && shopTransactions.data ? (
            <ShopTransactionsTable
              deliverShopTransaction={handleDeliverShopTransaction}
              cancelShopTransaction={handleCancelShopTransaction}
              indeliveryShopTransaction={handleindeliveryShopTransaction}
              intreatmentShopTransaction={handleIntreatmentShopTransaction}
              userId={currentUserId}
            ></ShopTransactionsTable>
          ) : (
            <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
          )}
        </div>
      </ConfirmContextProvider>
    </div>
  );
};

export default ShopValidation;
