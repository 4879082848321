import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../loader/Loader";
import PageLoadingFailNotification from "../../PageLoadingFailNotification/PageLoadingFailNotification";
import { getDigDaysByUserId } from "../../../redux/slices/DigDaysSlice";
import DigDayInfosCard from "../../digDays/DigDayInfosCard";

const PepUserActionDigDays = ({ userAction }) => {
  const dispatch = useDispatch();
  const { loading_getDigDaysByUserId, digDaysByUserId } = useSelector((state) => ({ ...state.digDays }));

  const userActionDigDaysDetails = useMemo(() => {
    if (digDaysByUserId && digDaysByUserId.data) {
      return digDaysByUserId.data.filter((digDay) => userAction?.digDayIds.includes(digDay.digDayId));
    } else {
      return [];
    }
  }, [digDaysByUserId, userAction]);

  useEffect(() => {
    if (userAction?.userId) {
      dispatch(getDigDaysByUserId(userAction?.userId));
    }
  }, [userAction, dispatch]);
  return loading_getDigDaysByUserId ? (
    <Loader />
  ) : digDaysByUserId && digDaysByUserId.data ? (
    <div className="list-group d-grid gap-2 border-0 w-auto p-2 " style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}>
      {userActionDigDaysDetails?.map((digday, index) => (
        <DigDayInfosCard digDay={digday} key={index} />
      ))}
      {userActionDigDaysDetails?.length === 0 && <em className="text-center">Pas de digDay posé</em>}
    </div>
  ) : (
    <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
  );
};

export default PepUserActionDigDays;
