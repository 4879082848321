import React from "react";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { Button } from "reactstrap";

const DeleteButtonWithConfirmation = ({ children, tooltipText, isDisabled, handleDeleteConfirm, payload }) => {
  const { isConfirmed } = useConfirm();
  const handleDeleteButton = async () => {
    const confirmed = await isConfirmed(children);

    if (confirmed) {
      handleDeleteConfirm(payload);
    }
  };
  return (
    <>
      <div data-bs-toggle="tooltip" data-bs-placement="top" title={tooltipText}>
        <Button color={"danger"} outline className="quest-validation-button fs-4" onClick={handleDeleteButton} disabled={isDisabled}>
          <i className="bx bxs-trash" />
        </Button>
      </div>
    </>
  );
};

export default DeleteButtonWithConfirmation;
