import { React, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import QuestDataService from "../services/QuestDataService";
import { getQuestList } from "../redux/slices/QuestSlice";
import { getUsersInfos } from "../redux/slices/UserSlice";
import QuestValidationTable from "../components/table/QuestValidationTable";
import { v4 as uuid } from "uuid";
import { Toaster } from "react-hot-toast";
import { Spinner } from "reactstrap";
import "../components/questValidation/questValidation.css";
import { useLocation } from "react-router-dom";
import { notify } from "../assets/utils/UtilsFunctons";

const QuestToValidate = () => {
  const { accounts } = useMsal();

  const dispatch = useDispatch();
  const location = useLocation();
  const { refreshQuestPageAfterDecisionUpdating, loading_getQuestList } = useSelector((state) => ({ ...state.questInfos }));
  const { loading_getUsersInfos, usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const [currentUserId, setCurrentUserId] = useState(location.state ? location.state?.userId : null);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    dispatch(getQuestList());
    if (location.state) {
      const { userId } = location?.state;
      setCurrentUserId(userId);
    } else {
      setCurrentUserId(null);
    }
    dispatch(getUsersInfos());
  }, [dispatch, refreshQuestPageAfterDecisionUpdating, refreshPage]);

  const postQuestDecisionHandler = async (formValue, closeModal) => {
    let questValidationContract = {
      questId: formValue.questId,
      decidedQuestState: formValue.decidedQuestState,
    };
    if (formValue.message.length > 0) {
      questValidationContract = {
        questId: formValue.questId,
        decidedQuestState: formValue.decidedQuestState,
        message: {
          messageId: uuid(),
          parentId: formValue.questId,
          userId: accounts[0].localAccountId,
          text: formValue.message,
        },
      };
    }

    try {
      if (questValidationContract) {
        await QuestDataService.postQuestDecision(questValidationContract);
        notify(1, "Modification apportée avec succés !");
        setRefreshPage(!refreshPage);
      }
    } catch (error) {
      notify(0, error?.response.data.title ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <h3 className="page-header">Quêtes à valider</h3>
      <div className="row">
        <div className="col-12">
          <div>
            {loading_getQuestList || loading_getUsersInfos ? (
              <div className="quest-validation-spinner">
                <Spinner color="secondary">Loading...</Spinner>
              </div>
            ) : (
              <>
                <QuestValidationTable
                  users={usersInfos}
                  userId={currentUserId}
                  postQuestDecision={postQuestDecisionHandler}
                  refreshMainPage={setRefreshPage}
                  refreshPage={refreshPage}
                ></QuestValidationTable>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestToValidate;
