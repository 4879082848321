import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getShopTransactionsById } from "../../redux/slices/ShopSlice";
import { convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";
import Enums from "../../assets/JsonData/Enums";
import { Badge } from "reactstrap";
import { orderBy } from "lodash";
import PageLoadingFailNotification from "../PageLoadingFailNotification/PageLoadingFailNotification";

const UserPurchases = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userTotalPurchase = 0;
  const { shopTransactionsById } = useSelector((state) => ({ ...state.shopConfig }));

  const getShopTransactionsByUserId = () => {
    let transactions = [];

    if (shopTransactionsById && shopTransactionsById.data) {
      transactions = shopTransactionsById.data;
    }

    userTotalPurchase = transactions.length;
    const sortedTransactions = orderBy(transactions, ["transactionNumber"], ["desc"]);

    return sortedTransactions?.slice(0, 5);
  };

  const handleSeeAllPurchases = () => {
    navigate("/ShopValidation/" + user.firstName + "-" + user.lastName, { state: { userId: user.userId } });
  };

  useEffect(() => {
    if (user && user?.userId) {
      dispatch(getShopTransactionsById(user?.userId));
    }
  }, []);

  /* eslint react/jsx-key: "off", react/no-unescaped-entities: "off" */
  return (
    <div>
      {user && shopTransactionsById && shopTransactionsById?.data ? (
        <div className="container py-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                <label className="list-group-item rounded-3 py-3" for="listGroupCheckableRadios1">
                  Or actuel
                  <span className="d-block small opacity-50">
                    {user?.coins} <i className="bx bx-coin-stack"></i>
                  </span>
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                <label className="list-group-item rounded-3 py-3" for="listGroupCheckableRadios1">
                  Or total gagné
                  <span className="d-block small opacity-50">-</span>
                </label>
              </div>
            </div>
          </div>

          <div className="row align-items-center py-4">
            <div className="col-md-9">
              <div className="h-100 p-5 bg-light border rounded-3">
                <h6>Dernier(s) achat(s) effectué(s) </h6>
                <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                  {getShopTransactionsByUserId()?.map((trans, index) => (
                    <label className="list-group-item rounded-3 py-3" for="listGroupCheckableRadios1" key={index}>
                      <span className="d-flex justify-content-between">
                        <span>{trans.itemName}</span>
                        <span>{convertDateToLocalTime(trans.purchaseDate)}</span>
                      </span>
                      <span className="d-flex justify-content-between">
                        <span className="d-block small opacity-50">
                          {trans.cost} <i className="bx bx-coin-stack"></i>
                        </span>
                        <span className="d-block small ">
                          <Badge
                            color={
                              trans.transactionState === 0
                                ? "primary"
                                : trans.transactionState === 1
                                ? "warning"
                                : trans.transactionState === 2
                                ? "success"
                                : "danger"
                            }
                            className="fs-6"
                          >
                            {Enums.matchTransactionState(trans.transactionState)}
                          </Badge>
                        </span>
                      </span>
                    </label>
                  ))}
                  {userTotalPurchase === 0 ? "pas d'achat effectué !" : ""}
                </div>
                <br />
                <button className="btn btn-primary" type="button" onClick={handleSeeAllPurchases} disabled={userTotalPurchase === 0}>
                  Voir l'ensemble des achats
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoadingFailNotification text={"La récupération des données a échoué"} />
      )}
    </div>
  );
};

export default UserPurchases;
