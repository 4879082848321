import React from "react";
import UserQuizRankDetailModal from "./UserQuizRankDetailModal";
import { convertDateToLocalTimeWithHour } from "../../assets/utils/UtilsFunctons";
import SortAndSearchTable from "../table/sortAndSearchTable/SortAndSearchTable";

const QuizRanksContentsTable = ({ data }) => {
  const columns = [
    {
      Header: "Rang",
      accessor: "rank",
    },
    {
      Header: "Prénom & Nom ",
      accessor: (row) => row.userFirstName + " " + row.userLastName,
    },
    {
      Header: "Score",
      accessor: "totalScore",
    },
    {
      Header: "Lancement",
      accessor: "startDate",
      Cell: ({ value }) => convertDateToLocalTimeWithHour(value),
    },

    {
      Header: "temps de réponse ",
      accessor: "fullAnswerTime",
    },
    {
      Header: " Bonne réponses",
      accessor: "goodAnswerCount",
    },
    {
      Header: " Action",
      // accessor: "goodAnswerCount",
      Cell: ({ row }) => <UserQuizRankDetailModal data={row.original} />,
    },
  ];

  const tableHooks = () => {
    "";
  };
  const handleRowClicked = () => {
    "";
  };
  return (
    <div>
      <div>
        <SortAndSearchTable data={data} columns={columns} tableHooks={tableHooks} refresh={data} handleRowClicked={handleRowClicked}></SortAndSearchTable>
      </div>
    </div>
  );
};

export default QuizRanksContentsTable;
