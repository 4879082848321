import React, { useState } from "react";
import CustomInput from "./CustomInput";

const EditableText = ({ defaultValue, handleChange, activateItem }) => {
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };

  const save = (val) => {
    setEdit(false);
    handleChange(val, defaultValue);
  };

  const close = () => {
    setEdit(false);
  };

  function CheckDefaultIcon() {
    return (
      <span onClick={handleEdit} style={{ marginLeft: "10px", color: "gray", cursor: "pointer" }}>
        <i className="bx bx-pencil fs-5"></i>
      </span>
    );
  }

  return (
    <div>
      {!edit ? (
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <p style={{ cursor: "pointer" }} onClick={() => activateItem(defaultValue)}>
            {defaultValue.title} <small className="text-muted">[{defaultValue?.nbChildren}]</small>
          </p>
          <CheckDefaultIcon />
        </div>
      ) : (
        <CustomInput defaultValue={defaultValue.title} saveText={save} cancelEdit={close} />
      )}
    </div>
  );
};

export default EditableText;
