import React from "react";
import ReactStars from "react-stars";
import Enums from "../../assets/JsonData/Enums";
import { convertDateToLocalTime, truncate } from "../../assets/utils/UtilsFunctons";
import DigDayDurationAndLocation from "./DigDayDurationAndLocation";

const DigDayInfosCard = ({ digDay, fullSize, onFocus, isClickable }) => {
  return (
    <div
      className="list-group-item rounded-3 py-3"
      style={{
        maxWidth: fullSize ? "500px" : "352px",
        minWidth: "300px",
        maxHeight: fullSize ? "100%" : "200px",
        border: `${onFocus ? "2px" : "1px"} solid ${onFocus ? "#2f528f" : "grey"}`,
        background: `${onFocus ? "aliceblue" : ""} `,
        cursor: isClickable ? "pointer" : "",
      }}
    >
      <div className="" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className="header d-flex justify-content-between" style={{ flex: "1" }}>
          <div className="title">
            <strong>{truncate(digDay.title, 55)}</strong>
          </div>
          <div className="state">{Enums.matchDigDayState(digDay.state)}</div>
        </div>
        <div className="description pt-1 text-break" style={{ flex: "2", fontSize: "14px", flexWrap: "wrap" }}>
          {fullSize ? digDay.description : truncate(digDay.description, 113)}
        </div>
        <div className="footer d-flex justify-content-between pt-1 align-items-center" style={{ flex: "1" }}>
          <div className="date-location d-flex justify-content-between">
            <div>
              <i className="bx bx-calendar-alt"></i>&nbsp;
              {convertDateToLocalTime(digDay.date)}
            </div>
            <div className="mx-2">
              <DigDayDurationAndLocation digDay={digDay} />
            </div>
          </div>
          <div className="rating">{digDay.rating ? <ReactStars count={5} size={24} value={digDay.rating} edit={false} /> : ""}</div>
        </div>
      </div>
    </div>
  );
};

export default DigDayInfosCard;
