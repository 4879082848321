import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SortAndSearchTable from "../table/sortAndSearchTable/SortAndSearchTable";
import { convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";
import Enums from "../../assets/JsonData/Enums";

export const MissionSheetListTable = () => {
  const navigate = useNavigate();
  const { missionsList } = useSelector((state) => ({ ...state.missionSheet }));

  const columns = [
    {
      Header: "Début de mission",
      accessor: "startDate",
      Cell: ({ value }) => (value ? convertDateToLocalTime(value) : ""),
    },
    {
      Header: "Statut de la fiche",
      accessor: "lastMissionSheetState",
      Cell: ({ value }) => Enums.matchMissionSheetState(value),
    },
    {
      Header: "Collaborateur",
      accessor: "consultantFullName",
    },
    {
      Header: "Mission",
      accessor: "consultantRole",
    },

    {
      Header: "Client",
      accessor: "clientName",
    },
    {
      Header: "Versions",
      accessor: "numberOfVersions",
    },
    {
      Header: "Statut mission",
      accessor: "endDate",
      Cell: ({ value }) => (value === null || value > new Date() ? "En cours" : "Terminée"),
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off", @typescript-eslint/no-unused-vars: "off" */
  const tableHooks = (hooks) => {};
  const handleRowClicked = (row) => {
    navigate("/MissionSheetDetailPage/" + row.original.missionId, { state: { consultantName: row.original.consultantFullName } });
  };

  return (
    <div>
      {missionsList.data ? (
        <SortAndSearchTable
          data={missionsList.data}
          columns={columns}
          tableHooks={tableHooks}
          refresh={""}
          handleRowClicked={handleRowClicked}
        ></SortAndSearchTable>
      ) : (
        ""
      )}
    </div>
  );
};
