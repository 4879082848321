import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import http from "../services/http-common";

const useGetLogById = (logId, refresh) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  const { refreshPageAfterLogPost } = useSelector((state) => ({ ...state.logs }));

  useEffect(() => {
    let isMounted = true; // Variable pour vérifier si le composant est monté

    const fetchData = async () => {
      try {
        setLoaded(false);
        const response = await http.get(`/log?parentId=${logId}`);

        if (isMounted) {
          setData(response.data);
          setError(null);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          setData(null);
        }
      } finally {
        if (isMounted) {
          setLoaded(true);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [refreshPageAfterLogPost, logId, refresh]);

  return { data, error, loaded };
};

export default useGetLogById;
