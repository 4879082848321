import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPepActions } from "../../../redux/slices/PepSlice";
import useUserRole from "../../../hooks/useUserRole";
import PepUserActionTable from "./PepUserActionTable";
import Loader from "../../loader/Loader";
import PageLoadingFailNotification from "../../PageLoadingFailNotification/PageLoadingFailNotification";
import NewPepActionModal from "../AddNewPepActionModal";

const PepUserActionComponent = () => {
  const { PepActionList, loading_getPepActions } = useSelector((state) => ({ ...state.pep }));
  const { isAdmin, isManager, hasAccessAsDeveloper } = useUserRole();
  const [updatePage, setUpdatePage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPepActions());
  }, [updatePage]);

  return loading_getPepActions ? (
    <Loader />
  ) : PepActionList && PepActionList.data ? (
    <div>
      <div className="d-flex justify-content-end">
        {(isAdmin || isManager || hasAccessAsDeveloper) && <NewPepActionModal updatePage={updatePage} setUpdatePage={setUpdatePage} />}
      </div>
      <div>
        <PepUserActionTable pepActions={PepActionList.data} />
      </div>
    </div>
  ) : (
    <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
  );
};

export default PepUserActionComponent;
