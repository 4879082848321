import React from "react";
import { useNavigate } from "react-router-dom";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const QuizDraftActionsControl = ({ handleDeleteQuiz, quiz }) => {
  const { isConfirmed } = useConfirm();
  const navigate = useNavigate();

  const handleDelete = async () => {
    let confirmed = await isConfirmed("souhaitez vous supprimer ce quiz ?");
    if (confirmed) {
      handleDeleteQuiz(quiz.quizConfigId);
    }
  };
  return (
    <div>
      <UncontrolledDropdown className="me-2" direction="start">
        <DropdownToggle color="primary">
          <i className="bx bx-dots-horizontal-rounded fs-5"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleDelete}>
            <i className="bx bx-trash"></i> Supprimer
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/QuizDetailledPage/" + quiz.quizConfigId, { state: { quizConfigId: quiz.quizConfigId } })}>
            <i className="bx bx-edit-alt"></i> Modifier
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default QuizDraftActionsControl;
