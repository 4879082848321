import { React, useCallback, useEffect } from "react";
import Sidebar from "../sidebar/Sidebar";
import TopNav from "../topnav/TopNav";
import { ErrorBoundary } from "react-error-boundary";
import Routes from "../Routes";
import LoginPage from "../../pages/LoginPage";
import ErrorBoundaryPage from "../../pages/ErrorBoundaryPage";
import { useDispatch } from "react-redux";
import { getCurrentMaintenance } from "../../redux/slices/MaintenanceConfigSlice";
import useUserRole from "../../hooks/useUserRole";

const MainContainer = ({ props }) => {
  const { haveAccessToBo } = useUserRole();
  const dispatch = useDispatch();

  const fallBackRenderer = useCallback(({ resetErrorBoundary }) => {
    return (
      <ErrorBoundaryPage
        callBack={() => {
          resetErrorBoundary();
        }}
      />
    );
  }, []);

  useEffect(() => {
    dispatch(getCurrentMaintenance());
  }, []);

  return (
    <>
      {haveAccessToBo ? (
        <div className="layout">
          <div className="sideNavBar" id="tesst">
            <Sidebar {...props} />
          </div>
          <div className="layout__content">
            <div className="topNavBar">
              <TopNav />
            </div>
            <div className="layout__content-main">
              <ErrorBoundary fallbackRender={fallBackRenderer}>
                <Routes />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      ) : (
        <>
          <LoginPage accessRestriction={"Accès refusé !"} />
          {localStorage.clear()}
        </>
      )}
    </>
  );
};

export default MainContainer;
