import { React, useState } from "react";
import useConfirm from "../../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../../modals/FormConfirmDialogModal";
import FormError from "../../form-error/FormError";
import { convertDateToLocalTime, compareDates, formatToISOString } from "../../../assets/utils/UtilsFunctons";
import { FormGroup, Label, Input, FormText, Row, Col, Button, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";

const CloseMissionForm = ({ missionSheet, consultantName, handleCloseMission }) => {
  const [closeMissionDate, setCloseMissionDate] = useState(null);
  const [errorText, setErrorText] = useState(undefined);
  const [displayError, setDisplayError] = useState(false);
  const [isClosingMission, setIsClosingMission] = useState(false);
  const { isConfirmed } = useConfirm();
  const missionSheetStartDate = convertDateToLocalTime(missionSheet?.startDate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isInputCorrect()) {
      const confirmed = await isConfirmed(`Souhaitez-vous clôturer la mission de ${consultantName}`);
      if (confirmed) {
        setIsClosingMission(true);
        handleCloseMission(closeMissionDate);
      }
    } else {
      setDisplayError(true);
      setErrorText("veuillez entrer une date postérieure à la date de début de la mission");
    }
  };
  const handleChange = (value) => {
    if (value) {
      setCloseMissionDate(formatToISOString(value));
    }
  };

  const isInputCorrect = () => {
    return compareDates(closeMissionDate, missionSheet?.startDate);
  };
  return (
    <div>
      <ConfirmModal />
      <form onSubmit={handleSubmit}>
        {displayError && <FormError errorText={errorText} />}
        <ListGroup className="my-2 ">
          <ListGroupItem disabled>
            <ListGroupItemHeading>{consultantName}</ListGroupItemHeading>
            <ListGroupItemText>Début de mission : &nbsp; {missionSheetStartDate}</ListGroupItemText>
          </ListGroupItem>
        </ListGroup>
        <FormGroup>
          <Label for="startDate">
            Date de clôture de la mission<sup>*</sup>
          </Label>
          <Input type="date" name="startDate" id="startDate" onChange={(e) => handleChange(e.target.value)} required autoComplete="off" />
          <FormText>la date de clôture doit être postérieure à la date de debut de la mission.</FormText>
        </FormGroup>
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="primary" type="submit" block disabled={false}>
              {isClosingMission ? <i className="bx bx-dots-horizontal-rounded bx-flashing"></i> : "clôturer"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default CloseMissionForm;
