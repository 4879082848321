import { React, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Enums from "../../assets/JsonData/Enums";
import QuestValidationActions from "../questValidation/QuestValidationActions";
import { getQuestListSimpleFormatFromApiData } from "../questValidation/functions";
import QuestSerialValidationModal from "../questValidation/QuestSerialValidationModal";
import QuestValidationFilterBar from "../questValidation/QuestValidationFilterBar";
import { questStateBadgeColor } from "../questValidation/functions";
import { Alert, Badge } from "reactstrap";
import TablePagination from "./table-pagination/TablePagination";

const QuestValidationTable = ({ postQuestDecision, userId, users, refreshMainPage, refreshPage }) => {
  const { questList } = useSelector((state) => ({ ...state.questInfos }));
  const [openedByDefault, setOpenedByDefault] = useState(false);
  const [questListFromRow, setQuestListFromRow] = useState([]);
  const [indexFromRow, setIndexFromRow] = useState(0);
  let stateList = [
    { nom: "En cours", state: 0 },
    { nom: "A valider", state: 1 },
    { nom: "En ajustement", state: 2 },
    { nom: "Validé", state: 3 },
    { nom: "Fermé", state: 4 },
  ];

  let filterByLowerQuestIteration = () => {
    const newList = getQuestListSimpleFormatFromApiData(questList?.data?.quests)?.concat(
      getQuestListSimpleFormatFromApiData(questList?.data?.blocs.flatMap((item) => item.subQuests))
    );
    return newList?.filter(
      (e) =>
        !newList.find(
          (qt) =>
            qt.title === e.title &&
            qt.userId === e.userId &&
            qt.questState === e.questState &&
            qt.questState === stateList.find(() => qt.state === 0) &&
            qt.questId !== e.questId &&
            qt.iteration < e.iteration
        )
    );
  };

  let compareDates = (a, b) => {
    // Gérer les cas où l'une des dates est vide
    if (!a.lastStateTransitionDate && !b.lastStateTransitionDate) return 0; // Les deux dates sont vides, pas de changement d'ordre
    if (!a.lastStateTransitionDate) return 1; // La date de 'a' est vide, 'b' vient avant
    if (!b.lastStateTransitionDate) return -1; // La date de 'b' est vide, 'a' vient avant

    return new Date(a.lastStateTransitionDate) - new Date(b.lastStateTransitionDate);
  };

  const [AllQuestsList] = useState(filterByLowerQuestIteration()?.sort(compareDates));

  const columns = [
    {
      Header: "Titre",
      accessor: "title",
    },
    {
      Header: "N° Exp.",
      accessor: "seasonNumber",
    },
    {
      Header: "Collab.",
      accessor: (row) => row.firstName + " " + row.lastName,
    },
    {
      Header: "Iterat°",
      accessor: "iteration",
    },
    {
      Header: "Etat",
      accessor: "questState",
      Cell: ({ value }) => {
        return <Badge color={questStateBadgeColor(value)}>{Enums.matchQuestStatut(value)}</Badge>;
      },
    },

    {
      Header: "Date",
      accessor: "lastStateTransitionDate",
      Cell: ({ value }) => {
        return value && new Date(value)?.toLocaleDateString("fr");
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        return (
          // <QuestDecisionButtonModal
          //   item={row.original}
          //   postQuestDecision={postQuestDecision}
          //   questStateBadgeColor={questStateBadgeColor}
          // ></QuestDecisionButtonModal>
          <QuestValidationActions quest={row.original} postQuestDecision={postQuestDecision}></QuestValidationActions>
        );
      },
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off", @typescript-eslint/no-unused-vars: "off" */
  const tableHooks = (hooks) => {};

  const handleRowClicked = (row) => {
    let listFromRow = AllQuestsList?.filter((quest) => {
      return quest.userId === row.original.userId && quest.title === row.original.title;
    });

    listFromRow?.sort((a, b) => a.iteration - b.iteration);

    let questRowIndex = listFromRow.length > 0 ? listFromRow.findIndex((quest) => quest.questId === row.original.questId) : 0;
    setOpenedByDefault(true);
    setIndexFromRow(questRowIndex >= 0 ? questRowIndex : 0);
    setQuestListFromRow(listFromRow);
  };

  const questStateItemDisplayValueFunc = useCallback((questSate) => {
    return <Badge color={questStateBadgeColor(questSate)}>{Enums.matchQuestStatut(questSate)}</Badge>;
  }, []);

  const userIdDisplayValueFunc = useCallback((userId) => {
    if (users && users.data) {
      let user = users.data.find((user) => user.userId === userId);
      return user ? user.firstName + " " + user.lastName : null;
    }
    return null;
  }, []);

  const getDefaultValueData = useCallback((data) => {
    if (data) {
      return data.filter((quest) => quest.questState === 1);
    }
    return null;
  }, []);

  return (
    <div>
      <div>
        {questList.data !== undefined ? (
          <>
            <QuestValidationFilterBar allQuests={AllQuestsList} refreshMainPage={refreshMainPage} refreshPage={refreshPage} />
            <div className="myCard">
              <TablePagination
                data={AllQuestsList ?? []}
                columns={columns}
                tableHooks={tableHooks}
                refresh={AllQuestsList}
                handleRowClicked={handleRowClicked}
                type={"default"}
                enableFilter={true}
                hasDefaultValue={true}
              >
                <element
                  key="questState"
                  filterKey="questState"
                  className="cell"
                  casesensitive={"true"}
                  showsearch={"true"}
                  isMulti
                  itemDisplayValueFunc={questStateItemDisplayValueFunc}
                  hasDefaultValue={true}
                  defaultValue={1}
                >
                  Statut
                </element>
                <element
                  key="userId"
                  filterKey="userId"
                  className="cell"
                  casesensitive={"true"}
                  showsearch={"true"}
                  itemDisplayValueFunc={userIdDisplayValueFunc}
                >
                  Collab.
                </element>
              </TablePagination>
            </div>
            {openedByDefault && (
              <QuestSerialValidationModal
                quests={questListFromRow}
                openedByDefault={openedByDefault}
                indexFromRow={indexFromRow}
                refreshMainPage={refreshMainPage}
                refreshPage={refreshPage}
              />
            )}
          </>
        ) : (
          <div className="py-3 ">
            <Alert color="danger">
              La récupération des données a echoué , veuillez ressayer en
              <a href="/QuestToValidate">
                <b> actualisant </b>
              </a>
              la page, ou vous reconnecter.
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestValidationTable;
