import http from "./http-common";

class LeagueConfigDataService {
  getAllLeagueConfigs() {
    return http.get("/league");
  }

  addNewLeagueConfigs(data) {
    return http.post("/league", data);
  }

  editLeagueConfigs(data) {
    return http.put("/league", data);
  }
  deleteLeagueConfigs(data) {
    return http.delete("/league", { params: { leagueId: data } });
  }

  postLeagueIconPicture(payload) {
    const formData = new FormData();
    formData.append("file", payload.file);

    return http.post(`/league/picture/icon?leagueId=${payload.leagueId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new LeagueConfigDataService();
