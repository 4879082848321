import { React, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Enums from "../../assets/JsonData/Enums";
import { convertDateToLocalTimeWithHour } from "../../assets/utils/UtilsFunctons";
import TablePagination from "../table/table-pagination/TablePagination";

const QuestConfigTable = ({ AllquestConfigs }) => {
  const { seasonList } = useSelector((state) => ({ ...state.seasonList }));
  const { blocConfigs } = useSelector((stateBloc) => ({ ...stateBloc.blocConfig }));
  const { enumList } = useSelector((state) => ({ ...state.enums }));
  const navigate = useNavigate();

  const getQuestFunctionName = (questFunc) => {
    if (!enumList || !enumList.data || !enumList.data.questFunction) {
      return "";
    }
    return enumList.data.questFunction[questFunc];
  };

  const getExpeditionNameFromId = (expeditionId) => {
    if (!expeditionId) {
      return "InterExpédition";
    }

    if (!seasonList || !seasonList.data) {
      return null;
    }

    if (seasonList && seasonList.data) {
      let seasonFound = seasonList.data.find((season) => season.seasonId === expeditionId);
      return seasonFound ? seasonFound.title : null;
    }
  };
  const columns = [
    {
      Header: "Titre",
      accessor: "title",
    },
    {
      Header: "Bloc",
      accessor: "blocName",
    },
    {
      Header: "ordre Bloc",
      accessor: "blocPosition",
    },
    {
      Header: "Catégorie",
      accessor: "category",
      Cell: ({ value }) => Enums.matchCategoryType(value),
    },

    {
      Header: "Thème",
      accessor: "theme",
    },
    {
      Header: "Type validation",
      accessor: "needsValidation",
      Cell: ({ value }) => (value ? "Manuelle" : "Automatique"),
    },
    {
      Header: "Fonction validation",
      accessor: "questFunction",
      Cell: ({ value }) => getQuestFunctionName(value),
    },
    {
      Header: "MaxProgress",
      accessor: "maxProgress",
    },
    {
      Header: "visible",
      accessor: "isVisibleByUsers",
      Cell: ({ value }) => Enums.getIscheckedIcon(value),
    },
    {
      Header: "Expédition",
      accessor: "seasonId",
      Cell: ({ value }) => getExpeditionNameFromId(value),
    },
    {
      Header: "Rôle",
      accessor: "role",
      Cell: ({ value }) => Enums.matchRoleType(value),
    },
    {
      Header: "MaxItération",
      accessor: "maxIteration",
    },
    {
      Header: "Or",
      accessor: "coins",
    },
    {
      Header: "Cristaux",
      accessor: "score",
    },
    {
      Header: "Cadeau",
      accessor: "gift",
      Cell: ({ value }) => Enums.getIscheckedIcon(value),
    },
    {
      Header: "Statut",
      accessor: "isEnabled",
      Cell: ({ value }) => Enums.getIscheckedIcon(value),
    },
    {
      Header: "Date activation",
      accessor: "activationDate",
      Cell: ({ value }) => convertDateToLocalTimeWithHour(value),
    },
    {
      Header: "Type expiration",
      Cell: ({ row }) => (row.original?.expirationDate ? "Date" : row.original?.expirationDuration ? "Durée" : ""),
    },
    {
      Header: "Expiration",
      accessor: (row) => row.expirationDate ?? row.expirationDuration,
      Cell: ({ value }) => convertDateToLocalTimeWithHour(value),
    },
  ];

  const tableHooks = () => {
    "";
  };
  const handleRowClicked = (row) => {
    navigate("/questConfigPage/" + row.original.questConfigId);
  };

  const getSeasonInfosFromSeasonId = (seasonId) => {
    if (!seasonId) {
      return "Interexpédition";
    }
    if (!seasonList || !seasonList.data) {
      return undefined;
    } else {
      let season = seasonList.data.find((season) => season.seasonId === seasonId);

      return season ? season.title : season;
    }
  };

  const getBlocNameFromBlocId = (blocId) => {
    if (!blocConfigs || !blocConfigs.data) {
      return null;
    } else {
      let findBloc = blocConfigs.data.find((bloc) => bloc.blocId === blocId);
      return findBloc ? findBloc.name : null;
    }
  };

  const transformQuestConfigData = useCallback((AllquestConfigs) => {
    let transformedData = [];
    if (!AllquestConfigs || !AllquestConfigs.data) {
      return transformedData;
    } else {
      AllquestConfigs.data.forEach((questConfig) => {
        if (questConfig.questSeasonConfigContracts.length === 0) {
          transformedData.push({
            blocId: questConfig.blocId,
            category: questConfig.category,
            description: questConfig.description,
            isVisibleByUsers: questConfig.isVisibleByUsers,
            listenedQuestConfigId: questConfig.listenedQuestConfigId,
            maxProgress: questConfig.maxProgress,
            needsValidation: questConfig.needsValidation,
            questConfigId: questConfig.questConfigId,
            questFunction: questConfig.questFunction,
            theme: questConfig.theme,
            title: questConfig.title,
            unlockedBy: questConfig.unlockedBy,
            year: questConfig.year,
            blocPosition: questConfig.blocPosition,
            blocName: getBlocNameFromBlocId(questConfig.blocId),
          });
        } else {
          questConfig.questSeasonConfigContracts.forEach((questSeasonConfig) => {
            transformedData.push({
              blocId: questConfig.blocId,
              category: questConfig.category,
              description: questConfig.description,
              isVisibleByUsers: questConfig.isVisibleByUsers,
              listenedQuestConfigId: questConfig.listenedQuestConfigId,
              maxProgress: questConfig.maxProgress,
              needsValidation: questConfig.needsValidation,
              questConfigId: questConfig.questConfigId,
              questFunction: questConfig.questFunction,
              theme: questConfig.theme,
              title: questConfig.title,
              unlockedBy: questConfig.unlockedBy,
              year: questConfig.year,
              blocPosition: questConfig.blocPosition,

              // questSeasonConfig
              activationDate: questSeasonConfig.activationDate,
              coins: questSeasonConfig.coins,
              expirationDate: questSeasonConfig.expirationDate,
              expirationDuration: questSeasonConfig.expirationDuration,
              gift: questSeasonConfig.gift,
              isEnabled: questSeasonConfig.isEnabled,
              maxIteration: questSeasonConfig.maxIteration,
              questSeasonConfigId: questSeasonConfig.questSeasonConfigId,
              role: questSeasonConfig.role,
              score: questSeasonConfig.score,
              seasonId: questSeasonConfig.seasonId,
              seasonInfos: getSeasonInfosFromSeasonId(questSeasonConfig.seasonId),
              blocName: getBlocNameFromBlocId(questConfig.blocId),
            });
          });
        }
      });
      return transformedData;
    }
  }, []);

  const [Alldata] = useState(transformQuestConfigData(AllquestConfigs));

  // fonction pour les itemDisplayValueFunc
  const expeditionItemDisplayValueFunc = useCallback((seasonId) => {
    return getSeasonInfosFromSeasonId(seasonId);
  }, []);
  const blocItemDisplayValueFunc = useCallback((blocId) => {
    return getBlocNameFromBlocId(blocId);
  }, []);
  const roleItemDisplayValueFunc = useCallback((role) => {
    return Enums.matchRoleType(role);
  }, []);
  const categoryItemDisplayValueFunc = useCallback((category) => {
    return Enums.matchCategoryType(category);
  }, []);

  const statusItemDisplayValueFunc = useCallback((status) => {
    return status ? "actif" : status === false ? "inactif" : " non défini";
  }, []);

  const visibleItemDisplayValueFunc = useCallback((isVisible) => {
    return isVisible ? "visible" : "non visible";
  }, []);

  return (
    <div>
      {Alldata && (
        <div>
          <TablePagination
            data={Alldata}
            columns={columns}
            tableHooks={tableHooks}
            refresh={Alldata}
            handleRowClicked={handleRowClicked}
            enableFilter={true}
            type={"default"}
            hasDefaultValue={true}
          >
            <element
              key="seasonId"
              filterKey="seasonId"
              className="cell"
              casesensitive={"true"}
              showsearch={"true"}
              isMulti
              itemDisplayValueFunc={expeditionItemDisplayValueFunc}
            >
              Expédition
            </element>
            <element
              key="blocId"
              filterKey="blocId"
              className="cell"
              casesensitive={"true"}
              showsearch={"true"}
              itemDisplayValueFunc={blocItemDisplayValueFunc}
            >
              Bloc
            </element>
            <element key="role" filterKey="role" className="cell" casesensitive={"true"} showsearch={"true"} itemDisplayValueFunc={roleItemDisplayValueFunc}>
              Rôle
            </element>
            <element
              key="category"
              filterKey="category"
              className="cell"
              casesensitive={"true"}
              showsearch={"true"}
              itemDisplayValueFunc={categoryItemDisplayValueFunc}
            >
              Catégorie
            </element>
            <element key="theme" filterKey="theme" className="cell" casesensitive={"true"} showsearch={"true"}>
              Thème
            </element>
            <element
              key="isVisibleByUsers"
              filterKey="isVisibleByUsers"
              className="cell"
              casesensitive={"true"}
              showsearch={"true"}
              itemDisplayValueFunc={visibleItemDisplayValueFunc}
            >
              Visible
            </element>
            <element
              key="isEnabled"
              filterKey="isEnabled"
              className="cell"
              casesensitive={"true"}
              showsearch={"true"}
              itemDisplayValueFunc={statusItemDisplayValueFunc}
            >
              Statut
            </element>
          </TablePagination>
        </div>
      )}
    </div>
  );
};
export default QuestConfigTable;
