import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LevelConfigDataService from "../../services/LevelConfigDataService";
import toast from "react-hot-toast";

const notify = (notif, state) => {
  switch (state) {
    case 1:
      toast.success(notif, { position: "top-right" });
      break;
    case 0:
      toast.error(notif, { position: "top-right" });
      break;
    default:
      toast.error("La requête a échoué !");
      break;
  }
};

export const getAllLevelConfigs = createAsyncThunk("levelConfigs/getAllLevelConfigs", async () => {
  try {
    const response = await LevelConfigDataService.getAllLevelConfigs();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});
export const addNewLevelConfigs = createAsyncThunk("levelConfigs/addNewLevelConfigs", async (data) => {
  try {
    const response = await LevelConfigDataService.addNewLevelConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const editLevelConfigs = createAsyncThunk("levelConfigs/editLevelConfigs", async (data) => {
  try {
    const response = await LevelConfigDataService.editLevelConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(21);
    return err.response.data;
  }
});

export const deleteLevelConfigs = createAsyncThunk("levelConfigs/deleteLevelConfigs", async (data) => {
  try {
    const response = await LevelConfigDataService.deleteLevelConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(41);
    return err.response.data;
  }
});

const LevelConfigSlice = createSlice({
  name: "levelConfigs",
  initialState: {
    levelConfigs: [1],
    loading_getLevelConfigs: true,
    loading_addLevelConfigs: true,
    dataPost: [],
    error: "",
    refreshLevelPageAfterUpdate: false,
  },
  reducers: {},
  extraReducers: {
    [getAllLevelConfigs.pending]: (state) => {
      state.loading_getLevelConfigs = true;
      console.log("pending");
    },
    [getAllLevelConfigs.fulfilled]: (state, { payload }) => {
      state.loading_getLevelConfigs = false;
      state.levelConfigs = payload;
      if (state.levelConfigs?.status === 200) {
        notify("Niveau récupéré avec succes !", 1);
      } else if (state.levelConfigs?.title) {
        notify(state.levelConfigs.title, 0);
      }
    },
    [addNewLevelConfigs.fulfilled]: (state, { payload }) => {
      state.loading_addLevelConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Niveau correctement ajouté !", 1);
        state.refreshLevelPageAfterUpdate = !state.refreshLevelPageAfterUpdate;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [editLevelConfigs.fulfilled]: (state, { payload }) => {
      state.loading_editLevelConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Infos modifiées avec succes !", 1);
        state.refreshLevelPageAfterUpdate = !state.refreshLevelPageAfterUpdate;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [deleteLevelConfigs.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Niveau supprimé !", 1);
        state.refreshLevelPageAfterUpdate = !state.refreshLevelPageAfterUpdate;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [getAllLevelConfigs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default LevelConfigSlice.reducer;
