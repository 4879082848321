import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeagueConfigs } from "../redux/slices/LeagueConfigSlice";
import { getAllLevelConfigs } from "../redux/slices/LevelConfigSlice";
import { getSeasonList } from "../redux/slices/SeasonSlice";
import { getUsersInfos } from "../redux/slices/UserSlice";
import LeagueTable from "../components/table/LeagueTable";
import LevelTable from "../components/table/LevelTable";
import { Toaster } from "react-hot-toast";

const LeagueAndLevelPage = () => {
  const { refreshLeaguePageAfterUpdate, leagueConfigs } = useSelector((stateLeague) => ({ ...stateLeague.leagueConfig }));
  const { refreshLevelPageAfterUpdate, levelConfigs } = useSelector((stateLevel) => ({ ...stateLevel.levelConfig }));
  const { seasonList } = useSelector((state) => ({ ...state.seasonList }));
  const [activeTab, setActiveTab] = useState("ligue_tab");
  let tabs = [
    { id: "ligue_tab", label: "Ligues" },
    { id: "niveau_tab", label: "Niveaux" },
  ];
  const dispatch = useDispatch();

  const doesExistACurrentSeason = () => {
    if (!seasonList || !seasonList.data) return false;
    else return seasonList?.data.some((season) => season.seasonType === 1);
  };

  const inStandBYPhaseOrNot = !doesExistACurrentSeason();
  useEffect(() => {
    dispatch(getAllLeagueConfigs());
    dispatch(getAllLevelConfigs());
    dispatch(getUsersInfos());
    dispatch(getSeasonList());
  }, [refreshLevelPageAfterUpdate, refreshLeaguePageAfterUpdate]);

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div>
        <ul className=" myCard d-flex flex-row ranking-nav" id="myTab" role="tablist">
          {tabs.map(({ id, label }) => (
            <li className="nav-item px-3 ranking-nav-item" key={id}>
              <p
                className={`mb-0 nav-link ${activeTab === id ? "active" : ""}`}
                role="tab"
                aria-selected={activeTab === id}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(id);
                }}
              >
                <a href="">{label}</a>
              </p>
            </li>
          ))}
        </ul>
      </div>
      {activeTab === "ligue_tab" && (
        <div
          className={` tab-pane fade ${activeTab === "ligue_tab" ? "show active" : ""}`}
          id={"ligue_tab"}
          role="tabpanel"
          aria-labelledby={`${"ligue_tab"}-tab`}
          key={"ligue_tab"}
        >
          <div>
            <h4 className="page-header">Gestion des ligues</h4>
          </div>
          <div>
            <div className="myCard">
              <div>{leagueConfigs && <LeagueTable inStandBYPhaseOrNot={inStandBYPhaseOrNot} />}</div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "niveau_tab" && (
        <div
          className={` tab-pane fade ${activeTab === "niveau_tab" ? "show active" : ""}`}
          id={"niveau_tab"}
          role="tabpanel"
          aria-labelledby={`${"niveau_tab"}-tab`}
          key={"niveau_tab"}
        >
          <div>
            <h4 className="page-header">Gestion des niveaux</h4>
          </div>
          <div>
            <div className="myCard">
              <div>{levelConfigs && <LevelTable inStandBYPhaseOrNot={inStandBYPhaseOrNot} />}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeagueAndLevelPage;
