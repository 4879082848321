import { React, useState } from "react";
import AddNewLeagueConfigForm from "../forms/AddNewLeagueConfigForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const AddNewLeagueConfigModal = ({ postLeagueConfig, sortedData }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <button className="add-questConf-btn" onClick={toggle}>
      <i className="bx bxs-plus-circle"></i>
    </button>
  );

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Ajout Nouvelle Ligue
        </ModalHeader>
        <ModalBody>
          <AddNewLeagueConfigForm postLeagueConfig={postLeagueConfig} sortedData={sortedData} modalConfig={setModal} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewLeagueConfigModal;
