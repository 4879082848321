import React from "react";
import DiscussionThread from "../../discussionPanel/DiscussionThread";
import useGetLogById from "../../../hooks/useGetlogById";
import Enums from "../../../assets/JsonData/Enums";
import Loader from "../../loader/Loader";

const ShopTransactionDiscussion = ({ logId, userId }) => {
  const { data, loaded } = useGetLogById(logId);

  return (
    <div>
      <div className="pt-2">
        <h6>
          <i className="bx bx-history thread-header-icon"></i> Historique
        </h6>
      </div>
      {!loaded ? (
        <Loader />
      ) : data ? (
        <div>
          <DiscussionThread log={data} userId={userId} stateTransition={Enums.matchTransactionState} />
        </div>
      ) : (
        <div>
          <span>
            <i>La récupération des données a échoué !</i>
          </span>
        </div>
      )}
    </div>
  );
};

export default ShopTransactionDiscussion;
