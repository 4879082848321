import { React, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPepActionsByUserId } from "../../../redux/slices/PepSlice";
import DigDaysPepActionCard from "./DigDaysPepActionCard";

const DigDaysPepActionComponent = ({ userId, pepActionList }) => {
  const dispatch = useDispatch();
  const { loading_getPepActionsByUserId, PepActionListByUserId } = useSelector((state) => ({ ...state.pep }));

  const getdidDayPepACtionsDetail = useMemo(() => {
    if (!pepActionList || !PepActionListByUserId) return [];
    return PepActionListByUserId?.data?.filter((pepAction) => pepActionList.includes(pepAction.id));
  }, [PepActionListByUserId, pepActionList]);

  useEffect(() => {
    dispatch(getPepActionsByUserId(userId));
  }, []);
  return (
    <div>
      {loading_getPepActionsByUserId ? (
        "Loading..."
      ) : (
        <div>
          {getdidDayPepACtionsDetail.map((pepAction) => {
            return (
              <div className="pt-1" key={pepAction.id}>
                <DigDaysPepActionCard pepAction={pepAction} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DigDaysPepActionComponent;
