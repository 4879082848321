import { React } from "react";
import { getQuestListSimpleFormatFromApiData } from "../questValidation/functions";
import { formatToISOString, notify } from "../../assets/utils/UtilsFunctons";
import EditableTable from "./EditableTable";
import { useDispatch, useSelector } from "react-redux";
import { editSeason } from "../../redux/slices/SeasonSlice";
import EditSeasonConfigModal from "../modals/EditSeasonConfigModal";
import { SeasonTypeConst } from "../../core/constant/seasonType";
import { Button, Alert } from "reactstrap";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import SeasonDataService from "../../services/SeasonDataService";

/* eslint react/no-unescaped-entities: "off" */
const SeasonTable = ({ seasonList, startNewSeason, closeSeason }) => {
  const { questList } = useSelector((state) => ({ ...state.questInfos }));
  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();
  const seasonConfTableHead = [
    "titre ",
    "Type ",
    "début",
    "Date limite",
    " releg. Explor. ||",
    " releg. Guide ||",
    " prom. Explor.&",
    " prom. Guide &",
    "Editer",
    "Action",
  ];
  const pastSeasonConfTableHead = ["titre ", "Type ", "début", "Date limite", " releg. Explor.", " releg. Guide", " prom. Explor.", " prom. Guide"];

  const launchOrcloseSeason = async (seasonType) => {
    let text = "";
    let unvalidatedQuests = getNumberOfQuestInWaintingForValidationState();
    if (seasonType === SeasonTypeConst.future) {
      text = (
        <span>
          <p>Souhaitez-vous Lancer cette nouvelle expédition ?</p>
          <div className="d-flex flex-column">
            <small> - lancement des nouvelles quêtes</small>
            {/* <small> - liste des avertissements à afficher (à définir)</small> */}
          </div>
        </span>
      );
    } else {
      text = (
        <span>
          <p>Souhaitez-vous clôturer l'expédition en cours ?</p>
          <div className="d-flex flex-column">
            {/*<small> - liste des avertissements à afficher (à définir)</small>*/}
            {unvalidatedQuests > 0 ? (
              <Alert color="danger">
                Il existe {unvalidatedQuests} quête(s) en attente de validation. <a href="/QuestToValidate">(afficher)</a>
              </Alert>
            ) : (
              ""
            )}
          </div>
        </span>
      );
    }
    let confirmed;
    confirmed = await isConfirmed(text);
    if (confirmed) {
      return seasonType === SeasonTypeConst.future ? startNewSeason() : closeSeason();
    }
  };

  const handleReshuffleTeams = async () => {
    let text = (
      <span>
        <p>Souhaitez-vous remanier les équipes de l'expédition ?</p>
      </span>
    );
    let confirmed = await isConfirmed(text);
    if (confirmed) {
      try {
        await SeasonDataService.refreshTeam();
        notify(1, "Les équipes ont été recomposées avec succés !");
      } catch (error) {
        notify(0, error?.response?.data?.title ?? "une erreur est survenue");
      }
    }
  };

  const concatAllQuestsInOneList = () => {
    if (!questList || !questList.data) return [];
    return getQuestListSimpleFormatFromApiData(questList?.data?.quests)?.concat(
      getQuestListSimpleFormatFromApiData(questList?.data?.blocs.flatMap((item) => item.subQuests))
    );
  };

  const getNumberOfQuestInWaintingForValidationState = () => {
    if (!questList || !questList.data) return 0;
    else return concatAllQuestsInOneList().filter((quest) => quest?.questState === 1 && quest.seasonId !== null).length;
  };

  const doesCurrentSeasonExist = () => {
    if (!seasonList) {
      return false;
    } else {
      if (seasonList) {
        return seasonList?.find((season) => season?.seasonType === SeasonTypeConst.current) !== undefined;
      }
      return false;
    }
  };

  const isCurrentSeasonSubmissionEndDateNotReached = (currentSeason) => {
    let todayDate = new Date().getTime();
    let currentSeasonEndDate = new Date(currentSeason.submissionEndDate).getTime();

    return todayDate < currentSeasonEndDate;
  };

  function getCurrentAndFutureSeasonInfos() {
    if (seasonList) {
      let seasons = [...seasonList];
      seasons = seasons?.filter((season) => season.seasonType === SeasonTypeConst.current || season.seasonType === SeasonTypeConst.future);
      seasons?.sort(function (a, b) {
        if (a.seasonType > b.seasonType) {
          return 1;
        }
        if (a.seasonType < b.seasonType) {
          return -1;
        }
        return 0;
      });
      return seasons;
    }
  }
  function getPastSeasonsInfos() {
    if (seasonList) {
      let seasons = [...seasonList];
      seasons = seasons?.filter((season) => season.seasonType === SeasonTypeConst.past);
      seasons?.sort(function (a, b) {
        if (a.seasonType > b.seasonType) {
          return 1;
        }
        if (a.seasonType < b.seasonType) {
          return -1;
        }
        return 0;
      });
      return seasons;
    }
  }

  const editSeasonConfig = (formValue) => {
    const payload = {
      submissionEndDate: formatToISOString(formValue.submissionEndDate),
      title: formValue.title,
      minConsultantScoreUpgradingLeague: parseInt(formValue.minConsultantScoreUpgradingLeague),
      maxConsultantScoreDowngradingLeague: parseInt(formValue.maxConsultantScoreDowngradingLeague),
      consultantPercentageDowngradingLeague: parseInt(formValue.consultantPercentageDowngradingLeague) / 100,
      consultantPercentageUpgradingLeague: parseInt(formValue.consultantPercentageUpgradingLeague) / 100,
      minGuideScoreUpgradingLeague: parseInt(formValue.minGuideScoreUpgradingLeague),
      maxGuideScoreDowngradingLeague: parseInt(formValue.maxGuideScoreDowngradingLeague),
      guidePercentageDowngradingLeague: parseInt(formValue.guidePercentageDowngradingLeague) / 100,
      guidePercentageUpgradingLeague: parseInt(formValue.guidePercentageUpgradingLeague) / 100,
      seasonType: parseInt(formValue.seasonType),
    };
    dispatch(editSeason(payload));
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderSeasonBody = (item, index) => (
    <tr key={index}>
      <td>{item.title}</td>
      {item.seasonType === SeasonTypeConst.future ? <td>Future</td> : item.seasonType === SeasonTypeConst.current ? <td>En cours</td> : <td>Passée</td>}
      <td>{item.startDate ? new Date(item.startDate).toLocaleDateString("fr") : ""}</td>
      <td>{item.submissionEndDate ? new Date(item.submissionEndDate).toLocaleDateString("fr") : ""}</td>
      <td>
        <span className="fs-6 text-danger">{"<="}</span> {item.maxConsultantScoreDowngradingLeague} <br /> <i className="bx bx-chevron-down text-danger fs-5" />{" "}
        {item.consultantPercentageDowngradingLeague * 100}%
      </td>
      <td>
        <span className="fs-6 text-danger">{"<="}</span>
        {item.maxGuideScoreDowngradingLeague} <br /> <i className="bx bx-chevron-down text-danger fs-5" /> {item.guidePercentageDowngradingLeague * 100}%
      </td>
      <td>
        <span className="fs-6 text-success">{">="}</span>
        {item.minConsultantScoreUpgradingLeague} <br /> <i className="bx bx-chevron-up text-success fs-5" /> {item.consultantPercentageUpgradingLeague * 100}%
      </td>
      <td>
        <span className="fs-6 text-success">{">="}</span>
        {item.minGuideScoreUpgradingLeague} <br /> <i className="bx bx-chevron-up text-success fs-5" /> {item.guidePercentageUpgradingLeague * 100}%
      </td>
      <td>
        {/* modification uniquement pour les expéditions en cours et futures */}
        {item.seasonType === SeasonTypeConst.current || item.seasonType === SeasonTypeConst.future ? (
          <EditSeasonConfigModal buttonLabel="Edit" item={item} editSeasonConfig={editSeasonConfig}></EditSeasonConfigModal>
        ) : (
          ""
        )}
      </td>
      <td>
        {item.seasonType !== SeasonTypeConst.past &&
          (item.seasonType === SeasonTypeConst.future ? (
            <div className="d-flex justify-content-around">
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={doesCurrentSeasonExist() ? "Une expédition est déjà en cours" : "Lancer l'expédition"}
              >
                <Button outline color={"success"} onClick={() => launchOrcloseSeason(item.seasonType)} disabled={doesCurrentSeasonExist()}>
                  Démarrer
                </Button>
              </div>
              <div data-bs-toggle="tooltip" data-bs-placement="top" title={" Remanier les équipes"}>
                <Button outline color="primary" onClick={() => handleReshuffleTeams()} disabled={doesCurrentSeasonExist()}>
                  <i className="bx bx-shuffle"></i>
                </Button>
              </div>
            </div>
          ) : (
            <Button
              outline
              color="danger"
              onClick={() => launchOrcloseSeason(item.seasonType)}
              disabled={isCurrentSeasonSubmissionEndDateNotReached(item)}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={isCurrentSeasonSubmissionEndDateNotReached(item) ? "Date limite de soumission pas encore atteinte" : "clôturer l'expédition en cours"}
            >
              Clôturer
            </Button>
          ))}
      </td>
    </tr>
  );

  return (
    <div>
      <ConfirmModal desactivateConfirmBtn={getNumberOfQuestInWaintingForValidationState() > 0}></ConfirmModal>
      {seasonList ? (
        <div>
          <div className="myCard">
            <EditableTable
              limit="10"
              striped={true}
              title="Liste des expéditions"
              headData={seasonConfTableHead}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={getCurrentAndFutureSeasonInfos() ?? []}
              renderBody={(item, index) => renderSeasonBody(item, index)}
            />
          </div>
          <br />
          <div className="myCard">
            {getPastSeasonsInfos().length !== 0 ? (
              <EditableTable
                limit="10"
                striped={true}
                title="expédition(s) passée(s)"
                headData={pastSeasonConfTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={getPastSeasonsInfos() ?? []}
                renderBody={(item, index) => renderSeasonBody(item, index)}
              />
            ) : (
              <p>Pas d'expéditons passées !</p>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default SeasonTable;
