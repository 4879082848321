import http from "./http-common";

class LevelConfigDataService {
  getAllLevelConfigs() {
    return http.get("/level");
  }

  addNewLevelConfigs(data) {
    return http.post("/level", data);
  }

  editLevelConfigs(data) {
    return http.put("/level", data);
  }

  deleteLevelConfigs(data) {
    return http.delete("/level", { params: { levelId: data } });
  }
}

export default new LevelConfigDataService();
