import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import useUserRole from "../hooks/useUserRole";
import PepUserActionComponent from "../components/pep/pep-gestion/PepUserActionComponent";
import PepConFigLayer from "../components/pep/pep-config/PepConFigLayer";

const PepPage = () => {
  const { isAdmin, isManager } = useUserRole();
  const [activeTab, setActiveTab] = useState("view-tab");
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    setTabs(
      isAdmin || isManager
        ? [
            { id: "view-tab", label: "Gestion", display: <PepUserActionComponent /> },
            { id: "config-tab", label: "Configuration", display: <PepConFigLayer /> },
          ]
        : [{ id: "view-tab", label: "Gestion", display: <PepUserActionComponent /> }]
    );
  }, [isAdmin, isManager]);

  return (
    <div>
      <h3 className="page-header">Gestion des PEP</h3>
      <div>
        <Toaster></Toaster>
      </div>

      <div>
        <ul className=" myCard d-flex flex-row ranking-nav" id="myTab" role="tablist">
          {tabs.map(({ id, label }) => (
            <li className="nav-item px-3 ranking-nav-item" key={id}>
              <p
                className={`mb-0 nav-link ${activeTab === id ? "active" : ""}`}
                role="tab"
                aria-selected={activeTab === id}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(id);
                }}
              >
                <a href="">{label}</a>
              </p>
            </li>
          ))}
        </ul>
      </div>
      {/* */}
      <div className=" myCard ranking-tab-display tab-content " id="myTabContent">
        {tabs.map(({ id, display }) => (
          <div className={` tab-pane fade ${activeTab === id ? "show active" : ""}`} id={id} role="tabpanel" aria-labelledby={`${id}-tab`} key={id}>
            {display}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PepPage;
