import React, { useCallback, useState } from "react";
import UtilsDataService from "../../../services/UtilsDataService";
import { notify } from "../../../assets/utils/UtilsFunctons";
import { Input, Button } from "reactstrap";

const TeamManagementComp = ({ data }) => {
  const [updatingSettings, setUpdatingSettings] = useState(false);
  const [defaultValue, setDefaultValue] = useState(data.minNbGuidePerTeam);
  const [minNbGuidePerTeam, setMinNbGuidePerTeam] = useState(data.minNbGuidePerTeam);

  const doesBalanceChanged = useCallback(() => {
    return minNbGuidePerTeam !== defaultValue;
  }, [minNbGuidePerTeam, data, defaultValue]);

  const handleSubmit = async () => {
    if (minNbGuidePerTeam) {
      let payload = {
        minNbGuidePerTeam: minNbGuidePerTeam,
      };

      try {
        setUpdatingSettings(true);
        await UtilsDataService.patchSettings(payload);
        setDefaultValue(payload.minNbGuidePerTeam);
        notify(1, "Paramètres modifiés avec succès !");
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        setUpdatingSettings(false);
      }
    }
  };
  const handleReset = () => {
    setMinNbGuidePerTeam(defaultValue);
  };
  return (
    <div>
      <div className="h-100 p-3 bg-light border rounded-3 mt-3">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex  flex-column   ">Nombre de guides minimum par équipe</div>
          <Input
            type="number"
            min="1"
            value={minNbGuidePerTeam}
            style={{ width: "auto" }}
            onChange={(e) => setMinNbGuidePerTeam(e.target.value ? parseInt(e.target.value) : 2)}
          ></Input>
        </div>
      </div>
      <br />
      {doesBalanceChanged() && (
        <div className="d-flex justify-content-end">
          <Button size="sm" color="primary" role="button" onClick={handleSubmit} disabled={updatingSettings}>
            {updatingSettings ? <i className="bx bx-loader-alt bx-spin"></i> : "Valider"}
          </Button>
          &nbsp;
          <Button size="sm" color="danger" role="button" onClick={handleReset} disabled={updatingSettings}>
            Annuler
          </Button>
        </div>
      )}
    </div>
  );
};

export default TeamManagementComp;
