import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../redux/slices/UtilsSlice";
import { useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from "reactstrap";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import TeamManagementComp from "../components/settings/teamManagement/TeamManagementComp";
import Loader from "../components/loader/Loader";
import { Toaster } from "react-hot-toast";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import { SETTING_TABS_CONST } from "../core/constant/setting-tabs-const";
import MaintenanceManagement from "../components/settings/maintenanceManagement/MaintenanceManagement.component";
import ConfirmModal from "../components/modals/FormConfirmDialogModal";

const Settings = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { loading_getSettings, appSettings } = useSelector((state) => ({ ...state.utils }));

  const activeTab = useMemo(() => {
    if (location && location?.hash && location?.hash?.length > 1) {
      return location.hash.split("#")[1];
    }
    return SETTING_TABS_CONST.teamManagement.tabId;
  }, [location]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div>
        <h2 className="page-header">Paramètres</h2>
      </div>
      <ConfirmContextProvider>
        <ConfirmModal />
        <div className="myCard">
          {loading_getSettings ? (
            <Loader />
          ) : appSettings && appSettings?.data ? (
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`nav-link ${activeTab === SETTING_TABS_CONST.teamManagement.tabId ? "active" : ""}`}
                    href={`#${SETTING_TABS_CONST.teamManagement.tabId}`}
                  >
                    <span>Gestion équipes</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`nav-link ${activeTab === SETTING_TABS_CONST.maintenance.tabId ? "active" : ""}`}
                    href={`#${SETTING_TABS_CONST.maintenance.tabId}`}
                  >
                    <span>{SETTING_TABS_CONST.maintenance.label}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={`nav-link ${activeTab === "autres" ? "active" : ""}`} href="#autres">
                    <span>Autres</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} style={{ background: "white", padding: "30px" }}>
                <TabPane tabId={SETTING_TABS_CONST.teamManagement.tabId}>
                  <TeamManagementComp data={appSettings?.data} />
                </TabPane>
                <TabPane tabId={SETTING_TABS_CONST.maintenance.tabId}>
                  <MaintenanceManagement />
                </TabPane>
                <TabPane tabId="autres">
                  <Row>
                    <Col sm="12">Autres configs</Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          ) : (
            <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
          )}
        </div>
      </ConfirmContextProvider>
    </div>
  );
};

export default Settings;
