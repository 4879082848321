import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeasonInfos } from "../../redux/slices/SeasonSlice";
import UpdateTeamInfos from "./UpdateTeamInfos";
import SortAndSearchTable from "../table/sortAndSearchTable/SortAndSearchTable";

const TeamRankTable = () => {
  const dispatch = useDispatch();
  const { currentSeason, loading_getSeasonInfos, seasonInfos, selectedSeasonIdForRank } = useSelector((state) => ({ ...state.seasonList }));
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));

  useEffect(() => {
    if (currentSeason && !selectedSeasonIdForRank) {
      dispatch(getSeasonInfos(currentSeason.seasonId));
    } else {
      dispatch(getSeasonInfos(selectedSeasonIdForRank));
    }
  }, [selectedSeasonIdForRank]);

  const getTeamMembersConsultantsNameFromusersInfos = (teamMembersId) => {
    let consultant = [];
    let userItem = undefined;
    if (usersInfos === [] || usersInfos === undefined || usersInfos.data === undefined) return consultant;
    else {
      teamMembersId.map((item) => {
        userItem = usersInfos.data.find((user) => user.userId === item);

        if (userItem !== undefined && userItem.role === 1) consultant.push(userItem.firstName + " " + userItem.lastName + "\n");
      });

      return consultant;
    }
  };

  const getTeamGuidesNameFromUserInfos = (teamMembersId, substituteId) => {
    let guide = [];
    let userItem = undefined;
    if (usersInfos === [] || usersInfos === undefined || usersInfos.data === undefined) {
      return guide;
    } else {
      // we take each userId and check their complete Infos in "userInfos", if found, we also check for the role and isGhost status
      teamMembersId.map((item) => {
        userItem = usersInfos.data.find((user) => user.userId === item);
        if (userItem !== undefined && userItem.role === 2 && !userItem.isGhost) guide.push(userItem.firstName + " " + userItem.lastName + "\n");
        if (userItem !== undefined && userItem.role === 2 && userItem.isGhost) guide.push(userItem.firstName + " " + userItem.lastName + "(ghost)\n");
        // trying to retrieve the substitute guide name
        if (substituteId !== null) {
          userItem = usersInfos.data.find((user) => user.userId === substituteId);
          if (userItem !== undefined && userItem.role === 2) guide.push(userItem.firstName + " " + userItem.lastName + "\n");
        }
      });

      return guide;
    }
  };

  const sortTeamsByRank = (teams) => {
    let list = [...teams];
    return list?.sort((a, b) => a.rank - b.rank);
  };

  const columns = [
    {
      Header: "Rang",
      accessor: (row) => row.rank, // accessor is the "key" in the data
    },
    {
      Header: "Equipes",
      accessor: "name",
    },
    {
      Header: "joueurs",
      accessor: "users",
      Cell: ({ value }) => value.length,
    },
    {
      Header: "guides",
      Cell: ({ row }) => {
        return (
          <ul>
            {getTeamGuidesNameFromUserInfos(row.original.users, row.original.substituteId).map((elt, index) => (
              <li key={index}>{elt}</li>
            ))}
          </ul>
        );
      },
    },
    {
      Header: "Cristaux",
      accessor: "score",
    },
    {
      Header: "Explorateurs",
      accessor: (row) => row.teamId,
      Cell: ({ row }) => {
        return (
          <ul>
            {getTeamMembersConsultantsNameFromusersInfos(row.original.users).map((elt, index) => (
              <li key={index}>{elt}</li>
            ))}
          </ul>
        );
      },
    },
    {
      Header: "Photo",
      accessor: "imageUrl",
      Cell: ({ value }) => <img src={value} alt="" className="img-thumbnail teamRankImage"></img>,
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <div>
          <UpdateTeamInfos data={row.original} seasonType={seasonInfos?.data?.seasonType}></UpdateTeamInfos>
        </div>
      ),
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off", @typescript-eslint/no-unused-vars: "off" */
  const tableHooks = (hooks) => {};
  const handleRowClicked = (row) => {};
  return (
    <div>
      {loading_getSeasonInfos || seasonInfos === [] || !seasonInfos || !seasonInfos.data ? (
        ""
      ) : (
        <SortAndSearchTable
          data={sortTeamsByRank(seasonInfos.data?.seasonTeams)}
          columns={columns}
          tableHooks={tableHooks}
          refresh={true}
          handleRowClicked={handleRowClicked}
        ></SortAndSearchTable>
      )}
    </div>
  );
};

export default TeamRankTable;
