import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import EditSeasonConfigForm from "../forms/EditSeasonConfigForm";

/* eslint react/no-unescaped-entities: "off" */
const EditSeasonConfigModal = ({ buttonLabel, editSeasonConfig, item }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );
  const label = buttonLabel;

  let button = "";
  let title = "";
  let form = "";

  const editButton = (
    <Button
      className="edit-leagueConf-btn"
      onClick={toggle}
      outline
      color="primary"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Modifier les configs de l'expédition"
    >
      <i className="bx bxs-pencil"></i>
    </Button>
  );

  button = editButton;
  title = "Modifier la configuration";
  form = <EditSeasonConfigForm item={item} modalConfig={setModal} editSeasonConfig={editSeasonConfig} label={label}></EditSeasonConfigForm>;

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false} size="md">
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>{form}</ModalBody>
      </Modal>
    </div>
  );
};

export default EditSeasonConfigModal;
