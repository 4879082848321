import http from "./http-common";

class QuestDataService {
  getQuestList() {
    return http.get("/quest");
  }
  getQuestListById(data) {
    return http.get("/quest?userId=" + data);
  }

  getCurrentQuestList() {
    return http.get("/quest/current");
  }

  postQuestDecision(data) {
    return http.put("/quest/decision", data);
  }

  assignQuestToUser(data) {
    return http.post("/quest", data);
  }
}

export default new QuestDataService();
