import { React, useState } from "react";
import DigDaysDecisionForm from "./DigDaysDecisionForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const DigDaysDecisionModal = ({ decision, digDay, handleDigDayDecision }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const digDayDecisionBtnTooltipText = (decision, state) => {
    if (decision === "validation") {
      return state === 2 ? "Valider le Digday" : "Le Digday n'est pas en état de validation";
    }
    if (decision === "rejection") {
      return state === 2 ? "Rejeter le Digday" : "Le Digday n'est pas en état de validation";
    }
    if (decision === "adjustment") {
      return state === 2 ? "Demander un ajustement" : "Le Digday n'est pas en état de validation";
    }
    if (decision === "cancel") {
      return "Annuler le digDay";
    }
    if (decision === "notification") {
      return "Envoyer un rappel";
    }
    return "";
  };

  const myBtn = (
    <div>
      <div data-toggle="tooltip" data-placement="bottom" title={digDayDecisionBtnTooltipText(decision, digDay.state)}>
        {decision === "validation" ? (
          <Button onClick={toggle} color="success" outline size="sm" disabled={digDay.state !== 2}>
            <i className="bx bx-check" style={{ fontSize: "1.5em" }}></i>
          </Button>
        ) : decision === "adjustment" ? (
          <Button onClick={toggle} color="warning" outline size="sm" disabled={digDay.state !== 2}>
            <i className="bx bx-refresh" style={{ fontSize: "1.5em" }}></i>
          </Button>
        ) : decision === "cancel" ? (
          <Button onClick={toggle} color="danger" outline size="sm">
            <i className="bx bx-trash" style={{ fontSize: "1.5em" }}></i>
          </Button>
        ) : decision === "notification" ? (
          <Button onClick={toggle} color="primary" outline size="sm">
            <i className="bx bx-bell" style={{ fontSize: "1.5em" }}></i>
          </Button>
        ) : (
          <Button onClick={toggle} color="danger" outline size="sm" disabled={digDay.state !== 2}>
            <i className="bx bx-x" style={{ fontSize: "1.5em" }}></i>
          </Button>
        )}
      </div>
    </div>
  );

  const modalTitle = (state) => {
    switch (state) {
      case "validation":
        return " Validation du digDay";

      case "adjustment":
        return " réajustement du digDay";

      case "rejection":
        return " rejet du digDay";
      case "notification":
        return " Rappel";
      case "cancel":
        return " annulation du digDay";
      default:
        return "";
    }
  };

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {modalTitle(decision)}
        </ModalHeader>
        <ModalBody>
          <DigDaysDecisionForm decision={decision} digDay={digDay} modalVisibility={setModal} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DigDaysDecisionModal;
