import React, { useCallback } from "react";
import RessourceStatusComp from "./RessourceStatusComp";
import useGetRessourceByActionId from "../../../hooks/useGetRessourceByActionId";

const RessourcesRenderComp = ({ resourceConfigId }) => {
  const { actionConfigRessources } = useGetRessourceByActionId(resourceConfigId);
  const renderActionUrl = useCallback(() => {
    if (!actionConfigRessources || actionConfigRessources?.length === 0) {
      return <span> Aucune ressource disponible.</span>;
    }

    return actionConfigRessources?.map((ressource, index) => {
      return (
        <div className="d-flex justify-content-start gap-3 align-items-baseline" key={index}>
          <a href={ressource.url} target="_blank" rel="noreferrer" className="text-truncate link-primary ">
            {ressource.title}
          </a>
          <div className="d-flex align-items-baseline ">
            {ressource.rating} <i className="bx bxs-star text-warning"></i>
          </div>
          <div>
            <RessourceStatusComp status={ressource.status} />
          </div>
        </div>
      );
    });
  }, [actionConfigRessources]);

  return <div>{renderActionUrl()}</div>;
};

export default RessourcesRenderComp;
