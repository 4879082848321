import React from "react";

const NotificationPage = () => {
  return (
    <div>
      <h2 className="page-header">Notifications</h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <h2> Page des Notifications</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
