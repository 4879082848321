import http from "./http-common";

class DigDaysDataService {
  getAllDigDays() {
    return http.get("/digday?fromBo=true");
  }
  getDigDaysByUserId(id) {
    return http.get("/digday?userId=" + id + "&fromBo=true");
  }

  updateUserDigDaysStock(data) {
    return http.put("/digday/userStock", data);
  }
  digDayDecision(data) {
    return http.put("/digday/changeState", data);
  }
  cancelDigDay(data) {
    return http.put("/digday/cancel", data);
  }
}

export default new DigDaysDataService();
