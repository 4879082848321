import { React, useState } from "react";
import QuestValidationForm from "../forms/QuestValidationForm";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const QuestValidationButton = ({ quest, color, icon, tooltipText, decision, postQuestDecision }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button outline color="danger" onClick={toggle}>
      &times;
    </Button>
  );

  return (
    <div>
      <div data-bs-toggle="tooltip" data-bs-placement="top" title={tooltipText}>
        <Button color={color} outline className="quest-validation-button fs-4" disabled={quest?.questState !== 1} onClick={toggle}>
          <i className={icon}></i>
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Détails de la quête
        </ModalHeader>
        <ModalBody>
          <QuestValidationForm formType={decision} item={quest} postQuestDecision={postQuestDecision} modalVisibility={toggle}></QuestValidationForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default QuestValidationButton;
