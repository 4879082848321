import React from "react";
import QuestValidationActions from "./QuestValidationActions";
import Enums from "../../assets/JsonData/Enums";
import MessageSender from "../discussionPanel/MessageSender";
import { questStateBadgeColor } from "./functions";
import { formatDateUtcToLocalTime } from "../../assets/utils/UtilsFunctons";
import { Row, Col, Badge, Progress } from "reactstrap";

const QuestSerialValidationHeader = ({ quest, questList, setUserAndTitleIndex, userAndTitleIndex, setIterationIndex, maxLength, postQuestDecision }) => {
  return (
    <div>
      <Row>
        <Col md={1}>
          <i
            className={`bx bxs-left-arrow fs-1  ${userAndTitleIndex === 0 ? "user-title-index-btn-disabled" : "previous-user-title-index-btn"}`}
            aria-disabled={userAndTitleIndex === 0}
            onClick={() => {
              if (userAndTitleIndex > 0) {
                setUserAndTitleIndex(userAndTitleIndex - 1);
                const indexNextValidation = questList[userAndTitleIndex - 1].findIndex((element) => element.questState === 1);
                if (indexNextValidation >= 0) {
                  setIterationIndex(indexNextValidation);
                } else {
                  setIterationIndex(0);
                }
              }
            }}
          ></i>
        </Col>
        <Col md={7}>
          <div className="d-flex flex-column bd-highlight mb-3">
            <div>
              <b>
                {quest?.title}&nbsp; #{quest?.iteration} &nbsp;
                <Badge color={questStateBadgeColor(quest?.questState)}>{Enums.matchQuestStatut(quest?.questState)}</Badge>
              </b>
            </div>
            <div>
              {<i>{quest?.firstName + " " + quest?.lastName} </i>},&nbsp;{quest?.role === 1 ? "Explorateur" : "Guide"}
            </div>
            <div className="text-muted">{quest?.seasonNumber ? "Expédition n° " + quest?.seasonNumber : "Inter-expédition"}</div>
          </div>
        </Col>
        <Col md={3}>
          <div className="d-flex flex-column bd-highlight mb-3">
            <div className="d-flex flex-row justify-content-center">
              <div> Coins: {quest?.coins}</div> &nbsp;
              <div> Score: {quest?.score}</div>
            </div>
            <br />
            <div>
              <QuestValidationActions quest={quest} postQuestDecision={postQuestDecision}></QuestValidationActions>
            </div>
          </div>
        </Col>
        <Col md={1}>
          <i
            className={`bx bxs-right-arrow fs-1  ${userAndTitleIndex === maxLength - 1 ? "user-title-index-btn-disabled" : "next-user-title-index-btn"}`}
            onClick={() => {
              if (userAndTitleIndex < maxLength - 1) {
                setUserAndTitleIndex(userAndTitleIndex + 1);
                const indexNextValidation = questList[userAndTitleIndex + 1].findIndex((element) => element.questState === 1);
                if (indexNextValidation >= 0) {
                  setIterationIndex(indexNextValidation);
                } else {
                  setIterationIndex(0);
                }
              }
            }}
          ></i>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <div className="d-flex flex-column bd-highlight mb-3">
            {quest?.maxProgress && (
              <div>
                <div>
                  Progrès:&nbsp;
                  {quest?.progress > 0 ? (
                    <Progress className="my-2" value={quest?.progress} max={quest?.maxProgress}>
                      {quest?.progress} / {quest?.maxProgress}
                    </Progress>
                  ) : (
                    quest?.progress + "/ " + quest?.maxProgress
                  )}
                </div>
              </div>
            )}
            {quest?.expirationDate && (
              <div>
                Date expiration : <Badge>{formatDateUtcToLocalTime(quest?.expirationDate)}</Badge>
              </div>
            )}
          </div>
        </Col>
        <Col md={10}>
          <MessageSender parentId={quest?.questId} parentType={0}></MessageSender>
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export default QuestSerialValidationHeader;
