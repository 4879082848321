import React from "react";
import halfCircle from "../../assets/images/Circle-half-full.svg";

const DigDayDurationAndLocation = ({ digDay }) => {
  return (
    <div className="d-flex flex-row align-middle">
      <div>
        {digDay.dayPeriod === 0 && <i className="bx bxs-circle "></i>}
        {digDay.dayPeriod === 1 && <img src={halfCircle} alt="AM" width={16} style={{ verticalAlign: "baseline" }} />}
        {digDay.dayPeriod === 2 && <img src={halfCircle} alt="PM" width={16} style={{ transform: "scaleX(-1)", verticalAlign: "baseline" }} />}
      </div>
      &nbsp;
      <div>{digDay.isAtOffice ? <i class="bx bxs-business " /> : <i class="bx bxs-home " />}</div>
    </div>
  );
};

export default DigDayDurationAndLocation;
