import { React } from "react";
import useConfirm from "../../FormConFirmDialog/UseFormConfirmDialog";
import { Button } from "reactstrap";

const RewardTemplateActionButton = ({ children, rewardTemplate, color, icon, tooltipText, decision, handleAction }) => {
  const { isConfirmed } = useConfirm();

  const handleDeleteButton = async () => {
    const confirmed = await isConfirmed(children);

    if (confirmed) {
      if (decision === "delete") {
        handleAction(rewardTemplate.rewardTemplateId);
      }
    }
  };

  return (
    <div>
      <div data-bs-toggle="tooltip" data-bs-placement="top" title={tooltipText}>
        <Button color={color} outline className="quest-validation-button fs-4" onClick={handleDeleteButton} disabled={rewardTemplate?.nbQuiz > 0}>
          <i className={icon}></i>
        </Button>
      </div>
    </div>
  );
};

export default RewardTemplateActionButton;
