import { React, useState } from "react";
import UpdateMissionForm from "./UpdateMissionForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const UpdateMissionModal = ({ handleUpdateMission, mission }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <Button onClick={toggle} color="warning" data-bs-toggle="tooltip" data-bs-placement="bottom" title="modification d'une mission">
      <i className="bx bx-edit" style={{ fontSize: "1.5em" }}></i>&nbsp;modifier la mission
    </Button>
  );

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Modification mission
        </ModalHeader>
        <ModalBody>
          <UpdateMissionForm handleUpdateMission={handleUpdateMission} modalVisibility={setModal} mission={mission}></UpdateMissionForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateMissionModal;
