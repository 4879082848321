import React, { useCallback, useState } from "react";
import useConfirm from "../../FormConFirmDialog/UseFormConfirmDialog";
import ToggleButton from "../../buttons/toggleButton/ToggleButton";
import { FormGroup, Label } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./maintenance_management.css";

const StartEndMaintenanceButton = ({ isMaintenanceRunning, HandleStartMaintenanceSubmit, HandleEndMaintenanceSubmit }) => {
  registerLocale("fr", fr);
  const { isConfirmed } = useConfirm();
  const [estimatedEnd, setEstimatedEnd] = useState(null);

  const renderPlanMaintenanceConfirmForm = useCallback(() => {
    return !isMaintenanceRunning ? (
      <p className="font-weight-bold h6 pb-3">Souhaitez-vous lancer une maintenance ?</p>
    ) : (
      <p className="font-weight-bold h6 pb-3">Souhaitez-vous arrêter la maintenance en cours ?</p>
    );
  }, [isMaintenanceRunning]);

  const handleSubmitButtonToggle = async () => {
    const confirmed = await isConfirmed(renderPlanMaintenanceConfirmForm());
    if (confirmed) {
      isMaintenanceRunning ? HandleEndMaintenanceSubmit() : HandleStartMaintenanceSubmit(estimatedEnd);
    } else {
      setEstimatedEnd(null);
    }
  };

  return (
    <div className="">
      {!isMaintenanceRunning && (
        <div>
          <FormGroup>
            <Label>
              Date de reprise prévisionnelle <span className="small"> (optionnelle)</span>
            </Label>

            <DatePicker
              selected={estimatedEnd}
              onChange={(date) => setEstimatedEnd(date)}
              locale="fr"
              showTimeSelect
              minDate={subDays(new Date(), 0)}
              minTime={new Date()}
              maxTime={new Date().setHours(23, 59)}
              timeFormat="p"
              timeIntervals={15}
              dateFormat="Pp"
              className="maintenance-estimatedEnd"
              isClearable={true}
            />
          </FormGroup>
        </div>
      )}
      <div data-bs-toggle="tooltip" data-bs-placement="top" title={!isMaintenanceRunning ? "Lancer la maintenance " : "Arrêter la maintenaznce en cours"}>
        <span>
          <ToggleButton state={isMaintenanceRunning} onClickHandler={handleSubmitButtonToggle} />
        </span>
      </div>
    </div>
  );
};

export default StartEndMaintenanceButton;
