import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfilePictureById } from "../../../redux/slices/UserSlice";

const GetCurrentUserProfilePicture = (props) => {
  const { loading_getUserProfilePicture, userProfilePicture, refreshUserPage } = useSelector((state) => ({ ...state.userInfos }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfilePictureById(props.userId));
  }, [refreshUserPage]);

  return (
    <div>
      {loading_getUserProfilePicture || !userProfilePicture ? (
        ""
      ) : !userProfilePicture.data || !userProfilePicture.data[0] ? (
        ""
      ) : (
        <img src={userProfilePicture?.data[0]?.url} alt="pas d'image" height={90} width={90} className="rounded float-left" />
      )}
    </div>
  );
};

export default GetCurrentUserProfilePicture;
