import { React, useState } from "react";
import { Table, Input, FormGroup, Label, Row, Col, Button, Alert, CloseButton } from "reactstrap";

const RewardTemplateEditableTable = ({ rewardTemplates, handleUpdateRewardTemplate }) => {
  const [rewards, setRewards] = useState(rewardTemplates?.rewards);
  const [rewardName, setRewardName] = useState(rewardTemplates?.name);
  const [isLoading, setIsloading] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertTextContent, setAlertTextContent] = useState("");

  const onChange = (e, position) => {
    const { name, value } = e.target;
    let editData = [];
    if (name === "gift") {
      editData = rewards.map((item) => (item.position === position && name ? { ...item, [name]: e.target?.checked } : item));
    } else {
      editData = rewards.map((item) => (item.position === position && name ? { ...item, [name]: value } : item));
    }

    setRewards(editData);
  };

  const getNewEmptyItem = () => {
    let index = rewards?.length;
    let position = index === 0 ? -1 : index;
    return { position: position, coins: 0, score: 0, gift: false };
  };

  const handleAddRow = () => {
    setRewards([...rewards, getNewEmptyItem()]);
  };

  const handleDeleteRow = (position) => {
    const editData = rewards.filter((item) => item.position !== position);
    setRewards(editData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormCorrectlyFilled) {
      const payload = {
        rewardTemplateId: rewardTemplates.rewardTemplateId,
        name: rewardName,
        archived: rewardTemplates.archived,
        nbQuiz: rewardTemplates.nbQuiz,
        rewards: rewards,
      };
      setIsloading(true);
      handleUpdateRewardTemplate(payload);
    }
  };

  const isFormCorrectlyFilled = () => {
    // nous avons une seule contrainte à vérifier :  l'odre concernant les
    //récompenses : on commence par autres (-1), puis 1, 2 , 3 ...
    // l'ordre est primordiale
    for (let i = 0; i < rewards.length; i++) {
      if (i === 0 && rewards[i].position !== -1) {
        setAlertTextContent("Les positions pour les récompenses doivent être croissantes !");
        setIsAlertVisible(true);
        return false; // Le tableau n'est pas ordonné de manière croissante
      }
      if (i !== 0 && rewards[i].position !== i) {
        setAlertTextContent("Les positions pour les récompenses doivent être croissantes !");
        setIsAlertVisible(true);
        return false; // Le tableau n'est pas ordonné de manière croissante
      }
    }

    if (rewards?.length === 0) {
      setAlertTextContent("Vous devez ajouter au moins une récompense !");
      setIsAlertVisible(true);
      return false; // Le tableau n'est pas ordonné de manière croissante
    }
    setAlertTextContent("");
    setIsAlertVisible(false);
    return true;
  };

  const onDismiss = () => {
    setIsAlertVisible(!isAlertVisible);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Nom du template</Label>
          <Input
            type="text"
            name="name"
            id="name"
            onChange={(e) => {
              setRewardName(e.target.value);
            }}
            value={rewardName}
            required
          />
        </FormGroup>
        <Table size="sm" responsive hover={false} className="nohover table-responsive-sm">
          <thead>
            <tr>
              <th>Position</th>
              <th>Coins</th>
              <th>Score</th>
              <th>Cadeau</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rewards?.map(({ position, coins, score, gift }, index) => (
              <tr key={index}>
                <td>
                  <input
                    readOnly
                    style={{ maxWidth: "100px" }}
                    name="position"
                    value={position}
                    type="number"
                    onChange={(e) => onChange(e, position)}
                    placeholder="Position"
                  />
                </td>
                <td>
                  <input style={{ maxWidth: "100px" }} name="coins" value={coins} type="number" onChange={(e) => onChange(e, position)} placeholder="coins" />
                </td>
                <td>
                  <input style={{ maxWidth: "100px" }} name="score" type="number" value={score} onChange={(e) => onChange(e, position)} placeholder="score" />
                </td>
                <td>
                  <Input style={{ maxWidth: "100px" }} name="gift" type="switch" checked={gift} onChange={(e) => onChange(e, position)} placeholder="gift" />
                </td>
                <td>
                  <CloseButton
                    title="Supprimer le média existant"
                    onClick={() => {
                      handleDeleteRow(position);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <button style={{ all: "unset", cursor: "pointer" }} onClick={handleAddRow} type="button">
          <i className="bx bx-plus-circle fs-5"> </i> ajouter une ligne
        </button>
        <Alert color="danger" isOpen={isAlertVisible} toggle={onDismiss}>
          {alertTextContent}
        </Alert>
        <hr />
        <Row className="mt-2 mx-2">
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block disabled={isLoading}>
              {isLoading ? <i className="bx bx-loader-alt bx-spin" /> : "Ajouter"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default RewardTemplateEditableTable;
