import { React, useState } from "react";
import { formatToISOString } from "../../assets/utils/UtilsFunctons";
import AddNewQuestSeasonConfigForm from "../forms/AddNewQuestSeasonConfigForm";
import { addQuestSeasonConfig } from "../../redux/slices/QuestConfigSlice";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const AddNewQuestSeasonConfigModal = ({ questConfig }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const dispatch = useDispatch();

  const addNewQuestSeasonConfig = (formValue) => {
    const payload = {
      questSeasonConfigId: uuid(),
      questConfigId: formValue.questConfigId,
      seasonId: formValue.seasonId === "null" ? null : formValue.seasonId,
      role: parseInt(formValue.role),
      coins: parseInt(formValue.coins),
      score: parseInt(formValue.score),
      maxIteration: parseInt(formValue.maxIteration),
      isEnabled: formValue.isEnabled === "true",
      gift: formValue.gift === "true",
      addToFutureSeasons: formValue.addToFutureSeasons,
      expirationDate: formValue.expirationType === 1 ? formatToISOString(formValue.expirationDate) : null,
      expirationDuration:
        formValue.expirationType === 2
          ? {
              hours: formValue.hours,
              days: formValue.days,
              months: formValue.months,
            }
          : null,
    };
    dispatch(addQuestSeasonConfig(payload));
  };

  return (
    <div>
      <div onClick={toggle}>
        <h6 style={{ cursor: "pointer" }}>
          <span style={{ textDecoration: "underline" }}>Ajout d'une configuration d'expédition</span> &nbsp; <i className="bx bx-plus-circle fs-5"></i>
        </h6>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Ajout d'une nouvelle expédition
        </ModalHeader>
        <ModalBody>
          <AddNewQuestSeasonConfigForm addNewQuestSeasonConfig={addNewQuestSeasonConfig} modalConfig={setModal} questConfig={questConfig} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewQuestSeasonConfigModal;
