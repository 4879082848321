import http from "./http-common";

class BlocConfigDataService {
	getAllBlocConfigs() {
		return http.get("/bloc");
	}

	addNewBlocConfigs(data) {
		return http.post("/bloc", data);
	}

	updateBlocConfigs(data) {
		return http.put("/bloc", data);
	}

	deleteBlocConfigs(data) {
		return http.delete("/bloc", { params: { guid: data } });
	}
}

export default new BlocConfigDataService();
