import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaintenanceService from "../../../services/MaintenanceService";
import EditableTable from "../../table/EditableTable";
import { formatDateUtcToLocalTime, formatToISOString } from "../../../assets/utils/UtilsFunctons";
import { getCurrentMaintenance, getMaintenanceHistory } from "../../../redux/slices/MaintenanceConfigSlice";
import StartEndMaintenanceButton from "./StartEndMaintenanceButton";
import { notify } from "../../../assets/utils/UtilsFunctons";
import "./maintenance_management.css";

const MaintenanceManagement = () => {
  const dispatch = useDispatch();
  const { currentMaintenance, maintenanceHistory, loading_currentMaintenance, loading_maintenanceHistory } = useSelector((state) => ({
    ...state.maintenanceConfig,
  }));

  const [reloadPage, setReloadPage] = useState(false);

  const TableHead = ["Lancé par", "Arrêté par", "Début", "Fin", "Durée"];

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderBody = (item, index) => (
    <>
      <tr key={index}>
        <td>{item.startedByUser}</td>
        <td>{item.endedByUser}</td>
        <td>{formatDateUtcToLocalTime(item.startTimestamp)}</td>
        <td>{formatDateUtcToLocalTime(item.endTimestamp)}</td>
        <td>{item.duration}</td>
      </tr>
    </>
  );

  const getCurrentMaintenanceId = useMemo(() => {
    if (!maintenanceHistory || !maintenanceHistory.data) {
      return undefined;
    }

    const runningMaintenance = maintenanceHistory?.data?.find((elt) => elt.endTimestamp === null);

    return runningMaintenance ? runningMaintenance?.maintenanceId : undefined;
  }, [maintenanceHistory]);

  const HandleEndMaintenanceSubmit = async () => {
    try {
      if (getCurrentMaintenanceId) {
        const payload = { maintenanceId: getCurrentMaintenanceId };
        await MaintenanceService.endMaintenance(payload);
        notify(1, "Maintenance terminée avec succès !");
        setReloadPage((prevState) => !prevState);
      }
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    }
  };

  const HandleStartMaintenanceSubmit = async (estimatedEnd) => {
    try {
      const payload = { estimatedEnd: estimatedEnd ? formatToISOString(estimatedEnd) : null, allowBackOfficeAndCrons: true };
      await MaintenanceService.startMaintenance(payload);
      notify(1, "Maintenance lancée avec succès !");
      setReloadPage((prevState) => !prevState);
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    }
  };

  useEffect(() => {
    dispatch(getCurrentMaintenance());
    dispatch(getMaintenanceHistory());
  }, [reloadPage]);

  const renderNoMaintenanceContent = useCallback(() => {
    return (
      <div>
        <h2 className="card-title h2-responsive mt-2 font-bold">
          <strong>Aucune maintenace en cours ...</strong>
        </h2>

        <p className="mt-4">
          <strong>Gérer les périodes de maintenance ou d&apos;arrêt temporaire des activités mobiles.</strong>
        </p>

        <div className="maintenance-settings-description mt-2">
          <ul>
            <li>
              <strong>ON :</strong> Active la maintenance et interrompt l&apos;activité mobile.
            </li>
            <li>
              <strong>OFF :</strong> Désactive la maintenance et permet la reprise normale des activités mobiles.
            </li>
          </ul>
        </div>

        <hr className="my-4 rgba-white-light" />

        <h6 className="bold">
          <strong> Activer une maintenance</strong>
        </h6>
      </div>
    );
  }, []);

  const renderMaintenanceContent = useCallback(() => {
    return (
      <div>
        <h2 className="card-title h2-responsive mt-2 font-bold">
          <strong>Maintenace en cours ...</strong>
        </h2>
        <br />

        {currentMaintenance?.data?.estimatedEnd && <h5> Reprise estimée vers : {formatDateUtcToLocalTime(currentMaintenance?.data.estimatedEnd)}</h5>}

        <p className="mt-4 text-muted">
          <strong>Gérer les périodes de maintenance ou d&apos;arrêt temporaire des activités mobiles.</strong>
        </p>

        <div className="maintenance-settings-description mt-2 text-muted">
          <ul>
            <li>
              <strong>ON :</strong> Active la maintenance et interrompt l&apos;activité mobile.
            </li>
            <li>
              <strong>OFF :</strong> Désactive la maintenance et permet la reprise normale des activités mobiles.
            </li>
          </ul>
        </div>

        <hr className="my-2 rgba-white-light" />

        <button type="button" className="btn btn-outline-white ">
          <span className="fs-5">Arrêter la maintenance</span>
        </button>
      </div>
    );
  }, [currentMaintenance]);

  const renderMaintenanceHistoryTable = useCallback(() => {
    return (
      <div className="mt-5">
        <EditableTable
          limit="5"
          loading={loading_maintenanceHistory}
          title="Historique des maintenances"
          headData={TableHead}
          renderHead={(item, index) => renderHead(item, index)}
          bodyData={maintenanceHistory?.status === 200 ? maintenanceHistory?.data : []}
          renderBody={(item, index) => renderBody(item, index)}
          striped={true}
        />
      </div>
    );
  }, [maintenanceHistory, loading_maintenanceHistory]);

  return (
    <div>
      {loading_currentMaintenance ? (
        "chargement ..."
      ) : currentMaintenance ? (
        <div
          className={`jumbotron text-center ${
            currentMaintenance?.data?.isMaintenanceRunning ? "border border-danger" : "border border-success"
          } bg-light lighten-2  white-text  p-3  rounded`}
        >
          {currentMaintenance?.data?.isMaintenanceRunning ? renderMaintenanceContent() : renderNoMaintenanceContent()}
          <StartEndMaintenanceButton
            isMaintenanceRunning={currentMaintenance?.data?.isMaintenanceRunning}
            HandleEndMaintenanceSubmit={HandleEndMaintenanceSubmit}
            HandleStartMaintenanceSubmit={HandleStartMaintenanceSubmit}
          />
        </div>
      ) : (
        "chargement ..."
      )}

      {renderMaintenanceHistoryTable()}
    </div>
  );
};

export default MaintenanceManagement;
