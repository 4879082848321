import React from "react";
import "./toggleButton.css";

const ToggleButton = ({ state, onClickHandler, isdisabled }) => {
  return (
    <div className="toggleBtn-component">
      <button onClick={() => onClickHandler()} type="button" disabled={isdisabled}>
        {state ? <i className="bx bxs-toggle-right " id="toggleBtn-activated-toggle"></i> : <i className="bx bx-toggle-left"></i>}
      </button>
    </div>
  );
};

export default ToggleButton;
