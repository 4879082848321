import { React } from "react";
import useGetLogById from "../../hooks/useGetlogById";
import DiscussionThread from "./DiscussionThread";
import MessageSender from "./MessageSender";

const DiscussionPanel = ({ logId, userId, parentType, stateTransition, refresh, readOnly }) => {
  /* eslint @typescript-eslint/no-unused-vars: "off" */
  const { data, error, loaded } = useGetLogById(logId, refresh);
  return (
    <div>
      <div className="pt-2">
        <h6>
          <i className="bx bx-history thread-header-icon"></i> Historique
        </h6>
      </div>
      {!loaded ? (
        <span>chargement...</span>
      ) : data ? (
        <div>
          {!readOnly && <MessageSender parentId={logId} parentType={parentType} />}
          <hr />
          <DiscussionThread log={data} userId={userId} stateTransition={stateTransition ?? undefined} />
        </div>
      ) : (
        <div>
          <span>
            <i>La récupération des données a échoué !</i>
          </span>
        </div>
      )}
    </div>
  );
};

export default DiscussionPanel;
