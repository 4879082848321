import React, { useState, useCallback, useMemo } from "react";
import useModalWithContent from "../../useModalWithContent/UseModalWithContent";
import AddEditRessourceContent from "./AddEditRessourceContent";
import RessourceStatusComp from "./RessourceStatusComp";
import PepDataService from "../../../services/PepDataService";
import { Alert } from "reactstrap";
import ResourceRatingHistory from "./ResourceRatingHistory";
import useGetAllUserListOption from "../../../hooks/useGetAllUserListOption";

const AddEditRessourceComp = ({ actionConfig, ressource, handleAddEditRessource, creation, editOnly }) => {
  const [ressourceList, setRessourceList] = useState(ressource);
  const [ressourceAction, setRessourceAction] = useState("");
  const [selectedRessource, setSelectedRessource] = useState(null);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const { userOptions } = useGetAllUserListOption();

  const handleAddRessource = (values) => {
    let newList = undefined;

    setRessourceList((prevList) => {
      let newValue = {
        title: values.ressourceTitle,
        url: values.ressourceUrl,
        id: prevList ? prevList?.length + 1 : 0,
        status: values.ressourceStatus,
        rating: values.ressourceRating,
      };

      if (prevList) {
        newList = [...prevList, newValue];
      } else {
        newList = [newValue];
      }

      return newList;
    });
    return newList;
  };

  const handleAddNewResourceConfig = useCallback(async (newRessource) => {
    setDisplayAlert(false);
    if (newRessource && actionConfig && actionConfig.actionConfigId) {
      let payload = {
        actionConfigId: actionConfig.actionConfigId,
        title: newRessource.ressourceTitle,
        url: newRessource.ressourceUrl,
        rating: newRessource.ressourceRating,
        status: newRessource.ressourceStatus,
      };

      try {
        const response = await PepDataService.addResource(payload);
        if (response?.status === 200) {
          setRessourceList((prevList) => {
            let newList = [...prevList, response.data];
            return newList;
          });
        }
      } catch (error) {
        setDisplayAlert(true);
        setAlertText(error?.response?.data?.Message ?? "Une erreur s'est produite lors de l'ajout de la ressource");
      }
    }
  }, []);

  const handleEditResourceConfig = useCallback(async (resourceId, newRessource) => {
    setDisplayAlert(false);
    if (newRessource && resourceId) {
      try {
        const response = await PepDataService.editResource(resourceId, newRessource);
        if (response?.status === 200) {
          setRessourceList((prevList) => {
            let newList = prevList?.map((rsc) => {
              if (rsc.id === resourceId) {
                return response.data;
              } else {
                return rsc;
              }
            });
            return newList;
          });
        }
      } catch (error) {
        setDisplayAlert(true);
        setAlertText(error?.response?.data?.Message ?? "Une erreur s'est produite lors de la modification de la ressource");
      }
    }
  }, []);

  const handleUpdateRessource = (updatedRessourceId, values) => {
    let newList = undefined;
    setRessourceList((prevList) => {
      newList = prevList?.map((rsc) => {
        if (rsc.id === updatedRessourceId) {
          return { ...rsc, title: values.ressourceTitle, url: values.ressourceUrl, status: values.ressourceStatus, rating: values.ressourceRating };
        } else {
          return rsc;
        }
      });
      return newList;
    });
    return newList;
  };

  const handleDeleteRessource = (ressourceId) => {
    let newList = undefined;
    setRessourceList((prevList) => {
      newList = prevList?.filter((rsc) => rsc?.id !== ressourceId);
      return newList;
    });
    return newList;
  };

  const ressourceActionTitle = useMemo(() => {
    switch (ressourceAction) {
      case "edit":
        return "Modifier une ressource";
      case "delete":
        return "Supprimer une ressource";
      case "add":
        return "Créer une nouvelle ressource";
      default:
        return "Créer une nouvelle ressource";
    }
  }, [ressourceAction]);

  const handleSubmit = (values) => {
    let newList = undefined;

    if (ressourceAction) {
      if (!actionConfig || !actionConfig.actionConfigId) {
        switch (ressourceAction) {
          case "edit": {
            newList = handleUpdateRessource(selectedRessource?.id, values.newValues);
            break;
          }
          case "delete": {
            newList = handleDeleteRessource(selectedRessource?.id);
            break;
          }
          default: {
            newList = handleAddRessource(values);
          }
        }
      } else {
        switch (ressourceAction) {
          case "edit": {
            newList = handleEditResourceConfig(values?.resourceId, values?.payload);
            break;
          }
          case "delete": {
            ("");
            break;
          }
          default: {
            handleAddNewResourceConfig(values);
          }
        }
      }
    }
    handleAddEditRessource(newList);
    closeModal();
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const renderModalContent = useCallback(() => {
    return (
      <AddEditRessourceContent
        handleSubmit={handleSubmit}
        handleCloseModal={handleCloseModal}
        action={ressourceAction}
        selectedRessource={selectedRessource}
        creation={creation}
        editOnly={editOnly}
      />
    );
  }, [ressourceAction, selectedRessource]);

  const { closeModal, ModalComponent, ModalButton } = useModalWithContent(renderModalContent(), ressourceActionTitle, "md");

  return (
    <div>
      <>
        <div className="m-2" style={{ maxHeight: "145px", overflow: "auto" }}>
          {ressourceList?.map((rsc, index) => {
            return (
              <div className="d-flex justify-content-between" key={index}>
                <div className="text-truncate">
                  <a href={rsc?.url ?? "#"} target="_blank" rel="noreferrer">
                    <span className="link-opacity-100 link-underline-dark"> {rsc?.title}</span> &nbsp;
                  </a>
                  <RessourceStatusComp status={rsc?.status} />
                  {!!rsc?.rating && (
                    <span className="px-1">
                      {rsc?.rating} <i className="bx bxs-star text-warning"></i>
                    </span>
                  )}
                </div>
                <div className="d-flex flex-row">
                  <div
                    onClick={() => {
                      setRessourceAction("edit");
                      setSelectedRessource(rsc);
                    }}
                    key={index + "edit"}
                  >
                    <ModalButton buttonText="" buttonColor="" icon={"bx bx-pencil"} />
                  </div>
                  {!editOnly && (
                    <div
                      onClick={() => {
                        setRessourceAction("delete");
                        setSelectedRessource(rsc);
                      }}
                      key={index + "delete"}
                    >
                      <ModalButton buttonText="" buttonColor="" icon={"bx bx-trash"} />
                    </div>
                  )}
                  {editOnly && (
                    <div
                      onClick={() => {
                        setRessourceAction("delete");
                        setSelectedRessource(rsc);
                      }}
                      key={index + "delete"}
                    >
                      <ResourceRatingHistory resource={rsc?.ratings} userList={userOptions} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <Alert color="danger" isOpen={displayAlert} className="text-center" toggle={() => setDisplayAlert(false)}>
          {alertText}
        </Alert>
        <ModalComponent />
      </>

      <div
        onClick={() => {
          setRessourceAction("add");
          setSelectedRessource(null);
        }}
      >
        <ModalButton buttonText="" buttonColor="" icon={"bx bx-plus"} />
        ressource
      </div>
    </div>
  );
};

export default AddEditRessourceComp;
