import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllQuestConfigs } from "../redux/slices/QuestConfigSlice";
import { getAllBlocConfigs } from "../redux/slices/BlocConfigSlice";
import { getSeasonList } from "../redux/slices/SeasonSlice";
import { getAllEnums } from "../redux/slices/EnumsSlice";
import QuestConfigDataService from "../services/QuestConfigDataService";
import BlocConfigDataService from "../services/BlocConfigDataService";
import { v4 as uuid } from "uuid";
import { Spinner } from "reactstrap";
import MyBreadcrumb from "../components/breadcrumb/MyBreadcrumb";
import NewQuestConfigForm from "../components/newQuestConfigPage/NewQuestConfigForm";
import "../components/newQuestConfigPage/newQuestConfigPage.css";
import { notify } from "../assets/utils/UtilsFunctons";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const NewQuestConfigPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { questConfigs, loading_getQuestConfigs } = useSelector((state) => ({ ...state.questConfigs }));
  const { seasonList } = useSelector((state) => ({ ...state.seasonList }));
  const { blocConfigs } = useSelector((stateBloc) => ({ ...stateBloc.blocConfig }));
  const { enumList } = useSelector((state) => ({ ...state.enums }));

  useEffect(() => {
    if (!seasonList) {
      dispatch(getSeasonList());
    }
    if (!blocConfigs) {
      dispatch(getAllBlocConfigs());
    }

    if (!questConfigs) {
      dispatch(getAllQuestConfigs());
    }
    if (!enumList) {
      dispatch(getAllEnums());
    }
  }, []);

  const handleAddNewQuestConfig = async (formValue) => {
    try {
      let payload = {
        questConfigId: uuid(),
        title: formValue.title,
        description: formValue.description,
        needsValidation: formValue.needsValidation,
        questFunction: formValue.questFunction,
        ListenedQuestConfigId:
          formValue.questFunction !== 4 && formValue.questFunction !== 5 && formValue.questFunction !== 6 ? null : formValue.ListenedQuestConfigId,
        unlockedBy: !formValue.blocId || !formValue.unlockedBy ? null : formValue.unlockedBy,
        blocId: formValue.blocId,
        theme: formValue.theme ?? "",
        category: formValue.category,
        year: formValue.questFunction === 6 ? parseInt(formValue.year) : null,
        isVisibleByUsers: formValue.isVisibleByUsers,
        maxProgress: formValue.needsValidation ? null : formValue.maxProgress,
      };

      if (formValue.newBlocConfig) {
        await BlocConfigDataService.addNewBlocConfigs(formValue.newBlocConfig);
      }

      await QuestConfigDataService.addNewQuestConfigs(payload);
      notify(1, "Configuration de quête ajoutée avec succès !");
      navigate("/questConfigPage/" + payload.questConfigId);
    } catch (error) {
      notify(0, error?.response.data.title ?? "une erreur est survenue");
    }
  };

  return (
    <div className="new-questConfig-page">
      <MyBreadcrumb pageTitle={"Administration des quêtes"} subPageTitle={"Ajout d'une nouvelle quête"}></MyBreadcrumb>
      {loading_getQuestConfigs ? (
        <div className="get-questConfig-spinner">
          <Spinner color="secondary"></Spinner>
        </div>
      ) : (
        <div className="main-content myCard">
          <NewQuestConfigForm handleAddNewQuestConfig={handleAddNewQuestConfig} />
        </div>
      )}
      <Toaster></Toaster>
    </div>
  );
};

export default NewQuestConfigPage;
