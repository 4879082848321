import { React, useState } from "react";
import { v4 as uuid } from "uuid";
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap";

const AddNewBloc = ({ getNewBlocConfig, closeModal }) => {
  const [formValue, setformValue] = useState({
    blocId: uuid(),
    name: "",
    isBlocVisibleByUser: "",
  });

  const handleSubmit = () => {
    const payload = {
      blocId: formValue.blocId,
      name: formValue.name,
      isBlocVisibleByUser: formValue.isBlocVisibleByUser === "true",
    };
    getNewBlocConfig(payload);
    closeModal();
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleReset = () => {
    closeModal();
  };

  return (
    <div>
      <FormGroup>
        <Label for="name">Nom bloc</Label>
        <Input type="text" name="name" id="name" onChange={handleChange} required placeholder="Nom Bloc" autoComplete="off" />
      </FormGroup>
      <FormGroup>
        <Label for="isBlocVisibleByUser">Visible pour les users</Label>
        <Input type="select" name="isBlocVisibleByUser" id="isBlocVisibleByUser" onChange={handleChange} required autoComplete="off">
          <option value="" disabled={true} selected="selected">
            Choisir une catégorie
          </option>
          <option value={true}> Oui</option>
          <option value={false}> Non</option>
        </Input>
      </FormGroup>
      <Row>
        <Col md={6}>
          <Button color="success" type="button" block onClick={handleSubmit}>
            Ajouter
          </Button>
        </Col>
        <Col md={6}>
          <Button color="danger" type="button" block onClick={handleReset}>
            Annuler
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AddNewBloc;
