import { React, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import ConfirmModal from "../components/modals/FormConfirmDialogModal";
import RewardTemplateDataService from "../services/RewardTemplateDataService";
import { getAllRewardTemplates } from "../redux/slices/RewardTemplateSlice";
import Loader from "../components/loader/Loader";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import UseModalWithContent from "../components/useModalWithContent/UseModalWithContent";
import RewardTemplateForm from "../components/rewardTemplate/RewardTemplateForm";
import "./../components/rewardTemplate/rewardTemplateCard.css";
import { notify } from "../assets/utils/UtilsFunctons";
import { Toaster } from "react-hot-toast";
import RewardTemplateTable from "../components/reward template/RewardTemplateTable";

const RewardTemplatePage = () => {
  const [refreshPage, setRefreshPage] = useState(false);
  const { rewardTemplates, loading_getAllRewardTemplates } = useSelector((state) => ({ ...state.rewardTemplate }));
  const dispatch = useDispatch();
  const handleAddRewardTemplate = async (formValue) => {
    try {
      if (formValue) {
        await RewardTemplateDataService.addNewRewardTemplate(formValue);
        notify(1, "Template Ajouté avec succés !");
        setRefreshPage((prev) => !prev);
      }
    } catch (error) {
      notify(0, error?.response?.data?.title ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };
  const handleAchiveRewardTemplate = async (formValue) => {
    try {
      if (formValue && formValue.rewardTemplateId) {
        await RewardTemplateDataService.archiveRewardTemplate(formValue.rewardTemplateId);
        let message = formValue.archived ? "Template activé avec succès !" : "Template archivé avec succès !";
        notify(1, message);
        setRefreshPage((prev) => !prev);
      }
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };
  const handleDeleteRewardTemplate = async (formValue) => {
    try {
      if (formValue) {
        await RewardTemplateDataService.deleteRewardTemplate(formValue);
        notify(1, "Template supprimé avec succès !");
        setRefreshPage((prev) => !prev);
      }
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };

  const handleUpdateRewardTemplate = async (formValue) => {
    try {
      if (formValue && formValue) {
        await RewardTemplateDataService.updateRewardTemplate(formValue);
        notify(1, "Template modifié avec succès !");
        setRefreshPage((prev) => !prev);
      }
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const { closeModal, ModalComponent, ModalButton } = UseModalWithContent(
    <RewardTemplateForm handleAddRewardTemplate={handleAddRewardTemplate} closeModal={handleCloseModal} />,
    "Ajouter un template"
  );

  useEffect(() => {
    dispatch(getAllRewardTemplates());
  }, [refreshPage]);

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="page-header">Modèles de gratification</h2>
        </div>
        <div>
          {rewardTemplates && rewardTemplates.data ? (
            <>
              <ModalButton buttonText="Ajouter template" buttonColor="success" />
              <ModalComponent />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <ConfirmContextProvider>
        <ConfirmModal></ConfirmModal>
        <div className="myCard">
          {loading_getAllRewardTemplates ? (
            <Loader></Loader>
          ) : rewardTemplates && rewardTemplates.data ? (
            <div>
              <RewardTemplateTable
                rewardTemplates={rewardTemplates.data}
                handleAchiveRewardTemplate={handleAchiveRewardTemplate}
                handleUpdateRewardTemplate={handleUpdateRewardTemplate}
                handleDeleteRewardTemplate={handleDeleteRewardTemplate}
              />
            </div>
          ) : (
            <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
          )}
        </div>
      </ConfirmContextProvider>
    </div>
  );
};

export default RewardTemplatePage;
