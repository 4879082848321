import { configureStore } from "@reduxjs/toolkit";
import QuestConfigReducer from "./slices/QuestConfigSlice";
import seasonReducer from "./slices/SeasonSlice";
import leagueConfigReducer from "./slices/LeagueConfigSlice";
import levelConfigReducer from "./slices/LevelConfigSlice";
import blocConfigReducer from "./slices/BlocConfigSlice";
import questInfosReducer from "./slices/QuestSlice";
import userInfoReducer from "./slices/UserSlice";
import EnumReducer from "./slices/EnumsSlice";
import missionSheetReducer from "./slices/MissionSheetSlice";
import shopItemReducer from "./slices/ShopSlice";
import logReducer from "./slices/LogSlice";
import digDaysReducer from "./slices/DigDaysSlice";
import quizReducer from "./slices/QuizSlice";
import rewardTemplateReducer from "./slices/RewardTemplateSlice";
import utilsReducer from "./slices/UtilsSlice";
import MaintenanceConfigSlice from "./slices/MaintenanceConfigSlice";
import PepReducer from "./slices/PepSlice";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  questConfigs: QuestConfigReducer,
  seasonList: seasonReducer,
  leagueConfig: leagueConfigReducer,
  levelConfig: levelConfigReducer,
  blocConfig: blocConfigReducer,
  questInfos: questInfosReducer,
  userInfos: userInfoReducer,
  enums: EnumReducer,
  missionSheet: missionSheetReducer,
  shopConfig: shopItemReducer,
  logs: logReducer,
  digDays: digDaysReducer,
  quiz: quizReducer,
  rewardTemplate: rewardTemplateReducer,
  utils: utilsReducer,
  maintenanceConfig: MaintenanceConfigSlice,
  pep: PepReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistReducers = persistReducer(persistConfig, rootReducer);

const Dstore = configureStore({
  reducer: persistReducers,
  middleware: [thunk],
});
const persistor = persistStore(Dstore);
export { Dstore, persistor };
