import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UtilsDataService from "../../services/UtilsDataService";

export const getSettings = createAsyncThunk("utils/getSettings", async () => {
  try {
    const response = await UtilsDataService.getSettings();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});
export const patchSettings = createAsyncThunk("utils/patchSettings", async (data) => {
  try {
    const response = await UtilsDataService.patchSettings(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const UtilsSlice = createSlice({
  name: "utils",
  initialState: {
    appSettings: null,
    loading_getSettings: true,
  },
  reducers: {},
  extraReducers: {
    [getSettings.pending]: (state) => {
      state.loading_getSettings = true;
    },
    [getSettings.fulfilled]: (state, { payload }) => {
      state.loading_getSettings = false;
      state.appSettings = payload;
    },
    [getSettings.rejected]: (state) => {
      state.loading_getSettings = false;
    },
  },
});

export default UtilsSlice.reducer;
