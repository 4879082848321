import { React, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem } from "reactstrap";

const BlocConfigDetailModal = ({ list }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <Button color="link" outline onClick={toggle}>
      <i className="bx bxs-show"> Voir détails</i>
    </Button>
  );

  const getQuestConfigUnlockedByGivenQuestId = (questId) => {
    if (list) {
      return list.find((questConfig) => questConfig.unlockedBy === questId);
    } else {
      return null;
    }
  };

  const getOrderedQuestConfigsByUnlockedBy = () => {
    let i = 1;
    let orderedList = [];
    let currentQuestConfig = getQuestConfigUnlockedByGivenQuestId(null);
    while (currentQuestConfig) {
      orderedList.push({ number: i++, title: currentQuestConfig.title });
      currentQuestConfig = getQuestConfigUnlockedByGivenQuestId(currentQuestConfig.questConfigId);
    }
    return orderedList;
  };

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Liste des quêtes associées au bloc
        </ModalHeader>
        <ModalBody>
          <div>
            quête(s) associées : <i>{list.length}</i>
          </div>
          <br />
          <div>
            <ListGroup flush>
              {list.length !== 0
                ? getOrderedQuestConfigsByUnlockedBy().map((item, index) => {
                    return <ListGroupItem key={index}>{item.number + ".   " + item.title}</ListGroupItem>;
                  })
                : "liste vide"}
            </ListGroup>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BlocConfigDetailModal;
