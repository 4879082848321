import axios from "axios";
import config from "../config/config";
import { msalInstance } from "./authConfig";
import { loginRequest } from "./authConfig";

const isTokenAboutToExpire = () => {
  let expirationDate = localStorage.getItem("tokenExpiration");
  const currentTime = new Date();
  const expirationTime = new Date(expirationDate ?? "");

  const timeDifference = expirationTime - currentTime;

  // check if remaining time is less than 5 minutes (300 000 millisecondes)
  return timeDifference < 300000;
};

// Fonction pour gérer les erreurs 503
const handleServiceUnavailable = () => {
  window.location.href = "/maintenance";
};

const cancelSource = axios.CancelToken.source();

const refreshToken = async () => {
  try {
    const response = await msalInstance.acquireTokenSilent(loginRequest);
    localStorage.setItem("token", response?.accessToken);
    localStorage.setItem("tokenExpiration", response?.expiresOn);
    msalInstance.setActiveAccount(response);
  } catch (error) {
    cancelSource.cancel("Request canceled");
    localStorage.setItem("token", null);
    localStorage.setItem("tokenExpiration", null);
    msalInstance.setActiveAccount(null);
    if (!msalInstance.interactionInProgress()) {
      msalInstance.logoutPopup();
    }
  }
};

const getAccessToken = async () => {
  let token = localStorage.getItem("token");
  if (!token || isTokenAboutToExpire()) {
    await refreshToken();
    return localStorage.getItem("token");
  } else {
    return token;
  }
};

const authenticatedAxios = axios.create({
  baseURL: config.baseURL,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

authenticatedAxios.interceptors.request.use(
  async (config) => {
    let token = "";
    await getAccessToken().then((response) => {
      token = response;
    });
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur de réponses
authenticatedAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Intercepter les réponses d'erreur
    if (error.response && error.response.status === 503) {
      // Traiter les erreurs 503 ici
      const serviceUnavailableError = new Error("Service temporairement indisponible");
      serviceUnavailableError.status = 503;
      handleServiceUnavailable();
      throw serviceUnavailableError;
    }

    if (error.response && error.response.status === 401) {
      // Traiter les erreurs 401 ici
      const unauthorizedError = new Error("Non autorisé");
      unauthorizedError.status = 401;
      // Rediriger vers la page de connexion
      localStorage.clear();
      msalInstance.logoutPopup();

      throw unauthorizedError;
    }
    return Promise.reject(error);
  }
);
export default authenticatedAxios;
