import http from "./http-common";

class UserDataService {
  getUsersInfos() {
    return http.get("/user");
  }
  getUserInfoById(data) {
    return http.get("/user", { params: { userId: data } });
  }

  getUserScoreVariations(data) {
    return http.get("/user/scoreVariations", { params: { userId: data } });
  }
  getUserCoinVariations(data) {
    return http.get("/user/coinVariations", { params: { userId: data } });
  }
  getUserSession(data) {
    return http.get("/user/session", { params: { userId: data } });
  }

  addNewUser(data) {
    return http.post("/user", data);
  }

  updateUserInfos(data) {
    return http.put("/user", data);
  }

  desactivateUser(data) {
    return http.put(`/user/deactivate?userId=${data}`);
  }

  deleteUser(data) {
    return http.delete(`/user?userId=${data}`);
  }

  getUserProfilePictureById(userId) {
    return http.get(`/user/picture/profile?userId=${userId}`);
  }

  postUserProfilePicture(payload) {
    const formData = new FormData();
    formData.append("file", payload.file);

    return http.post(`/user/picture/profile?userId=${payload.userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  deleteUserProfilePicture(userId) {
    return http.delete(`/user/picture/profile?userId=${userId}`);
  }

  /*gain*/

  updateUserCoins(data) {
    return http.put("/user/coins", data);
  }
  updateUserScore(data) {
    return http.put("/user/score", data);
  }
}

export default new UserDataService();
