import { React } from "react";
import EditableTable from "./EditableTable";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { addNewLevelConfigs } from "../../redux/slices/LevelConfigSlice";
import AddNewLevelConfigModal from "../modals/AddNewLevelConfigModal";
import EditLevelConfigModal from "../modals/EditLevelConfigModal";
import { Button } from "reactstrap";

const LevelTable = ({ inStandBYPhaseOrNot }) => {
  const { levelConfigs, loading_getLevelConfigs } = useSelector((stateLevel) => ({ ...stateLevel.levelConfig }));
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));

  const levelConfTableHead = ["Niveau", "Boost", "Nombre Utilisateurs", "Action"];

  const dispatch = useDispatch();

  function checkSort(levels) {
    for (let i = 1; i < Object.keys(levels).length; i++) {
      if (levels[i - 1].levelId > levels[i].levelId) {
        return false;
      }
    }
    return true;
  }

  function sortLevelId(data) {
    if (data) {
      let levels = { ...data };
      if (checkSort(levels)) {
        return levels;
      }
      for (let i = 1; i < Object.keys(levels).length; i++) {
        let current = levels[i];
        let j = i - 1;

        while (j >= 0 && levels[j].levels < current.levelId) {
          levels[j + 1] = levels[j];
          j -= 1;
        }
        levels[j + 1] = current;
      }
      return levels;
    }
  }

  let data = null;
  if (sortLevelId(levelConfigs.data)) {
    data = Object.values(sortLevelId(levelConfigs.data));
  }

  const addNewLevelConfig = (formValue) => {
    const payload = {
      levelId: formValue.levelId,
      boost: formValue.boost,
      levelConfigId: uuid(),
    };
    dispatch(addNewLevelConfigs(payload));
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderLevelBody = (item, index, data, user) => (
    <tr key={index}>
      <td>{item.levelId}</td>
      <td>{item.boost}%</td>
      {user != null ? (
        <td>
          {user.reduce((i, element) => {
            if (item.levelId === element.experience.levelId) {
              i = i + 1;
            }
            return i;
          }, 0)}
        </td>
      ) : (
        <td></td>
      )}
      <td>
        <div className="d-flex justify-content-start">
          <div className="mx-2">
            <EditLevelConfigModal buttonLabel="Edit" item={item} inStandBYPhaseOrNot={inStandBYPhaseOrNot}></EditLevelConfigModal>
          </div>
          <div>
            {data != null && data.at(-1).levelId === item.levelId ? (
              <EditLevelConfigModal buttonLabel="Delete" item={item} inStandBYPhaseOrNot={inStandBYPhaseOrNot}></EditLevelConfigModal>
            ) : (
              <Button color="danger" style={{ float: "left", marginRight: "10px" }} disabled={true}>
                <i className="bx bxs-trash"></i>
              </Button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );

  return (
    <div>
      <EditableTable
        limit="10"
        loading={loading_getLevelConfigs}
        striped={true}
        title="Liste des niveaux"
        modal={<AddNewLevelConfigModal postLevelConfig={addNewLevelConfig}></AddNewLevelConfigModal>}
        headData={levelConfTableHead}
        renderHead={(item, index) => renderHead(item, index)}
        bodyData={levelConfigs.status === 200 ? levelConfigs.data : []}
        renderBody={(item, index) => renderLevelBody(item, index, data, usersInfos.data)}
      />
    </div>
  );
};

export default LevelTable;
