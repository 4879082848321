import http from "./http-common";

class LogDataService {
  getLogByParentId(data) {
    return http.get("/log?parentId=" + data);
  }
  postLogByParentId(data) {
    return http.post("/log", data);
  }
  postLogFile(payload) {
    const formData = new FormData();
    formData.append("file", payload.mediaUrl);

    return http.post(`/log/file?userId=${payload.userId}&parentId=${payload.parentId}&parentType=${payload.parentType}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new LogDataService();
