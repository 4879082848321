import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MaintenanceService from "../../services/MaintenanceService";
import { notify } from "../../assets/utils/UtilsFunctons";

export const getCurrentMaintenance = createAsyncThunk("maintenance/getCurrentMaintenance", async () => {
  try {
    const response = await MaintenanceService.getMaintenance();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err?.response?.data?.title ?? "une erreur est survenue, veuillez reessayer", 0);
    return err.response.data;
  }
});

export const getMaintenanceHistory = createAsyncThunk("maintenance/getMaintenanceHistory", async () => {
  try {
    const response = await MaintenanceService.getMaintenanceHistory();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err?.response?.data?.title ?? "une erreur est survenue, veuillez reessayer", 0);
    return err.response.data;
  }
});

const MaintenanceConfigSlice = createSlice({
  name: "missionSheet",
  initialState: {
    currentMaintenance: undefined,
    loading_currentMaintenance: false,
    maintenanceHistory: undefined,
    loading_maintenanceHistory: false,
    isMaintenanceStarted: false,
    loading_startMaintenace: false,
    isMaintenanceEnded: false,
    loading_EndMaintenace: false,
  },
  reducers: {},
  extraReducers: {
    [getCurrentMaintenance.pending]: (state) => {
      state.loading_currentMaintenance = true;
    },
    [getCurrentMaintenance.fulfilled]: (state, { payload }) => {
      state.loading_currentMaintenance = false;
      state.currentMaintenance = payload;
      if (payload?.status === 200) {
        ("");
      } else if (payload?.title) {
        notify(payload.title, 0);
      }
    },
    // maintenance history
    [getMaintenanceHistory.pending]: (state) => {
      console.log("loading maintenance");
      state.loading_maintenanceHistory = true;
    },
    [getMaintenanceHistory.fulfilled]: (state, { payload }) => {
      state.loading_maintenanceHistory = false;
      state.maintenanceHistory = payload;
      if (payload?.status === 200) {
        ("");
      } else if (payload?.title) {
        notify(payload.title, 0);
      }
    },
  },
});

export default MaintenanceConfigSlice.reducer;
