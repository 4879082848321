import { React, useState } from "react";
import DiscussionPanel from "../discussionPanel/DiscussionPanel";
import Enums from "../../assets/JsonData/Enums";
import MissionSheetVersionDetailsTab from "./MissionSheetVersionDetailsTab";
import { convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Row, Col } from "reactstrap";
import useUserRole from "../../hooks/useUserRole";

const MissonSheetVersionModal = ({ missionVersion, consultantName, clientCompany }) => {
  const { isAdmin, hasAccessAsDeveloper, isManager } = useUserRole();
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle} outline color="danger">
      &times;
    </Button>
  );

  const visibleBtn = (
    <button onClick={toggle} className="button-with-no-style" data-bs-toggle="tooltip" data-bs-placement="top" title="voir détails">
      <i className="bx bx-search-alt-2 fs-4"></i>
    </button>
  );

  return (
    <div>
      {visibleBtn}
      <Modal isOpen={modal} toggle={toggle} keyboard={false} size="lg">
        <ModalHeader toggle={toggle} close={closeBtn}>
          {"Fiche Mission " +
            "V." +
            missionVersion.versionNumber +
            " (" +
            convertDateToLocalTime(missionVersion.missionPeriodDetails.startDate) +
            " - " +
            convertDateToLocalTime(missionVersion.missionPeriodDetails.endDate) +
            ")"}
        </ModalHeader>
        <ModalBody>
          <div className="m-3">
            <Row>
              <Col md={12}>
                <Row className="pb-4">
                  <span>
                    <b>
                      <i>{consultantName}</i>
                    </b>
                  </span>
                  <span>
                    <b>
                      <i>{missionVersion?.consultantRole}</i>
                    </b>
                  </span>
                  <span>
                    <b>
                      <i>{clientCompany}</i>
                    </b>
                  </span>
                </Row>
                <Row>
                  <MissionSheetVersionDetailsTab missionVersion={missionVersion} />
                </Row>
              </Col>
              {(isAdmin || hasAccessAsDeveloper || isManager) && (
                <Col md={12}>
                  <DiscussionPanel
                    logId={missionVersion.missionSheetId}
                    parentType={2}
                    stateTransition={Enums.matchMissionSheetState}
                    readOnly={!(isAdmin || hasAccessAsDeveloper || isManager)}
                  />
                </Col>
              )}
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MissonSheetVersionModal;
