import { React, useState } from "react";
import CreatableSelect from "react-select/creatable";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const AddNewShopItemConfigForm = ({ postShopItemConfig, modalConfig, existingThemeList }) => {
  const { isConfirmed } = useConfirm();
  const [formValue, setformValue] = useState({
    name: "",
    description: "",
    theme: "",
    cost: 0,
    pictureData: "",
    stock: null,
    stockIllimite: false,
    isActivated: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const confirmed = await isConfirmed("Souhaitez-vous ajouter cet article à la boutique ?");
    if (confirmed) {
      postShopItemConfig(formValue);
      modalConfig(false);
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckBoxChange = (event) => {
    if (event.target.name === "isActivated") {
      setformValue({
        ...formValue,
        isActivated: event.target.checked,
      });
    }
    if (event.target.name === "stockIllimite") {
      setformValue({
        ...formValue,
        stockIllimite: event.target.checked,
        stock: event.target.checked ? "" : formValue.stock,
      });
    }
  };

  const handleFileInputChange = (event) => {
    const fileList = event.target.files;
    setformValue({
      ...formValue,
      pictureData: fileList[0],
    });
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });
  };

  const getThemeOptions = () => {
    let options = [];
    existingThemeList?.map((theme) => options.push({ value: theme, label: theme }));
    return options;
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Nom</Label>
          <Input type="text" name="name" id="name" required placeholder="Name" autoComplete="off" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input type="textarea" name="description" id="description" placeholder="Description" autoComplete="off" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="theme">Thème</Label>
          <CreatableSelect
            name="theme"
            isClearable
            isSearchable
            options={getThemeOptions()}
            onChange={handleReactSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
            placeholder="Thème ..."
          />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <Label for="cost">Prix</Label>
              <Input type="number" name="cost" id="cost" placeholder="Cost" autoComplete="off" onChange={handleChange} required />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="stock">Stock</Label>
              <Input
                type="number"
                min="0"
                name="stock"
                id="stock"
                placeholder="Stock"
                autoComplete="off"
                onChange={handleChange}
                disabled={formValue.stockIllimite}
                required={!formValue.stockIllimite}
                value={formValue.stock}
              />
              <small>
                <div className="form-check py-2 align-item-center">
                  <input type="checkbox" className="form-check-input" id="stockIllimite" name="stockIllimite" onChange={handleCheckBoxChange} />
                  <label className="form-check-label" for="stockIllimite">
                    Stock illimité
                  </label>
                </div>
              </small>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <div className="form-check py-2 align-item-center">
            <input
              type="checkbox"
              className="form-check-input"
              id="isActivated"
              name="isActivated"
              onChange={handleCheckBoxChange}
              checked={formValue.isActivated}
            />
            <label className="form-check-label" for="isActivated">
              Rendre ce produit actif pour les achats
            </label>
          </div>
        </FormGroup>
        <h4>Image</h4>
        <FormGroup className="mb-4">
          <Label for="exampleFile">Fichier</Label>
          <Input id="exampleFile" name="file" type="file" accept="image/png, image/jpeg" onChange={handleFileInputChange} required />
        </FormGroup>
        <Button color="success" type="submit" block>
          Ajouter
        </Button>
      </Form>
    </div>
  );
};

export default AddNewShopItemConfigForm;
