import { React, useState } from "react";
import AddNewShopItemConfigForm from "../forms/AddNewShopItemConfigForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const AddNewShopItemConfigModal = ({ postShopItemConfig, existingThemeList }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <Button color="success" onClick={toggle} id="addNewShopItemBtnTooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Ajouter un nouveau article">
      <i className="bx bx-add-to-queue" style={{ fontSize: "1.5em" }}></i>&nbsp;Ajouter un article
    </Button>
  );

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Ajout nouvel item
        </ModalHeader>
        <ModalBody>
          <AddNewShopItemConfigForm postShopItemConfig={postShopItemConfig} modalConfig={setModal} existingThemeList={existingThemeList} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewShopItemConfigModal;
