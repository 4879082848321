import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { InputGroup, InputGroupText, Input } from "reactstrap";

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);
  return (
    <div className="d-flex justify-content-end">
      <div className="row">
        <div className="col align-self-end">
          <InputGroup>
            <Input
              placeholder={count + " lignes..."}
              value={value || ""}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
            />
            <InputGroupText>
              <i className="bx bx-search"></i>
            </InputGroupText>
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default GlobalFilter;
