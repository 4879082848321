import React from "react";
import { Badge } from "reactstrap";

const RewardTemplateCard = ({ reward }) => {
  return (
    <article className="article">
      <h5 className="article__category">{reward.name}</h5>
      <hr />
      <span className="article__excerpt text-end mb-3 d-flex justify-content-end">
        <Badge color={reward.archived ? "danger" : "success"}>{reward.archived ? "Archivé" : "Actif"}</Badge>
      </span>
      {reward.rewards.map((item, index) => (
        <p className="article__excerpt" key={index}>
          <Badge color="primary" pill>
            {item.position === -1 ? "Autres" : item.position === 1 ? item.position + "er" : item.position + "ème"}
          </Badge>
          <br />
          coins {item.coins} - score {item.score} - cadeau {item.gift ? "Oui" : "Non"}
          <hr />
        </p>
      ))}
    </article>
  );
};

export default RewardTemplateCard;
