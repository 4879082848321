import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import PlanifyQuizConfigComponent from "../components/quiz/PlanifyQuizConfigComponent";
import DoneAndPastQuizContentComponent from "../components/quiz/DoneAndPastQuizContentComponent";
import ActivateQuizToggleButton from "../components/quiz/ActivateQuizToggleButton";
import ConfirmModal from "../components/modals/FormConfirmDialogModal";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import QuizConfigDataService from "../services/QuizConfigDataService";
import Loader from "../components/loader/Loader";
import { getQuizConfigById, getQuizRanksContents } from "../redux/slices/QuizSlice";
import { getAllRewardTemplates } from "../redux/slices/RewardTemplateSlice";
import EditQuizConfigForm from "../components/quiz/EditQuizConfigForm";
import QuizQuestionAccordion from "../components/quiz/QuizQuestionAccordion";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import { Row, Col, Button, Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import { notify } from "../assets/utils/UtilsFunctons";
import "../components/quiz/quizStyle.css";

const QuizDetailledPage = () => {
  const [refreshPage, setRefreshPage] = useState(false);
  const [uploadingQuizContent, setUploadingQuizContent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading_quizConfigById, quizConfigById, selectedQuiz } = useSelector((state) => ({ ...state.quiz }));
  const { rewardTemplates, loading_getAllRewardTemplates } = useSelector((state) => ({ ...state.rewardTemplate }));

  const handleUpdateQuizConfig = async (formValue) => {
    try {
      if (formValue) {
        setUploadingQuizContent(true);
        await QuizConfigDataService.updateQuizConfig(formValue);
        // on uploade le media du quiz au besoin
        if (formValue?.mediaUrl && formValue.doesExistingMediachange) {
          let payload = {
            quizConfigId: formValue.quizConfigId,
            mediaUrl: formValue.mediaUrl,
          };

          await QuizConfigDataService.postQuizConfigMedia(payload).catch((error) => {
            notify(0, error?.response?.data?.title ?? "une erreur est survenue lors du chargement du média");
          });
        }
        // on supprime le media du quiz au besoin
        if (!formValue?.mediaUrl && formValue.deleteMedia) {
          await QuizConfigDataService.deleteQuizConfigMedia(formValue.quizConfigId).catch((error) => {
            notify(0, error?.response?.data?.title ?? "une erreur est survenue lors du chargement du média");
          });
        }
        notify(1, "Quiz modifié avec succés !");
        setRefreshPage(!refreshPage);
      }
    } catch (error) {
      notify(0, error?.response?.data?.title ?? "une erreur est survenue");
    } finally {
      setUploadingQuizContent(false);
    }
  };

  const handleActivateQuizConfig = async () => {
    try {
      if (selectedQuiz) {
        await QuizConfigDataService.activateQuizConfig(selectedQuiz);
        notify(1, "Quiz activé avec succés !");
        setRefreshPage(!refreshPage);
      }
    } catch (error) {
      notify(0, error?.response?.data?.title ?? "une erreur est survenue");
    }
  };

  const getQuizConfigStateTextAndColor = () => {
    switch (quizConfigById?.data?.state) {
      case 0:
        return { text: "En cours", color: "success" };
      case 1:
        return { text: "En cours de classement", color: "success" };
      case 2:
        return { text: "Terminé", color: "success" };
      case 3:
        return { text: "Brouillon", color: "warning" };
      case 4:
        return { text: "Prêts / A planifier", color: "primary" };
      case 5:
        return { text: "Planifiés", color: "success" };

      default:
        return { text: "undefined", color: "info" };
    }
  };

  const isQuizConfigOnDraftState = () => {
    return quizConfigById?.data?.state === 3;
  };

  const isQuizConfigOnReadyState = () => {
    return quizConfigById?.data?.state > 3;
  };

  const isQuizOnDoneOrInProgressState = () => {
    switch (quizConfigById?.data?.state) {
      case 0:
        return true;
      case 1:
        return true;
      case 2:
        return true;
      default:
        return false;
    }
  };

  const processQuizQuestionsWithMedia = async (questionsWithMedia) => {
    const promises = questionsWithMedia.map((item) => QuizConfigDataService.postQuizQuestionMedia(item));
    const results = await Promise.all(promises);

    return results;
  };

  const processQuizQuestionsWithMediaToDelete = async (questionsWithMediaToDelete) => {
    const promises = questionsWithMediaToDelete.map((item) => QuizConfigDataService.deleteQuizQuestionMedia(item?.questionConfigId));
    const results = await Promise.all(promises);

    return results;
  };

  const handleQuizConfigContent = async (formValue) => {
    const filteredQuestionWithMedia = formValue?.questionConfigs?.filter((item) => item.mediaUrl !== null && item.doesExistingMediachange);
    const questionsWithMedia = Array.from(filteredQuestionWithMedia, (item) => ({
      questionConfigId: item.questionConfigId,
      mediaUrl: item.mediaUrl,
    }));
    const filteredQuestionWithMediaToDelete = formValue?.questionConfigs?.filter((item) => item.mediaUrl === null && item.hadMediaContentBefore);
    const questionsWithMediaToDelete = Array.from(filteredQuestionWithMediaToDelete, (item) => ({
      questionConfigId: item.questionConfigId,
    }));

    if (formValue) {
      try {
        if (formValue) {
          setUploadingQuizContent(true);
          // on poste le content de tout le quiz
          await QuizConfigDataService.updateQuizConfigContent(formValue);

          // on uploade les questions ayant un fichier média
          if (questionsWithMedia?.length > 0) {
            await processQuizQuestionsWithMedia(questionsWithMedia).catch((error) => {
              notify(0, error?.response?.data?.title ?? "une erreur est survenue lors du chargement du média");
            });
          }
          // on delete les media à supprimer au besoin
          if (questionsWithMediaToDelete?.length > 0) {
            await processQuizQuestionsWithMediaToDelete(questionsWithMediaToDelete).catch((error) => {
              notify(0, error?.response?.data?.title ?? "une erreur est survenue lors de la suppression du média");
            });
          }
          notify(1, "Question(s) ajoutées avec succés !");
          setRefreshPage(!refreshPage);
        }
      } catch (error) {
        notify(0, error?.response?.data?.title ?? "une erreur est survenue");
      } finally {
        setUploadingQuizContent(false);
      }
    }
  };

  const handlePlanifyQuizConfig = async (quizPlanificationDate) => {
    try {
      if (quizPlanificationDate) {
        await QuizConfigDataService.planifyQuizConfig(quizPlanificationDate);
        notify(1, "Quiz planifié avec succés !");
        setRefreshPage(!refreshPage);
      }
    } catch (error) {
      notify(0, error?.response?.data?.title ?? "une erreur est survenue");
    }
  };

  const goBackToQuizPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getQuizConfigById(selectedQuiz));
    dispatch(getAllRewardTemplates());
    dispatch(getQuizRanksContents(selectedQuiz));
  }, [refreshPage, selectedQuiz]);

  return (
    <div>
      <Toaster></Toaster>

      <div>
        {loading_quizConfigById || loading_getAllRewardTemplates ? (
          <Loader />
        ) : (
          <ConfirmContextProvider>
            <div>
              <ConfirmModal></ConfirmModal>
              {quizConfigById && quizConfigById.data && rewardTemplates && rewardTemplates.data ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="page-header">
                      <h2 className="page-header"> Détails du quiz</h2>
                    </div>
                    <div>
                      <Button type="button" color="primary" outline onClick={goBackToQuizPage}>
                        <i className="bx bx-arrow-back fs-5"></i> Retour
                      </Button>
                    </div>
                  </div>
                  <div className="myCard">
                    <Row>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start">
                          <h5>
                            Configuration <i className="bx bx-wrench"></i>
                          </h5>
                          <div>
                            <Badge color={getQuizConfigStateTextAndColor().color} className="fs-6 mx-2">
                              {getQuizConfigStateTextAndColor().text}
                            </Badge>
                          </div>
                        </div>
                        <ActivateQuizToggleButton
                          isToggleChecked={!isQuizConfigOnDraftState()}
                          handleActivateQuizConfig={handleActivateQuizConfig}
                          quiz={quizConfigById?.data}
                        />
                      </div>
                      <Col md={9}>
                        <EditQuizConfigForm
                          quiz={quizConfigById.data}
                          handleUpdateQuizConfig={handleUpdateQuizConfig}
                          disableButton={quizConfigById.data?.state !== 3}
                        />
                      </Col>
                      <Col md={3}>
                        <div className="d-flex align-items-center flex-column bd-highlight mb-3">
                          {isQuizConfigOnReadyState() && (
                            <PlanifyQuizConfigComponent quiz={quizConfigById.data} handlePlanifyQuizConfig={handlePlanifyQuizConfig} />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {isQuizOnDoneOrInProgressState() ? (
                    <>
                      <DoneAndPastQuizContentComponent
                        quiz={quizConfigById.data}
                        handleQuizConfigContent={handleQuizConfigContent}
                        disableButton={quizConfigById.data?.state !== 3}
                      />
                    </>
                  ) : (
                    <div className="myCard mt-3">
                      <Row>
                        <QuizQuestionAccordion
                          quiz={quizConfigById.data}
                          handleQuizConfigContent={handleQuizConfigContent}
                          disableButton={quizConfigById.data?.state !== 3}
                        />
                      </Row>
                    </div>
                  )}
                </>
              ) : (
                <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
              )}
              {uploadingQuizContent && (
                <Modal isOpen={true}>
                  <ModalHeader>Chargement</ModalHeader>
                  <ModalBody>
                    <Loader />
                  </ModalBody>
                </Modal>
              )}
            </div>
          </ConfirmContextProvider>
        )}
      </div>
    </div>
  );
};

export default QuizDetailledPage;
