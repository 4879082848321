import React from "react";
import { Spinner } from "reactstrap";

const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Spinner color="secondary">Loading...</Spinner>
    </div>
  );
};

export default Loader;
