import { React, useState } from "react";
import { FormGroup, Label, Input, FormText, Row, Col, Button } from "reactstrap";

const EditOrDeleteUserProfilePictureForm = (props) => {
  const [imgFile, setImgFile] = useState(null);

  const handleFileInputChange = (event) => {
    const fileList = event.target.files;
    setImgFile(fileList[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.typeOfAction === "update") {
      props.addOrUpdateUserProfilePicture(imgFile);
    } else {
      props.deleteUserProfilePicture();
    }
    props.modalConfig(false);
  };

  const handleReset = () => {
    props.modalConfig(false);
  };

  /* eslint react/no-unescaped-entities: "off" */
  return (
    <div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        {props.typeOfAction === "update" ? (
          <FormGroup>
            <Label for="exampleFile">Fichier</Label>
            <Input id="exampleFile" name="file" type="file" accept="image/png, image/jpeg" onChange={handleFileInputChange} />
            <FormText>Veuillez selectionner une photo</FormText>
          </FormGroup>
        ) : (
          <FormGroup className="mt-3 mb-3 text-center">
            <div className="text-align-center">
              <i className="bx bx-error-circle bx-tada confirmForm-icon"></i>
            </div>
            <Label className="mx-3">Souhaitez-vous supprimer la photo de profil de l'utilisateur ?</Label>
          </FormGroup>
        )}
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block>
              {props.typeOfAction === "update" ? "Modifier" : "Confirmer"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default EditOrDeleteUserProfilePictureForm;
