import { React } from "react";
import EditableTable from "./EditableTable";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { addNewBlocConfigs, updateBlocConfigs, deleteBlocConfigs } from "../../redux/slices/BlocConfigSlice";
import AddNewBlocConfigModal from "../modals/AddNewBlocConfigModal";
import EditBlocConfigModal from "../modals/EditBlocConfigModal";
import BlocConfigDetailModal from "../modals/BlocConfigDetailModal";
import "./blocTable.css";

const BlocTable = () => {
  const { blocConfigs, loading_getBlocConfigs } = useSelector((stateBloc) => ({ ...stateBloc.blocConfig }));
  const { questConfigs } = useSelector((state) => ({ ...state.questConfigs }));
  let questConfigListById = [];

  const blocConfTableHead = ["Nom du bloc", "Visible pour les users", "Configurations de quêtes", "actions"];

  const dispatch = useDispatch();

  const addNewBlocConfig = (formValue) => {
    const payload = {
      blocId: uuid(),
      name: formValue.name,
      isBlocVisibleByUser: formValue.isBlocVisibleByUser === "true",
    };
    dispatch(addNewBlocConfigs(payload));
  };

  const updateBlocConfig = (formValue) => {
    const payload = {
      blocId: formValue.blocId,
      name: formValue.name,
      isBlocVisibleByUser: formValue.isBlocVisibleByUser === "true",
    };
    dispatch(updateBlocConfigs(payload));
  };

  const deleteBlocConfig = (formValue) => {
    dispatch(deleteBlocConfigs(formValue));
  };

  const getQuestConfigListByBLocId = (blocId) => {
    if (!questConfigs || !questConfigs.data) return [];
    else {
      return questConfigs.data.filter((questConfig) => questConfig.blocId === blocId);
    }
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderBlocBody = (item, index) => (
    <>
      <tr key={index}>
        <td>{item.name}</td>
        <td>{item.isBlocVisibleByUser === true ? "oui" : "non"}</td>
        <td>{(questConfigListById = getQuestConfigListByBLocId(item.blocId)).length}</td>
        <td>
          <div className="action-section-modal">
            <BlocConfigDetailModal list={questConfigListById}></BlocConfigDetailModal>
            &nbsp;&nbsp;
            <EditBlocConfigModal buttonLabel="Edit" item={item} updateOrDeleteBlocConfig={updateBlocConfig}></EditBlocConfigModal>
            &nbsp;&nbsp;
            <EditBlocConfigModal
              buttonLabel="Delete"
              item={item}
              updateOrDeleteBlocConfig={deleteBlocConfig}
              buttonActive={questConfigListById.length > 0}
            ></EditBlocConfigModal>
          </div>
        </td>
      </tr>
    </>
  );

  return (
    <div>
      <EditableTable
        limit="10"
        loading={loading_getBlocConfigs}
        title="Liste des blocs"
        modal={<AddNewBlocConfigModal postBlocConfig={addNewBlocConfig}></AddNewBlocConfigModal>}
        headData={blocConfTableHead}
        renderHead={(item, index) => renderHead(item, index)}
        bodyData={blocConfigs?.status === 200 ? blocConfigs.data : []}
        renderBody={(item, index) => renderBlocBody(item, index)}
        striped={true}
      />
    </div>
  );
};

export default BlocTable;
