import React from "react";
import { useCallback, useEffect, useState } from "react";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import { Badge } from "reactstrap";
import { sortBy } from "lodash";
import fr from "date-fns/locale/fr";
import { format } from "date-fns";
import { DATE_RANGE_FILTER } from "../../../core/constant/filterComponent-const";
import "react-datepicker/dist/react-datepicker.css";
import "./myFilterItem.css";

const MyFilterItem = ({ name, filterKey, data, itemDisplayValueFunc, isMulti, onFilterUpdate, defaultValue, isDateRange, dateRangeKeyFilter }) => {
  let [currentDefaultValue, setCurrentDefaultValue] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  let [searchParams] = useSearchParams();

  const handleReactSelectChange = (choice, filter) => {
    if (Array.isArray(choice)) {
      onFilterUpdate(
        Array.from(choice, (item) => item.value),
        filterKey
      );
    } else {
      onFilterUpdate(choice?.value, filter, undefined);
    }
  };

  const handleDateFilterSelect = (date) => {
    let startDate = date[0];
    let endDate = date[1];
    if (startDate && endDate) {
      onFilterUpdate({ startDate: format(startDate, "dd/MM/yyyy"), endDate: format(endDate, "dd/MM/yyyy") }, DATE_RANGE_FILTER, dateRangeKeyFilter);
    }
    if (!startDate && !endDate) {
      onFilterUpdate(undefined, DATE_RANGE_FILTER, dateRangeKeyFilter);
    }
  };

  const getFilterOptions = useCallback(() => {
    let optionsList = [];
    if (!data) {
      return optionsList;
    } else {
      [...new Set(data)].map(
        (item) => item !== undefined && optionsList.push({ value: item, label: itemDisplayValueFunc ? itemDisplayValueFunc(item) : item })
      );
      return sortBy(optionsList, "label");
    }
  }, [data, itemDisplayValueFunc]);

  useEffect(() => {
    if (isMulti && defaultValue && defaultValue.length > 1) {
      setCurrentDefaultValue(getFilterOptions().filter((item) => defaultValue.includes(item.value)));
    } else {
      setCurrentDefaultValue(getFilterOptions().find((item) => item.value === defaultValue && searchParams?.size > 0));
    }
  }, [defaultValue, searchParams]);

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <Badge color="dark">{children}</Badge>
    </components.SingleValue>
  );

  return (
    <div className="d-flex justify-content-start w-100 gap-2 align-items-center">
      <span> {name}</span>
      {!isDateRange ? (
        <Select
          className="w-100"
          name={name}
          id={name}
          autoComplete="off"
          isMulti={isMulti}
          onChange={(e) => handleReactSelectChange(e, filterKey)}
          isSearchable
          isClearable
          options={getFilterOptions()}
          placeholder="Tout ..."
          value={currentDefaultValue}
          components={{ SingleValue }}
        />
      ) : (
        <DatePicker
          locale={fr}
          className="filter-date-range me-2 w-100"
          clearButtonClassName="filter-date-range-clear-button "
          showIcon={true}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
            handleDateFilterSelect(update);
          }}
          isClearable
          placeholderText="Choisir une période"
          dateFormat="dd/MM/yyyy"
        />
      )}
    </div>
  );
};

export default MyFilterItem;
