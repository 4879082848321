import http from "./http-common";

class QuizConfigDataService {
  getAllQuizConfigs() {
    return http.get("/quizConfig/list");
  }
  getQuizConfigById(quizConfigId) {
    return http.get("/quizConfig?quizConfigId=" + quizConfigId);
  }

  getQuizRanksContents(quizConfigId) {
    return http.get("/quiz/ranksContents?quizConfigId=" + quizConfigId);
  }

  addNewQuizConfigs(data) {
    return http.post("/quizConfig", data);
  }

  updateQuizConfig(data) {
    return http.put("/quizConfig", data);
  }
  updateQuizConfigContent(data) {
    return http.put("/quizConfig/content", data);
  }

  activateQuizConfig(quizConfigId) {
    return http.put("/quizConfig/activate?quizConfigId=" + quizConfigId);
  }

  planifyQuizConfig(data) {
    return http.put("/quizConfig/planify", data);
  }

  deleteQuizConfig(quizConfigId) {
    return http.delete("/quizConfig?quizConfigId=" + quizConfigId);
  }

  postQuizConfigMedia(payload) {
    const formData = new FormData();
    formData.append("file", payload.mediaUrl);

    return http.post(`/quizConfig/media?quizConfigId=${payload.quizConfigId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  postQuizQuestionMedia(payload) {
    const formData = new FormData();
    formData.append("file", payload.mediaUrl);

    return http.post(`/quizConfig/question/media?questionConfigId=${payload.questionConfigId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteQuizConfigMedia(quizConfigId) {
    return http.delete("/quizConfig/media?quizConfigId=" + quizConfigId);
  }
  deleteQuizQuestionMedia(questionConfigId) {
    return http.delete("/quizConfig/question/media?questionConfigId=" + questionConfigId);
  }
}

export default new QuizConfigDataService();
