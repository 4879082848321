import React from "react";
import Enums from "../../assets/JsonData/Enums";
import { questStateBadgeColor } from "./functions";
import { formatDateUtcToLocalTime } from "../../assets/utils/UtilsFunctons";
import { Card, Badge } from "reactstrap";
const QuestSerialValidationIterationList = ({ quests, setIterationIndex, iterationIndex }) => {
  return (
    <div className="mt-2">
      {quests?.map((quest, index) => (
        <div className="d-flex flex-column" key={index}>
          <div>
            <Card
              body
              className="mb-0 quest-serial-validation-iteration-list-item"
              onClick={() => {
                setIterationIndex(index);
              }}
              color={iterationIndex === index ? "primary" : ""}
              outline
            >
              <div className="d-flex justify-content-around">
                <div className="fs-1">#{quest?.iteration}</div>
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex flex-row justify-content-center">
                    <Badge color={questStateBadgeColor(quest?.questState)}>{Enums.matchQuestStatut(quest?.questState)}</Badge>
                  </div>
                  <br />
                  <div className="d-flex flex-row justify-content-center">{formatDateUtcToLocalTime(quest?.lastStateTransitionDate)}</div>
                </div>
              </div>
            </Card>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <i className="bx bx-chevron-down fs-1"></i>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestSerialValidationIterationList;
