import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import { getUsersInfos } from "../../redux/slices/UserSlice";
import { convertDateToLocalTime, daysBetweenTwoDates } from "../../assets/utils/UtilsFunctons";
import Select from "react-select";
import { Button, Form, FormGroup, Label, Input, Row, Col, Alert, FormText, ListGroup, ListGroupItem } from "reactstrap";

const UpdateMissionForm = ({ mission, handleUpdateMission, modalVisibility }) => {
  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [textAlert, setTextAlert] = useState(undefined);
  const { loading_getUsersInfos, usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { loading_modifiedMission } = useSelector((state) => ({ ...state.missionSheet }));
  const [formValue, setformValue] = useState({
    missionId: mission.missionId,
    userId: mission.userId,
    startDate: mission.startDate,
    boondProjectId: mission.boondProjectId,
    missionNumber: mission.missionNumber,
    clientCompany: mission.clientCompany,
    consultantRole: mission.consultantRole,
    clientMissionManager: mission.clientMissionManager,
    clientMonitoringManager: mission.clientMonitoringManager,
  });

  const handleAlertVisibility = () => {
    setAlertVisibility(!alertVisibility);
    modalVisibility(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isStartDateInputValid()) {
      let confirmed = await isConfirmed(InputsRecapForConfirmation());
      if (confirmed) {
        handleUpdateMission(formValue);
      }
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleReactSelectChange = (choice) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        userId: choice?.value ?? null,
      };
    });
  };

  const handleReset = () => {
    modalVisibility(false);
  };
  const usersListOption = () => {
    let list = [];

    if (usersInfos && usersInfos.data) {
      usersInfos.data.map((user) => {
        list.push({ value: user.userId, label: user.firstName + " " + user.lastName });
      });
      return list;
    } else return list;
  };

  const isStartDateInputValid = () => {
    if (!formValue.startDate) {
      return false;
    } else {
      let todayDate = new Date().getTime();
      let missionDate = new Date(formValue.startDate).getTime();

      if (missionDate > todayDate) {
        setAlertVisibility(true);
        setTextAlert("la  date de début ne peut pas être dans le futur !");
        return false;
      }
      return true;
    }
  };

  const isOneMonthDelayPast = () => {
    let todayDate = new Date();
    return daysBetweenTwoDates(todayDate, formValue.startDate) >= 30;
  };

  const InputsRecapForConfirmation = () => {
    return (
      <div>
        {isOneMonthDelayPast() && <Alert color="danger"> Attention : création d’une mission avec plus d’un mois de retard </Alert>}
        <span> Souhaitez-vous créer cette nouvelle mission ?</span>
        <br />
        <ListGroup>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div>Consultant </div>
              <div>{usersListOption().find((user) => user.value === formValue.userId)?.label}</div>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div>Début </div>
              <div>{convertDateToLocalTime(formValue.startDate)}</div>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div>Id projet </div>
              <div>{formValue.boondProjectId}</div>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div> Client </div>
              <div>{formValue.clientCompany}</div>
            </div>
          </ListGroupItem>
        </ListGroup>
      </div>
    );
  };

  useEffect(() => {
    if (!usersInfos) {
      dispatch(getUsersInfos());
    }
  }, []);

  return (
    <div>
      {loading_getUsersInfos ? (
        "chargement..."
      ) : (
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <div>
            <Alert color="danger" isOpen={alertVisibility} toggle={handleAlertVisibility}>
              {textAlert}
            </Alert>
            <ConfirmModal></ConfirmModal>
          </div>
          <FormGroup>
            <Label for="userId">
              Nom du consultant <sup>*</sup>
            </Label>
            <Select
              name="userId"
              required
              isClearable
              isSearchable
              defaultValue={usersListOption().find((opt) => opt.value === formValue.userId)}
              options={usersListOption()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
            />
          </FormGroup>
          <FormGroup>
            <Label for="startDate">
              Date de début<sup>*</sup>
            </Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              onChange={handleChange}
              required
              autoComplete="off"
              value={new Date(formValue.startDate.slice(0, 10)).toISOString().slice(0, 10)}
            />
            <FormText>la date de début doit être inférieure à la date du jour.</FormText>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="boondProjectId">
                  Id projet BoondManager <sup>*</sup>
                </Label>
                <Input
                  type="number"
                  name="boondProjectId"
                  id="boondProjectId"
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  min={0}
                  readOnly
                  disabled
                  defaultValue={formValue.boondProjectId}
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="missionNumber"> N° mission</Label>
                <Input
                  type="text"
                  name="missionNumber"
                  id="missionNumber"
                  onChange={handleChange}
                  autoComplete="off"
                  defaultValue={formValue.missionNumber}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="clientCompany">
                  Nom client<sup>*</sup>
                </Label>
                <Input
                  type="text"
                  name="clientCompany"
                  id="clientCompany"
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  defaultValue={formValue.clientCompany}
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="consultantRole">
                  Rôle du consultant <sup>*</sup>
                </Label>
                <Input
                  type="text"
                  name="consultantRole"
                  id="consultantRole"
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  defaultValue={formValue.consultantRole}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="clientMissionManager">
                  Responsable client <sup>*</sup>
                </Label>
                <Input
                  type="text"
                  name="clientMissionManager"
                  id="clientMissionManager"
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  defaultValue={formValue.clientMissionManager}
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="clientMonitoringManager">Responsable technique client</Label>
                <Input
                  type="text"
                  name="clientMonitoringManager"
                  id="clientMonitoringManager"
                  onChange={handleChange}
                  autoComplete="off"
                  defaultValue={formValue.clientMonitoringManager}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button color="danger" type="reset" block>
                Annuler
              </Button>
            </Col>
            <Col md={6}>
              <Button color="warning" type="submit" block disabled={loading_modifiedMission}>
                {loading_modifiedMission ? <i className="bx bx-dots-horizontal-rounded bx-flashing"></i> : "Modifier"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default UpdateMissionForm;
