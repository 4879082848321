import { React, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { v4 as uuid } from "uuid";
import QuestDataService from "../../services/QuestDataService";
import QuestSerialValidationHeader from "./QuestSerialValidationHeader";
import QuestSerialValidationDiscussion from "./QuestSerialValidationDiscussion";
import QuestSerialValidationIterationList from "./QuestSerialValidationIterationList";
import { notify } from "../../assets/utils/UtilsFunctons";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";

const QuestSerialValidationModal = ({ quests, openedByDefault, indexFromRow, refreshMainPage, refreshPage }) => {
  const { accounts } = useMsal();

  const [modal, setModal] = useState(false);
  const [openByRowDoubleClick, setOpenByRowDoubleClick] = useState(openedByDefault);
  const [userAndTitleIndex, setUserAndTitleIndex] = useState(0);
  const [refreshDiscussion, setRefreshDiscussion] = useState(false);

  const groupQuestsByUserAndTitle = (quests) => {
    let questsByNameAndTitle = [];

    quests.forEach((quest) => {
      let cle = quest.userId + "-" + quest.title;

      if (!questsByNameAndTitle[cle]) {
        questsByNameAndTitle[cle] = [];
      }

      questsByNameAndTitle[cle].push(quest);
    });

    let result = Object.values(questsByNameAndTitle);
    result.forEach((subTab) => {
      subTab.sort((a, b) => a.iteration - b.iteration);
    });

    if (openedByDefault) {
      return result;
    } else
      return result.filter((quests) => {
        return quests.some((quest) => {
          return quest.questState === 1;
        });
      });
  };

  const [questsByUserAndTitle, setQuestsByUserAndTitle] = useState(groupQuestsByUserAndTitle(quests));
  //
  const firstQuestTovalidateIndex = questsByUserAndTitle.length > 0 ? questsByUserAndTitle[0].findIndex((elt) => elt.questState === 1) : 0;
  const [iterationIndex, setIterationIndex] = useState(indexFromRow ? indexFromRow : firstQuestTovalidateIndex > 0 ? firstQuestTovalidateIndex : 0);

  const toggle = () => {
    setModal(!modal);
    setOpenByRowDoubleClick(false);
  };

  const closeBtn = (
    <Button
      className="close"
      onClick={() => {
        toggle();
        refreshMainPage(!refreshPage);
      }}
    >
      &times;
    </Button>
  );

  const postQuestDecisionHandler = async (formValue, closeModal) => {
    let questValidationContract = {
      questId: formValue.questId,
      decidedQuestState: formValue.decidedQuestState,
    };
    if (formValue.message.length > 0) {
      questValidationContract = {
        questId: formValue.questId,
        decidedQuestState: formValue.decidedQuestState,
        message: {
          messageId: uuid(),
          parentId: formValue.questId,
          userId: accounts[0].localAccountId,
          text: formValue.message,
        },
      };
    }

    try {
      if (questValidationContract) {
        await QuestDataService.postQuestDecision(questValidationContract);

        let newQuestsByUserAndTitle = questsByUserAndTitle?.map((subArray, subArrayIndex) => {
          if (subArrayIndex === userAndTitleIndex) {
            // Utilisation de  .map() pour créer un nouveau sous-tableau avec l'élément modifié
            const updatedSubArray = subArray.map((objet) => {
              if (objet.questId === questValidationContract.questId) {
                return {
                  ...objet,
                  questState: questValidationContract.decidedQuestState,
                  lastStateTransitionDate: new Date(),
                };
              }
              return objet;
            });
            return updatedSubArray;
          }
          return subArray; // Pour les sous-tableaux non modifiés,  on retourne le sous-tableau d'origine
        });

        setQuestsByUserAndTitle(newQuestsByUserAndTitle);
        setRefreshDiscussion(!refreshDiscussion);
        notify(1, "Modification apportée avec succés !");
      }
    } catch (error) {
      notify(0, error?.response?.data.title ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };

  return (
    <div>
      <i className="bx bx-check-circle" onClick={() => toggle()}></i>
      <Modal className="quest-serial-validation-modal" isOpen={modal || openByRowDoubleClick} toggle={toggle} backdrop={"static"} keyboard={false} size="xl">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Validation en série : {userAndTitleIndex + 1} / {questsByUserAndTitle.length}
        </ModalHeader>
        {questsByUserAndTitle && questsByUserAndTitle.length > 0 && (
          <ModalBody>
            <QuestSerialValidationHeader
              quest={questsByUserAndTitle[userAndTitleIndex][iterationIndex]}
              questList={questsByUserAndTitle}
              setIterationIndex={setIterationIndex}
              setUserAndTitleIndex={setUserAndTitleIndex}
              userAndTitleIndex={userAndTitleIndex}
              maxLength={questsByUserAndTitle.length}
              postQuestDecision={postQuestDecisionHandler}
            />
            <Row>
              <Col md={4} className="quest-serial-validation-iteration-list">
                <QuestSerialValidationIterationList
                  quests={questsByUserAndTitle[userAndTitleIndex]}
                  setIterationIndex={setIterationIndex}
                  iterationIndex={iterationIndex}
                />
              </Col>
              <Col md={8}>
                <QuestSerialValidationDiscussion
                  logId={questsByUserAndTitle[userAndTitleIndex][iterationIndex]?.questId}
                  userId={accounts[0]?.localAccountId}
                  refresh={refreshDiscussion}
                />
              </Col>
            </Row>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

export default QuestSerialValidationModal;
