import { React, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllDigDays, digDayDecision, cancelDigDay } from "../redux/slices/DigDaysSlice";
import { postLogByParentId } from "../redux/slices/LogSlice";
import { v4 as uuid } from "uuid";
import DigDaysDecisionModal from "../components/digDays/DigDaysDecisionModal";
import DigDayDetailTable from "../components/digDays/DigDayDetailTable";
import DiscussionPanel from "../components/discussionPanel/DiscussionPanel";
import { Row, Col, Button } from "reactstrap";
import { Toaster } from "react-hot-toast";
import Loader from "../components/loader/Loader";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import Enums from "../assets/JsonData/Enums";
import useUserRole from "../hooks/useUserRole";
import DigDaysPepActionComponent from "../components/digDays/digDays-pep-actions/DigDaysPepActionComponent";

const DigDayDetailPage = () => {
  const { isAdmin, hasAccessAsDeveloper, isManager } = useUserRole();
  const navigate = useNavigate();
  const params = useParams();
  const digDayId = params?.digDayId;
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const { loading_getAllDigDays, digDays, refreshDigDaysPage } = useSelector((state) => ({ ...state.digDays }));

  const handleDigDayDecision = (decision, data) => {
    let payload = undefined;
    if (decision === "cancel") {
      payload = {
        digDayId: data.digDayId,
        message: {
          messageId: uuid(),
          parentId: data.digDayId,
          userId: accounts[0]?.idTokenClaims?.oid,
          text: data.message,
        },
      };
      dispatch(cancelDigDay(payload));
    }
    if (decision === "notification") {
      payload = {
        messageId: uuid(),
        parentId: data.digDayId,
        userId: accounts[0]?.idTokenClaims?.oid,
        text: data.message,
      };
      dispatch(postLogByParentId(payload));
    }
    if (decision === "validation" || decision === "rejection" || decision === "adjustment") {
      payload = {
        digDayId: data.digDayId,
        decidedDigDayState: data.decidedDigDayState,
        message: data.message
          ? {
              messageId: uuid(),
              parentId: data.digDayId,
              userId: accounts[0]?.idTokenClaims?.oid,
              text: data.message,
            }
          : undefined,
      };
      dispatch(digDayDecision(payload));
    }
  };

  const digDayActionsBasedOnState = (digDay) => {
    if (digDay) {
      if (digDay.state === 0) {
        // planifié
        return (
          <div className="d-flex justify-content-end">
            <DigDaysDecisionModal decision="cancel" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
          </div>
        );
      }

      if (digDay.state === 1 || digDay.state === 3) {
        // attente de soumission ou correction
        return (
          <div className="d-flex justify-content-end">
            <DigDaysDecisionModal decision="notification" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
          </div>
        );
      }

      if (digDay.state === 2) {
        // en attente de validation
        return (
          <div className="d-flex justify-content-end">
            <span className="mx-2">
              <DigDaysDecisionModal decision="validation" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
            </span>
            <span className="mx-2">
              <DigDaysDecisionModal decision="adjustment" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
            </span>
            <span className="mx-2">
              <DigDaysDecisionModal decision="rejection" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
            </span>
          </div>
        );
      }
    }
  };

  const handleGoBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getAllDigDays());
  }, [refreshDigDaysPage, dispatch]);
  return (
    <div>
      <Toaster></Toaster>
      <div className="d-flex justify-content-between">
        <h2 className="page-header">Détails du DigDay</h2>
        <div>
          <Button outline color="primary" onClick={handleGoBackButton}>
            <i className="bx bx-arrow-back"> Retour</i>
          </Button>
        </div>
      </div>
      {loading_getAllDigDays ? (
        <Loader></Loader>
      ) : digDays && digDays.data ? (
        <div className="myCard">
          <div className="mb-2">
            {(isAdmin || hasAccessAsDeveloper || isManager) && digDayActionsBasedOnState(digDays.data.find((dd) => dd.digDayId === digDayId))}
          </div>
          <Row>
            <Col lg={5}>
              <DigDayDetailTable digDay={digDays.data.find((dd) => dd.digDayId === digDayId)}></DigDayDetailTable>
              <br />
              <DigDaysPepActionComponent
                userId={digDays.data.find((dd) => dd.digDayId === digDayId)?.userId}
                pepActionList={digDays.data.find((dd) => dd.digDayId === digDayId)?.actionIds}
              />
            </Col>
            <Col lg={1}>
              <div className="vr h-100"></div>
            </Col>

            <Col lg={6}>
              <DiscussionPanel
                logId={digDayId}
                userId={digDays.data.find((dd) => dd.digDayId === digDayId)?.userId}
                parentType={1}
                stateTransition={Enums.matchDigDayState}
                readOnly={!(isAdmin || hasAccessAsDeveloper || isManager)}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
      )}
    </div>
  );
};

export default DigDayDetailPage;
