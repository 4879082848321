import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import ConfirmModal from "../components/modals/FormConfirmDialogModal";
import Loader from "../components/loader/Loader";
import QuizConfigDataService from "../services/QuizConfigDataService";
import { getAllQuizConfigs } from "../redux/slices/QuizSlice";
import { getAllRewardTemplates } from "../redux/slices/RewardTemplateSlice";
import UseModalWithContent from "../components/useModalWithContent/UseModalWithContent";
import { Toaster } from "react-hot-toast";
import { notify } from "../assets/utils/UtilsFunctons";
import AddNewQuizForm from "../components/quiz/AddNewQuizForm";
import "../assets/css/userDetailledInfosPage.css";
import QuizConfigTable from "../components/quiz/QuizConfigTable";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";

const QuizPage = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [tabs, setTabs] = useState([]);
  const { loading_quizConfigs, quizConfigs } = useSelector((state) => ({ ...state.quiz }));
  const { rewardTemplates, loading_getAllRewardTemplates } = useSelector((state) => ({ ...state.rewardTemplate }));
  const [refreshPage, setRefreshPage] = useState(false);
  const dispatch = useDispatch();

  const handleAddNewQuizConfig = async (formValue) => {
    try {
      if (formValue) {
        await QuizConfigDataService.addNewQuizConfigs(formValue);
        // on uploade le media du quiz au besoin
        if (formValue?.mediaUrl) {
          let payload = {
            quizConfigId: formValue.quizConfigId,
            mediaUrl: formValue.mediaUrl,
          };
          await QuizConfigDataService.postQuizConfigMedia(payload).catch((error) => {
            notify(0, error?.response?.data?.title ?? "une erreur est survenue lors du chargement du média");
          });
        }
        setRefreshPage((prev) => !prev);
        notify(1, "Quiz Ajouté avec succésss !");
      }
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };

  const handleDeleteQuiz = async (formValue) => {
    if (formValue) {
      try {
        if (formValue) {
          await QuizConfigDataService.deleteQuizConfig(formValue);
          notify(1, "Quiz supprimé avec succés !");
          setRefreshPage((prev) => !prev);
        }
      } catch (error) {
        notify(0, error?.message ?? "une erreur est survenue");
      }
    }
  };

  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    dispatch(getAllQuizConfigs());
    dispatch(getAllRewardTemplates());
    setTabs([
      { id: "tab1", label: "Brouillons", state: [3] },
      { id: "tab2", label: "Prêts / A planifier", state: [4] },
      { id: "tab3", label: "Planifiés", state: [5] },
      { id: "tab4", label: "En cours", state: [0, 1] },
      { id: "tab5", label: "Passés", state: [2] },
    ]);
  }, [refreshPage]);

  const { closeModal, ModalComponent, ModalButton } = UseModalWithContent(
    <AddNewQuizForm handleAddNewQuizConfig={handleAddNewQuizConfig} handleCloseModal={handleCloseModal} />,
    "Ajouter un quiz",
    "lg"
  );

  const getQuizExistingThemeList = () => {
    let themeList = [];
    if (loading_quizConfigs || !quizConfigs || quizConfigs.status !== 200 || !quizConfigs.data) {
      return themeList;
    } else {
      themeList = Array.from(quizConfigs.data, (item) => item.theme);
      return [...new Set(themeList)];
    }
  };

  const getThemeOptions = () => {
    let options = [];
    let existingThemeList = getQuizExistingThemeList();
    existingThemeList?.map((theme) => options.push({ value: theme, label: theme }));
    localStorage.setItem("quizThemeOptions", JSON.stringify(options));
  };

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="page-header">Gestion des quiz</h2>
        </div>
        <div>
          {!loading_getAllRewardTemplates && rewardTemplates && rewardTemplates.data ? (
            <>
              <ModalButton buttonText="Ajouter quiz" buttonColor="success" />
              <ModalComponent />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <ConfirmContextProvider>
        <ConfirmModal></ConfirmModal>
        <div className="myCard">
          {tabs?.length <= 0 || loading_quizConfigs ? (
            <Loader></Loader>
          ) : quizConfigs && quizConfigs.data ? (
            <div>
              {getThemeOptions()}
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {tabs.map(({ id, label, index }) => (
                  <li className="nav-item" key={index}>
                    <p
                      className={`nav-link ${activeTab === id ? "active" : ""}`}
                      role="tab"
                      aria-selected={activeTab === id}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab(id);
                      }}
                    >
                      <a href="">{label}</a>
                    </p>
                  </li>
                ))}
              </ul>
              <div className="tab-content" id="myTabContent">
                {tabs.map(({ id, state, index }) => (
                  <div className={`tab-pane fade ${activeTab === id ? "show active" : ""}`} id={id} role="tabpanel" aria-labelledby={`${id}-tab`} key={index}>
                    <div>
                      <QuizConfigTable
                        data={quizConfigs.data?.filter((quiz) => state.includes(quiz.state))}
                        quizState={state}
                        handleDeleteQuiz={handleDeleteQuiz}
                      ></QuizConfigTable>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
          )}
        </div>
      </ConfirmContextProvider>
    </div>
  );
};

export default QuizPage;
