import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../services/authConfig";
import "../assets/css/loginPage.css";
import logo from "../assets/images/logo.png";
import { Alert } from "reactstrap";

const LoginPage = ({ accessRestriction }) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    /* eslint @typescript-eslint/no-empty-function: "off" */
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        localStorage.setItem("token", response?.accessToken);
        localStorage.setItem("tokenExpiration", response?.expiresOn);
        instance.setActiveAccount(response);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
  });

  return (
    <div className="loginPage-container">
      <div className="loginPage-content">
        <section className="pt-3 pb-5  ">
          <div className=" pt-4 pb-5 px-5 ">
            <div className="row d-flex justify-content-between pt-lg-5 align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-7 text-center text-lg-left mb-5 mb-lg-0 loginPage-gold-text">
                <h1 className="display-3 font-weight-bold loginPage-gold-text ">Bienvenue sur votre back-office </h1>
                <div className="my-4" data-aos="fade-up">
                  <p className="lead text-dark">Diggers+, un moyen de vous rapprocher de façon ludique tout en gardant le contexte de l’entreprise!</p>
                </div>
              </div>
              <div className="col ">
                <div className="  loginPage-left-content ">
                  <div className="col-xl-8 col-md-10 pb-5 ">
                    <img src={logo} alt="logo" className="loginPAge-logo img-fluid" />
                  </div>
                  <div className="col-xl-8 col-md-10 ">
                    <div className="social-login">
                      <button className="btn microsoft-btn social-btn" type="button" onClick={handleLogin}>
                        <span>
                          <i className="bx bxl-microsoft"> </i> Se connecter avec Microsoft
                        </span>
                      </button>
                    </div>
                    {accessRestriction && (
                      <Alert color="danger" className="text-center">
                        {accessRestriction}
                      </Alert>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LoginPage;
