import { React, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import EditLeagueIconForm from "./EditLeagueIconForm";

/* eslint react/no-unescaped-entities: "off" */
const EditLeagueIconModal = ({ handleUpdateLeagueIconPicture, leagueId }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  return (
    <div>
      <button type="button" className="btn btn-outline-warning btn-sm" data-toggle="tooltip" title="Modifier l'icône" onClick={toggle}>
        <i className="bx bxs-edit-alt "></i>
      </button>
      {modal && (
        <Modal isOpen={modal} toggle={toggle} keyboard={false}>
          <ModalHeader toggle={toggle} close={closeBtn}>
            Modification d'image
          </ModalHeader>
          <ModalBody>
            <EditLeagueIconForm handleUpdateLeagueIconPicture={handleUpdateLeagueIconPicture} leagueId={leagueId} modalConfig={setModal}></EditLeagueIconForm>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default EditLeagueIconModal;
