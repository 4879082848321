import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const containsValue = (obj, value) => {
  if (obj === value) {
    return true;
  }

  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && containsValue(obj[key], value)) {
      return true;
    }
  }

  return false;
};

const isInDateFormat = (data) => {
  if (!data || typeof data !== "string") {
    return false;
  } else {
    let splittedDate = data.split("/");

    if (splittedDate.length !== 3) {
      return false;
    }

    let day = splittedDate[0];
    let month = splittedDate[1];
    let year = splittedDate[2];

    if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
      return false;
    }

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }

    return true;
  }
};

// Fonction de filtrage
export const filterTableItems = (filterToApply, rows) => {
  let AllquestConf = rows;
  return AllquestConf?.filter((questConf) => {
    // Vérifier chaque propriété du filtre
    for (const filterKey in filterToApply) {
      // Si la propriété de filtre est null ou undefined, passer à la suivante
      if (
        (filterToApply[filterKey] === null && filterKey !== "seasonId") ||
        filterToApply[filterKey] === undefined ||
        (Array.isArray(filterToApply[filterKey]) && filterToApply[filterKey]?.length === 0)
      )
        continue;

      // Si la propriété de filtre est un tableau, vérifier si la valeur du questConf est incluse dans le tableau
      if (Array.isArray(filterToApply[filterKey])) {
        if (!filterToApply[filterKey].includes(questConf[filterKey])) {
          return false;
        }
      } else if (filterKey === "date") {
        // Si la propriété de filtre est une date, vérifier si elle est dans l'année sélectionnée
        const filterDate = filterToApply[filterKey];
        const questDate = new Date(questConf[filterKey]);
        if (filterDate !== questDate.getFullYear()) {
          return false;
        }
      } else if (isInDateFormat(filterToApply[filterKey]) && filterKey?.includes("start")) {
        // Si la valeur du questConf est dans un format de date, vérifier si elle correspond à la valeur du filtre
        const filterDate = dayjs(filterToApply[filterKey], "DD/MM/YYYY");
        const questDate = dayjs(questConf[filterKey], "DD/MM/YYYY");
        if (!filterDate.isValid() || !questDate.isValid() || questDate.isBefore(filterDate)) {
          return false;
        }
      } else if (isInDateFormat(filterToApply[filterKey]) && filterKey?.includes("end")) {
        // Si la valeur du questConf est dans un format de date, vérifier si elle correspond à la valeur du filtre
        const filterDate = dayjs(filterToApply[filterKey], "DD/MM/YYYY");
        const questDate = dayjs(questConf[filterKey], "DD/MM/YYYY");
        if (!filterDate.isValid() || !questDate.isValid() || questDate.isAfter(filterDate)) {
          return false;
        }
      } else {
        // Sinon, vérifier si la valeur du questConf correspond à la valeur du filtre
        if (questConf[filterKey] !== filterToApply[filterKey] && !containsValue(questConf[filterKey], filterToApply[filterKey])) {
          return false;
        }
      }
    }

    // Si le questConf satisfait toutes les conditions, le conserver dans le tableau filtré
    return true;
  });
};

let valueConversion = (value) => {
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }
  if (value === "null") {
    return null;
  }
  if (!isNaN(value)) {
    return parseInt(value); // Convertir en nombre entier si c'est possible
  }
  if (value === "" || value === "") {
    return ""; // Préserver les chaînes de caractères vides
  }

  return value; // Conserver les chaînes de caractères par défaut
};

// trasform url params to filter object
export const getFilters = (searchParams) => {
  const newParamsObject = {};

  // Parcours des paramètres de recherche de l'URL
  for (let [key, value] of searchParams.entries()) {
    let newValue = valueConversion(value);
    // Si la clé existe déjà dans l'objet
    if (Object.prototype.hasOwnProperty.call(newParamsObject, key)) {
      // Si la valeur est déjà un tableau, ajoute simplement la nouvelle valeur
      if (Array.isArray(newParamsObject[key])) {
        newParamsObject[key].push(newValue);
      } else {
        // Sinon, crée un tableau avec les deux valeurs
        newParamsObject[key] = [newParamsObject[key], newValue];
      }
    } else {
      // Si la clé n'existe pas encore, crée-la dans l'objet
      newParamsObject[key] = newValue;
    }
  }

  // Met à jour l'état avec le nouvel objet de paramètres
  return newParamsObject;
};
