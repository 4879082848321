import { React, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const AddNewBlocConfigForm = (props) => {
  const [formValue, setformValue] = useState({
    blocId: "",
    name: "",
    isBlocVisibleByUser: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (props.getNewBlocConfig) {
      props.getNewBlocConfig(formValue);
    } else {
      props.postBlocConfig(formValue);
    }

    props.modalConfig(false);
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleReset = () => {
    props.modalConfig(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Label for="name">Nom bloc</Label>
          <Input type="text" name="name" id="name" onChange={handleChange} required placeholder="Nom Bloc" autoComplete="off" />
        </FormGroup>
        <FormGroup>
          <Label for="isBlocVisibleByUser">Visible pour les users</Label>
          <Input type="select" name="isBlocVisibleByUser" id="isBlocVisibleByUser" onChange={handleChange} required autoComplete="off">
            <option value="" disabled={true} selected="selected">
              Choisir une catégorie
            </option>
            <option value={true}> Oui</option>
            <option value={false}> Non</option>
          </Input>
        </FormGroup>
        <Row>
          <Col md={6}>
            <Button color="success" type="submit" block>
              Ajouter
            </Button>
          </Col>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddNewBlocConfigForm;
