import { React, useState } from "react";
import RewardTemplateItemDetail from "./RewardTemplateItemDetail";
import { Button } from "reactstrap";
import RewardTemplateEditableTable from "./RewardTemplateEditableTable";

const EditRewardTemplateForm = ({ rewardTemplate, handleUpdateRewardTemplate }) => {
  const [editRole, setEditRole] = useState(false);

  const handleClick = () => {
    setEditRole((prev) => !prev);
  };
  return (
    <div>
      <div className={editRole ? "d-flex justify-content-start mb-2" : "d-flex justify-content-end mb-2"}>
        <Button color={editRole ? "primary" : "warning"} className="float-righ" role="button" onClick={handleClick} disabled={rewardTemplate?.nbQuiz > 0}>
          {editRole ? "Détails" : "Modifier"}
        </Button>
      </div>
      {editRole ? (
        <RewardTemplateEditableTable rewardTemplates={rewardTemplate} handleUpdateRewardTemplate={handleUpdateRewardTemplate} />
      ) : (
        <RewardTemplateItemDetail rewardTemplate={rewardTemplate} />
      )}
    </div>
  );
};

export default EditRewardTemplateForm;
