import React from "react";
import SortAndSearchTable from "../table/sortAndSearchTable/SortAndSearchTable";
import { convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";
import MissonSheetVersionModal from "./MissonSheetVersionModal";
import MissionSheetDecisionModal from "./MissionSheetDecisionModal";
import Enums from "../../assets/JsonData/Enums";
import useUserRole from "../../hooks/useUserRole";

const MissionSheetVersionsTable = ({ missionSheets, consultantName, clientCompany, handleMissionSheetDecison, missionId }) => {
  const { isAdmin, hasAccessAsDeveloper, isManager } = useUserRole();
  let sortedMissionSheets = [...missionSheets];
  sortedMissionSheets.sort((a, b) => b.versionNumber - a.versionNumber);
  const columns = [
    {
      Header: "Dernière soumission",
      accessor: "lastSubmissionDate",
      Cell: ({ value }) => (value ? convertDateToLocalTime(value) : ""),
    },
    {
      Header: "Date limite",
      Cell: ({ row }) => convertDateToLocalTime(row.original.missionPeriodDetails?.endDate),
    },
    {
      Header: "Statut",
      accessor: "state",
      Cell: ({ value }) => Enums.matchMissionSheetState(value),
    },
    {
      Header: "Version",
      accessor: "versionNumber",
      Cell: ({ value }) => "V." + value,
    },

    {
      Header: "Visualiser",
      Cell: ({ row }) => {
        return <MissonSheetVersionModal missionVersion={row.original} consultantName={consultantName} clientCompany={clientCompany}></MissonSheetVersionModal>;
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        return (
          (isAdmin || hasAccessAsDeveloper || isManager) && (
            <div className="d-flex justify-content-evenly">
              <MissionSheetDecisionModal
                decision="validation"
                handleMissionSheetDecison={handleMissionSheetDecison}
                missionId={missionId}
                missionSheet={row.original}
              ></MissionSheetDecisionModal>
              <MissionSheetDecisionModal
                decision="adjustment"
                handleMissionSheetDecison={handleMissionSheetDecison}
                missionId={missionId}
                missionSheet={row.original}
              ></MissionSheetDecisionModal>
              <MissionSheetDecisionModal
                decision="rejection"
                handleMissionSheetDecison={handleMissionSheetDecison}
                missionId={missionId}
                missionSheet={row.original}
              ></MissionSheetDecisionModal>
            </div>
          )
        );
      },
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off", @typescript-eslint/no-unused-vars: "off" */
  const tableHooks = (hooks) => {};
  const handleRowClicked = (row) => {};
  return (
    <div>
      {missionSheets ? (
        <SortAndSearchTable
          data={sortedMissionSheets}
          columns={columns}
          tableHooks={tableHooks}
          refresh={""}
          handleRowClicked={handleRowClicked}
          noClickableRow={true}
        ></SortAndSearchTable>
      ) : (
        ""
      )}
    </div>
  );
};

export default MissionSheetVersionsTable;
