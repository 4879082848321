import { React, useState } from "react";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";

import { Label, FormGroup, Input, Row, Col, Button, Alert } from "reactstrap";

const AddManualUserGainForm = ({ handleUserGainUpdate, userInfos, handleCloseModal }) => {
  const { isConfirmed } = useConfirm();
  const [isUpdatingGain, setIsUpdatingGain] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [formValue, setformValue] = useState({
    coinsAmount: undefined,
    scoreAmount: undefined,
    message: null,
  });

  let userName = userInfos?.firstName + " " + userInfos?.lastName;

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  let confirmTextContent = (
    <div>
      <p>Confirmation gain manuel</p>
      <br />
      <p>
        Etes-vous sûr de vouloir appliquer le gain manuel suivant pour l&apos;utilisateur : <br />
        <strong>{userName}</strong> <br /> {`[ Or : ${formValue.coinsAmount ?? "-"} / Cristaux : ${formValue.scoreAmount ?? "-"} ] `}?
      </p>
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValueCorrect()) {
      const confirmed = await isConfirmed(confirmTextContent);
      if (confirmed) {
        handleUserGainUpdate(formValue);
        setIsUpdatingGain(true);
      }
    }
  };

  const handleReset = () => {
    handleCloseModal();
  };

  const onDismiss = () => setDisplayAlert(false);

  const isFormValueCorrect = () => {
    if (!formValue.coinsAmount && !formValue.scoreAmount) {
      setAlertText("Veuillez entrer une valeur au minimum");
      setDisplayAlert(true);
      return false;
    }
    setAlertText();
    setDisplayAlert(false);
    return true;
  };

  return (
    <div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <Alert color="danger" isOpen={displayAlert} toggle={onDismiss}>
          {alertText}
        </Alert>

        <FormGroup>
          <Label for="message">
            Justification <sup>*</sup>
          </Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            onChange={handleChange}
            value={formValue.name}
            required
            placeholder="(255 car. max)"
            maxLength="255"
          />
        </FormGroup>
        <FormGroup>
          <Label for="coinsAmount">Or</Label>
          <Input type="number" name="coinsAmount" id="coinsAmount" onChange={handleChange} value={formValue.name} />
        </FormGroup>
        <FormGroup>
          <Label for="scoreAmount">Cristaux</Label>
          <Input type="number" name="scoreAmount" id="scoreAmount" onChange={handleChange} value={formValue.name} />
        </FormGroup>
        <Row className="mt-2">
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block disabled={isUpdatingGain}>
              {isUpdatingGain ? <i className="bx bx-loader-alt bx-spin" /> : "Ajouter"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AddManualUserGainForm;
