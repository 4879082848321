import { React, useState, useMemo } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const useModalWithContent = (initialContent, headerTitle, modalSize) => {
  const [displayModal, setDisplayModal] = useState(false);

  const modalContent = useMemo(() => {
    return initialContent;
  }, [initialContent]);

  const toggleDisplayModal = () => {
    setDisplayModal(!displayModal);
  };

  const openModal = () => {
    setDisplayModal(true);
  };

  const closeModal = () => {
    setDisplayModal(false);
  };

  const ModalComponent = ({ backdrop }) => (
    <Modal isOpen={displayModal} toggle={closeModal} backdrop={backdrop || "static"} keyboard={false} size={modalSize}>
      <ModalHeader toggle={closeModal}>{headerTitle}</ModalHeader>
      <ModalBody>{modalContent}</ModalBody>
    </Modal>
  );

  const ModalButton = ({ buttonText, buttonColor, icon, size, disabled }) => (
    <Button color={buttonColor} type="button" onClick={toggleDisplayModal} size={size} disabled={disabled ?? false}>
      {icon && <i className={`${icon} fs-5 mx-1 `}></i>}
      {buttonText}
    </Button>
  );

  return { openModal, closeModal, ModalComponent, ModalButton };
};

export default useModalWithContent;
