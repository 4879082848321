import { React, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUsersInfos } from "../../redux/slices/UserSlice";
import DigDaysDecisionModal from "./DigDaysDecisionModal";
import Loader from "../loader/Loader";
import ReactStars from "react-stars";
import { convertDateToLocalTime, truncate } from "../../assets/utils/UtilsFunctons";
import Enums from "../../assets/JsonData/Enums";
import TablePagination from "../table/table-pagination/TablePagination";
import DigDayDurationAndLocation from "./DigDayDurationAndLocation";
import { orderBy } from "lodash";
import useUserRole from "../../hooks/useUserRole";

const DigDaysTable = ({ digDays, handleDigDayDecision }) => {
  const navigate = useNavigate();
  const { isAdmin, hasAccessAsDeveloper, isManager } = useUserRole();

  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));

  const sortedDigDays = useCallback(() => {
    let sortedData = orderBy(digDays?.data, ["date", "fullName"], ["asc", "asc"]);
    return sortedData;
  }, [digDays]);

  const digDayActionsBasedOnState = (digDay) => {
    if (digDay.state === 0) {
      // planifié
      return (
        <>
          <DigDaysDecisionModal decision="cancel" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
        </>
      );
    }

    if (digDay.state === 1 || digDay.state === 3) {
      // attente de soumission ou correction
      return (
        <>
          <DigDaysDecisionModal decision="notification" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
        </>
      );
    }

    if (digDay.state === 2) {
      // en attente de validation
      return (
        <>
          <DigDaysDecisionModal decision="validation" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
          <DigDaysDecisionModal decision="adjustment" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
          <DigDaysDecisionModal decision="rejection" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
        </>
      );
    }
  };

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }) => (value ? convertDateToLocalTime(value) : ""),
    },
    {
      Header: "Statut",
      accessor: "state",
      Cell: ({ value }) => Enums.matchDigDayState(value),
    },
    {
      Header: "Collaborateur",
      accessor: "fullName",
    },
    {
      Header: "Titre",
      accessor: "title",
      Cell: ({ value }) => truncate(value, 18),
    },

    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => truncate(value, 18),
    },
    {
      Header: "Durée/Lieu",

      Cell: ({ row }) => <DigDayDurationAndLocation digDay={row?.original} />,
    },
    {
      Header: "Evaluation",
      accessor: "rating",
      Cell: ({ value }) => (value ? <ReactStars count={5} size={24} value={value} edit={false} /> : ""),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-evenly">{(isAdmin || hasAccessAsDeveloper || isManager) && digDayActionsBasedOnState(row.original)}</div>
      ),
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off", @typescript-eslint/no-unused-vars: "off" */
  const tableHooks = (hooks) => {};
  const handleRowClicked = (row) => {
    navigate("/DigDayDetailPage/" + row.original.digDayId);
  };

  const userIdDisplayValueFunc = useCallback(
    (userId) => {
      if (usersInfos && usersInfos.data) {
        let user = usersInfos.data.find((user) => user.userId === userId);
        return user ? user.firstName + " " + user.lastName : null;
      }
      return null;
    },
    [usersInfos]
  );

  const questStateItemDisplayValueFunc = useCallback((digDaySate) => {
    return Enums.matchDigDayState(digDaySate);
  }, []);

  const getDefaultValueData = useCallback((data) => {
    if (data) {
      return data.filter((digDay) => digDay.state === 2);
    }
    return null;
  }, []);

  return (
    <div>
      {digDays.data ? (
        <div>
          <div>
            <TablePagination
              data={sortedDigDays() ?? []}
              columns={columns}
              tableHooks={tableHooks}
              refresh={digDays?.data || isAdmin || !hasAccessAsDeveloper || userIdDisplayValueFunc}
              handleRowClicked={handleRowClicked}
              type={"default"}
              enableFilter={true}
              hasDefaultValue={true}
              getDefaultValueData={getDefaultValueData}
            >
              <element
                key="state"
                filterKey="state"
                className="cell"
                casesensitive={"true"}
                showsearch={"true"}
                isMulti
                itemDisplayValueFunc={questStateItemDisplayValueFunc}
                hasDefaultValue={true}
                defaultValue={2}
              >
                Statut
              </element>
              <element
                key="userId"
                filterKey="userId"
                className="cell"
                casesensitive={"true"}
                showsearch={"true"}
                itemDisplayValueFunc={userIdDisplayValueFunc}
              >
                Collab.
              </element>
              <element key="date" filterKey="date" className="cell" casesensitive={"true"} showsearch={"true"} isDate={true}>
                Date
              </element>
            </TablePagination>
          </div>
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

export default DigDaysTable;
