import React from "react";
import useConfirm from "../../FormConFirmDialog/UseFormConfirmDialog";
import ToggleButton from "../../buttons/toggleButton/ToggleButton";

const RewardTemplateStateComp = ({ rewardTemplate, handleAchiveRewardTemplate }) => {
  const { isConfirmed } = useConfirm();
  const handleRewardTemplateButtonToggle = async () => {
    const confirmed = await isConfirmed(
      <div>
        {!rewardTemplate.archived ? "Souhaitez-vous archiver le modèle de gratification" : "Souhaitez-vous activer le modèle de gratification"}:{" "}
        <em>{rewardTemplate.name}</em>
      </div>
    );
    if (confirmed) {
      handleAchiveRewardTemplate(rewardTemplate);
    }
  };
  return (
    <div className="d-flex flex-column">
      <div data-bs-toggle="tooltip" data-bs-placement="top" title={!rewardTemplate.archived ? "Archiver ce template" : "activer ce template"}>
        <span>
          <ToggleButton state={!rewardTemplate.archived} onClickHandler={handleRewardTemplateButtonToggle} />
        </span>
      </div>
      <small className="text-muted ">{!rewardTemplate.archived ? "actif" : "archivé"}</small>
    </div>
  );
};

export default RewardTemplateStateComp;
