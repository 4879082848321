import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import QuestConfigDataService from "../services/QuestConfigDataService";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import { getAllBlocConfigs } from "../redux/slices/BlocConfigSlice";
import QuestConfigDetailPage from "../components/modals/QuestConfigDetailPage";
import { updateQuestConfigs } from "../redux/slices/QuestConfigSlice";
import { getUsersInfos } from "../redux/slices/UserSlice";
import { getSeasonList } from "../redux/slices/SeasonSlice";
import { getAllEnums } from "../redux/slices/EnumsSlice";
import { getAllQuestConfigs } from "../redux/slices/QuestConfigSlice";
import ConfirmModal from "../components/modals/FormConfirmDialogModal";
import { notify } from "../assets/utils/UtilsFunctons";
import { Toaster } from "react-hot-toast";
import { Spinner } from "reactstrap";

const QuestConfigPage = () => {
  const { questConfigs, loading_getQuestConfigs, refreshQuestConfigPage } = useSelector((state) => ({ ...state.questConfigs }));
  const { loading_getSeasonList, seasonList } = useSelector((state) => ({ ...state.seasonList }));
  const { loading_getUsersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { enumList } = useSelector((state) => ({ ...state.enums }));
  const { blocConfigs } = useSelector((stateBloc) => ({ ...stateBloc.blocConfig }));
  const [questconfid, setQuestConfId] = useState();
  const [refreshPage, setRefreshPage] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const updateQuestConfig = (formValue) => {
    dispatch(updateQuestConfigs(formValue));
  };

  const handleDeleteQuestconfig = async () => {
    try {
      if (questconfid) {
        await QuestConfigDataService.deleteQuestConfig(questconfid);
        notify(1, "Configuration de quête supprimée avec succés !");
        navigate("/QuestManagement");
      }
    } catch (error) {
      notify(0, error?.response.data.title ?? "une erreur est survenue");
    }
  };

  const handleActivateOrDesactivateQuestSeasonconfig = async (payload) => {
    try {
      if (payload) {
        await QuestConfigDataService.ActivateOrDesactivateQuestSeasonconfig(payload);
        notify(1, "Modification apportée avec succés !");
        setRefreshPage(!refreshPage);
      }
    } catch (error) {
      notify(0, error?.response.data.title ?? "une erreur est survenue");
    }
  };

  useEffect(() => {
    if (!seasonList) {
      dispatch(getSeasonList());
    }
    dispatch(getAllQuestConfigs());
    if (!blocConfigs) {
      dispatch(getAllBlocConfigs());
    }
    dispatch(getUsersInfos());
    if (!enumList) {
      dispatch(getAllEnums());
    }

    const id = params.questConfigId;
    setQuestConfId(id ? id : "");
  }, [refreshQuestConfigPage, refreshPage]);

  return (
    <div>
      <div>
        {loading_getQuestConfigs || loading_getSeasonList || loading_getUsersInfos ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner color="secondary">Loading...</Spinner>
          </div>
        ) : (
          <ConfirmContextProvider>
            <ConfirmModal />
            <QuestConfigDetailPage
              item={!questConfigs || !questConfigs.data ? undefined : questConfigs.data.find((item) => item.questConfigId === questconfid)}
              updateQuestConfig={updateQuestConfig}
              handleDeleteQuestconfig={handleDeleteQuestconfig}
              handleActivateOrDesactivateQuestSeasonconfig={handleActivateOrDesactivateQuestSeasonconfig}
              setRefreshPage={setRefreshPage}
              refreshPage={refreshPage}
            ></QuestConfigDetailPage>
          </ConfirmContextProvider>
        )}
      </div>
      <div>
        <Toaster></Toaster>
      </div>
    </div>
  );
};

export default QuestConfigPage;
