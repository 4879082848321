import { React, useState } from "react";
import { InputGroup, InputGroupText, Input, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import EditShopItemConfigModal from "../modals/EditShopItemConfigModal";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ShopItemCard from "../Shop/shopItem/ShopItemCard";

const ShopCard = ({ shopItems, activateItem, existingThemeList, postShopItemConfig, deleteShopItemConfig }) => {
  const { isConfirmed } = useConfirm();
  const [filteredShopItem, setFilteredShopItem] = useState(shopItems);

  const handleShopItemActivationToggle = async (value) => {
    const confirmed = await isConfirmed(value.isActivated ? "Souhaitez-vous désactiver cet article " : "Souhaitez-vous activer cet article");
    if (confirmed) {
      activateItem(value);
    }
  };

  const searchByNameOrDescription = (search) => {
    if (search !== "") {
      setFilteredShopItem(
        shopItems.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()) || item.description.toLowerCase().includes(search.toLowerCase()))
      );
    } else {
      setFilteredShopItem(shopItems);
    }
  };

  const themeFilterItem = (themeList) => {
    if (themeList && themeList.length !== 0)
      return themeList.map((theme, index) => (
        <DropdownItem onClick={() => filterShopItemByTheme(theme)} key={index}>
          {theme}
        </DropdownItem>
      ));
  };

  const filterShopItemByTheme = (theme) => {
    if (theme !== "") {
      setFilteredShopItem(shopItems.filter((item) => item.theme.toLowerCase() === theme.toLowerCase()));
    } else {
      setFilteredShopItem(shopItems);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="searchBar_item mt-3">
        <div>
          <InputGroup>
            <ButtonGroup>
              <UncontrolledDropdown>
                <DropdownToggle caret>
                  <i className="bx bx-filter-alt"> Filtrer</i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Théme</DropdownItem>
                  {existingThemeList === undefined ? "" : themeFilterItem(existingThemeList)}
                  <DropdownItem divider />
                  <DropdownItem onClick={() => filterShopItemByTheme("")}>effacer filtre</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </InputGroup>
        </div>
        &nbsp;
        <div className="searchInput_item">
          {
            <InputGroup>
              <InputGroupText>
                <i className="bx bx-search-alt"></i>
              </InputGroupText>
              <Input type="text" className="search_input" placeholder="Search " onChange={(e) => searchByNameOrDescription(e.target.value)} />
            </InputGroup>
          }
        </div>
      </div>
      <div className="  mt-3">
        <div className="shopItemCards-wrapper">
          {filteredShopItem === undefined
            ? ""
            : filteredShopItem.map((item, index) => {
                return (
                  <div key={index}>
                    <ShopItemCard
                      item={item}
                      editItem={
                        <EditShopItemConfigModal
                          buttonLabel={"Edit"}
                          shopItem={item}
                          postShopItemConfig={postShopItemConfig}
                          existingThemeList={existingThemeList}
                        ></EditShopItemConfigModal>
                      }
                      deleteItem={
                        <EditShopItemConfigModal buttonLabel={"Delete"} shopItem={item} deleteShopItemConfig={deleteShopItemConfig}></EditShopItemConfigModal>
                      }
                      handleShopItemActivationToggle={handleShopItemActivationToggle}
                    ></ShopItemCard>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
