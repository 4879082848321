import React from "react";
import { convertDateToLocalTimeWithHour } from "../../assets/utils/UtilsFunctons";
import { Badge, Input } from "reactstrap";

const UserQuizRanksDetailsContent = ({ data }) => {
  return (
    <div>
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
            <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
              <span className="d-block  ">
                <i className="bx bx-user"></i>
                {data.userFirstName + " " + data.userLastName}
              </span>
              <span className="d-block  ">Classement :{data.rank}</span>
              <span className="d-block small ">
                Etat :
                {data.participationState === 0 ? (
                  <Badge color="secondary">Non démarré</Badge>
                ) : data.participationState === 1 ? (
                  <Badge color="primary">En cours</Badge>
                ) : (
                  <Badge color="success">Terminé</Badge>
                )}
              </span>
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
            <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
              <span className="d-block  ">
                <Badge>{convertDateToLocalTimeWithHour(data.startDate)}</Badge>
              </span>
              <span className="d-block  ">Score :{data.totalScore}</span>
              <span className="d-block  ">Durée :{data.fullAnswerTime} s</span>
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
            <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
              <span className="d-block  ">Gain coins :{data.coinsEarned}</span>
              <span className="d-block  ">Gain score :{data.scoreEarned}</span>
              <span className="d-block  ">cadeau :{data.gift ? "Oui" : "Non"}</span>
            </label>
          </div>
        </div>
      </div>

      <h6 className="mt-3">Réponses</h6>

      {data.userQuestions?.map((question, index) => (
        <div className="h-100 p-3 bg-light border rounded-3 mt-3" key={index}>
          <div className="d-flex align-items-start flex-column ">
            <Input readOnly valid={question.userPoints !== 0} invalid={question.userPoints === 0} value={question.questionText} />
            <div className="d-flex justify-content-between mt-2 w-100">
              <div>
                <span className="opacity-50">Temps de réponse :</span> {question.answerTime} s
              </div>
              <div>
                <span className="opacity-50">points :</span> {question.userPoints}
              </div>
            </div>
            <div className="mx-3"></div>
            <div>
              <span className="opacity-50">Réponse :</span>{" "}
              {question.userAnswers.map((answer, index) => (
                <span key={index}>{answer + " "}</span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserQuizRanksDetailsContent;
