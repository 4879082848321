import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllQuestConfigs } from "../redux/slices/QuestConfigSlice";
import { getAllEnums } from "../redux/slices/EnumsSlice";
import { getSeasonList } from "../redux/slices/SeasonSlice";
import { getAllBlocConfigs } from "../redux/slices/BlocConfigSlice";
import QuestConfigTable from "../components/QuestConfigItems/QuestConfigTable";
import { Toaster } from "react-hot-toast";
import { Spinner, Alert } from "reactstrap";
import "../assets/css/queteManagement.css";
import MyBreadcrumb from "../components/breadcrumb/MyBreadcrumb";

const QuestManagement = () => {
  const { questConfigs, loading_getQuestConfigs, refreshQuestConfigPage } = useSelector((state) => ({ ...state.questConfigs }));
  const { loading_getSeasonList, seasonList } = useSelector((state) => ({ ...state.seasonList }));
  const { loading_getAllEnums, enumList } = useSelector((state) => ({ ...state.enums }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoToNewQuestPage = () => {
    navigate("/nouveau-questConfig");
  };
  useEffect(() => {
    if (!seasonList) {
      dispatch(getSeasonList());
    }

    dispatch(getAllBlocConfigs());

    dispatch(getAllQuestConfigs());

    if (!enumList) {
      dispatch(getAllEnums());
    }
  }, [refreshQuestConfigPage]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <MyBreadcrumb pageTitle={"Administration des quêtes"} subPageTitle={"Quêtes et configurations d'expéditions associées"}></MyBreadcrumb>
        {!loading_getSeasonList && seasonList && !loading_getAllEnums && enumList ? (
          <button className="add-questConf-btn" onClick={handleGoToNewQuestPage}>
            <i className="bx bxs-plus-circle"></i>
          </button>
        ) : (
          <Alert color="secondary">...</Alert>
        )}
      </div>
      <div>
        <Toaster></Toaster>
      </div>
      <div className="row">
        <div className="col-12">
          {loading_getQuestConfigs ? (
            <div className="get-questConfig-spinner">
              <Spinner color="secondary"></Spinner>
            </div>
          ) : (
            <div>
              <QuestConfigTable AllquestConfigs={questConfigs.status === 200 && questConfigs ? questConfigs : null}></QuestConfigTable>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestManagement;
