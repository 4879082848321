import { React, useState, useCallback, useEffect } from "react";
import MyFilterItem from "./MyFilterItem";
import { useSearchParams } from "react-router-dom";
import { getFilters } from "../../../assets/utils/Table.helpers";
import { DATE_RANGE_FILTER } from "../../../core/constant/filterComponent-const";

const TablePaginationFilter = ({ rows, onFilter, options }) => {
  let [searchParams] = useSearchParams();
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters(getFilters(searchParams));
  }, [searchParams]);

  const isDataInDateFormat = useCallback((data) => {
    if (!data) {
      return true;
    } else {
      let date = new Date(data);
      return date instanceof Date || !data;
    }
  }, []);

  const getDataFromAttribute = useCallback((isObjectValue, filterKey, filterSubKey, isDate) => {
    if (rows) {
      let data = Array.from(rows, (item) => (!isObjectValue ? item[filterKey] : item[filterKey][filterSubKey]));
      if (isDate && data.every(isDataInDateFormat)) {
        return data
          ?.filter((item) => item)
          ?.map((elt) => {
            const date = new Date(elt);
            return date?.getFullYear();
          });
      }

      return data;
    } else return undefined;
  }, []);

  const transformDataIntoArray = useCallback(
    (data) => {
      if (Array.isArray(data)) {
        return data;
      } else {
        return [data];
      }
    },
    [options]
  );

  const deleteUndefinedValuesFromFilters = useCallback((allFilters) => {
    if (allFilters) {
      for (let key in allFilters) {
        if (allFilters[key] === undefined) {
          delete allFilters[key];
        }
      }
    }
    return allFilters;
  }, []);

  const handleFilterChange = useCallback(
    (currentFilter, filterKey, dateRangeFilter) => {
      if (filterKey === DATE_RANGE_FILTER) {
        setFilters((prev) => {
          const newFilters = deleteUndefinedValuesFromFilters({
            ...prev,
            [dateRangeFilter?.startDate]: currentFilter?.startDate,
            [dateRangeFilter?.endDate]: currentFilter?.endDate,
          });
          onFilter(newFilters);
          return newFilters;
        });
      } else {
        setFilters((prev) => {
          const newFilters = deleteUndefinedValuesFromFilters({ ...prev, [filterKey]: currentFilter });
          onFilter(newFilters);
          return newFilters;
        });
      }
    },
    [filters]
  );

  const chunks = transformDataIntoArray(options).reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 4);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <>
      <div>
        {Array.isArray(options) ? (
          <div style={{ display: "grid", rowGap: "1ch" }}>
            {chunks.map((chunk, index) => (
              <div key={index} style={{ display: "grid", gap: "10px", gridTemplateColumns: "repeat(auto-fill, minmax(260px, 10fr))" }}>
                {chunk?.map((item, index) => {
                  return (
                    <div key={index} className="w-100">
                      <MyFilterItem
                        name={item.props.children}
                        filterKey={item.props.filterKey}
                        data={
                          item.props.isDateRange ? [] : getDataFromAttribute(item.props.isObjectKey, item.props.filterKey, item.props.subKey, item.props.isDate)
                        }
                        isMulti={item.props.isMulti}
                        onFilterUpdate={handleFilterChange}
                        itemDisplayValueFunc={item.props.itemDisplayValueFunc}
                        defaultValue={filters?.[item.props.filterKey]}
                        hasDefaultValue={filters?.[item.props.filterKey]}
                        isDateRange={item.props.isDateRange}
                        dateRangeKeyFilter={item.props.isDateRange ? { startDate: item.props.rangeStartDate, endDate: item.props.rangeEndDate } : undefined}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ) : (
          <div className="w-50 " style={{ rowGap: "10px" }}>
            <MyFilterItem
              name={chunks[0][0]?.props.children}
              filterKey={chunks[0][0]?.props.filterKey}
              data={
                chunks[0][0]?.props.isDateRange
                  ? []
                  : getDataFromAttribute(chunks[0][0]?.props.isObjectKey, chunks[0][0]?.props.filterKey, chunks[0][0]?.props.subKey, chunks[0][0]?.props.isDate)
              }
              isMulti={chunks[0][0]?.props.isMulti}
              onFilterUpdate={handleFilterChange}
              itemDisplayValueFunc={chunks[0][0]?.props.itemDisplayValueFunc}
              defaultValue={filters?.[chunks[0][0]?.props.filterKey]}
              hasDefaultValue={filters?.[chunks[0][0]?.props.filterKey]}
              isDateRange={chunks[0][0]?.props.isDateRange}
              dateRangeKeyFilter={
                chunks[0][0]?.props.isDateRange ? { startDate: chunks[0][0]?.props.rangeStartDate, endDate: chunks[0][0]?.props.rangeEndDate } : undefined
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TablePaginationFilter;
