import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPepActionsByUserId } from "../../../redux/slices/PepSlice";
import { getDigDaysByUserId } from "../../../redux/slices/DigDaysSlice";
import { getMissionDetailByUserId } from "../../../redux/slices/MissionSheetSlice";
import PageLoadingFailNotification from "../../PageLoadingFailNotification/PageLoadingFailNotification";
import UserDigDaysStockTable from "./UserDigDaysStockTable";
import UserDigDaysHistory from "../../digDays/UserDigDaysHistory";
import UserMissionsHistory from "../../missionSheet/UserMissionsHistory";
import { Col, Row, TabContent, TabPane, Label } from "reactstrap";
import "./userInternalActions.css";
import PepUserActionTable from "../../pep/pep-gestion/PepUserActionTable";

const UserDigDaysandMission = () => {
  const { loading_getUserInfoById, userInfos, currentUserInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { PepActionListByUserId, loading_getPepActionsByUserId } = useSelector((state) => ({ ...state.pep }));
  const { loading_getDigDaysByUserId, digDaysByUserId } = useSelector((state) => ({ ...state.digDays }));
  const { missionDetailByUserId, loading_getMissionDetailByUserId } = useSelector((state) => ({ ...state.missionSheet }));

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    dispatch(getPepActionsByUserId(currentUserInfos?.userId));
    dispatch(getDigDaysByUserId(currentUserInfos?.userId));
    dispatch(getMissionDetailByUserId(currentUserInfos?.userId));
  }, [currentUserInfos]);
  return (
    <div>
      {currentUserInfos &&
      userInfos &&
      digDaysByUserId &&
      digDaysByUserId?.data &&
      missionDetailByUserId &&
      missionDetailByUserId?.data &&
      !loading_getUserInfoById ? (
        <div className=" py-3">
          <div className="row user-internal-action">
            <div className="col-2">
              <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a
                  class={`nav-link ${activeTab == "1" ? "active" : ""} section-button`}
                  id="v-pills-home-tab"
                  data-toggle="pill"
                  onClick={() => setActiveTab("1")}
                  role="tab"
                >
                  PEP
                </a>
                <a
                  class={`nav-link ${activeTab == "2" ? "active" : ""} section-button`}
                  id="v-pills-profile-tab"
                  data-toggle="pill"
                  onClick={() => setActiveTab("2")}
                  role="tab"
                >
                  Dig days
                </a>
                <a
                  class={`nav-link ${activeTab == "3" ? "active" : ""} section-button`}
                  id="v-pills-messages-tab"
                  data-toggle="pill"
                  onClick={() => setActiveTab("3")}
                  role="tab"
                >
                  Missions
                </a>
              </div>
            </div>
            <div className="col-10 row user-internal-actions-container">
              <TabContent activeTab={activeTab} style={{ background: "white", padding: "10px" }}>
                <TabPane tabId="1">
                  <h4>PEP Contents</h4>
                  {loading_getPepActionsByUserId ? (
                    "chargement ..."
                  ) : PepActionListByUserId && PepActionListByUserId.data && PepActionListByUserId.data.length !== 0 ? (
                    <PepUserActionTable pepActions={PepActionListByUserId.data} />
                  ) : (
                    <label className="list-group-item rounded-3 py-3" for="listGroupCheckableRadios1">
                      Aucun PEP disponible.
                    </label>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  <div>
                    <h4>Dig Days</h4>
                    <fieldset class="border rounded-3 p-3 bg-light">
                      <Row>
                        <Col md="6" sm="12">
                          <Label>Compteur</Label>
                          <br />
                          <UserDigDaysStockTable userDigDayBalances={userInfos?.data?.userDigDayBalances} userRole={userInfos?.data?.role} />
                        </Col>

                        <Col md="6" sm="12">
                          {loading_getDigDaysByUserId ? (
                            "chargement ..."
                          ) : (
                            <div className="d-flex justify-content-around">
                              <div className="d-flex" style={{ height: "200px" }}>
                                <div className="vr"></div>
                              </div>
                              {digDaysByUserId && digDaysByUserId.data && digDaysByUserId.data.length !== 0 ? (
                                <UserDigDaysHistory digDays={digDaysByUserId.data} userId={currentUserInfos?.userId} />
                              ) : (
                                <label className="list-group-item rounded-3 py-3" for="listGroupCheckableRadios1">
                                  Aucun digDay n&apos;a été posé.
                                </label>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </fieldset>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div>
                    <h4>Missions</h4>
                    <fieldset class="border rounded-3 p-3 bg-light">
                      {loading_getMissionDetailByUserId ? (
                        "Chargement ..."
                      ) : missionDetailByUserId && missionDetailByUserId?.data ? (
                        <UserMissionsHistory userMissions={missionDetailByUserId?.data} />
                      ) : (
                        ""
                      )}
                    </fieldset>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      ) : (
        <PageLoadingFailNotification text={"La récupération des données a échoué"} />
      )}
    </div>
  );
};

export default UserDigDaysandMission;
