import React from "react";
import "./sidebar.css";
import logo from "../../assets/images/logo.png";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import SubMenu from "./SubMenu";

const Sidebar = (props) => {
  const activeItem = sidebar_items.findIndex((item) => item.route === props?.location?.pathname);

  return (
    <div className="sidebar">
      <div>
        <div className="sidebar__logo">
          <img src={logo} alt="company logo" />
        </div>
      </div>
      {sidebar_items.map((item, index) => (
        <div key={index}>
          <SubMenu item={item} key={index} active={index === activeItem} />
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
