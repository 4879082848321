import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPepConfigFullTree } from "../../../redux/slices/PepSlice";
import PepConfigComponent from "./PepConfigComponent";
import Loader from "../../loader/Loader";
import PageLoadingFailNotification from "../../PageLoadingFailNotification/PageLoadingFailNotification";
import ConfirmContextProvider from "../../FormConFirmDialog/FormConfirmContextProvider";
import ConfirmModal from "../../modals/FormConfirmDialogModal";

const PepConFigLayer = () => {
  const { PepConfigFullTree, loading_getPepConfigFullTree } = useSelector((state) => ({ ...state.pep }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPepConfigFullTree());
  }, []);

  return (
    <div>
      {loading_getPepConfigFullTree ? (
        <Loader />
      ) : PepConfigFullTree && PepConfigFullTree.data ? (
        <ConfirmContextProvider>
          <ConfirmModal />
          <PepConfigComponent pepConfigFullTree={PepConfigFullTree.data} />
        </ConfirmContextProvider>
      ) : (
        <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
      )}
    </div>
  );
};

export default PepConFigLayer;
