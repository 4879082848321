import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MissionSheetDataService from "../../services/MissionSheetDataService";
import { notify } from "../../assets/utils/UtilsFunctons";

export const getMissionsList = createAsyncThunk("missionSheet/getMissionsList", async () => {
  try {
    const response = await MissionSheetDataService.getMissionsList();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const getMissionDetailById = createAsyncThunk("missionSheet/getMissionDetailById", async (data) => {
  try {
    const response = await MissionSheetDataService.getMissionDetailById(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});
export const getMissionDetailByUserId = createAsyncThunk("missionSheet/getMissionDetailByUserId", async (data) => {
  try {
    const response = await MissionSheetDataService.getMissionDetailByUserId(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const addNewMission = createAsyncThunk("missionSheet/addNewMission", async (data) => {
  try {
    const response = await MissionSheetDataService.addNewMission(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(0, err?.response?.data?.Message);
    return err.response.data;
  }
});

export const updateMission = createAsyncThunk("missionSheet/updateMission", async (data) => {
  try {
    const response = await MissionSheetDataService.updateMission(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});
export const setMissionSheetDecision = createAsyncThunk("missionSheet/setMissionSheetDecision", async (data) => {
  try {
    const response = await MissionSheetDataService.setMissionSheetDecision(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const MisionSheetSlice = createSlice({
  name: "missionSheet",
  initialState: {
    missionsList: [],
    loading_getMissionsList: false,
    missionDetailById: undefined,
    loading_getMissionDetailById: false,
    missionDetailByUserId: undefined,
    loading_getMissionDetailByUserId: false,
    newMission: undefined,
    loading_addNewMission: false,
    modifiedMission: undefined,
    loading_modifiedMission: false,
    missionDecision: undefined,
    loading_missionDecision: false,
    refreshMissionPage: false,
    refreshMissionSheetPage: false,
  },
  reducers: {},
  extraReducers: {
    [getMissionsList.pending]: (state) => {
      state.loading_getMissionsList = true;
    },
    [getMissionsList.fulfilled]: (state, { payload }) => {
      state.loading_getMissionsList = false;
      state.missionsList = payload;
      if (payload?.status === 200) {
        ("");
      } else if (payload?.title) {
        notify(payload.title, 0);
      }
    },
    [getMissionDetailById.pending]: (state) => {
      state.loading_getMissionDetailById = true;
    },
    [getMissionDetailById.fulfilled]: (state, { payload }) => {
      state.loading_getMissionDetailById = false;
      state.missionDetailById = payload;
      if (payload?.status === 200) {
        ("");
      } else if (payload?.title) {
        notify(payload.title, 0);
      }
    },
    [getMissionDetailByUserId.pending]: (state) => {
      state.loading_getMissionDetailByUserId = true;
    },
    [getMissionDetailByUserId.fulfilled]: (state, { payload }) => {
      state.loading_getMissionDetailByUserId = false;
      state.missionDetailByUserId = payload;
      if (payload?.status === 200) {
        ("");
      } else if (payload?.title) {
        notify(payload.title, 0);
      }
    },
    [addNewMission.pending]: (state) => {
      state.loading_addNewMission = true;
      state.newMission = undefined;
    },
    [addNewMission.fulfilled]: (state, { payload }) => {
      state.loading_addNewMission = false;
      state.newMission = payload;
      if (payload?.status === 200) {
        notify(1, "Mission ajoutée avec succès !");
        state.refreshMissionPage = !state.refreshMissionPage;
        state.newMission = undefined;
      } else if (payload?.title) {
        notify(0, payload.title);
        state.newMission = undefined;
      }
    },
    [updateMission.pending]: (state) => {
      state.loading_modifiedMission = true;
      state.modifiedMission = undefined;
    },
    [updateMission.fulfilled]: (state, { payload }) => {
      state.loading_modifiedMission = false;
      state.modifiedMission = payload;
      if (payload?.status === 200) {
        notify(1, "Mission modifiée avec succès !");
        state.refreshMissionPage = !state.refreshMissionPage;
      } else if (payload?.title) {
        notify(0, payload.title);
      }
    },
    [setMissionSheetDecision.pending]: (state) => {
      state.loading_missionDecision = true;
      state.missionDecision = undefined;
    },
    [setMissionSheetDecision.fulfilled]: (state, { payload }) => {
      state.loading_missionDecision = false;
      state.missionDecision = payload;
      if (payload?.status === 200) {
        notify(1, "fiche mission modifiée avec succès !");
        state.refreshMissionSheetPage = !state.refreshMissionSheetPage;
      } else if (payload?.title) {
        notify(0, payload.title);
      }
    },
  },
});

export default MisionSheetSlice.reducer;
