import { React, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router";
import { getAllDigDays, digDayDecision, cancelDigDay } from "../redux/slices/DigDaysSlice";
import { getUsersInfos } from "../redux/slices/UserSlice";
import { postLogByParentId } from "../redux/slices/LogSlice";
import DigDaysTable from "../components/digDays/DigDaysTable";
import { v4 as uuid } from "uuid";
import { Toaster } from "react-hot-toast";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import Loader from "../components/loader/Loader";
import DigDaysSerialValidationComp from "../components/digDays/digDays-serial-validation/DigDaysSerialValidationComp";
import DigDaysSerialValidationModal from "../components/digDays/digDays-serial-validation/DigDaysSerialValidationModal";
import useUserRole from "../hooks/useUserRole";

const DigDaysPage = () => {
  const { isAdmin, hasAccessAsDeveloper } = useUserRole();

  const dispatch = useDispatch();
  const location = useLocation();
  const { accounts } = useMsal();
  const [currentUser] = useState(location.state ? location.state.user : null);
  const { loading_getAllDigDays, digDays, refreshDigDaysPage } = useSelector((state) => ({ ...state.digDays }));
  const { usersInfos, loading_getUsersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { refreshPageAfterLogPost } = useSelector((state) => ({ ...state.logs }));
  const [refreshOnModalClose, setRefreshOnModalClose] = useState(false);

  const handleDigDayDecision = (decision, data) => {
    let payload = undefined;
    if (decision === "cancel") {
      payload = {
        digDayId: data.digDayId,
        message: {
          messageId: uuid(),
          parentId: data.digDayId,
          userId: accounts[0]?.idTokenClaims?.oid,
          text: data.message,
        },
      };
      dispatch(cancelDigDay(payload));
    }
    if (decision === "notification") {
      payload = {
        messageId: uuid(),
        parentId: data.digDayId,
        userId: accounts[0]?.idTokenClaims?.oid,
        text: data.message,
      };
      dispatch(postLogByParentId(payload));
    }
    if (decision === "validation" || decision === "rejection" || decision === "adjustment") {
      payload = {
        digDayId: data.digDayId,
        decidedDigDayState: data.decidedDigDayState,
        message: data.message
          ? {
              messageId: uuid(),
              parentId: data.digDayId,
              userId: accounts[0]?.idTokenClaims?.oid,
              text: data.message,
            }
          : undefined,
      };
      dispatch(digDayDecision(payload));
    }
  };

  const renderDigDaysSerialValidationContent = useCallback(() => {
    return <DigDaysSerialValidationComp digDays={digDays} />;
  }, [digDays]);

  useEffect(() => {
    dispatch(getAllDigDays());
    if (!usersInfos) {
      dispatch(getUsersInfos());
    }
  }, [refreshDigDaysPage, refreshPageAfterLogPost, refreshOnModalClose]);

  return (
    <div>
      <Toaster></Toaster>
      <div className="d-flex justify-content-between">
        <h2 className="page-header">Gestion des Dig Days</h2>
        <div>
          {digDays?.data && digDays?.status === 200 ? (
            <>
              {(isAdmin || hasAccessAsDeveloper) && (
                <DigDaysSerialValidationModal refreshOnModalClose={refreshOnModalClose} setRefreshOnModalClose={setRefreshOnModalClose}>
                  {renderDigDaysSerialValidationContent()}
                </DigDaysSerialValidationModal>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        {loading_getAllDigDays || loading_getUsersInfos ? (
          <Loader></Loader>
        ) : (
          <div>
            {digDays?.data && digDays?.status === 200 && usersInfos ? (
              <div className="myCard">
                <DigDaysTable digDays={digDays} handleDigDayDecision={handleDigDayDecision} currentUser={currentUser}></DigDaysTable>
              </div>
            ) : (
              <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DigDaysPage;
