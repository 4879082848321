import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import http from "../services/http-common";
import { sortBy } from "lodash";
import useUserRole from "./useUserRole";
import { useMsal } from "@azure/msal-react";

const useGetAllUserListOption = () => {
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { isAdmin, hasAccessAsDeveloper } = useUserRole();
  const [userOptions, setUserOptions] = useState([]);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const { accounts } = useMsal();

  const connectedUserId = accounts[0]?.idTokenClaims?.oid;

  useEffect(() => {
    let isMounted = true; // Variable pour vérifier si le composant est monté

    const fetchData = async () => {
      try {
        if (!usersInfos || !usersInfos?.data) {
          setLoaded(false);
          const response = await http.get(`/user?getPrivateStats=false`);

          if (isMounted) {
            let options = [];
            if (response && response.data) {
              response.data.map((user) => {
                if (isAdmin || hasAccessAsDeveloper || (!isAdmin && user?.operatingManagerId === connectedUserId)) {
                  options.push({ value: user.userId, label: `${user.firstName} ${user.lastName}`, isActivated: user.isActivated });
                }
              });
            }
            setUserOptions(sortBy(options, "label"));
            setError(null);
          }
        } else {
          let options = [];
          usersInfos?.data.map((user) => {
            if (isAdmin || hasAccessAsDeveloper || (!isAdmin && user?.operatingManagerId === connectedUserId)) {
              options.push({ value: user.userId, label: `${user.firstName} ${user.lastName}`, isActivated: user.isActivated });
            }
          });
          setUserOptions(sortBy(options, "label"));
          setError(null);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          setUserOptions([]);
        }
      } finally {
        if (isMounted) {
          setLoaded(true);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return { userOptions, error, loaded };
};

export default useGetAllUserListOption;
