import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import { FormGroup, Label, Col, Button, Input } from "reactstrap";
import { RessourcesStatus } from "../../../core/constant/pep/PepConstant";

const AddEditRessourceContent = ({ handleSubmit, handleCloseModal, action, selectedRessource, creation, editOnly }) => {
  const formik = useFormik({
    initialValues:
      action == !"edit"
        ? {
            ressourceTitle: "",
            ressourceUrl: "",
            ressourceStatus: undefined,
            ressourceRating: 3,
          }
        : {
            ressourceTitle: selectedRessource?.title,
            ressourceUrl: selectedRessource?.url,
            ressourceStatus: selectedRessource?.status,
            ressourceRating: selectedRessource?.rating,
          },
    onSubmit: (values) => {
      if (action === "edit") {
        handleSubmit({ resourceId: selectedRessource?.id, payload: getResourceConfigChanges(), newValues: values });
      }
      if (action === "add") {
        handleSubmit(values);
      }
      if (action === "delete" && creation) {
        handleSubmit(selectedRessource?.id);
      }
    },
  });

  const getResourceConfigChanges = useCallback(() => {
    let changes = [];
    if (action === "edit") {
      if (selectedRessource?.title !== formik.values.ressourceTitle) {
        changes.push({
          op: "replace",
          path: "/title",
          value: formik.values.ressourceTitle,
        });
      }
      if (selectedRessource?.url !== formik.values.ressourceUrl) {
        changes.push({
          op: "replace",
          path: "/url",
          value: formik.values.ressourceUrl,
        });
      }
      if (selectedRessource?.status !== formik.values.ressourceStatus) {
        changes.push({
          op: "replace",
          path: "/status",
          value: formik.values.ressourceStatus,
        });
      }
      if (selectedRessource?.rating !== formik.values.ressourceRating) {
        changes.push({
          op: "replace",
          path: "/rating",
          value: formik.values.ressourceRating,
        });
      }
    }
    return changes;
  }, [formik.values]);

  const isFormCorrect = useMemo(() => {
    return action !== "delete"
      ? formik.values.ressourceTitle?.length > 1 && formik.values.ressourceUrl?.length > 1 && formik.values.ressourceStatus !== undefined
      : undefined;
  }, [formik.values]);

  const submitButtonLabel = useMemo(() => {
    switch (action) {
      case "delete":
        return "Supprimer";
      case "edit":
        return "Modifier";
      default:
        return "Ajouter";
    }
  }, [action]);

  const getRessourceStatusOPtion = () => {
    let ressourceOptions = [];
    if (RessourcesStatus) {
      RessourcesStatus?.map((ressource) => {
        ressourceOptions.push({ label: ressource.label, value: ressource.status });
      });
    }
    return ressourceOptions;
  };

  const handleSelectChange = (e) => {
    formik.setValues({ ...formik.values, ["ressourceStatus"]: e ? e.value : "" });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {action !== "delete" ? (
        <>
          <FormGroup row>
            <Label htmlFor="ressourceTitle" sm={2}>
              Titre
            </Label>
            <Col sm={10}>
              <Input
                id="ressourceTitle"
                name="ressourceTitle"
                value={formik.values.ressourceTitle}
                onChange={formik.handleChange}
                placeholder=" -- Ajouter un titre"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="ressourceUrl" sm={2}>
              Ressources
            </Label>
            <Col sm={10}>
              <Input
                id="ressourceUrl"
                name="ressourceUrl"
                type="url"
                value={formik.values.ressourceUrl}
                onChange={formik.handleChange}
                placeholder=" -- Ajouter une URL"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="ressourceStatus" sm={2}>
              Statut
            </Label>
            <Col sm={10}>
              <Select
                onChange={(e) => handleSelectChange(e)}
                placeholder="-- sélectionner un statut"
                options={getRessourceStatusOPtion()}
                value={getRessourceStatusOPtion().find((rsc) => rsc.value === formik.values.ressourceStatus)}
              />
            </Col>
          </FormGroup>
          {editOnly && (
            <FormGroup row>
              <Label htmlFor="ressourceRating" sm={2}>
                Eval.
              </Label>
              <Col sm={3}>
                <Input
                  id="ressourceRating"
                  name="ressourceRating"
                  defaultValue={""}
                  value={formik.values.ressourceRating}
                  onChange={formik.handleChange}
                  type="number"
                  min={3}
                  max={5}
                  disabled={editOnly}
                />
              </Col>
            </FormGroup>
          )}
        </>
      ) : (
        <div className="text-center p-4">
          Souhaitez-vous supprimer la ressource <br />
          <em>{selectedRessource?.title}</em> <br />
        </div>
      )}

      <div className="d-flex justify-content-center">
        <Button
          className="m-2"
          size="md"
          color="danger"
          role="button"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        &nbsp;
        <Button className="m-2" size="md" color="primary" disabled={!isFormCorrect && action !== "delete"} type="submit">
          {submitButtonLabel}
        </Button>
      </div>
    </form>
  );
};

export default AddEditRessourceContent;
