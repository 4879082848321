import { React } from "react";
import EditableTable from "../table/EditableTable";
import DisEnableQuestSeasonConfig from "../modals/DisEnableQuestSeasonConfig";
import ConfirmContextProvider from "../FormConFirmDialog/FormConfirmContextProvider";
import DeleteFutureSeasonConfigModal from "../modals/DeleteFutureSeasonConfigModal";
import { deleteQuestSeasonConfig } from "../../redux/slices/QuestConfigSlice";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Enums from "../../assets/JsonData/Enums";
import EditQuestSeasonConfigModal from "../modals/EditQuestSeasonConfigModal";
import AssignPersonalQuestComp from "../questSeasonConfig/AssignPersonalQuestComp";

const QuestSeasonItemTable = ({ data, questConfig, handleActivateOrDesactivateQuestSeasonconfig, users }) => {
  const questSeasonTableHead = ["Expédition", "Type", "Role", "Coins", "Score", "Cadeau", "Max Itération", "Etat", "Actions"];
  const dispatch = useDispatch();
  const { seasonList } = useSelector((state) => ({ ...state.seasonList }));
  let seasonDetails = undefined;
  const getSeasonDetail = (item) => {
    let result = undefined;
    !seasonList || seasonList.length === 0 || !seasonList.data
      ? (result = undefined)
      : (result = seasonList.data.find((season) => season.seasonId === item.seasonId));

    seasonDetails = result;
    return true;
  };

  const handleDeleteQuestSeasonConfig = (questSeasonConfigId) => {
    const payload = {
      questConfigId: questConfig.questConfigId,
      questSeasonConfigId: questSeasonConfigId,
    };
    dispatch(deleteQuestSeasonConfig(payload));
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderQuestSeasonBody = (item, index) => (
    <>
      {!getSeasonDetail(item) ? (
        ""
      ) : (
        <tr key={index}>
          <td className="questSeasonInfo">{seasonDetails === undefined ? "Interexpédition" : seasonDetails.title}</td>
          <td className="questSeasonInfo">
            <i className="bx bx-time-five"></i>&nbsp;{seasonDetails === undefined ? "permanente" : Enums.matchSeasonType(seasonDetails.seasonType)}
          </td>
          <td className="questSeasonInfo">
            <i className="bx bx-group bx"></i>&nbsp;{Enums.matchClass(item.role)}
          </td>
          <td className="questSeasonInfo">
            <i className="bx bx-coin"></i> {item.coins}
          </td>
          <td className="questSeasonInfo">
            <i className="bx bx-trophy"></i> {item.score}
          </td>
          <td className="questSeasonInfo">
            <i className="bx bx-gift"></i> {item.gift === true ? "oui" : "non"}
          </td>
          <td className="questSeasonInfo">
            <i className="bx bx-revision"></i> {item.maxIteration ?? "illimitée"}
          </td>
          <td className="questSeasonInfo">
            <ConfirmContextProvider>
              <DisEnableQuestSeasonConfig
                item={item}
                handleActivateOrDesactivateQuestSeasonconfig={handleActivateOrDesactivateQuestSeasonconfig}
              ></DisEnableQuestSeasonConfig>
            </ConfirmContextProvider>
          </td>
          <td className="questSeasonInfo">
            <div className="d-flex justify-content-evenly">
              <EditQuestSeasonConfigModal item={item} questConfig={questConfig}></EditQuestSeasonConfigModal>
              <ConfirmContextProvider>
                <DeleteFutureSeasonConfigModal
                  item={item}
                  handleDeleteQuestSeasonConfig={handleDeleteQuestSeasonConfig}
                  isDeleteDisabled={false}
                ></DeleteFutureSeasonConfigModal>
                {item.role === 4 && <AssignPersonalQuestComp item={item} users={users} title={questConfig?.title} />}
              </ConfirmContextProvider>
            </div>
          </td>
        </tr>
      )}
    </>
  );

  return (
    <div>
      <EditableTable
        limit="10"
        loading={false}
        title=""
        headData={questSeasonTableHead}
        renderHead={(item, index) => renderHead(item, index)}
        bodyData={data}
        renderBody={(item, index) => renderQuestSeasonBody(item, index)}
        striped={true}
      />
    </div>
  );
};

export default QuestSeasonItemTable;
