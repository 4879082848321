import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DigDaysDataService from "../../services/DigDaysDataService";
import { notify } from "../../assets/utils/UtilsFunctons";

export const getAllDigDays = createAsyncThunk("DigDays/getAllDigDays", async () => {
  try {
    const response = await DigDaysDataService.getAllDigDays();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const updateUserDigDaysStock = createAsyncThunk("DigDays/updateUserDigDaysStock", async (data) => {
  try {
    const response = await DigDaysDataService.updateUserDigDaysStock(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const getDigDaysByUserId = createAsyncThunk("DigDays/getDigDaysByUserId", async (id) => {
  try {
    const response = await DigDaysDataService.getDigDaysByUserId(id);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const digDayDecision = createAsyncThunk("DigDays/digDayDecision", async (data) => {
  try {
    const response = await DigDaysDataService.digDayDecision(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const cancelDigDay = createAsyncThunk("DigDays/cancelDigDay", async (data) => {
  try {
    const response = await DigDaysDataService.cancelDigDay(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const DigDaysSlice = createSlice({
  name: "digDays",
  initialState: {
    digDays: undefined,
    loading_getAllDigDays: false,
    digDaysByUserId: undefined,
    loading_getDigDaysByUserId: false,
    decison: undefined,
    loading_digDayDecision: false,
    cancel: undefined,
    loading_cancelDigDay: false,
    refreshDigDaysPage: false,
    updateUserDigDaysStock: undefined,
    loading_updateUserDigDaysStock: false,
  },
  reducers: {},
  extraReducers: {
    [getAllDigDays.pending]: (state) => {
      state.loading_getAllDigDays = true;
    },
    [getAllDigDays.fulfilled]: (state, { payload }) => {
      state.loading_getAllDigDays = false;
      state.digDays = payload;
      if (payload?.status !== 200 && payload?.title) {
        /* No action if status === 200, so we check for errors only */
        notify(0, payload.title);
      }
    },
    [getDigDaysByUserId.pending]: (state) => {
      state.loading_getDigDaysByUserId = true;
    },
    [getDigDaysByUserId.fulfilled]: (state, { payload }) => {
      state.loading_getDigDaysByUserId = false;
      state.digDaysByUserId = payload;
      if (payload?.status !== 200 && payload?.title) {
        /* No action if status === 200, so we check for errors only */
        notify(0, payload.title);
      }
    },

    [updateUserDigDaysStock.pending]: (state) => {
      state.loading_updateUserDigDaysStock = true;
    },
    [updateUserDigDaysStock.fulfilled]: (state, { payload }) => {
      state.loading_updateUserDigDaysStock = false;
      state.updateUserDigDaysStock = payload;
      if (payload?.status !== 200 && payload?.title) {
        /* No action if status === 200, so we check for errors only */
        notify(0, payload.title);
      }
    },

    [digDayDecision.pending]: (state) => {
      state.loading_digDayDecision = true;
    },
    [digDayDecision.fulfilled]: (state, { payload }) => {
      state.loading_digDayDecision = false;
      state.decison = payload;
      if (payload?.status === 200) {
        notify(1, "décision enregistrée avec succès");
        state.refreshDigDaysPage = !state.refreshDigDaysPage;
      } else if (payload?.title) {
        notify(0, payload.title);
      }
    },
    [cancelDigDay.pending]: (state) => {
      state.loading_cancelDigDay = true;
    },
    [cancelDigDay.fulfilled]: (state, { payload }) => {
      state.loading_cancelDigDay = false;
      state.cancel = payload;
      if (payload?.status === 200) {
        notify(1, "digDay annulé avec succès");
        state.refreshDigDaysPage = !state.refreshDigDaysPage;
      } else if (payload?.title) {
        notify(0, payload.title);
      }
    },
  },
});

export default DigDaysSlice.reducer;
