import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import EditBlocConfigForm from "../forms/EditBlocConfigForm";

const EditBlocConfigModal = ({ buttonLabel, buttonActive, item, updateOrDeleteBlocConfig }) => {
  const [modal, setModal] = useState(false);
  let button = "";
  let title = "";
  let form = "";

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );
  const label = buttonLabel;

  const editButton = (
    <Button color="warning" className="edit-leagueConf-btn" onClick={toggle}>
      <i className="bx bxs-pencil"> Modifier</i>
    </Button>
  );

  const deleteButton = (
    <Button color="danger" onClick={toggle} style={{ float: "left", marginRight: "10px" }} disabled={buttonActive}>
      <i className="bx bxs-trash"> Supprimer</i>
    </Button>
  );

  if (label === "Edit") {
    button = editButton;
    title = "Modifier le bloc";
    form = <EditBlocConfigForm item={item} modalConfig={setModal} updateOrDeleteBlocConfig={updateOrDeleteBlocConfig} label={label}></EditBlocConfigForm>;
  } else {
    button = deleteButton;
    title = "Voulez-vous supprimer ce bloc ?";
    form = <EditBlocConfigForm item={item} modalConfig={setModal} updateOrDeleteBlocConfig={updateOrDeleteBlocConfig} label={label}></EditBlocConfigForm>;
  }

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>{form}</ModalBody>
      </Modal>
    </div>
  );
};

export default EditBlocConfigModal;
