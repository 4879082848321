import { React, useState } from "react";
import { formatToISOString } from "../../assets/utils/UtilsFunctons";
import EditQuestSeasonConfigForm from "../forms/EditQuestSeasonConfigForm";
import ConfirmContextProvider from "../FormConFirmDialog/FormConfirmContextProvider";
import { updateQuestSeasonConfig } from "../../redux/slices/QuestConfigSlice";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const EditQuestSeasonConfigModal = ({ item, questConfig }) => {
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  const updateQuestSeasonConfigFunc = (formValue) => {
    const payload = {
      questSeasonConfigId: formValue.questSeasonConfigId,
      coins: parseInt(formValue.coins),
      score: parseInt(formValue.score),
      maxIteration: parseInt(formValue.maxIteration),
      gift: formValue.gift === "true",
      expirationDate: formValue.expirationType === 1 ? formatToISOString(formValue.expirationDate) : null,
      expirationDuration:
        formValue.expirationType === 2
          ? {
              hours: formValue.hours,
              days: formValue.days,
              months: formValue.months,
            }
          : null,
    };
    dispatch(updateQuestSeasonConfig(payload));
  };

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <Button color="warning" onClick={toggle} size="sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Modifier la quête d'expédition">
      <i className="bx bxs-edit"></i>
    </Button>
  );

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Modification Quête d'expédition
        </ModalHeader>
        <ModalBody>
          <ConfirmContextProvider>
            <EditQuestSeasonConfigForm item={item} questConfig={questConfig} modalVisibility={setModal} updateQuestSeasonConfig={updateQuestSeasonConfigFunc} />
          </ConfirmContextProvider>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default EditQuestSeasonConfigModal;
