import { React, useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const QuestValidationForm = ({ item, formType, postQuestDecision, modalVisibility }) => {
  const [formTitle, setFormTitle] = useState("");
  const [submitButtonColor, setSubmitButtonColor] = useState("");
  const [submitButtonText, setSubmitButtonText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setformValue] = useState({
    questId: item.questId,
    decidedQuestState: "",
    message: "",
  });

  const customizeFormFromformType = () => {
    switch (formType) {
      case "validation":
        {
          setFormTitle(
            <div>
              Êtes-vous sûr de valider la quête <br /> <b> {item.title}</b> {item.iteration ? "n° " + item.iteration : ""} du collaborateur &nbsp;
              <b>
                <i>{item.firstName + " " + item.lastName} </i>
              </b>
              ?
            </div>
          );
          setSubmitButtonColor("success");
          setSubmitButtonText("Valider");
          setformValue({
            ...formValue,
            ["decidedQuestState"]: 3,
          });
        }
        break;
      case "adjustment":
        {
          setFormTitle(
            <div>
              Êtes-vous sûr de demander l&apos;ajustement de la quête <b> {item.title}</b> {item.iteration ? "n° " + item.iteration : ""} du collaborateur
              &nbsp;
              <b>
                <i>{item.firstName + " " + item.lastName} </i>
              </b>
              ?
            </div>
          );
          setSubmitButtonColor("warning");
          setSubmitButtonText("Valider");
          setformValue({
            ...formValue,
            ["decidedQuestState"]: 2,
          });
        }
        break;
      case "rejection":
        {
          setFormTitle(
            <div>
              Êtes-vous sûr de rejeter la quête <br /> <b> {item.title}</b> {item.iteration ? "n° " + item.iteration : ""} du collaborateur &nbsp;
              <b>
                <i>{item.firstName + " " + item.lastName} </i>
              </b>
              ?
            </div>
          );
          setSubmitButtonColor("warning");
          setSubmitButtonText("Rejeter");
          setformValue({
            ...formValue,
            ["decidedQuestState"]: 0,
          });
        }
        break;
      default:
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    postQuestDecision(formValue, modalVisibility);
  };

  const handleReset = () => {
    modalVisibility();
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    customizeFormFromformType();
  }, []);

  return (
    <div className="quest-validation-form ">
      <div className="quest-validation-form-header">
        <div>
          <i className="bx bx-error-circle bx-tada quest-validation-form-icon"></i>
        </div>
        <div className="quest-validation-form-title">{formTitle}</div>
      </div>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            rows={4}
            required={formValue.decidedQuestState !== 3}
            autoComplete="off"
            onChange={handleChange}
            placeholder={
              formValue.decidedQuestState === 3 ? "Insérez un message de conversation (optionnel)" : "Insérez un message de conversation (obligatoire)"
            }
          />
        </FormGroup>
        <FormGroup>
          {formValue.decidedQuestState !== 3 && (
            <div>
              <i>
                <sup className="red-star-character">*</sup> Pour {formValue.decidedQuestState === 0 ? "rejeter une quête" : "demander un ajustement"}, il faut y
                associer un message.
              </i>
            </div>
          )}
        </FormGroup>
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color={submitButtonColor} type="submit" block disabled={isLoading}>
              {isLoading && <i className="bx bx-loader-alt bx-spin"></i>} &nbsp;
              {submitButtonText}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default QuestValidationForm;
