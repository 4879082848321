import { React, useEffect } from "react";
import { Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getSeasonInfos } from "../../redux/slices/SeasonSlice";
import PageLoadingFailNotification from "../PageLoadingFailNotification/PageLoadingFailNotification";

const ExperienceAndSeason = () => {
  const { userInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { seasonList, seasonInfos } = useSelector((state) => ({ ...state.seasonList }));
  const { leagueConfigs } = useSelector((state) => ({ ...state.leagueConfig }));

  const user = userInfos?.data;

  const getCurrentSeasonId = () => {
    if (seasonList && seasonList.data) {
      for (let i in seasonList.data) {
        if (seasonList.data[i].seasonType === 1) {
          return seasonList.data[i].seasonId;
        }
      }
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSeasonInfos(getCurrentSeasonId()));
  }, []);

  let currentLeague;
  if (leagueConfigs?.data && user) {
    for (let i in leagueConfigs.data) {
      if (leagueConfigs.data[i].leagueId === user?.leagueId) {
        currentLeague = leagueConfigs.data[i];
        break;
      }
    }
  }

  let currentTeam;
  if (seasonInfos?.data?.seasonTeams && user) {
    for (let i in seasonInfos.data.seasonTeams) {
      if (seasonInfos.data.seasonTeams[i].teamId === user?.teamId) {
        currentTeam = seasonInfos.data.seasonTeams[i];
        break;
      }
    }
  }

  return (
    <div>
      {userInfos && userInfos.data ? (
        <div className="container py-5">
          <div className="row align-items-md-stretch">
            <div className="col-md-6">
              <div className="h-100 p-4 bg-light border rounded-3">
                <h6>Expérience</h6>
                <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                    Niveau
                    <span className="d-block small opacity-50">{user?.experience?.levelId}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios2">
                    Jour #<span className="d-block small opacity-50">{user?.experience?.daysSinceBirthday + "/365"}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios3">
                    Date de contrat
                    <span className="d-block small opacity-50">
                      {user?.experience?.contractAnniversary.substring(0, user?.experience?.contractAnniversary.indexOf("T"))}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="h-100 p-4 bg-light border rounded-3">
                <h6>Expédition en cours</h6>
                <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                    Cristaux
                    <span className="d-block small opacity-50">{user?.score}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios2">
                    Rang
                    <span className="d-block small opacity-50">{user?.rank}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios3">
                    Equipe
                    <span className="d-block small opacity-50">{currentTeam?.name}</span>
                  </label>

                  <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios4">
                    Ligue
                    <span className="d-block small opacity-50">{currentLeague?.name}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-md-stretch">
            <div className="h-100 p-4 bg-light border rounded-3">
              <h6>Boost</h6>
              <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                <Row>
                  <div className="col-md-4">
                    <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios1">
                      Boost de niveau
                      <span className="d-block small opacity-50">{user?.levelBoost}</span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios2">
                      Boost de ligue<span className="d-block small opacity-50">{user?.leagueBoost}</span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="list-group-item rounded-3 py-3" htmlFor="listGroupCheckableRadios3">
                      Boost total
                      <span className="d-block small opacity-50">{user?.totalBoost}</span>
                    </label>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoadingFailNotification text={"La récupération des données a échoué"} />
      )}
    </div>
  );
};

export default ExperienceAndSeason;
