import { React } from "react";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { Button } from "reactstrap";

const DeleteFutureSeasonConfigModal = ({ item, handleDeleteQuestSeasonConfig, isDeleteDisabled }) => {
  const { isConfirmed } = useConfirm();

  const myBtn = (
    <Button
      color="danger"
      onClick={() => handleSubmit()}
      size="sm"
      disabled={isDeleteDisabled}
      data-toggle="tooltip"
      data-placement="top"
      title="Supprimer l'expédition"
    >
      <i className="bx bxs-trash"></i>
    </Button>
  );
  const handleSubmit = async () => {
    const confirmed = await isConfirmed("Souhaitez vous supprimer cette quête d'expédition ?");
    if (confirmed) {
      handleDeleteQuestSeasonConfig(item.questSeasonConfigId);
    }
  };

  return (
    <div>
      <ConfirmModal></ConfirmModal>
      {myBtn}
    </div>
  );
};

export default DeleteFutureSeasonConfigModal;
