import { React, useState } from "react";
import AddNewUserForm from "../forms/AddNewUserForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const AddNewUserModal = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <Button color="success" onClick={toggle} data-bs-toggle="tooltip" data-bs-placement="top" title="Ajouter un nouvel utilisateur">
      <i className="bx bx-add-to-queue" style={{ fontSize: "1.5em" }}></i>&nbsp;Ajouter un utilisateur
    </Button>
  );

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false} size="md">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Ajout nouvel utilisateur
        </ModalHeader>
        <ModalBody>
          <AddNewUserForm addUser={props.addUser} modalVisibility={setModal} currentSeason={props.currentSeason} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewUserModal;
