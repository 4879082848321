import { React, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, FormGroup, Label, Input, Row, Col, Alert, UncontrolledTooltip } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const AddNewUserForm = (props) => {
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const [alertVisiblity, setAlertVisiblity] = useState(false);
  const [alertText] = useState("");
  const [formValue, setformValue] = useState({
    UserId: "",
    FirstName: "",
    LastName: "",
    Email: "",
    JobDescription: "",
    Role: "1",
    DigDaysInitialStock: "",
    BirthDay: "",
    ContractAnniversary: "",
    isGhost: true,
    operatingManagerId: null,
  });
  const handleAlertVisiblity = () => {
    setAlertVisiblity(!alertVisiblity);
  };

  const hangleCheckBoxChange = (event) => {
    if (event.target.name === "isGhost") {
      setformValue({
        ...formValue,
        isGhost: event.target.checked,
      });
    }
    if (event.target.name === "Role") {
      setformValue({
        ...formValue,
        Role: event.target.value,
      });
    }
  };
  const handleChange = (event) => {
    if (event.target.name !== "isGhost" && event.target.name !== "Role") {
      if (event.target.name === "ContractAnniversary") {
        setformValue({
          ...formValue,
          [event.target.name]: event.target.value,
          DigDaysInitialStock: getYearProRataValue(new Date(event.target.value)),
        });
      } else
        setformValue({
          ...formValue,
          [event.target.name]: event.target.value,
        });
    }
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    props.addUser(formValue);
    props.modalVisibility(false);
  };
  const handleReset = () => {
    props.modalVisibility(false);
  };

  const isLeapYear = (year) => {
    if (year % 4 === 0) {
      if (year % 100 === 0) {
        return year % 400 === 0;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const getDayOfYear = (date) => {
    let jan1 = new Date();
    jan1.setMonth(0);
    jan1.setDate(1);
    jan1.setFullYear(date.getFullYear());

    let diffMs = date.getTime() - jan1.getTime();
    let days = Math.round(diffMs / (1000 * 60 * 60 * 24));
    return days + 1;
  };

  const getYearProRataValue = (date) => {
    let currentYear = new Date().getFullYear();
    if (currentYear > date.getFullYear()) {
      return 18;
    }
    let daysInYear = isLeapYear(date.getFullYear()) ? 366 : 365;
    return Math.round(((daysInYear - getDayOfYear(date)) * 18) / daysInYear);
  };

  const DigDaysItems = (max, dateContract) => {
    let choices = [];
    choices.push(
      <option value={getYearProRataValue(new Date(dateContract))} key={0}>
        Prorata
      </option>
    );
    for (let pas = 1; pas <= max; pas++) {
      choices.push(<option value={pas} key={pas} />);
    }
    return choices;
  };

  const isSeasonInProgress = () => {
    return new Date() < new Date(props.currentSeason?.submissionEndDate);
  };

  const getOperationalManagerOptions = useCallback(() => {
    let choices = [];

    if (usersInfos && usersInfos.data) {
      usersInfos.data.map((user) => {
        {
          choices.push({ value: user.userId, label: `${user.firstName} ${user.lastName}` });
        }
      });
    }
    return choices;
  }, [usersInfos]);

  return (
    <div>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Alert color="danger" isOpen={alertVisiblity} toggle={handleAlertVisiblity}>
            {alertText}
          </Alert>
        </FormGroup>
        <FormGroup>
          <Label for="firstName">Prénom</Label>
          <Input type="text" name="FirstName" id="firstName" onChange={handleChange} required autoComplete="off" placeholder="Prénom de l'utilisateur" />
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Nom</Label>
          <Input type="text" name="LastName" id="lastName" required placeholder="Nom de l'utilisateur" autoComplete="off" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <div className="input-group mb-3">
            <Input type="text" name="Email" id="Email" required placeholder="Email" autoComplete="off" onChange={handleChange} />
            <span className="input-group-text" id="basic-addon2">
              @diggers-consulting.com
            </span>
          </div>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="ListenedQuestConfigId">Manager opérationnel</Label>
          <Select
            name="operatingManagerId"
            id="operatingManagerId"
            autoComplete="off"
            required
            isClearable
            isSearchable
            onChange={handleReactSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            placeholder="Manager opérationel"
            options={getOperationalManagerOptions()}
          />
        </FormGroup>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="userId">Azure ID</Label>
              <Input type="text" name="UserId" id="UserId" onChange={handleChange} required autoComplete="off" placeholder="Azure ID" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="ContractAnniversary">Date contrat</Label>
              <Input type="date" name="ContractAnniversary" id="ContractAnniversary" required onChange={handleChange} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="BirthDay">Anniversaire</Label>
              <Input type="date" name="BirthDay" id="BirthDay" required autoComplete="off" onChange={handleChange} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="blocId">Solde DigDays</Label>
              <Input
                type="number"
                list="iterationList"
                name="DigDaysInitialStock"
                id="DigDaysInitialStock"
                value={formValue.DigDaysInitialStock}
                onChange={handleChange}
                autoComplete="off"
                min="0"
                max="18"
              />
              {formValue.ContractAnniversary !== "" ? <datalist id="iterationList">{DigDaysItems(18, formValue.ContractAnniversary)}</datalist> : ""}
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="JobDescription">Job description</Label>
          <Input type="textarea" name="JobDescription" id="JobDescription" required placeholder="Job description" onChange={handleChange} />
        </FormGroup>
        <Row>
          <Col md={6}>
            <FormGroup>
              <fieldset className="border px-1">
                <legend className="float-none  w-auto small ">Role</legend>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="Role" id="Role1" value={1} onClick={hangleCheckBoxChange} defaultChecked />
                  <label className="form-check-label" for="Role1">
                    Explorateur
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="Role" id="Role2" required value={2} onClick={hangleCheckBoxChange} />
                  <label className="form-check-label" for="Role2">
                    Guide
                  </label>
                </div>
              </fieldset>
            </FormGroup>
          </Col>
          <Col md={6}>
            {/* isGhost field only displayed when role=2 (guide)*/}
            {formValue.Role === "2" ? (
              <div>
                <FormGroup id="isGhostCheckBox">
                  <div className="form-check py-2 align-item-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isGhost"
                      name="isGhost"
                      onChange={hangleCheckBoxChange}
                      checked={formValue.isGhost}
                      disabled={isSeasonInProgress()}
                    />
                    <label className="form-check-label" for="isGhost">
                      Ghost
                    </label>
                  </div>
                </FormGroup>
                {isSeasonInProgress() ? (
                  <UncontrolledTooltip placement="right" target="isGhostCheckBox">
                    Un nouveau guide doit forcément être ghost s'il est créé en cours d'expédition
                  </UncontrolledTooltip>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <br />
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block>
              Ajouter
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddNewUserForm;
