import React from "react";
import Enums from "../../../assets/JsonData/Enums";
import ShopTransactionActionButton from "./ShopTransactionActionButton";
import MessageSender from "../../discussionPanel/MessageSender";
import { Badge } from "reactstrap";

const ShopTransactionModalHeader = ({ transactionItem, handleShopTransactionUpdate }) => {
  return (
    <>
      <div className="d-flex justify-content-between px-5 py-3">
        <div className="d-flex flex-column fs-6">
          <div>
            <i className="bx bx-purchase-tag-alt "></i>&nbsp;<span>{transactionItem.itemName}</span> &nbsp;
            {Enums.matchTransactionState(transactionItem.transactionState)}
          </div>
          <div>
            <i className="bx bx-user"></i>&nbsp;{transactionItem.firstName + " " + transactionItem.lastName}
          </div>
          <div>
            <i className="bx bx-coin-stack"></i>&nbsp;{transactionItem.cost}
          </div>
          <div>
            <i className="bx bx-calendar"></i>&nbsp;
            <Badge> {new Date(transactionItem.purchaseDate)?.toLocaleDateString("fr") + " " + transactionItem.purchaseDate.slice(11, 16)}</Badge>
          </div>
        </div>
        <div>
          <ShopTransactionActionButton transactionItem={transactionItem} handleShopTransactionUpdate={handleShopTransactionUpdate} />
        </div>
      </div>
      <div>
        <MessageSender parentId={transactionItem.transactionId} parentType={3}></MessageSender>
      </div>
    </>
  );
};

export default ShopTransactionModalHeader;
