import { React, useState } from "react";
import ShopTransactionModalHeader from "./ShopTransactionModalHeader";
import ShopTransactionDiscussion from "./ShopTransactionDiscussion";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const ShopTransactionModal = ({ transactionItem, openModal, handleShopTransactionUpdate, setOpenModal }) => {
  const [modal, setModal] = useState(openModal);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button
      className="close"
      onClick={() => {
        toggle();
        setOpenModal(false);
      }}
    >
      &times;
    </Button>
  );

  return (
    <div className="shopTransaction-modal">
      <i className="bx bx-check-circle  " onClick={() => toggle()}></i>
      <Modal className="quest-serial-validation-modal" isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false} size="xl">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Transaction boutique
        </ModalHeader>

        <ModalBody>
          <ShopTransactionModalHeader transactionItem={transactionItem} handleShopTransactionUpdate={handleShopTransactionUpdate} />
          <hr />
          <ShopTransactionDiscussion logId={transactionItem.transactionId} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ShopTransactionModal;
