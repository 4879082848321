import React from "react";
import PaginatedTable from "./PaginatedTable";
import { Spinner } from "reactstrap";
import "./editableTable.css";

const EditableTable = (props) => {
  return (
    <div>
      <div className="editableTitleHeader">
        <div>
          <h5>{props.title}</h5>
        </div>
        <div>{props.modal}</div>
      </div>
      {props.loading ? (
        <div className="get-questConfig-spinner">
          <Spinner color="secondary">Loading...</Spinner>
        </div>
      ) : (
        <PaginatedTable
          limit={props.limit}
          headData={props.headData}
          renderHead={props.renderHead}
          bodyData={props.bodyData}
          renderBody={props.renderBody}
          striped={props.striped}
        />
      )}
    </div>
  );
};

export default EditableTable;
