import { React, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

const AddNewLevelConfigForm = (props) => {
  const { levelConfigs } = useSelector((stateLevel) => ({ ...stateLevel.levelConfig }));

  const getMaxLevel = (data) => {
    var max = 0;
    for (const element of data) {
      if (element.levelId > max) {
        max = element.levelId;
      }
    }
    return max;
  };

  const [formValue, setformValue] = useState({
    levelId: getMaxLevel(levelConfigs.data) + 1,
    boost: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    formValue.boost = parseInt(formValue.boost);
    props.postLevelConfig(formValue);
    props.modalConfig(false);
  };

  const handleChanges = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event) => {
    handleChanges(event);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <h6>ID du niveau</h6>
          {formValue.levelId}
        </FormGroup>
        <FormGroup>
          <Label for="boost">Boost (%)</Label>
          <Input type="float" name="boost" id="boost" required placeholder="Boost" autoComplete="off" onChange={handleChange} />
        </FormGroup>
        <Button color="success" type="submit">
          Ajouter
        </Button>
      </Form>
    </div>
  );
};

export default AddNewLevelConfigForm;
