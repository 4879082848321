import React, { useState } from "react";
import { Table } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./table.css";

const PaginatedTable = (props) => {
  let Data = [...props.bodyData];

  const initDataShow = props.limit && Data ? Data.slice(0, Number(props.limit)) : Data;
  const [dataShow, setDataShow] = useState(initDataShow);

  let pages = 1;
  let range = [];

  if (props.limit !== undefined) {
    let page = Math.floor(Data.length / Number(props.limit));
    pages = Data.length % Number(props.limit) === 0 ? page : page + 1;
    range = [...Array(pages).keys()];
  }

  const [currPage, setCurrPage] = useState(0);

  const selectPage = (page) => {
    const start = Number(props.limit) * page;
    const end = start + Number(props.limit);

    setDataShow(Data.slice(start, end));

    setCurrPage(page);
  };

  return (
    <div>
      {props.modal}
      <div className="table-wrapper">
        <Table striped={props.striped}>
          {props.headData && props.renderHead ? (
            <thead>
              <tr>{props.headData.map((item, index) => props.renderHead(item, index))}</tr>
            </thead>
          ) : null}
          {Data && props.renderBody ? <tbody className="align-middle">{dataShow.map((item, index) => props.renderBody(item, index))}</tbody> : null}
        </Table>
      </div>
      {pages > 1 ? (
        <div className="table__pagination">
          {range.map((item, index) => (
            <div key={index} className={`table__pagination-item ${currPage === index ? "active" : ""}`} onClick={() => selectPage(index)}>
              {item + 1}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default PaginatedTable;
