import http from "./http-common";

class ShopDataService {
  getShopItem() {
    return http.get("/shopItem", { params: { getDeactivated: true } });
  }

  addNewShopItem(data) {
    const formData = new FormData();
    const ShopItemContract = {
      name: data.name,
      description: data.description,
      theme: data.theme,
      cost: data.cost,
      stock: data.stock,
      isActivated: data.isActivated,
      shopItemId: data.shopItemId,
    };
    formData.append("File", data.pictureData);
    formData.append("ShopItemContract", JSON.stringify(ShopItemContract));
    return http.post("/shopItem", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  editShopItem(data) {
    const formData = new FormData();
    const ShopItemContract = {
      name: data.name,
      description: data.description,
      theme: data.theme,
      cost: data.cost,
      stock: data.stock,
      isActivated: data.isActivated,
      shopItemId: data.shopItemId,
    };
    formData.append("File", data.pictureData);
    formData.append("ShopItemContract", JSON.stringify(ShopItemContract));
    return http.put("/shopItem", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  deleteShopItem(data) {
    return http.delete("/shopItem", { params: { shopItemId: data.shopItemId } });
  }

  getShopTransactions() {
    return http.get("/transaction");
  }
  getShopTransactionsById(data) {
    return http.get("/transaction?userId=" + data);
  }

  intreatmentShopTransaction(data) {
    return http.put("/transaction/intreatment?transactionId=" + data);
  }
  deliverShopTransaction(data) {
    return http.put("/transaction/deliver?transactionId=" + data);
  }
  indeliveryShopTransaction(data) {
    return http.put("/transaction/indelivery?transactionId=" + data);
  }
  cancelShopTransaction(data) {
    return http.put("/transaction/cancel?transactionId=" + data);
  }
}

export default new ShopDataService();
