import { React, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const EditLeagueConfigForm = ({ item, label, editLeagueConfig, deleteLeagueConfig, modalConfig }) => {
  const { leagueConfigs } = useSelector((stateLeague) => ({ ...stateLeague.leagueConfig }));

  const [formValue, setformValue] = useState({
    leagueId: item.leagueId,
    name: item.name,
    boost: item.boost,
    previousLeagueId: item.previousLeagueId,
    colorCode: item.colorCode,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (label === "Edit") {
      formValue.boost = parseInt(formValue.boost);
      editLeagueConfig(formValue);
    } else if (label === "Delete") {
      deleteLeagueConfig(item.leagueId);
    }
  };

  const handleChange = (e) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleReset = () => {
    modalConfig(false);
  };

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <Row>
        <Col md={10}>
          <h6>Nom de la ligue</h6>
          {label === "Edit" ? (
            <FormGroup>
              <Label for="name">Nom</Label>
              <Input type="text" name="name" id="name" required placeholder="name" autoComplete="off" onChange={handleChange} defaultValue={formValue.name} />
            </FormGroup>
          ) : (
            formValue.name
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={10}>
          <h6>Boost de la ligue (%)</h6>
          {label === "Edit" ? (
            <FormGroup>
              <Label for="boost">Boost (%)</Label>
              <Input
                type="number"
                min="0"
                name="boost"
                id="boost"
                autoComplete="off"
                placeholder="boost"
                required
                onChange={handleChange}
                defaultValue={formValue.boost}
              />
            </FormGroup>
          ) : (
            formValue.boost
          )}
        </Col>
      </Row>
      <FormGroup>
        <Label for="boost">Code couleur </Label>
        <Input type="color" name="colorCode" id="colorCode" required onChange={handleChange} value={formValue.colorCode} />
      </FormGroup>
      <Row>
        <Col md={10}>
          <h6>Ligue précédente</h6>
          {label === "Edit" ? (
            <FormGroup>
              <Input
                type="select"
                name="previousLeagueId"
                id="previousLeagueId"
                autoComplete="off"
                onChange={handleChange}
                defaultValue={formValue.previousLeagueId}
              >
                <option value={null}>Pas de ligue précédente</option>
                {leagueConfigs === [] || leagueConfigs === undefined || leagueConfigs.data === undefined || leagueConfigs.data === "null"
                  ? "Pas de ligue disponible"
                  : leagueConfigs.data.map((item) => {
                      if (item.leagueId !== formValue.leagueId) {
                        return (
                          <option key={item.leagueId} value={item.leagueId}>
                            {item.name}
                          </option>
                        );
                      }
                    })}
              </Input>
            </FormGroup>
          ) : formValue.previousLeagueId ? (
            leagueConfigs.data.map((item) => {
              if (item.leagueId === formValue.previousLeagueId) {
                return item.name;
              }
            })
          ) : (
            "Pas de ligue précédente"
          )}
        </Col>
      </Row>

      <br />
      <Row></Row>
      <br />
      {label === "Edit" ? (
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block>
              Modifier
            </Button>
          </Col>
        </Row>
      ) : (
        <Button color="danger" type="submit">
          Supprimer
        </Button>
      )}
    </Form>
  );
};

export default EditLeagueConfigForm;
