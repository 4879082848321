import { React, useState } from "react";
import { FormGroup, Label, Input, FormText, Row, Col, Button } from "reactstrap";

const EditLeagueIconForm = ({ handleUpdateLeagueIconPicture, leagueId, modalConfig }) => {
  const [imgFile, setImgFile] = useState(null);

  const handleFileInputChange = (event) => {
    const fileList = event.target.files;
    setImgFile(fileList[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      leagueId: leagueId,
      file: imgFile,
    };
    handleUpdateLeagueIconPicture(payload);
    modalConfig(false);
  };

  const handleReset = () => {
    modalConfig(false);
  };
  return (
    <div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Label for="exampleFile">Fichier</Label>
          <Input id="exampleFile" name="file" type="file" accept="image/png, image/jpeg" onChange={handleFileInputChange} />
          <FormText>Veuillez selectionner une photo</FormText>
        </FormGroup>

        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block>
              Modifier
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default EditLeagueIconForm;
