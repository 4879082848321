import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import { v4 as uuid } from "uuid";
import { FormGroup, Label, Button, Col, Input, FormText } from "reactstrap";

const AddNewAxisForm = ({ handleClose, handleAddAxis }) => {
  const [isloading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      axisId: uuid(),
      axisTitle: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      handleAddAxis(values);
    },
  });

  const isFormCorrect = useMemo(() => {
    return formik.values.axisTitle?.length > 1 && formik.values.axisTitle?.length <= 30;
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label htmlFor="axisTitle" sm={3}>
          Axe
        </Label>
        <Col sm={9}>
          <Input
            id="axisTitle"
            name="axisTitle"
            value={formik.values.axisTitle}
            onChange={formik.handleChange}
            placeholder=" -- Ajouter un  axe"
            maxLength="30"
          />
          <FormText>30 caractères max.</FormText>
        </Col>
      </FormGroup>

      <div className="d-flex justify-content-center">
        <Button
          className="m-2"
          size="md"
          color="danger"
          role="button"
          onClick={() => {
            handleClose();
          }}
        >
          Annuler
        </Button>
        &nbsp;
        <Button className="m-2" size="md" color="primary" disabled={!isFormCorrect || isloading} type="submit">
          {isloading && <i className="bx bx-loader-alt bx-spin"></i>} Ajouter
        </Button>
      </div>
    </form>
  );
};

export default AddNewAxisForm;
