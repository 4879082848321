import { React, useState } from "react";
import { useSelector } from "react-redux";
import SortAndSearchTable from "../../table/sortAndSearchTable/SortAndSearchTable";
import ShopTransactionModal from "./ShopTransactionModal";
import Enums from "../../../assets/JsonData/Enums";
import useConfirm from "../../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../../modals/FormConfirmDialogModal";
import ShopTransactionActionButton from "./ShopTransactionActionButton";
import { Badge, Alert, InputGroup, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";

/* eslint react/no-unescaped-entities: "off", @typescript-eslint/no-unused-vars: "off", @typescript-eslint/no-empty-function: "off" */
const ShopTransactionsTable = ({ deliverShopTransaction, cancelShopTransaction, indeliveryShopTransaction, intreatmentShopTransaction, userId }) => {
  const { isConfirmed } = useConfirm();
  const { shopTransactions, refreshAfterTransactionStateUpdate } = useSelector((state) => ({ ...state.shopConfig }));
  const [currentFilteredState, setCurrentFilteredState] = useState("");
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [transactionData, setTransactionData] = useState(undefined);

  let TransactionStateList = [
    { nom: "En traitement", state: 0 },
    { nom: "En livraison", state: 1 },
    { nom: "Livré", state: 2 },
    { nom: "Annulé", state: 3 },
  ];

  const handleShopTransactionUpdate = async (action, id) => {
    let text;
    if (action === "deliver") {
      text = (
        <span>
          <p>Souhaitez-vous terminer cette transaction ?</p>
          <div className="d-flex flex-column">
            <small> - la transaction passera au statut "Livré"</small>
          </div>
        </span>
      );
    }
    if (action === "cancel") {
      text = (
        <span>
          <p>Souhaitez-vous annuler cette transaction ?</p>
          <div className="d-flex flex-column">
            <small> - le collaborateur sera rembousé</small>
            <small> - le stock de l'article sera incrémenté</small>
          </div>
        </span>
      );
    }
    if (action === "indelivery") {
      text = (
        <span>
          <p>Souhaitez-vous valider la livraison de cette transaction ?</p>
          <div className="d-flex flex-column">
            <small> - la transaction passera au statut "En livraison"</small>
          </div>
        </span>
      );
    }
    if (action === "intreatment") {
      text = (
        <span>
          <p>Souhaitez-vous reinitialiser cette transaction ?</p>
          <div className="d-flex flex-column">
            <small> - la transaction passera au statut "En traitement"</small>
          </div>
        </span>
      );
    }

    const confirmed = await isConfirmed(text);
    if (confirmed) {
      if (action === "deliver") {
        deliverShopTransaction(id);
      }
      if (action === "cancel") {
        cancelShopTransaction(id);
      }
      if (action === "indelivery") {
        indeliveryShopTransaction(id);
      }
      if (action === "intreatment") {
        intreatmentShopTransaction(id);
      }
    }
  };

  const columns = [
    {
      Header: "Id Transaction",
      accessor: "transactionNumber",
    },
    {
      Header: "Collaborateur",
      accessor: (row) => row.firstName + " " + row.lastName, // accessor is the "key" in the data
    },
    {
      Header: "Nom article",
      accessor: "itemName",
    },

    {
      Header: "Prix",
      accessor: "cost",
    },
    {
      Header: "Etat",
      accessor: "transactionState",
      Cell: ({ value }) => {
        return Enums.matchTransactionState(value);
      },
    },
    {
      Header: "date",
      accessor: "purchaseDate",
      Cell: ({ value }) => {
        return <Badge> {new Date(value).toLocaleDateString("fr") + " " + value.slice(11, 16)}</Badge>;
      },
    },
  ];

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Actions",
        Header: "Actions",
        Cell: ({ row }) => {
          return <ShopTransactionActionButton transactionItem={row.original} handleShopTransactionUpdate={handleShopTransactionUpdate} />;
        },
      },
    ]);
  };
  const handleRowClicked = (row) => {
    setOpenTransactionModal(true);
    setTransactionData(row.original);
  };

  const sortedDataByTransactionId = () => {
    let sortedData = shopTransactions ? [...shopTransactions.data] : [];
    if (sortedData !== undefined) {
      return sortedData.sort((x, y) => y.transactionNumber - x.transactionNumber);
    } else {
      return [];
    }
  };

  /** filter **/

  const transactionStateFilterItem = () => {
    return TransactionStateList.map((state, index) => (
      <DropdownItem onClick={() => filterTransactionByState(state.state)} key={index}>
        {state.nom}
      </DropdownItem>
    ));
  };

  const sortedTransactionsData = sortedDataByTransactionId();
  const [filteredTransactionsBystate, setFilteredTransactionBystate] = useState(
    userId ? sortedTransactionsData.filter((trans) => trans.userId === userId) : sortedTransactionsData
  );

  const filterTransactionByState = (state) => {
    if (state !== "") {
      setCurrentFilteredState(TransactionStateList.find((st) => st.state === state)?.nom);
      setFilteredTransactionBystate(sortedTransactionsData?.filter((item) => item.transactionState === state));
    } else {
      setCurrentFilteredState(null);
      setFilteredTransactionBystate(sortedTransactionsData);
    }
  };

  return (
    <div>
      <ConfirmModal></ConfirmModal>
      {openTransactionModal && (
        <ShopTransactionModal
          transactionItem={transactionData}
          openModal={openTransactionModal}
          handleShopTransactionUpdate={handleShopTransactionUpdate}
          setOpenModal={setOpenTransactionModal}
        />
      )}
      {shopTransactions !== [] && shopTransactions && shopTransactions.status === 200 && shopTransactions.data ? (
        <div>
          <div>
            <InputGroup>
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle caret color="primary">
                    <i className="bx bx-filter-alt"> Filtrer par état</i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {transactionStateFilterItem()}
                    <DropdownItem divider />
                    <DropdownItem onClick={() => filterTransactionByState("")}>effacer filtre</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            </InputGroup>
            {currentFilteredState && <Badge>{currentFilteredState}</Badge>}
          </div>
          <SortAndSearchTable
            data={filteredTransactionsBystate}
            columns={columns}
            tableHooks={tableHooks}
            refresh={[refreshAfterTransactionStateUpdate, currentFilteredState]}
            handleRowClicked={handleRowClicked}
          ></SortAndSearchTable>
        </div>
      ) : (
        <div className="py-3 ">
          <Alert color="danger">
            La récupération des données a echoué , veuillez ressayer en
            <a href="/QuestToValidate">
              <b> actualisant</b>
            </a>
            la page.
          </Alert>
        </div>
      )}
    </div>
  );
};

export default ShopTransactionsTable;
