import { React, useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GeneraralUserInfos from "../components/userDetailledInfosPage/GeneralUserInfos";
import ExperienceAndSeason from "../components/userDetailledInfosPage/ExperienceAndSeason";
import UserQuests from "../components/userDetailledInfosPage/UserQuests";
import UserPurchases from "../components/userDetailledInfosPage/UserPurchases";
import UserCoinAndScoreVariations from "../components/userDetailledInfosPage/UserCoinAndScoreVariations";
import { getUserInfosById, getUserProfilePictureById } from "../redux/slices/UserSlice";
import { getAllLeagueConfigs } from "../redux/slices/LeagueConfigSlice";
import { getSeasonList } from "../redux/slices/SeasonSlice";
import UserDigDaysandMission from "../components/userDetailledInfosPage/user-digDays-and-mission/UserDigDaysandMission";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import GetCurrentUserProfilePicture from "../components/userDetailledInfosPage/EditOrDeleteUserProfilPicture/GetCurrentUserProfilePicture";
import { Toaster } from "react-hot-toast";
import Loader from "../components/loader/Loader";
import { Button, Badge, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from "reactstrap";
import "../assets/css/userDetailledInfosPage.css";
import UserSessions from "../components/userDetailledInfosPage/UserSessions";
import userAdmin from "../assets/images/user-admin.svg";
const UserDetailledInfosPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const activeTab = useMemo(() => {
    if (location && location?.hash && location?.hash?.length > 1) {
      return location.hash.split("#")[1];
    }
    return "infos";
  }, [location]);

  const navigate = useNavigate();
  const { loading_getUserInfoById, refreshUserPage, userInfos, currentUserInfos } = useSelector((state) => ({ ...state.userInfos }));

  const [currentUserName, setCurrentUserName] = useState(currentUserInfos.firstName + " " + currentUserInfos.lastName);

  const goBackToUserPage = () => {
    navigate("/UsersPage");
  };

  const goBackBtn = <Button onClick={goBackToUserPage}>Retour</Button>;

  useEffect(() => {
    dispatch(getUserInfosById(currentUserInfos?.userId));
    dispatch(getSeasonList());
    dispatch(getAllLeagueConfigs());
    dispatch(getUserProfilePictureById(currentUserInfos?.userId));
  }, [refreshUserPage, currentUserInfos]);

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      {loading_getUserInfoById ? (
        <Loader></Loader>
      ) : userInfos && userInfos.data ? (
        <div>
          <div className="d-flex justify-content-between py-2">
            <div className="d-flex justify-content-between">
              <div>
                <GetCurrentUserProfilePicture userId={currentUserInfos?.userId}></GetCurrentUserProfilePicture>
              </div>
              <div className="d-flex align-items-start flex-column px-4">
                <span>
                  <h5 className="">
                    Fiche utilisateur :{" "}
                    <small className="fs-5">
                      {currentUserName}{" "}
                      {currentUserInfos?.canChangeTeamDuringSeason && <img src={userAdmin} alt="AM" width={16} style={{ verticalAlign: "baseline" }} />}
                    </small>
                  </h5>
                  <span className="text-muted">{userInfos?.data?.biographie}</span>
                  <h6>
                    <span>
                      <Badge color={userInfos?.data?.isActivated ? "success" : "danger"}> {userInfos?.data?.isActivated ? "actif" : "inactif"}</Badge>
                    </span>
                  </h6>
                </span>
              </div>
            </div>
            <div>{goBackBtn}</div>
          </div>
          <ConfirmContextProvider>
            <div className="myCard">
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink className={`nav-link ${activeTab === "infos" ? "active" : ""}`} href="#infos">
                      <span>Infos générales</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`nav-link ${activeTab === "experiences" ? "active" : ""}`} href="#experiences">
                      <span>Expérience & Expédition</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`nav-link ${activeTab === "quetes" ? "active" : ""}`} href="#quetes">
                      <span>Quêtes</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`nav-link ${activeTab === "boutique" ? "active" : ""}`} href="#boutique">
                      <span>Boutique</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`nav-link ${activeTab === "actions-interne" ? "active" : ""}`} href="#actions-interne">
                      <span>Actions internes</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`nav-link ${activeTab === "gains" ? "active" : ""}`} href="#gains">
                      <span>Historique gains</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={`nav-link ${activeTab === "connexions" ? "active" : ""}`} href="#connexions">
                      <span>Connexions</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} style={{ background: "white", padding: "15px" }}>
                  <TabPane tabId="infos">
                    <Row>
                      <Col sm="12">
                        <GeneraralUserInfos setCurrentUserName={setCurrentUserName} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="experiences">
                    <Row>
                      <Col sm="12">
                        <ExperienceAndSeason />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="quetes">
                    <Row>
                      <Col sm="12">
                        <UserQuests />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="boutique">
                    <Row>
                      <Col sm="12">
                        <UserPurchases user={currentUserInfos} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="actions-interne">
                    <Row>
                      <Col sm="12">
                        <UserDigDaysandMission user={currentUserInfos} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="gains">
                    <Row>
                      <Col sm="12">
                        <UserCoinAndScoreVariations userId={currentUserInfos?.userId} userInfos={userInfos} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="connexions">
                    <Row>
                      <Col sm="12">
                        <UserSessions />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </ConfirmContextProvider>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserDetailledInfosPage;
