import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LeagueConfigDataService from "../../services/LeagueConfigDataService";
import toast from "react-hot-toast";

const notify = (notif, state) => {
  switch (state) {
    case 1:
      toast.success(notif, { position: "top-right" });
      break;
    case 0:
      toast.error(notif, { position: "top-right" });
      break;
    default:
      toast.error("La requête a échoué !");
      break;
  }
};

export const getAllLeagueConfigs = createAsyncThunk("leagueConfigs/getAllLeagueConfigs", async () => {
  try {
    const response = await LeagueConfigDataService.getAllLeagueConfigs();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const addNewLeagueConfigs = createAsyncThunk("leagueConfigs/addNewLeagueConfigs", async (data) => {
  try {
    const response = await LeagueConfigDataService.addNewLeagueConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const editLeagueConfigs = createAsyncThunk("leagueConfigs/editLeagueConfigs", async (data) => {
  try {
    const response = await LeagueConfigDataService.editLeagueConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const deleteLeagueConfigs = createAsyncThunk("leagueConfigs/deleteLeagueConfigs", async (data) => {
  try {
    const response = await LeagueConfigDataService.deleteLeagueConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const postLeagueIconPicture = createAsyncThunk("leagueConfigs/postLeagueIconPicture", async (data) => {
  try {
    const response = await LeagueConfigDataService.postLeagueIconPicture(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const LeagueConfigSlice = createSlice({
  name: "leagueConfigs",
  initialState: {
    leagueConfigs: [1],
    loading_getLeagueConfigs: true,
    loading_addLeagueConfigs: true,
    dataPost: [],
    error: "",
    refreshLeaguePageAfterUpdate: false,
  },
  reducers: {},
  extraReducers: {
    [getAllLeagueConfigs.pending]: (state) => {
      state.loading_getLeagueConfigs = true;
      console.log("pending");
    },
    [getAllLeagueConfigs.fulfilled]: (state, { payload }) => {
      state.loading_getLeagueConfigs = false;
      state.leagueConfigs = payload;
      if (state.dataPost.status === 200) {
        notify("Ligue correctement récupérées !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [getAllLeagueConfigs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [addNewLeagueConfigs.fulfilled]: (state, { payload }) => {
      state.loading_addLeagueConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Ligue créée avec succès !", 1);
        state.refreshLeaguePageAfterUpdate = !state.refreshLeaguePageAfterUpdate;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [editLeagueConfigs.fulfilled]: (state, { payload }) => {
      state.loading_editLeagueConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Ligue modifiée avec succès !", 1);
        state.refreshLeaguePageAfterUpdate = !state.refreshLeaguePageAfterUpdate;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [deleteLeagueConfigs.fulfilled]: (state, { payload }) => {
      state.loading_addLeagueConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Ligue correctement supprimée !", 1);
        state.refreshLeaguePageAfterUpdate = !state.refreshLeaguePageAfterUpdate;
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [postLeagueIconPicture.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Image ajoutée avec succès !", 1);
        state.refreshLeaguePageAfterUpdate = !state.refreshLeaguePageAfterUpdate;
      } else if (state.dataPost?.title) {
        notify(state.dataPost.title, 0);
      }
    },
  },
});

export default LeagueConfigSlice.reducer;
