import { React, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import useUserRole from "../../hooks/useUserRole";
import "./submenu.css";

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const { isAdmin, hasAccessAsDeveloper, isValidator, isReadOnly, isManager } = useUserRole();
  //const { isAdmin, isDeveloper, isValidator, isReadOnly } = { isAdmin: true, isDeveloper: false, isValidator: true, isReadOnly: true };

  const showSubnav = () => setSubnav(!subnav);

  const renderAdminPannel = useCallback(() => {
    return (
      <div className="sideNavBar__content">
        {!item.subNav ? (
          <NavLink to={item.route} onClick={item.subNav && showSubnav} exact="true" activeclassname="active">
            <div className="sidebar__item">
              <div className="sidebar__item-inner">
                {item.subNav && subnav ? <i className={`arrow ${item.iconOpened}`}></i> : item.subNav ? <i className={`arrow ${item.iconClosed}`}></i> : null}
                {!item.subNav && <i className={item.icon}></i>}
                <span>{item.display_name}</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <div onClick={item.subNav && showSubnav} className="sidebar__sup_item">
            <div className="sidebar__item">
              <div className="sidebar__item-inner">
                {item.subNav && subnav ? <i className={`arrow ${item.iconOpened}`}></i> : item.subNav ? <i className={`arrow ${item.iconClosed}`}></i> : null}
                {!item.subNav && <i className={item.icon}></i>}
                <span>{item.display_name}</span>
              </div>
            </div>
          </div>
        )}
        <div className="submenu-item">
          {subnav &&
            item.subNav?.map((item, index) => {
              return (
                <NavLink to={item.route} key={index} activeclassname="active">
                  <div className="sidebar__item">
                    <div className={`sidebar__item-inner ${item.active ? "active" : ""}`}>
                      <i className={item.icon}></i>
                      <span>{item.display_name}</span>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>
      </div>
    );
  }, [item, subnav]);

  const renderValidatorPannel = useCallback(() => {
    return (
      <div className="sideNavBar__content">
        {!item.validatorAccess ? (
          ""
        ) : !item.subNav ? (
          <NavLink to={item.route} onClick={item.subNav && showSubnav} exact="true" activeclassname="active">
            <div className="sidebar__item">
              <div className="sidebar__item-inner">
                {item.subNav && subnav ? <i className={`arrow ${item.iconOpened}`}></i> : item.subNav ? <i className={`arrow ${item.iconClosed}`}></i> : null}
                {!item.subNav && <i className={item.icon}></i>}
                <span>{item.display_name}</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <div onClick={item.subNav && showSubnav} className="sidebar__sup_item">
            <div className="sidebar__item">
              <div className="sidebar__item-inner">
                {item.subNav && subnav ? <i className={`arrow ${item.iconOpened}`}></i> : item.subNav ? <i className={`arrow ${item.iconClosed}`}></i> : null}
                {!item.subNav && <i className={item.icon}></i>}
                <span>{item.display_name}</span>
              </div>
            </div>
          </div>
        )}
        <div className="submenu-item">
          {subnav &&
            item.subNav?.map((item, index) => {
              return !item.validatorAccess ? (
                ""
              ) : (
                <NavLink to={item.route} key={index} activeclassname="active">
                  <div className="sidebar__item">
                    <div className={`sidebar__item-inner ${item.active ? "active" : ""}`}>
                      <i className={item.icon}></i>
                      <span>{item.display_name}</span>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>
      </div>
    );
  }, [item, subnav]);

  const renderReadOnlyPannel = useCallback(() => {
    return (
      <div className="sideNavBar__content">
        {!item.readOnlyAccess ? (
          ""
        ) : !item.subNav ? (
          <NavLink to={item.route} onClick={item.subNav && showSubnav} exact="true" activeclassname="active">
            <div className="sidebar__item">
              <div className="sidebar__item-inner">
                {item.subNav && subnav ? <i className={`arrow ${item.iconOpened}`}></i> : item.subNav ? <i className={`arrow ${item.iconClosed}`}></i> : null}
                {!item.subNav && <i className={item.icon}></i>}
                <span>{item.display_name}</span>
              </div>
            </div>
          </NavLink>
        ) : (
          ""
        )}
        <div className="submenu-item">
          {item?.subNav?.map((item, index) => {
            return !item.readOnlyAccess ? (
              ""
            ) : (
              <NavLink to={item.route} key={index} activeclassname="active">
                <div className="sidebar__item">
                  <div className={`sidebar__item-inner ${item.active ? "active" : ""}`}>
                    <i className={item.icon}></i>
                    <span>{item.display_name}</span>
                  </div>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }, [item, subnav]);

  const renderuserpanelBasedOnRole = useCallback(() => {
    if (isAdmin || hasAccessAsDeveloper) {
      return renderAdminPannel();
    }
    if (isValidator) {
      return renderValidatorPannel();
    }
    if (isReadOnly || isManager) {
      return renderReadOnlyPannel();
    }
  }, [isAdmin, isValidator, isReadOnly, isManager, hasAccessAsDeveloper, renderAdminPannel, renderValidatorPannel, renderReadOnlyPannel]);

  return (
    <>
      {/* <div className="sideNavBar__content">
        {item.restrictedAccess && !hasAccessTorestrictedContent ? (
          ""
        ) : item.subNav ? (
          <div onClick={item.subNav && showSubnav}>
            <div className="sidebar__item">
              <div className="sidebar__item-inner">
                {item.subNav && subnav ? <i className={`arrow ${item.iconOpened}`}></i> : item.subNav ? <i className={`arrow ${item.iconClosed}`}></i> : null}
                {!item.subNav && <i className={item.icon}></i>}
                <span>{item.display_name}</span>
              </div>
            </div>
          </div>
        ) : (
          <NavLink to={item.route} onClick={item.subNav && showSubnav} exact="true" activeclassname="active">
            <div className="sidebar__item">
              <div className="sidebar__item-inner">
                {item.subNav && subnav ? <i className={`arrow ${item.iconOpened}`}></i> : item.subNav ? <i className={`arrow ${item.iconClosed}`}></i> : null}
                {!item.subNav && <i className={item.icon}></i>}
                <span>{item.display_name}</span>
              </div>
            </div>
          </NavLink>
        )}
        <div className="submenu-item">
          {subnav &&
            item.subNav?.map((item, index) => {
              return item.restrictedAccess && !hasAccessTorestrictedContent ? (
                ""
              ) : (
                <NavLink to={item.route} key={index} activeclassname="active">
                  <div className="sidebar__item">
                    <div className={`sidebar__item-inner ${item.active ? "active" : ""}`}>
                      <i className={item.icon}></i>
                      <span>{item.display_name}</span>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>
      </div> */}
      <div className="sideNavBar__content">{renderuserpanelBasedOnRole()}</div>
    </>
  );
};

export default SubMenu;
