import { React, useState } from "react";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import QuizQuestionConfigComponent from "./QuizQuestionConfigComponent";
import { isQuizQuestionCorrectlyFilled } from "../../assets/utils/UtilsFunctons";
import { v4 as uuid } from "uuid";
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Button, Col } from "reactstrap";

const QuizQuestionAccordion = ({ quiz, handleQuizConfigContent, disableButton }) => {
  const [quizQuestionList, setQuizQuestionList] = useState(quiz.questionConfigs);
  const [numberOfQuestions, setNumberOfQuestions] = useState(quiz.questionConfigs?.length);
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };
  const { isConfirmed } = useConfirm();
  const handleAddNewQuizQuestion = () => {
    toggle((numberOfQuestions + 1)?.toString());
    setNumberOfQuestions((prev) => prev + 1);
    let newQuestion = {
      questionConfigId: uuid(),
      position: quizQuestionList.length + 1,
      text: "",
      timeLimit: 30,
      questionType: "",
      mediaUrl: null,
      answerConfigs: [],
    };

    setQuizQuestionList([...quizQuestionList, newQuestion]);
  };

  const handleDeleteQuizQuestion = (questionIndex) => {
    const newQuestions = quizQuestionList.filter((_, index) => index !== questionIndex);
    setNumberOfQuestions(newQuestions?.length);
    setQuizQuestionList(newQuestions);
  };

  const updateOrAddQuestion = (newQuestion) => {
    const existingIndex = quizQuestionList.findIndex((question) => question.questionConfigId === newQuestion.questionConfigId);

    if (existingIndex !== -1) {
      // Si l'élément existe déjà, remplacez-le dans la liste
      const updatedQuestionsList = [...quizQuestionList];
      updatedQuestionsList[existingIndex] = newQuestion;
      setQuizQuestionList(updatedQuestionsList);
    } else {
      // Sinon, ajoutez simplement le nouvel élément à la liste
      setQuizQuestionList([...quizQuestionList, newQuestion]);
    }
  };

  const handleSaveQuizContent = async () => {
    let confirmed = await isConfirmed(" Souhaitez vous enregister le(s) question(s) du quiz !");
    if (confirmed) {
      let formValue = {
        quizConfigId: quiz.quizConfigId,
        questionConfigs: quizQuestionList,
      };
      handleQuizConfigContent(formValue);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Questions</h5>
        <Button color="success" type="button" outline onClick={handleAddNewQuizQuestion} disabled={disableButton}>
          <span>
            <i className="bx bx-plus"></i>
            Ajouter une question
          </span>
        </Button>
      </div>
      <Accordion open={open} toggle={toggle}>
        {quizQuestionList?.map((questionConfig, index) => (
          <AccordionItem key={index} open={false}>
            <AccordionHeader targetId={questionConfig.position?.toString()}>
              Question n° {questionConfig.position} &nbsp;
              {isQuizQuestionCorrectlyFilled(questionConfig) ? <i className="bx bx-check-circle fs-5"></i> : <i className="bx bx-error-circle fs-5"></i>}
            </AccordionHeader>
            <AccordionBody accordionId={questionConfig.position?.toString()}>
              <QuizQuestionConfigComponent
                questionConfig={questionConfig}
                handleDeleteQuizQuestion={handleDeleteQuizQuestion}
                index={index}
                updateOrAddQuestion={updateOrAddQuestion}
                disableButton={disableButton}
              />
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
      <div className="d-flex justify-content-start">
        <Col md={6}>
          <Button color="primary" type="button" block disabled={disableButton} className="mt-4" onClick={handleSaveQuizContent}>
            <span>
              Enregistrer les questions <i className="bx bx-save fs-4"></i>
            </span>
          </Button>
        </Col>
      </div>
    </>
  );
};

export default QuizQuestionAccordion;
