import React from "react";
import { useMemo } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Table } from "reactstrap";
import GlobalFilter from "./GlobalFilter";
import "../table.css";

/* eslint react/jsx-key: "off" */
const SortAndSearchTable = (props) => {
  const data = useMemo(() => props.data, [props.refresh]);
  const columns = useMemo(() => props.columns, [props.refresh]);

  const tableInstance = useTable({ columns, data }, useGlobalFilter, props.tableHooks, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  return (
    <div>
      <div className="pt-3">
        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter}></GlobalFilter>
      </div>
      <Table {...getTableProps()} striped responsive hover>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? column.isSortedDesc ? <i className=" bx bx-sort-down">&nbsp;</i> : <i className=" bx bx-sort-up">&nbsp;</i> : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onDoubleClick={() => props.handleRowClicked(row)} className={props.noClickableRow ? "" : "clickableRow"}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SortAndSearchTable;
