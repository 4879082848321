import React, { useCallback, useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import TablePagination from "../../table/table-pagination/TablePagination";
import { convertDateToLocalTimeWithHour } from "../../../assets/utils/UtilsFunctons";
import Enums from "../../../assets/JsonData/Enums";
import PepUserActionDigDays from "./PepUserActionDigDays";

const PepUserActionTable = ({ pepActions }) => {
  const [isVisible, setIsvisible] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const toggle = () => {
    setIsvisible(!isVisible);
  };

  const columns = [
    {
      Header: "Collaborateur",
      accessor: "userFullName",
    },
    {
      Header: "Axe",
      accessor: "axis.title",
    },
    {
      Header: "Domaine",
      accessor: "domain.title",
    },
    {
      Header: "Compétence",
      accessor: "skill.title",
    },
    {
      Header: "Action",
      accessor: "title",
    },
    {
      Header: "Niveau",
      accessor: "level",
      Cell: ({ value }) => {
        return Enums.matchPepActionConfigLevel(value) || null;
      },
    },
    {
      Header: "Priorité",
      accessor: "priority",
      Cell: ({ value }) => {
        return Enums.matchPepActionPriority(value) || null;
      },
    },
    {
      Header: "Statut",
      accessor: "status",
      Cell: ({ value }) => {
        return Enums.matchPepActionStatus(value) || null;
      },
    },
    {
      Header: "Progres.",
      accessor: "progress",
      Cell: ({ value }) => {
        return <span> {value} %</span> || null;
      },
    },
    {
      Header: "Début",
      accessor: "startDate",
      Cell: ({ value }) => {
        return convertDateToLocalTimeWithHour(value) || null;
      },
    },
    {
      Header: "Fin",
      accessor: "endDate",
      Cell: ({ value }) => {
        return convertDateToLocalTimeWithHour(value) || null;
      },
    },
    {
      Header: "DigDays",
      accessor: "digDaysPlanned",
    },
  ];

  const tableHooks = () => {
    return "";
  };

  const handleRowClicked = (row) => {
    toggle();
    setSelectedRow(row.original);
  };

  const renderPepOffCanvaDetail = useCallback(() => {
    return (
      <Offcanvas toggle={toggle} direction="end" isOpen={isVisible}>
        <OffcanvasHeader toggle={toggle}>
          <span className="text-center">DigDays</span>
        </OffcanvasHeader>
        <OffcanvasBody className="">
          <PepUserActionDigDays userAction={selectedRow} />
          <hr />
          <span className="fs-6 pb-2">
            <strong> Commentaires :</strong> <br />
          </span>
          {selectedRow?.comments || <em className="text-center">Aucun commentaire</em>}
        </OffcanvasBody>
      </Offcanvas>
    );
  }, [isVisible, selectedRow]);

  return (
    <div>
      <TablePagination
        data={pepActions}
        columns={columns}
        tableHooks={tableHooks}
        refresh={false}
        handleRowClicked={handleRowClicked}
        type={"default"}
        enableFilter={true}
        hasDefaultValue={false}
      >
        <element key="userFullName" filterKey="userFullName" className="cell" casesensitive={"true"} showsearch={"true"}>
          Collab..
        </element>
        <element key="axis" filterKey="axis" isObjectKey={true} subKey={"title"} className="cell" casesensitive={"true"} showsearch={"true"}>
          Axe
        </element>
        <element key={"domain"} filterKey={"domain"} isObjectKey={true} subKey={"title"} className="cell" casesensitive={"true"} showsearch={"true"}>
          Domaine
        </element>
        <element key="skill" filterKey="skill" isObjectKey={true} subKey={"title"} className="cell" casesensitive={"true"} showsearch={"true"}>
          Compétence
        </element>
      </TablePagination>
      {renderPepOffCanvaDetail()}
    </div>
  );
};

export default PepUserActionTable;
