import { React, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormGroup, Label, Col, Input, Button, Form, Row } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import ToggleButton from "../buttons/toggleButton/ToggleButton";
import Enums from "../../assets/JsonData/Enums";
import questConfigUtils from "./questConfig.utils";
import UseModalWithContent from "../useModalWithContent/UseModalWithContent";
import AddNewBloc from "./AddNewBloc";

const NewQuestConfigForm = ({ handleAddNewQuestConfig, isEditFormat, questConfigItem }) => {
  const navigate = useNavigate();
  const [hasBlocConfig, setHasBlocConfig] = useState(questConfigItem && questConfigItem.blocId ? true : false);
  const [newBlocConfig, setNewBlocConfig] = useState(null);
  const currentYear = new Date().getFullYear();
  const [isAddingQuestConfig, setIsAddingQuestConfig] = useState(false);
  const { questConfigs } = useSelector((state) => ({ ...state.questConfigs }));
  const { blocConfigs } = useSelector((stateBloc) => ({ ...stateBloc.blocConfig }));
  const { enumList } = useSelector((state) => ({ ...state.enums }));
  const disableEdition = isEditFormat && questConfigItem;
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);

  const [formValue, setformValue] = useState(
    !isEditFormat && !questConfigItem
      ? {
          title: null,
          description: null,
          needsValidation: null,
          questFunction: 0,
          unlockedBy: null,
          blocId: null,
          isVisibleByUsers: false,
          ListenedQuestConfigId: null,
          theme: null,
          category: null,
          maxProgress: 1,
          year: currentYear,
          newBlocConfig: null,
        }
      : {
          title: questConfigItem.title,
          description: questConfigItem.description,
          needsValidation: questConfigItem.needsValidation,
          questFunction: questConfigItem.questFunction,
          questConfigId: questConfigItem.questConfigId,
          unlockedBy: questConfigItem.unlockedBy,
          blocId: questConfigItem.blocId,
          isVisibleByUsers: questConfigItem.isVisibleByUsers,
          theme: questConfigItem.theme,
          maxProgress: questConfigItem.maxProgress,
          ListenedQuestConfigId: questConfigItem.listenedQuestConfigId,
          year: questConfigItem.year,
          category: questConfigItem.category,
        }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAddingQuestConfig(true);
    handleAddNewQuestConfig(formValue);
  };

  const handleReset = () => {
    if (isEditFormat) {
      setIsUpdateButtonActive(false);
      setformValue({
        title: questConfigItem.title,
        description: questConfigItem.description,
        needsValidation: questConfigItem.needsValidation,
        questFunction: questConfigItem.questFunction,
        questConfigId: questConfigItem.questConfigId,
        unlockedBy: questConfigItem.unlockedBy,
        blocId: questConfigItem.blocId,
        isVisibleByUsers: questConfigItem.isVisibleByUsers,
        theme: questConfigItem.theme,
        maxProgress: questConfigItem.maxProgress,
        ListenedQuestConfigId: questConfigItem.listenedQuestConfigId,
        year: questConfigItem.year,
        category: questConfigItem.category,
      });
    } else {
      navigate("/QuestManagement");
    }
  };

  const handleChange = (e) => {
    setIsUpdateButtonActive(true);
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });

    // gestion la concordance des données lors du changement de bloc
    if (event.name === "blocId") {
      setNewBlocConfig(null);
      let lastBlocElement = questConfigUtils.getBlocLastQuestConfig(questConfigs, choice?.value);
      setformValue((prevalue) => {
        return {
          ...prevalue,
          newBlocConfig: null,
          category: lastBlocElement?.category,
          theme: lastBlocElement?.theme,
          unlockedBy: lastBlocElement?.questConfigId,
        };
      });
    }
  };

  const handleManualValueChange = (element, operation) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [element]: operation === "add" ? ++formValue[element] : --formValue[element],
      };
    });
  };

  const getNewBlocConfig = (blocValue) => {
    setNewBlocConfig(blocValue);
    setformValue((prevalue) => {
      return {
        ...prevalue,
        newBlocConfig: blocValue,
        blocId: blocValue.blocId,
      };
    });
  };

  const handleHasBlocConfigToggle = () => {
    if (hasBlocConfig) {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          newBlocConfig: null,
          blocId: null,
        };
      });
    }
    setHasBlocConfig(!hasBlocConfig);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const { closeModal, ModalComponent, ModalButton } = UseModalWithContent(
    <AddNewBloc getNewBlocConfig={getNewBlocConfig} closeModal={handleCloseModal} />,
    "Ajouter un bloc"
  );

  return (
    <div className="new-questConfig-form">
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <Row className="pb-2">
          <Col md={2} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            <Label>Quête de bloc</Label>
            <ToggleButton state={hasBlocConfig} onClickHandler={handleHasBlocConfigToggle} isdisabled={disableEdition} />
          </Col>
          {hasBlocConfig && (
            <>
              <Col md={6} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                <Label htmlFor="blocId" className="new-questConf-blocItem">
                  <span>
                    Choix du bloc<sup>*</sup>&nbsp;
                  </span>
                </Label>

                <Select
                  name="blocId"
                  isClearable
                  isSearchable
                  value={[...questConfigUtils.getExistingBlocName(blocConfigs), { label: newBlocConfig?.name, value: newBlocConfig?.blocId }].find(
                    (item) => item.value === formValue.blocId
                  )}
                  options={
                    newBlocConfig
                      ? [...questConfigUtils.getExistingBlocName(blocConfigs), { label: newBlocConfig.name, value: newBlocConfig.blocId }]
                      : questConfigUtils.getExistingBlocName(blocConfigs)
                  }
                  onChange={handleReactSelectChange}
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="nom du bloc"
                  required
                  className="new-questConf-blocName"
                  isDisabled={disableEdition}
                />

                <div className="new-questConf-blocItem" style={{ color: "red !important", fontSize: "8px" }}>
                  <>
                    <ModalButton buttonText="" buttonColor="" icon={"bx bx-plus-circle"} disabled={disableEdition} />
                    <ModalComponent />
                  </>
                </div>
              </Col>
              <Col md={3} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                <Label htmlFor="isVisibleByUsers">
                  <span>position</span>
                </Label>

                <Select
                  name="position"
                  isSearchable
                  value={{ value: "fin", label: "Fin" }}
                  options={{ value: "fin", label: "Fin" }}
                  onChange={handleReactSelectChange}
                  noOptionsMessage={() => "Pas d'options"}
                  placeholder="Position"
                  isDisabled
                />
              </Col>
            </>
          )}
        </Row>
        {hasBlocConfig && formValue.blocId && (
          <FormGroup row className="new-questConf-formGroup">
            <Label htmlFor="ListenedQuestConfigId" sm={2}>
              <span>
                Quête mère <sup>*</sup>
              </span>
            </Label>
            <Col sm={6}>
              <Select
                name="ListenedQuestConfigId"
                isDisabled
                isClearable
                isSearchable
                value={questConfigUtils.getExistingQuestConfigTitle(questConfigs).find((item) => item.value === formValue.unlockedBy)}
                placeholder="quête mère..."
                options={questConfigUtils.getExistingQuestConfigByBlocId(questConfigs, formValue.blocId)}
                onChange={handleReactSelectChange}
                noOptionsMessage={() => "Pas d'options"}
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup row className="new-questConf-formGroup">
          <Label htmlFor="title" sm={2}>
            <span>
              Titre<sup>*</sup>
            </span>
          </Label>
          <Col sm={6}>
            <Input id="title" name="title" placeholder="Titre de la quête" type="text" onChange={handleChange} required value={formValue.title} />
          </Col>
        </FormGroup>
        <FormGroup row className="new-questConf-formGroup">
          <Label htmlFor="description" sm={2}>
            <span>
              Description<sup>*</sup>
            </span>
          </Label>
          <Col sm={6}>
            <Input
              id="description"
              name="description"
              type="textarea"
              onChange={handleChange}
              required
              placeholder="Description ..."
              value={formValue.description}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="new-questConf-formGroup">
          <Label htmlFor="category" sm={2}>
            <span>
              Catégorie<sup>*</sup>
            </span>
          </Label>
          <Col sm={4}>
            <Select
              name="category"
              required
              isClearable
              isSearchable
              isDisabled={(formValue.blocId && !formValue.newBlocConfig) || disableEdition}
              value={Enums.getQuestConfigCategoryOptions().find((category) => category.value === formValue.category)}
              placeholder="catégorie..."
              options={Enums.getQuestConfigCategoryOptions()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="new-questConf-formGroup">
          <Label htmlFor="theme" sm={2}>
            <span>
              Thème<sup>*</sup>
            </span>
          </Label>
          <Col sm={4}>
            <CreatableSelect
              name="theme"
              isClearable
              required
              isSearchable
              isDisabled={(formValue.blocId && !formValue.newBlocConfig) || disableEdition}
              value={questConfigUtils.getExistingTheme(questConfigs)?.find((theme) => theme.value === formValue.theme)}
              options={questConfigUtils.getExistingTheme(questConfigs)}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
              formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
              placeholder="Theme..."
            />
          </Col>
        </FormGroup>
        <FormGroup row className="new-questConf-formGroupe">
          <Label htmlFor="needsValidation" sm={2}>
            <span>
              Tye de validation<sup>*</sup>
            </span>
          </Label>
          <Col sm={3}>
            <Select
              name="needsValidation"
              isClearable
              isSearchable
              value={Enums.getQuestConfigValidationTypeOptions().find((item) => item.value === formValue.needsValidation)}
              options={Enums.getQuestConfigValidationTypeOptions()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
              placeholder="Type de validation"
              required
              isDisabled={disableEdition}
            />
          </Col>
          <Col sm={7}>
            {formValue.needsValidation === false && (
              <FormGroup>
                <fieldset className="border  new-questConfig-fieldset">
                  <legend className="float-none  w-auto small legend">&nbsp; Paramètres de validation automatique&nbsp; </legend>

                  <FormGroup className="new-questConf-formGroup">
                    <Label htmlFor="questFunction">
                      <span>
                        Fonction de validation<sup>*</sup>
                      </span>
                    </Label>
                    <Col sm={10}>
                      <Select
                        name="questFunction"
                        required
                        isClearable
                        isSearchable
                        value={questConfigUtils.getQuestFunctionOptions(enumList).find((item) => item.value === formValue.questFunction)}
                        placeholder="Fonction de validation..."
                        options={questConfigUtils.getQuestFunctionOptions(enumList)}
                        onChange={handleReactSelectChange}
                        noOptionsMessage={() => "Pas d'options"}
                        isDisabled={disableEdition}
                      />
                    </Col>
                  </FormGroup>
                  {/* optional field only displayed when needsValidation = "false" and questFuncton = "4,5,6" (...RecurrentQuest) */}
                  {(formValue.questFunction === 4 || formValue.questFunction === 5 || formValue.questFunction === 6) && formValue.needsValidation === false && (
                    <FormGroup className="new-questConf-formGroup">
                      <Label htmlFor="ListenedQuestConfigId">
                        <span>
                          Quête liée <sup>*</sup>
                        </span>
                      </Label>
                      <Col sm={10}>
                        <Select
                          name="ListenedQuestConfigId"
                          required
                          isClearable
                          isSearchable
                          value={questConfigUtils.getExistingQuestConfigTitle(questConfigs).find((item) => item.value === formValue.ListenedQuestConfigId)}
                          placeholder="Choisir une quête liée..."
                          options={questConfigUtils.getExistingQuestConfigTitle(questConfigs)}
                          onChange={handleReactSelectChange}
                          noOptionsMessage={() => "Pas d'options"}
                          isDisabled={disableEdition}
                        />
                      </Col>
                    </FormGroup>
                  )}
                  {/* optional field only displayed when needsValidation = "false" and questFuncton = "6" (YearCountRecurrentQuest)*/}
                  {formValue.questFunction === 6 && formValue.needsValidation === false && (
                    <FormGroup>
                      <Label htmlFor="year">Année</Label>
                      <Col sm={8} className="d-flex justify-content-center">
                        <button
                          name="year"
                          onClick={() => handleManualValueChange("year", "subtract")}
                          className="new-questConf-form-button-value"
                          type="button"
                          disabled={disableEdition}
                        >
                          <i className="bx bx-caret-left-circle fs-2"></i>
                        </button>
                        <input type="number" value={formValue.year} readOnly className="text-center questConf-year-field" isDisabled={disableEdition} />
                        <button
                          name="year"
                          onClick={() => handleManualValueChange("year", "add")}
                          className="new-questConf-form-button-value"
                          type="button"
                          disabled={disableEdition}
                        >
                          <i className="bx bx-caret-right-circle fs-2"></i>
                        </button>
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label htmlFor="maxProgress">Objectif</Label>
                    <Col sm={8} className="d-flex justify-content-center">
                      <button
                        disabled={formValue.maxProgress === 1 || disableEdition}
                        name="maxProgress"
                        onClick={() => handleManualValueChange("maxProgress", "subtract")}
                        className="new-questConf-form-button-value"
                        type="button"
                      >
                        <i className="bx bx-caret-left-circle fs-2"></i>
                      </button>
                      <input type="number" value={formValue.maxProgress} readOnly className="text-center questConf-year-field" disabled={disableEdition} />
                      <button
                        name="maxProgress"
                        onClick={() => handleManualValueChange("maxProgress", "add")}
                        className="new-questConf-form-button-value"
                        type="button"
                        disabled={disableEdition}
                      >
                        <i className="bx bx-caret-right-circle fs-2"></i>
                      </button>
                    </Col>
                  </FormGroup>
                </fieldset>
              </FormGroup>
            )}
          </Col>
        </FormGroup>

        {formValue.needsValidation && (
          <FormGroup row className="new-questConf-formGroup">
            <Label htmlFor="questFunction" sm={2}>
              <span>Fonction de validation</span>
            </Label>
            <Col sm={4}>
              <Select
                name="questFunction"
                required
                isClearable
                isSearchable
                value={questConfigUtils.getQuestFunctionOptions(enumList).find((item) => item.value === formValue.questFunction)}
                placeholder="catégorie..."
                options={questConfigUtils.getQuestFunctionOptions(enumList)}
                onChange={handleReactSelectChange}
                noOptionsMessage={() => "Pas d'options"}
                isDisabled={disableEdition}
              />
            </Col>
          </FormGroup>
        )}

        <FormGroup row className="new-questConf-formGroup">
          <Label htmlFor="isVisibleByUsers" sm={2}>
            <span>
              Visible<sup>*</sup>
            </span>
          </Label>
          <Col sm={4}>
            <Select
              name="isVisibleByUsers"
              isClearable
              isSearchable
              value={Enums.getYesOrNoOptions().find((item) => item.value === formValue.isVisibleByUsers)}
              options={Enums.getYesOrNoOptions()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
              placeholder="Visibilité"
              required
              isDisabled={disableEdition}
            />
          </Col>
        </FormGroup>

        <div className="pt-2 d-flex flex-row-reverse">
          <Button color="danger" type="reset" className="px-5 mx-3">
            Annuler
          </Button>

          <Button color={isEditFormat ? "warning" : "success"} type="submit" className="px-5" disabled={isAddingQuestConfig || !isUpdateButtonActive}>
            {isAddingQuestConfig ? <i className="bx bx-loader-alt bx-spin" /> : isEditFormat ? "Modifier" : "Ajouter"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default NewQuestConfigForm;
