import { React, useCallback } from "react";
import _ from "lodash";
import { getFileTypeFromFileName } from "../../assets/utils/UtilsFunctons";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import QuizResponseProposalComponent from "./QuizResponseProposalComponent";
import { Row, Col, FormGroup, Label, Input, Badge, Button, Alert, CloseButton } from "reactstrap";

const QuizQuestionConfigComponent = ({ questionConfig, handleDeleteQuizQuestion, index, updateOrAddQuestion, disableButton }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertTextContent, setAlertTextContent] = useState("");
  const [isQuestionCorrect, setIsQuestionCorrect] = useState(false);
  const [mediaInputKey, setMediaInputKey] = useState(1);
  const [preview, setPreview] = useState(null);
  const [questions, setQuestions] = useState(questionConfig?.answerConfigs);
  const [imageLoaded, setImageLoaded] = useState(false);
  let getMInOrMaxNumberValue = useCallback((minOrMax) => {
    let existingResponses = questions?.length >= 1 ? questions : null;
    if (existingResponses && existingResponses[0]) {
      let minAndMax = null;
      let response = existingResponses[0]?.text;
      minAndMax = response?.split("|");
      if (minAndMax && minOrMax === "min") {
        return minAndMax[0];
      }
      if (minAndMax && minOrMax === "max") {
        return minAndMax[1];
      }
    }
    return null;
  }, []);

  const [formValue, setformValue] = useState({
    questionConfigId: questionConfig.questionConfigId,
    position: questionConfig.position,
    text: questionConfig.text,
    timeLimit: questionConfig.timeLimit,
    questionType: questionConfig.questionType,
    mediaUrl: questionConfig.mediaUrl,
    hadMediaContentBefore: questionConfig.mediaUrl !== null,
    doesExistingMediachange: false,
    answerConfigs: questions,
    minNumberResponse: questionConfig.questionType === 2 ? getMInOrMaxNumberValue("min") : null,
    maxNumberResponse: questionConfig.questionType === 2 ? getMInOrMaxNumberValue("max") : null,
    fileName: questionConfig.fileName,
  });

  const onDismiss = () => setIsAlertVisible(!isAlertVisible);

  const handleAddQuestion = () => {
    if (questions.length < 4) {
      let newQuestionList = [...questions, { answerConfigId: uuid(), text: "", isCorrect: false }];
      setQuestions(newQuestionList);
      setformValue({
        ...formValue,
        answerConfigs: newQuestionList,
      });
    } else {
      setIsAlertVisible(true);
      setAlertTextContent("Impossible d'avoir plus de 4 propositions de réponses !");
    }
  };
  const handleDeleteQuestion = (questionIndex) => {
    const newQuestionList = questions.filter((_, index) => index !== questionIndex);
    setQuestions(newQuestionList);
    setformValue({
      ...formValue,
      answerConfigs: newQuestionList,
    });
  };

  const handleAnswerChange = (index, event) => {
    setIsQuestionCorrect(false);
    let newQuestions = _.cloneDeep(questions);
    newQuestions[index].text = event.target.value;
    setQuestions(newQuestions);
    setformValue({
      ...formValue,
      answerConfigs: newQuestions,
    });
  };
  const handleIsCorrectChange = (index) => {
    setIsQuestionCorrect(false);
    let newQuestions = _.cloneDeep(questions);
    newQuestions[index].isCorrect = !newQuestions[index].isCorrect;
    setQuestions(newQuestions);
    setformValue({
      ...formValue,
      answerConfigs: newQuestions,
    });
  };

  const handleChange = (event) => {
    setIsQuestionCorrect(false);
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
      answerConfigs: questions,
    });
  };

  const handleReactSelectChange = (choice) => {
    let defaultMultipleChoice = [
      { answerConfigId: uuid(), text: "", isCorrect: false },
      { answerConfigId: uuid(), text: "", isCorrect: false },
    ];
    setIsQuestionCorrect(false);
    setformValue((prevalue) => {
      return {
        ...prevalue,
        questionType: choice?.value ?? null,
        answerConfigs: choice?.value <= 1 && questions?.length === 0 ? defaultMultipleChoice : questions,
      };
    });
    if (choice?.value <= 1 && questions?.length === 0) {
      setQuestions(defaultMultipleChoice);
    }
    if (choice?.value && choice?.value > 1) {
      setQuestions([]);
      setformValue((prevalue) => {
        return {
          ...prevalue,
          answerConfigs: [],
        };
      });
    }
  };

  const handleReactMultiSelectChange = (choice) => {
    setIsQuestionCorrect(false);
    let reponses = [];
    choice?.forEach((element) => {
      reponses.push({ answerConfigId: uuid(), text: element.value, isCorrect: true });
    });

    setformValue((prevalue) => {
      return {
        ...prevalue,
        answerConfigs: reponses,
      };
    });
  };
  const handleReactSelectResponseNumberChange = (choice, event) => {
    setIsQuestionCorrect(false);
    let newValue = choice ? choice.value : null;
    if (event.name === "reponses-min") {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          minNumberResponse: newValue,
        };
      });
    } else {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          maxNumberResponse: newValue,
        };
      });
    }
  };

  const handleFileInputChange = (e) => {
    setIsQuestionCorrect(false);
    const file = e.target.files[0];
    setformValue({
      ...formValue,
      mediaUrl: file,
      fileName: file?.name,
      doesExistingMediachange: true,
    });

    const fileFormat = getFileTypeFromFileName(file);
    if (fileFormat !== "Inconnu") {
      if (file.type.match("image/*")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          setPreview(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleQuestionSubmit = (event) => {
    event.preventDefault();

    if (isQuizQuestionCorrectlyFilled(formValue)) {
      setIsQuestionCorrect(true);
      setAlertTextContent("");
      setIsAlertVisible(false);
      if (formValue.questionType !== 2) {
        updateOrAddQuestion(formValue);
      } else {
        let newPayload = {
          ...formValue,
          answerConfigs: [
            {
              answerConfigId: uuid(),
              text: formValue.maxNumberResponse ? `${formValue.minNumberResponse}|${formValue.maxNumberResponse}` : formValue.minNumberResponse,
              isCorrect: true,
            },
          ],
        };
        updateOrAddQuestion(newPayload);
      }
    }
  };

  const isQuizQuestionCorrectlyFilled = (questionConfig) => {
    let correctProposal =
      questionConfig.questionType === 0 || questionConfig.questionType === 1
        ? questionConfig.answerConfigs?.filter((question) => question.isCorrect === true)
        : 1;
    let numberOfAnswer = questionConfig.answerConfigs?.length;
    let numberOfCorrectAnswer = correctProposal ? correctProposal.length : 0;
    let timeLimit = questionConfig.timeLimit ? parseInt(questionConfig.timeLimit) : 0;
    // Vérifier qu'il y a une bonne réponse associée
    if ((questionConfig.questionType === 0 || questionConfig.questionType === 1) && numberOfCorrectAnswer === 0) {
      setIsAlertVisible(true);
      setAlertTextContent("Veuillez fournir au moins une réponse correcte !");
      return 0;
    }

    // si  string  : une et seule prosition correcte + min proposition 1
    if (questionConfig.questionType === 3 && numberOfAnswer === 0) {
      setIsAlertVisible(true);
      setAlertTextContent("Veuillez fournir une ou plusieurs réponses correctes !");
      return 0;
    }
    // si nombre  verifier que au moins le min n'est pas null
    if (questionConfig.questionType === 2 && !formValue.minNumberResponse) {
      setIsAlertVisible(true);
      setAlertTextContent("Veuillez fournir une réponse correcte minimale !");
      return 0;
    }
    // si nombre  et si min et max, verifier que min < max
    if (
      questionConfig.questionType === 2 &&
      formValue.minNumberResponse &&
      formValue.maxNumberResponse &&
      formValue.minNumberResponse > formValue.maxNumberResponse
    ) {
      setIsAlertVisible(true);
      setAlertTextContent("La réponse correcte minimale doit être inférieure à la maximale !");
      return 0;
    }

    // pour les QCU et QCM on a min proposition = 2 et max proposition = 4
    if ((questionConfig.questionType === 0 || questionConfig.questionType === 1) && (numberOfAnswer < 2 || numberOfAnswer > 4)) {
      setIsAlertVisible(true);
      setAlertTextContent("Pour les QCU et QCM veuillez fournir entre 2 et 4 propositions de réponse !");
      return 0;
    }

    // si QCU alors une et une seule proposition de réponse correcte + au moins 2 propositions + max propositions 4
    if (questionConfig.questionType === 0 && numberOfCorrectAnswer !== 1) {
      setIsAlertVisible(true);
      setAlertTextContent("Veuillez fournir une et une seule réponse correcte pour le QCU !");
      return 0;
    }

    // si QCM alors au moins une proposition de réponse correcte + au moins 2 propositions + max propositions 4
    if (questionConfig.questionType === 1 && numberOfCorrectAnswer < 0) {
      setIsAlertVisible(true);
      setAlertTextContent("Veuillez fournir au moins une réponse correcte pour le QCM !");
      return 0;
    }

    if (timeLimit < 5) {
      setIsAlertVisible(true);
      setAlertTextContent("La limite de temps doit être supérieure à 5 secondes");
      return 0;
    }
    if (questionConfig.text.length < 3) {
      setIsAlertVisible(true);
      setAlertTextContent("La question doit comporter au moins 3 caractères");
      return 0;
    }

    // si tout est correctement remplie

    return 1;
  };

  const questionTypeListOptions = () => {
    let list = [
      { value: 0, label: "QCU" },
      { value: 1, label: "QCM" },
      { value: 2, label: "NOMBRE" },
      { value: 3, label: "CHAINE DE CARACTERES" },
    ];

    return list;
  };

  // recuperer la/les reponse(s) par defaut pour les chaines de caractere
  let defaultStringResponsesValues = () => {
    let values = [];
    let existingResponses = formValue.answerConfigs?.length >= 1 ? formValue.answerConfigs : null;

    if ((formValue.questionType === 2 || formValue.questionType === 3) && existingResponses) {
      existingResponses.map((element) => values.push({ value: element.text, label: element.text }));
    }
    return values;
  };

  // recuperer la/les reponse(s) par defaut pour les chaines de caractere
  let defaultNumberResponsesValues = (givenResponse, minOrMax) => {
    let existingResponses = givenResponse?.length >= 1 ? givenResponse : null;

    if (existingResponses && existingResponses[0]) {
      let minAndMax = null;
      let response = existingResponses[0]?.text;
      minAndMax = response?.split("|");

      if (minAndMax && minOrMax === "min") {
        return [{ value: minAndMax[0], label: minAndMax[0] }];
      }
      if (minAndMax && minOrMax === "max") {
        return [{ value: minAndMax[1], label: minAndMax[1] }];
      }
    }
    return [];
  };

  const isNewReponseFormatCorrect = (newResponse) => {
    if (formValue.questionType === 2) {
      const intValue = parseInt(newResponse, 10);
      if (!isNaN(intValue) && Number.isInteger(intValue)) {
        return true;
      }

      return false;
    } else return newResponse?.length > 0;
  };

  const handleRemoveMedia = () => {
    setformValue({
      ...formValue,
      mediaUrl: null,
    });
    setPreview(null);
    setMediaInputKey((prevKey) => prevKey + 1);
  };

  let isQuestionMediaAnImage = useCallback((fileName) => {
    if (fileName) {
      return getFileTypeFromFileName(fileName) === "Image";
    }
    return false, [questionConfig];
  });

  return (
    <div>
      <form onSubmit={handleQuestionSubmit}>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <div>
              <Badge color="primary" pill>
                1
              </Badge>
            </div>
            <div>
              <h6> &nbsp; Informations générales</h6>
            </div>
          </div>
          <Button color="danger" onClick={() => handleDeleteQuizQuestion(index)} disabled={disableButton}>
            <i className="bx bx-trash"></i>
          </Button>
        </div>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="position">
                Position <sup>*</sup>
              </Label>
              <Input
                type="number"
                min={1}
                name="position"
                id="position"
                onChange={handleChange}
                required
                placeholder="ordre de la question"
                autoComplete="off"
                value={formValue.position}
                readOnly={disableButton}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="timeLimit">
                Limite de temps <sup>*</sup>
              </Label>
              <Input
                type="number"
                min={5}
                name="timeLimit"
                id="timeLimit"
                onChange={handleChange}
                required
                placeholder="en seconde"
                autoComplete="off"
                value={formValue.timeLimit}
                readOnly={disableButton}
              />
            </FormGroup>
          </Col>

          {(preview || formValue.mediaUrl) && (
            <Col>
              {preview && (
                <div>
                  <img src={preview} alt="Aperçu du fichier" className="quiz-img-preview" />
                </div>
              )}
              {!preview && formValue.mediaUrl && isQuestionMediaAnImage({ name: formValue.fileName }) && (
                <div>
                  <img
                    src={formValue.mediaUrl}
                    className="quiz-img-preview"
                    alt="aperçu indisponible"
                    onLoad={() => {
                      setImageLoaded(true);
                    }}
                    style={{ display: imageLoaded ? "block" : "none" }}
                  />
                  {formValue.mediaUrl && !imageLoaded && <span>chargement du media ...</span>}
                </div>
              )}
              {formValue.mediaUrl && !isQuestionMediaAnImage({ name: formValue.fileName }) && (
                <div className="d-flex align-items-center justify-contents-center">
                  <span>
                    <a href={formValue.mediaUrl}>
                      <i className="bx bx-file-blank fs-4"> </i>&nbsp;
                      <span>voir le fichier {getFileTypeFromFileName({ name: formValue.fileName })} existant</span>
                    </a>
                  </span>
                </div>
              )}
            </Col>
          )}
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="text">
                Question <sup>*</sup>
              </Label>
              <Input
                type="text"
                name="text"
                id="text"
                onChange={handleChange}
                required
                placeholder="Question du quiz"
                autoComplete="off"
                value={formValue.text}
                readOnly={disableButton}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="mediaUrl">Media de la question &nbsp;</Label>
              {formValue.mediaUrl ? (
                <span data-toggle="tooltip" data-placement="top">
                  <CloseButton
                    title="Supprimer le média existant"
                    onClick={() => {
                      handleRemoveMedia();
                    }}
                  />
                </span>
              ) : (
                ""
              )}
              <Input
                type="file"
                name="mediaUrl"
                id="mediaUrl"
                onChange={handleFileInputChange}
                autoComplete="off"
                readOnly={disableButton}
                key={mediaInputKey}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="questionType">
                Type de la question <sup>*</sup>
              </Label>
              <Select
                name="questionType"
                required
                isClearable
                isSearchable
                options={questionTypeListOptions()}
                onChange={handleReactSelectChange}
                placeholder="choisir le type de la question"
                noOptionsMessage={() => "Pas d'options"}
                defaultValue={questionTypeListOptions().filter((opt) => opt.value === formValue.questionType)}
                readOnly={disableButton}
              />
            </FormGroup>
          </Col>
          {
            // affichage conditionnel si la question est de type  "chaine de caractères"
            formValue.questionType == 3 && (
              <Col md={6}>
                <FormGroup>
                  <Label for="questionType">
                    Réponse(s) <sup>*</sup>
                  </Label>
                  <CreatableSelect
                    name="reponses"
                    isClearable
                    isSearchable
                    isMulti
                    defaultValue={defaultStringResponsesValues()}
                    isValidNewOption={isNewReponseFormatCorrect}
                    options={[]}
                    onChange={handleReactMultiSelectChange}
                    noOptionsMessage={() => "Pas d'options"}
                    formatCreateLabel={(inputValue) => `Ajouter "${inputValue}"`}
                    placeholder="Ajouter vos réponse(s) ..."
                  />
                </FormGroup>
              </Col>
            )
          }
          {
            // affichage conditionnel si la question est de type "entier"
            formValue.questionType == 2 && (
              <Col md={6}>
                <Label for="questionType" className="px-2">
                  Réponse(s)
                </Label>
                <Row>
                  <Col md={6}>
                    <FormGroup className="d-flex justify-content-start">
                      <Label for="questionType" className="px-2">
                        Min <sup>*</sup>
                      </Label>
                      <CreatableSelect
                        name="reponses-min"
                        isClearable
                        required
                        defaultValue={defaultNumberResponsesValues(formValue.answerConfigs, "min")}
                        isValidNewOption={isNewReponseFormatCorrect}
                        options={[]}
                        onChange={handleReactSelectResponseNumberChange}
                        noOptionsMessage={() => "Pas d'options"}
                        formatCreateLabel={(inputValue) => `Ajouter "${inputValue}"`}
                        placeholder="Min"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="d-flex justify-content-start">
                      <Label for="questionType" className="px-2">
                        Max
                      </Label>
                      <CreatableSelect
                        name="reponses-max"
                        isClearable
                        defaultValue={defaultNumberResponsesValues(formValue.answerConfigs, "max")}
                        isValidNewOption={isNewReponseFormatCorrect}
                        options={[]}
                        onChange={handleReactSelectResponseNumberChange}
                        noOptionsMessage={() => "Pas d'options"}
                        formatCreateLabel={(inputValue) => `Ajouter "${inputValue}"`}
                        placeholder="Max"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            )
          }
        </Row>
        <hr />
        {(formValue.questionType === 0 || formValue.questionType === 1) && (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-between">
                <div>
                  <Badge color="primary" pill>
                    2
                  </Badge>
                </div>
                <div>
                  <h6> &nbsp; Propositions de réponse</h6>
                </div>
              </div>
              <button className="add-questConf-btn " type="button" onClick={() => handleAddQuestion()} disabled={disableButton}>
                <i className="bx bxs-plus-circle fs-1"></i>
              </button>
            </div>
            <Row>
              {questions?.map((proposal, questionIndex) => (
                <div key={questionIndex}>
                  <QuizResponseProposalComponent
                    proposal={proposal}
                    proposalNumber={questionIndex}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleAnswerChange={handleAnswerChange}
                    handleIsCorrectChange={handleIsCorrectChange}
                    questionType={formValue.questionType}
                    disableButton={disableButton}
                  ></QuizResponseProposalComponent>
                </div>
              ))}
            </Row>
          </>
        )}
        <Alert color="danger" isOpen={isAlertVisible} toggle={onDismiss}>
          {alertTextContent}
        </Alert>
        <Button color={isQuestionCorrect ? "success" : "primary"} type="submit" block className="mt-4" disabled={isQuestionCorrect || disableButton}>
          <span>
            {isQuestionCorrect ? "Entrées correctes" : "Vérification des entrées"} <i className="bx bx-check-shield fs-4"></i>
          </span>
        </Button>
      </form>
    </div>
  );
};

export default QuizQuestionConfigComponent;
