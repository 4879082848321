import { React, useState } from "react";
import UserQuizRanksDetailsContent from "./UserQuizRanksDetailsContent";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const UserQuizRankDetailModal = ({ data }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const visibleBtn = (
    <button onClick={toggle} className="button-with-no-style" data-bs-toggle="tooltip" data-bs-placement="top" title="voir détails">
      <i className="bx bx-show"></i>
    </button>
  );

  return (
    <div>
      {visibleBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false} size="lg">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Détails des réponses
        </ModalHeader>
        <ModalBody>
          <UserQuizRanksDetailsContent data={data} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserQuizRankDetailModal;
