import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import QuestConfigDataService from "../../services/QuestConfigDataService";
import toast from "react-hot-toast";

const notify = (notif, state) => {
  switch (state) {
    case 1:
      toast.success(notif, { position: "top-right" });
      break;
    case 0:
      toast.error(notif, { position: "top-right" });
      break;
    default:
      toast.error(" La récuperation des données a échoué !");
      break;
  }
};

export const getAllQuestConfigs = createAsyncThunk("questConfigs/getAllQuestConfigs", async () => {
  try {
    const response = await QuestConfigDataService.getAllQuestConfigs();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const addNewQuestConfigs = createAsyncThunk("questConfigs/addNewQuestConfigs", async (data) => {
  try {
    const response = await QuestConfigDataService.addNewQuestConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const updateQuestConfigs = createAsyncThunk("questConfigs/updateQuestConfigs", async (data) => {
  try {
    const response = await QuestConfigDataService.updateQuestConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const deleteQuestConfigs = createAsyncThunk("questConfigs/deleteQuestConfigs", async (data) => {
  try {
    const response = await QuestConfigDataService.deleteQuestConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const addQuestSeasonConfig = createAsyncThunk("questConfigs/addQuestSeasonConfig", async (data) => {
  try {
    const response = await QuestConfigDataService.addQuestSeasonconfig(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const updateQuestSeasonConfig = createAsyncThunk("questConfigs/updateQuestSeasonConfig", async (data) => {
  try {
    const response = await QuestConfigDataService.updateQuestSeasonconfig(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const deleteQuestSeasonConfig = createAsyncThunk("questConfigs/deleteQuestSeasonConfig", async (data) => {
  try {
    const response = await QuestConfigDataService.deleteQuestSeasonconfig(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

const QuestConfigSlice = createSlice({
  name: "questConfigs",
  initialState: {
    questConfigs: null,
    dataPost: "",
    dataPut: "",
    refreshQuestConfigPage: false,
    loading_getQuestConfigs: true,
    loading_addQuestConfigs: true,
  },
  reducers: {},
  extraReducers: {
    [getAllQuestConfigs.pending]: (state) => {
      state.loading_getQuestConfigs = true;
      state.refreshQuestConfigPage = false;
    },
    [getAllQuestConfigs.fulfilled]: (state, { payload }) => {
      state.loading_getQuestConfigs = false;
      state.questConfigs = payload;
    },

    [addNewQuestConfigs.pending]: (state) => {
      state.loading_addQuestConfigs = true;
      state.refreshQuestConfigPage = false;
    },
    [addNewQuestConfigs.fulfilled]: (state, { payload }) => {
      state.loading_addQuestConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify(" Données enregistrées avec succes !", 1);
        state.refreshQuestConfigPage = true;
      }
    },

    [updateQuestConfigs.pending]: (state) => {
      state.refreshQuestConfigPage = false;
    },
    [updateQuestConfigs.fulfilled]: (state, { payload }) => {
      if (payload.status === 200 && payload.statusText === "OK") {
        state.refreshQuestConfigPage = true;
        notify(" Mise à jour avec succes !", 1);
      }
    },

    [deleteQuestConfigs.fulfilled]: (state, { payload }) => {
      state.loading_addQuestConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200) notify(" Suppression avec succes !", 1);
    },

    [addQuestSeasonConfig.pending]: (state, { payload }) => {
      state.refreshQuestConfigPage = false;
      state.dataPost = payload;
    },

    [addQuestSeasonConfig.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200) {
        notify("Quête d'expédition créée avec Succes", 1);
        state.refreshQuestConfigPage = true;
      }
    },

    [updateQuestSeasonConfig.pending]: (state) => {
      state.refreshQuestConfigPage = false;
    },

    [updateQuestSeasonConfig.fulfilled]: (state, { payload }) => {
      state.dataPut = payload;
      if (state.dataPut.status === 200) {
        notify("Quête d'expédition Modifiée avec Succes", 1);
        state.refreshQuestConfigPage = true;
      } else state.maj = false;
    },
    [deleteQuestSeasonConfig.fulfilled]: (state, { payload }) => {
      if (payload.status === 200) {
        notify("Quête d'expédition supprimée avec Succes", 1);
        state.refreshQuestConfigPage = true;
      } else state.maj = false;
    },
  },
});

export default QuestConfigSlice.reducer;
