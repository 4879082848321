import { React } from "react";
import Enums from "../../assets/JsonData/Enums";
import useGetLogById from "../../hooks/useGetlogById";
import Loader from "../loader/Loader";
import DiscussionThread from "../discussionPanel/DiscussionThread";
const QuestSerialValidationDiscussion = ({ logId, userId, refresh }) => {
  const { data, loaded } = useGetLogById(logId, refresh);

  return (
    <div>
      <div className="pt-2">
        <h6>
          <i className="bx bx-history thread-header-icon"></i> Historique
        </h6>
      </div>
      {!loaded ? (
        <Loader />
      ) : data ? (
        <div>
          <DiscussionThread log={data} userId={userId} stateTransition={Enums.matchQuestStateWithBadge} />
        </div>
      ) : (
        <div>
          <span>
            <i>La récupération des données a échoué !</i>
          </span>
        </div>
      )}
    </div>
  );
};

export default QuestSerialValidationDiscussion;
