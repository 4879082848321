import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import DesactivateUserGuideForm from "./DesactivateUserGuideForm";
import "bootstrap/dist/css/bootstrap.min.css";

const DesactivateUserGuideModal = ({ desactivateUserGuide, currentGuide }) => {
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const subsituteUserGuideListe = () => {
    let list = [];
    if (usersInfos === undefined || usersInfos.data === undefined) {
      return list;
    } else {
      usersInfos.data.map((user) => {
        if ((user.role === 2 && user.userId !== currentGuide && user.isGhost) || user.role === 3)
          list.push({ userId: user.userId, completeName: user.firstName + " " + user.lastName });
      });
      return list;
    }
  };

  /* eslint react/no-unescaped-entities: "off" */
  return (
    <div>
      <button className="w-90 btn btn-lg btn-warning my-5" type="submit" onClick={toggle}>
        Désactiver
      </button>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Desactivation d'un guide
        </ModalHeader>
        <ModalBody>
          <DesactivateUserGuideForm
            subsituteGuideList={subsituteUserGuideListe()}
            modalVisibility={setModal}
            desactivateUserGuide={desactivateUserGuide}
          ></DesactivateUserGuideForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DesactivateUserGuideModal;
