import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import UsersPage from "../pages/UsersPage";
import NotificationPage from "../pages/NotificationPage";
import QuestManagement from "../pages/QuestManagement";
import RankingPage from "../pages/RankingPage";
import SeasonManagement from "../pages/SeasonManagement";
import Settings from "../pages/Settings";
import ShopManagement from "../pages/ShopManagement";
import ShopValidation from "../pages/ShopValidation";
import QuestToValidate from "../pages/QuestToValidate";
import LeagueAndLevelPage from "../pages/LeagueAndLevelPage";
import BlocPage from "../pages/BlocPage";
import QuestConfigPage from "../pages/QuestConfigPage";
import UserDetailledInfosPage from "../pages/UserDetailledInfosPage";
import MissionSheetPage from "../pages/MissionSheetPage";
import MissionSheetDetailPage from "../pages/MissionSheetDetailPage";
import DigDaysPage from "../pages/DigDaysPage";
import DigDayDetailPage from "../pages/DigDayDetailPage";
import QuizPage from "../pages/QuizPage";
import QuizDetailledPage from "../pages/QuizDetailledPage";
import RewardTemplatePage from "../pages/RewardTemplatePage";
import NewQuestConfigPage from "../pages/NewQuestConfigPage";
import PepPage from "../pages/PepPage";
import MaintenancePage from "../pages/MaintenancePage";
import RestrictedAccess from "./restrictedAccess/RestrictedAccess";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Dashboard />} />

      <Route
        path="/UsersPage"
        element={
          <RestrictedAccess>
            <UsersPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/NotificationPage"
        element={
          <RestrictedAccess>
            <NotificationPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/QuestManagement"
        element={
          <RestrictedAccess>
            <QuestManagement />
          </RestrictedAccess>
        }
      />
      <Route
        path="/RankingPage"
        element={
          <RestrictedAccess>
            <RankingPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/SeasonManagement"
        element={
          <RestrictedAccess>
            <SeasonManagement />
          </RestrictedAccess>
        }
      />
      <Route
        path="/LeagueAndLevelPage"
        element={
          <RestrictedAccess>
            <LeagueAndLevelPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/Settings"
        element={
          <RestrictedAccess>
            <Settings />
          </RestrictedAccess>
        }
      />
      <Route
        path="/ShopManagement"
        element={
          <RestrictedAccess>
            <ShopManagement />
          </RestrictedAccess>
        }
      />
      <Route path="/ShopValidation" element={<ShopValidation />} />
      <Route path="/ShopValidation/:userId" element={<ShopValidation />} />
      <Route path="/QuestToValidate" element={<QuestToValidate />} />
      <Route
        path="/BlocPage"
        element={
          <RestrictedAccess>
            <BlocPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/questConfigPage/:questConfigId"
        element={
          <RestrictedAccess>
            <QuestConfigPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/UserDetailledInfosPage/:userId"
        element={
          <RestrictedAccess>
            <UserDetailledInfosPage />
          </RestrictedAccess>
        }
      />
      <Route path="/MissionSheetPage" element={<MissionSheetPage />} />
      <Route path="/MissionSheetDetailPage/:missionId" element={<MissionSheetDetailPage />} />
      <Route path="/DigdaysPage" element={<DigDaysPage />} />
      <Route path="/DigDayDetailPage/:digDayId" element={<DigDayDetailPage />} />
      <Route path="/PepPage" element={<PepPage />} />
      <Route
        path="/QuizPage"
        element={
          <RestrictedAccess>
            <QuizPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/QuizDetailledPage/:quizId"
        element={
          <RestrictedAccess>
            <QuizDetailledPage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/RewardTemplatePage"
        element={
          <RestrictedAccess>
            <RewardTemplatePage />
          </RestrictedAccess>
        }
      />
      <Route
        path="/nouveau-questConfig"
        element={
          <RestrictedAccess>
            <NewQuestConfigPage />
          </RestrictedAccess>
        }
      />
      <Route path="/maintenance" element={<MaintenancePage />} />
    </Routes>
  );
};

export default AppRoutes;
