import React from "react";
import { Table } from "reactstrap";
import ReactStars from "react-stars";
import { convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";
import Enums from "../../assets/JsonData/Enums";
import DigDayDurationAndLocation from "./DigDayDurationAndLocation";

const DigDayDetailTable = ({ digDay }) => {
  return (
    <div>
      {digDay && (
        <Table size="sm">
          <thead></thead>
          <tbody>
            <tr>
              <td className="text-muted">Date DigDay</td>
              <td>{convertDateToLocalTime(digDay.date)}</td>
            </tr>
            <tr>
              <td className="text-muted">Statut</td>
              <td>{Enums.matchDigDayState(digDay.state)}</td>
            </tr>
            <tr>
              <td className="text-muted"> Collaborateur</td>
              <td>{digDay.fullName}</td>
            </tr>
            <tr>
              <td className="text-muted">Titre</td>
              <td>{digDay.title}</td>
            </tr>
            <tr>
              <td className="text-muted"> Description</td>
              <td>
                <div className="text-break">{digDay.description}</div>
              </td>
            </tr>
            <tr>
              <td className="text-muted"> Evaluation</td>
              <td>{digDay.rating ? <ReactStars count={5} size={24} value={digDay.rating} edit={false} /> : ""}</td>
            </tr>
            <tr>
              <td className="text-muted">Durée / Lieu</td>
              <td>
                <DigDayDurationAndLocation digDay={digDay} />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default DigDayDetailTable;
