import { useState, useEffect } from "react";
import http from "../services/http-common";

const useGetRessourceByActionId = (actionConfigId, refresh) => {
  const [data, setData] = useState(null);
  const [actionConfigRessources, setActionConfigRessources] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true; // Variable pour vérifier si le composant est monté

    const fetchData = async () => {
      try {
        setLoaded(false);
        const response = await http.get(`/pepConfig/actionConfig/${actionConfigId}`);

        if (isMounted) {
          setData(response.data);
          setActionConfigRessources(response.data.resources);
          setError(null);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          setData(null);
        }
      } finally {
        if (isMounted) {
          setLoaded(true);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [actionConfigId, refresh]);

  return { data, error, loaded, actionConfigRessources };
};

export default useGetRessourceByActionId;
