import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import { FormGroup, Label, Col, Button, Input, FormText } from "reactstrap";

const NewDomainOrSkillsContentForm = ({ field, handleCloseModal, handleAddNewDomain, handleAddNewSkill, axeParent, domainParent }) => {
  const [isloading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      pepAxe: axeParent,
      pepDomain: domainParent,
      pepSkill: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      if (field === "domain") {
        handleAddNewDomain(values);
      } else {
        handleAddNewSkill(values);
      }
    },
  });

  const isFormCorrect = useMemo(() => {
    if (field === "domain") {
      return formik.values.pepDomain?.length > 1 && formik.values.pepDomain?.length <= 30 && axeParent && axeParent?.title?.length > 1;
    } else {
      return (
        formik.values.pepSkill?.length > 1 &&
        formik.values.pepSkill?.length <= 30 &&
        axeParent &&
        axeParent?.title?.length > 1 &&
        domainParent &&
        domainParent?.title?.length > 1
      );
    }
  }, [formik.values]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup row>
          <Label htmlFor="pepAxe" sm={3}>
            Axe
          </Label>
          <Col sm={9}>
            <Input id="pepAxe" name="pepAxe" value={axeParent?.title} readOnly disabled placeholder=" -- axe" />
          </Col>
        </FormGroup>
        {field === "domain" ? (
          <FormGroup row>
            <Label htmlFor="pepDomain" sm={3}>
              Domaine
            </Label>
            <Col sm={9}>
              <Input
                id="pepDomain"
                name="pepDomain"
                value={formik.values.pepDomain}
                onChange={formik.handleChange}
                placeholder=" -- Ajouter une domaine"
                maxLength="30"
              />
              <FormText>30 caractères max.</FormText>
            </Col>
          </FormGroup>
        ) : (
          <>
            <FormGroup row>
              <Label htmlFor="pepDomain" sm={3}>
                Domaine
              </Label>
              <Col sm={9}>
                <Input id="pepAxe" name="pepAxe" value={domainParent?.title} readOnly disabled placeholder=" -- domaine" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="pepSkill" sm={3}>
                Compétence
              </Label>
              <Col sm={9}>
                <Input
                  id="pepSkill"
                  name="pepSkill"
                  value={formik.values.pepSkill}
                  onChange={formik.handleChange}
                  placeholder=" -- Ajouter une compétence"
                  maxLength="30"
                />
                <FormText>30 caractères max.</FormText>
              </Col>
            </FormGroup>
          </>
        )}

        <div className="d-flex justify-content-center">
          <Button
            className="m-2"
            size="md"
            color="danger"
            role="button"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Annuler
          </Button>
          &nbsp;
          <Button className="m-2" size="md" color="primary" disabled={!isFormCorrect || isloading} type="submit">
            {isloading && <i className="bx bx-loader-alt bx-spin"></i>} Ajouter
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewDomainOrSkillsContentForm;
