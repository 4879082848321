import React from "react";
import { formatDateUtcToLocalTime } from "../../assets/utils/UtilsFunctons";
import "./discussionFileRender.css";

const DiscussionFileRender = ({ message }) => {
  const getFileTypeFromFileName = () => {
    const fileExtension = message.fileName?.split(".").pop().toLowerCase();
    const fileTypeMappings = {
      pdf: "PDF",
      jpg: "Image",
      jpeg: "Image",
      png: "Image",
      gif: "Image",
      doc: "Word",
      docx: "Word",
      mp4: "Video",
      avi: "Video",
      mov: "Video",
      xlsx: "Excel",
      csv: "CSV",
    };
    const fileType = fileTypeMappings[fileExtension ?? ""];
    return fileType || "Inconnu";
  };

  const messageFileType = getFileTypeFromFileName();

  const getFileIconFromFileType = () => {
    switch (messageFileType) {
      case "PDF":
        return "bx bxs-file-pdf";
      case "Word":
        return "bx bxs-file-doc";
      case "Video":
        return "bx bxs-videos";
      case "Excel":
        return "bx bxs-file";
      case "CSV":
        return "bx bxs-file";
      default:
        return "bx bxs-file-blank";
    }
  };

  const renderOtherFileFormatExeptImages = () => {
    return (
      <div>
        <div className="message-file-type-container-exept-image  fs-6">
          <div className="d-flex">
            <div>
              <i className={`${getFileIconFromFileType()} mx-2 fs-3`}></i>
            </div>
            <div>{message.fileName}</div>
            <div>
              <a href={message.fileUrl} target="_blank" rel="noreferrer">
                <i className="bx bxs-download fs-3 mx-2 download-discussion-file"></i>
              </a>
            </div>
          </div>
        </div>
        <span className="small">{formatDateUtcToLocalTime(message.date)}</span>
      </div>
    );
  };
  return (
    <div className="message-file-type-container">
      {messageFileType === "Image" && (
        <a href={message.fileUrl} target="_blank" rel="noreferrer">
          <div className="image-message-container mb-2">
            <img src={message.fileUrl} alt="image message" className="image-message-display" />

            <span className="msg_time_send">{formatDateUtcToLocalTime(message.date)}</span>
          </div>
        </a>
      )}

      {messageFileType === "Video" && renderOtherFileFormatExeptImages()}
      {messageFileType === "PDF" && renderOtherFileFormatExeptImages()}
      {messageFileType === "Word" && renderOtherFileFormatExeptImages()}
      {messageFileType === "Excel" && renderOtherFileFormatExeptImages()}
      {messageFileType === "CSV" && renderOtherFileFormatExeptImages()}
      {messageFileType === "Inconnu" && renderOtherFileFormatExeptImages()}
    </div>
  );
};

export default DiscussionFileRender;
