import React from "react";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import { FormGroup, Label, Input } from "reactstrap";

const ActivateQuizToggleButton = ({ isToggleChecked, handleActivateQuizConfig, quiz }) => {
  const { isConfirmed } = useConfirm();
  let doesQuizHaveAtLeastOneValidQuestion = () => {
    if (quiz && quiz?.questionConfigs) {
      return quiz.questionConfigs.length >= 1;
    }

    return false;
  };
  const handleClick = async () => {
    let confirmed = await isConfirmed(
      isToggleChecked ? " Souhaitez vous passer le quiz en  mode brouillon" : "Souhaitez vous passer le quiz en  mode prêt/ à planifier "
    );
    if (confirmed) {
      handleActivateQuizConfig();
    }
  };

  let quizHaveAtLeastOneValidQuestion = doesQuizHaveAtLeastOneValidQuestion();
  return (
    <>
      <ConfirmModal></ConfirmModal>
      <FormGroup
        switch
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={quizHaveAtLeastOneValidQuestion ? "Changer le statut du quiz à prêt" : "Il faut au minimum ajouter une question au quiz"}
      >
        <Label check className="fs-6">
          Quiz prêt ?
        </Label>
        <Input type="switch" checked={isToggleChecked} onClick={handleClick} className="fs-4" disabled={!quizHaveAtLeastOneValidQuestion} />
      </FormGroup>
    </>
  );
};

export default ActivateQuizToggleButton;
