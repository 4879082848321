import React, { useCallback, useMemo, useState } from "react";
import EditableText from "../../../core/component/editable-text/EditableText";
import useModalWithContent from "../../useModalWithContent/UseModalWithContent";
import PepDataService from "../../../services/PepDataService";
import "./pep-config.css";
import NewDomainOrSkillsContentForm from "../newDomainOrSkillModal/NewDomainOrSkillsContentForm";
import { notify } from "../../../assets/utils/UtilsFunctons";
import AddNewActionConfigForm from "../actionConfig/AddNewActionConfigForm";
import ViewEditActionConfigForm from "../actionConfig/ViewEditActionConfigForm";
import AddNewAxisForm from "../axis/AddNewAxisForm";
import useConfirm from "../../FormConFirmDialog/UseFormConfirmDialog";
import { Alert } from "reactstrap";

const PepConfigComponent = ({ pepConfigFullTree }) => {
  const [PepConfigFullTree, setPepConfigFullTree] = useState(pepConfigFullTree);
  const { isConfirmed } = useConfirm();

  const PEP_Section = {
    axe: "Axe",
    domain: "Domaines",
    skill: "Compétences",
    action: "Actions",
  };

  const MODALACTIONS = {
    consultAction: "consultAction",
    addNewAxis: "addNewAxis",
    addNewDomain: "addNewDomain",
    addNewSkill: "addNewSkill",
    addNewAction: "addNewAction",
    viewEditAction: "viewEditAction",
    deleteAxis: "deleteAxis",
    deleteDomain: "deleteDomain",
    deleteSkill: "deleteSkill",
    deleteAction: "deleteAction",
  };

  const [PEPAxe, setPEPAxe] = useState();
  const [PEPDomain, setPEPDomain] = useState();
  const [PEPSkill, setPEPSkill] = useState();
  const [PEPAction, setPEPAction] = useState();

  const [modalActionType, setModalActiontype] = useState(null);

  const handleAddNewPepAxis = useCallback(async (values) => {
    if (values && values.axisId && values.axisTitle) {
      const payload = {
        axisId: values.axisId,
        title: values.axisTitle,
      };

      try {
        {
          const response = await PepDataService.addNewPepAxis(payload);
          notify(1, "Axe ajouté avec succés !");
          setPepConfigFullTree((prevData) => [...prevData, { ...response?.data, domains: [] }]);
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        closeModal();
      }
    }
  }, []);

  const handleEditPepAxis = useCallback(async (newTitle, pepAxis) => {
    if (newTitle && pepAxis && pepAxis.axisId) {
      const payload = {
        axisId: pepAxis.axisId,
        title: newTitle,
      };

      try {
        {
          const response = await PepDataService.EditPepAxis(payload);
          notify(1, "Axe modifié avec succés !");
          setPepConfigFullTree((prevData) => prevData.map((axis) => (axis.axisId === payload.axisId ? { ...axis, title: response?.data?.title } : axis)));
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      }
    }
  }, []);

  const handleDeletePepAxis = useCallback(async (axisToDelete) => {
    const confirmed = await isConfirmed(
      <div>
        Souhaitez-vous supprimer l&apos;axe <br /> <span className="fw-bold">{axisToDelete.title}</span> ?
      </div>
    );
    if (confirmed && axisToDelete && axisToDelete.axisId) {
      try {
        await PepDataService.DeletePepAxis(axisToDelete.axisId);
        notify(1, "Axe supprimé avec succés !");
        setPepConfigFullTree((prevData) => prevData.filter((axis) => axis.axisId !== axisToDelete.axisId));
      } catch (error) {
        notify(0, error?.response?.data?.Message ?? "une erreur est survenue");
      }
    }
  });

  const handleAddNewPepDomain = useCallback(async (values) => {
    if (values && values.pepAxe && values.pepAxe.axisId && values.pepDomain) {
      const payload = {
        axisId: values.pepAxe.axisId,
        title: values.pepDomain,
      };

      try {
        {
          const response = await PepDataService.addNewPepDomain(payload);
          notify(1, "Domaine ajouté avec succés !");
          setPepConfigFullTree((prevData) =>
            prevData.map((axis) => (axis.axisId === payload?.axisId ? { ...axis, domains: [...axis.domains, { ...response?.data, skills: [] }] } : axis))
          );
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        closeModal();
      }
    }
  }, []);

  const handleEditPepDomain = useCallback(async (newTitle, pepDomain) => {
    if (newTitle && pepDomain && pepDomain.axisId && pepDomain.domainId) {
      const payload = {
        axisId: pepDomain.axisId,
        domainId: pepDomain.domainId,
        title: newTitle,
      };

      try {
        {
          const response = await PepDataService.EditPepDomain(payload);
          notify(1, "Domaine modifié avec succés !");
          setPepConfigFullTree((prevData) =>
            prevData.map((axis) =>
              axis.axisId === payload.axisId
                ? {
                    ...axis,
                    domains: axis.domains.map((domain) => (domain.domainId === payload.domainId ? { ...domain, title: response?.data?.title } : domain)),
                  }
                : axis
            )
          );
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      }
    }
  }, []);

  const handleDeletePepDomain = useCallback(async (domainToDelete) => {
    const confirmed = await isConfirmed(
      <div>
        Souhaitez-vous supprimer le domaine <br /> <span className="fw-bold">{domainToDelete.title}</span> ?
      </div>
    );
    if (confirmed && domainToDelete && domainToDelete.domainId) {
      try {
        await PepDataService.deletePepDomain(domainToDelete.domainId);
        notify(1, "Domaine supprimé avec succés !");
        setPepConfigFullTree((prevData) =>
          prevData.map((axis) =>
            axis.axisId === domainToDelete.axisId ? { ...axis, domains: axis.domains.filter((domain) => domain.domainId !== domainToDelete.domainId) } : axis
          )
        );
      } catch (error) {
        notify(0, error?.response?.data?.Message ?? "une erreur est survenue");
      }
    }
  });

  const handleAddNewPepSkill = useCallback(async (values) => {
    if (values && values.pepAxe && values.pepAxe.axisId && values.pepDomain && values.pepDomain.domainId && values.pepSkill) {
      const payload = {
        pepAxisId: values.pepAxe.axisId,
        pepDomainId: values.pepDomain.domainId,
        title: values.pepSkill,
      };

      try {
        {
          const response = await PepDataService.addNewPepSkill(payload);
          notify(1, "Compétence ajoutée avec succés !");
          setPepConfigFullTree((prevData) =>
            prevData.map((axis) =>
              axis.axisId === payload?.pepAxisId
                ? {
                    ...axis,
                    domains: axis.domains.map((domain) =>
                      domain.domainId === payload?.pepDomainId ? { ...domain, skills: [...domain.skills, { ...response.data, actionConfigs: [] }] } : domain
                    ),
                  }
                : axis
            )
          );
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        closeModal();
      }
    }
  }, []);

  const handleEditPepSkill = useCallback(async (newTitle, pepSkill) => {
    if (newTitle && pepSkill && pepSkill.axisId && pepSkill.domainId && pepSkill.skillId) {
      const payload = {
        axisId: pepSkill.axisId,
        domainId: pepSkill.domainId,
        skillId: pepSkill.skillId,
        title: newTitle,
      };

      try {
        {
          const response = await PepDataService.EditPepSkill(payload);
          notify(1, "Compétence modifiée avec succés !");
          setPepConfigFullTree((prevData) =>
            prevData.map((axis) =>
              axis.axisId === payload.axisId
                ? {
                    ...axis,
                    domains: axis.domains.map((domain) =>
                      domain.domainId === payload.domainId
                        ? {
                            ...domain,
                            skills: domain.skills.map((skill) => (skill.skillId === payload.skillId ? { ...skill, title: response?.data?.title } : skill)),
                          }
                        : domain
                    ),
                  }
                : axis
            )
          );
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      }
    }
  }, []);

  const handleDeletePepSkill = useCallback(async (skillToDelete) => {
    const confirmed = await isConfirmed(
      <div>
        Souhaitez-vous supprimer la compétence <br /> <span className="fw-bold">{skillToDelete.title}</span> ?
      </div>
    );
    if (confirmed && skillToDelete && skillToDelete.skillId) {
      try {
        await PepDataService.deletePepSkill(skillToDelete.skillId);
        notify(1, "Compétence supprimée avec succés !");
        setPepConfigFullTree((prevData) =>
          prevData.map((axis) =>
            axis.axisId === skillToDelete.axisId
              ? {
                  ...axis,
                  domains: axis.domains.map((domain) =>
                    domain.domainId === skillToDelete.domainId
                      ? { ...domain, skills: domain.skills.filter((skill) => skill.skillId !== skillToDelete.skillId) }
                      : domain
                  ),
                }
              : axis
          )
        );
      } catch (error) {
        notify(0, error?.response?.data?.Message ?? "une erreur est survenue");
      }
    }
  }, []);

  const handleAddNewActionConfig = useCallback(async (values) => {
    if (values && values.pepSkill && values.pepSkill.skillId && values.title && values.level !== undefined) {
      const payload = {
        title: values.title,
        instruction: values.instruction,
        level: values.level,
        isEnable: values.isEnable,
        pepSkillId: values.pepSkill.skillId,
        resources: values.ressources,
      };

      try {
        {
          const response = await PepDataService.addNewPepActionConfig(payload);
          notify(1, "Action ajoutée avec succés !");
          setPepConfigFullTree((prevData) =>
            prevData.map((axis) =>
              axis.axisId === values.pepSkill.axisId
                ? {
                    ...axis,
                    domains: axis.domains.map((domain) =>
                      domain.domainId === values.pepSkill.domainId
                        ? {
                            ...domain,
                            skills: domain.skills.map((skill) =>
                              skill.skillId === values.pepSkill.skillId ? { ...skill, actionConfigs: [...skill.actionConfigs, { ...response?.data }] } : skill
                            ),
                          }
                        : domain
                    ),
                  }
                : axis
            )
          );
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        closeModal();
      }
    }
  }, []);

  const handleEditActionConfig = useCallback(async (values) => {
    if (values && values.payload && values.actionConfig && values.actionConfig.actionConfigId) {
      try {
        {
          const response = await PepDataService.editPepAction(values.actionConfig.actionConfigId, values.payload);
          notify(1, "Action modifiée avec succés !");
          setPepConfigFullTree((prevData) =>
            prevData.map((axis) =>
              axis.axisId === values.actionConfig.axisId
                ? {
                    ...axis,
                    domains: axis.domains.map((domain) =>
                      domain.domainId === values.actionConfig.domainId
                        ? {
                            ...domain,
                            skills: domain.skills.map((skill) =>
                              skill.skillId === values.actionConfig.skillId
                                ? {
                                    ...skill,
                                    actionConfigs: skill.actionConfigs.map((action) =>
                                      action.actionConfigId === values.actionConfig.actionConfigId ? { ...response?.data } : action
                                    ),
                                  }
                                : skill
                            ),
                          }
                        : domain
                    ),
                  }
                : axis
            )
          );
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        closeModal();
      }
    }
  }, []);

  const handleDeleteActionConfig = useCallback(async (actionToDelete) => {
    const confirmed = await isConfirmed(
      <div>
        Souhaitez-vous supprimer l&apos;action <br /> <span className="fw-bold">{actionToDelete.title}</span> ?
        <br className="mx-2" />
        <div className="pt-4">
          <Alert color="warning">toutes les ressources ratachées à l'action seront perdues !</Alert>
        </div>
      </div>
    );
    if (confirmed && actionToDelete && actionToDelete.actionConfigId) {
      try {
        await PepDataService.deletePepActionConfig(actionToDelete.actionConfigId);
        notify(1, "Action supprimée avec succés !");
        setPepConfigFullTree((prevData) =>
          prevData.map((axis) =>
            axis.axisId === actionToDelete.axisId
              ? {
                  ...axis,
                  domains: axis.domains.map((domain) =>
                    domain.domainId === actionToDelete.domainId
                      ? {
                          ...domain,
                          skills: domain.skills.map((skill) =>
                            skill.skillId === actionToDelete.skillId
                              ? { ...skill, actionConfigs: skill.actionConfigs.filter((action) => action.actionConfigId !== actionToDelete.actionConfigId) }
                              : skill
                          ),
                        }
                      : domain
                  ),
                }
              : axis
          )
        );
      } catch (error) {
        notify(0, error?.response?.data?.Message ?? "une erreur est survenue");
      }
    }
  }, []);

  const getPepAxisConfig = useMemo(() => {
    return PepConfigFullTree ? Array.from(PepConfigFullTree, (item) => ({ axisId: item.axisId, title: item.title, nbChildren: item?.domains?.length })) : [];
  }, [PepConfigFullTree]);

  const getPepDomainConfigByAxisId = useMemo(() => {
    const selectedAxis = PepConfigFullTree?.find((item) => item.axisId === PEPAxe?.axisId);
    return selectedAxis?.domains
      ? Array.from(selectedAxis?.domains, (item) => ({
          axisId: selectedAxis?.axisId,
          domainId: item.domainId,
          title: item.title,
          nbChildren: item?.skills?.length,
        }))
      : [];
  }, [PepConfigFullTree, PEPAxe]);

  const getPepSkillConfigByaxisDomainId = useMemo(() => {
    const selectedAxis = PepConfigFullTree?.find((item) => item.axisId === PEPAxe?.axisId);
    const selectedDomain = selectedAxis?.domains.find((item) => item.domainId === PEPDomain?.domainId);
    return selectedDomain?.skills
      ? Array.from(selectedDomain?.skills, (item) => ({
          axisId: selectedAxis?.axisId,
          domainId: selectedDomain?.domainId,
          skillId: item.skillId,
          title: item.title,
          nbChildren: item?.actionConfigs?.length,
        }))
      : [];
  }, [PepConfigFullTree, PEPAxe, PEPDomain]);

  const getPepActionConfigByAxisDomainSkillId = useMemo(() => {
    const selectedAxis = PepConfigFullTree?.find((item) => item.axisId === PEPAxe?.axisId);
    const selectedDomain = selectedAxis?.domains.find((item) => item.domainId === PEPDomain?.domainId);
    const selectedSkill = selectedDomain?.skills.find((item) => item.skillId === PEPSkill?.skillId);
    return selectedSkill?.actionConfigs
      ? Array.from(selectedSkill?.actionConfigs, (item) => ({
          axisId: selectedAxis?.axisId,
          domainId: selectedDomain?.domainId,
          skillId: selectedSkill?.skillId,
          actionConfigId: item.actionConfigId,
          title: item.title,
          instruction: item.instruction,
          isEnable: item.isEnable,
          level: item.level,
        }))
      : [];
  }, [PepConfigFullTree, PEPAxe, PEPDomain, PEPSkill]);

  const SettingActiveAxe = useCallback((value) => {
    setPEPAxe(value);
  }, []);

  const SettingActiveDomain = useCallback((value) => {
    setPEPDomain(value);
  }, []);

  const SettingActiveSkill = useCallback((value) => {
    setPEPSkill(value);
  }, []);

  const SettingActiveAction = useCallback(() => {
    "";
  }, []);

  const handleAddPepNewItem = useCallback((section) => {
    switch (section) {
      case PEP_Section.axe:
        setModalActiontype(MODALACTIONS.addNewAxis);
        break;
      case PEP_Section.domain:
        setModalActiontype(MODALACTIONS.addNewDomain);
        break;
      case PEP_Section.skill:
        setModalActiontype(MODALACTIONS.addNewSkill);
        break;
      case PEP_Section.action:
        setModalActiontype(MODALACTIONS.addNewAction);
        break;
    }
    openModal();
  }, []);

  const disableAddNewPepItemButton = useCallback(
    (section) => {
      switch (section) {
        case PEP_Section.axe:
          return false;
        case PEP_Section.domain:
          return !PEPAxe;
        case PEP_Section.skill:
          return !PEPAxe || !PEPDomain;
        case PEP_Section.action:
          return !PEPAxe || !PEPDomain || !PEPSkill;
      }
    },
    [PEPAxe, PEPDomain, PEPSkill]
  );

  const renderPepSectionHeader = useCallback(
    (section) => {
      const disableAddButton = disableAddNewPepItemButton(section);
      return (
        <div className="d-flex justify-content-start">
          <h6>{section}</h6>
          <div className="px-2" data-bs-toggle="tooltip" data-bs-placement="top" title={!disableAddButton ? "Ajouter" : "Veuillez sélectionner un parent"}>
            <button className="button-with-no-style " onClick={() => handleAddPepNewItem(section)} disabled={disableAddButton}>
              <i className={`bx bx-plus fs-5 ${!disableAddButton ? "text-success" : "text-muted"} `}></i>
            </button>
          </div>
        </div>
      );
    },
    [disableAddNewPepItemButton]
  );

  const renderPepAxe = useCallback(() => {
    return (
      <div className="pep-axes" style={{ paddingRight: "5px", borderRight: "1px solid GRAY" }}>
        {renderPepSectionHeader(PEP_Section.axe)}
        {getPepAxisConfig.map((axis, index) => {
          return (
            <div className={`d-flex flex-row ${axis.axisId === PEPAxe?.axisId ? "active-axe" : ""}`} key={index}>
              <EditableText
                handleChange={handleEditPepAxis}
                defaultValue={axis}
                id={axis.axisId}
                activateItem={SettingActiveAxe}
                index={index}
                key={axis.axisId}
              />
              {axis?.nbChildren === 0 && (
                <div>
                  <button className="button-with-no-style px-2 " onClick={() => handleDeletePepAxis(axis)} disabled={axis?.nbChildren > 0}>
                    <i className={`bx bx-trash fs-5 `}></i>
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }, [getPepAxisConfig, PEPAxe]);

  const renderPepDomain = useCallback(() => {
    return (
      <div className="pep-domains" style={{ paddingRight: "5px", borderRight: "1px solid GRAY" }}>
        {renderPepSectionHeader(PEP_Section.domain)}
        {getPepDomainConfigByAxisId.map((domain, index) => {
          return (
            <div className={`d-flex flex-row ${domain.domainId === PEPDomain?.domainId ? "active-domain" : ""}`} key={index}>
              <EditableText
                handleChange={handleEditPepDomain}
                defaultValue={domain}
                id={domain.domainId}
                activateItem={SettingActiveDomain}
                index={index}
                key={domain.domainId}
              />
              {domain.nbChildren === 0 && (
                <div>
                  <button className="button-with-no-style px-2 " onClick={() => handleDeletePepDomain(domain)} disabled={domain.nbChildren > 0}>
                    <i className={`bx bx-trash fs-5  `}></i>
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }, [getPepDomainConfigByAxisId, PEPAxe, PEPDomain, SettingActiveDomain]);

  const renderPepSkill = useCallback(() => {
    return (
      <div className="pep-skills" style={{ paddingRight: "5px", borderRight: "1px solid GRAY" }}>
        {renderPepSectionHeader(PEP_Section.skill)}

        {getPepSkillConfigByaxisDomainId.map((skill, index) => {
          return (
            <div className={`d-flex flex-row ${skill.skillId === PEPSkill?.skillId ? "active-skill" : ""}`} key={index}>
              <EditableText
                handleChange={handleEditPepSkill}
                defaultValue={skill}
                id={skill.skillId}
                activateItem={SettingActiveSkill}
                index={index}
                key={skill.skillId + "/" + skill.title}
              />
              {skill.nbChildren === 0 && (
                <div>
                  <button className="button-with-no-style px-2 " onClick={() => handleDeletePepSkill(skill)} disabled={skill.nbChildren > 0}>
                    <i className={`bx bx-trash fs-5  `}></i>
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }, [getPepSkillConfigByaxisDomainId, PEPAxe, PEPDomain, PEPSkill]);

  const renderPepAction = useCallback(() => {
    return (
      <div className="pep-skills">
        {renderPepSectionHeader(PEP_Section.action)}
        {getPepActionConfigByAxisDomainSkillId.map((action, index) => {
          return (
            <div key={index} className="d-flex flex-row">
              <div
                style={{ display: "flex", alignItems: "baseline" }}
                onClick={() => {
                  SettingActiveAction(index);
                  setPEPAction(action);
                  setModalActiontype(MODALACTIONS.viewEditAction);
                  openModal();
                }}
              >
                <p style={{ cursor: "pointer" }}>{action.title}</p>
              </div>
              <div>
                <button className="button-with-no-style px-2 " onClick={() => handleDeleteActionConfig(action)}>
                  <i className={`bx bx-trash fs-5  `}></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [getPepActionConfigByAxisDomainSkillId]);

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, []);

  const renderPepModalTitle = useCallback(() => {
    switch (modalActionType) {
      case MODALACTIONS.addNewAxis: {
        return "Ajouter un nouvel axe";
      }
      case MODALACTIONS.addNewDomain: {
        return "Ajouter un nouveau domaine";
      }
      case MODALACTIONS.addNewSkill: {
        return "Ajouter une nouvelle compétence";
      }
      case MODALACTIONS.addNewAction: {
        return "Ajouter une nouvelle action";
      }
      case MODALACTIONS.viewEditAction: {
        return "Voir ou modifier une action";
      }
      case MODALACTIONS.deleteAxis: {
        return "Supprimer un axe";
      }
      case MODALACTIONS.deleteDomain: {
        return "Supprimer un domaine";
      }
      case MODALACTIONS.deleteSkill: {
        return "Supprimer une compétence";
      }
      case MODALACTIONS.deleteAction: {
        return "Supprimer une action";
      }
      default:
        return "Pep";
    }
  }, [modalActionType]);

  const renderActionModalContent = useCallback(() => {
    switch (modalActionType) {
      case MODALACTIONS.addNewAxis: {
        return <AddNewAxisForm handleClose={handleCloseModal} handleAddAxis={handleAddNewPepAxis} />;
      }
      case MODALACTIONS.addNewDomain: {
        return (
          <NewDomainOrSkillsContentForm field={"domain"} handleCloseModal={handleCloseModal} handleAddNewDomain={handleAddNewPepDomain} axeParent={PEPAxe} />
        );
      }
      case MODALACTIONS.addNewSkill: {
        return (
          <NewDomainOrSkillsContentForm
            handleCloseModal={handleCloseModal}
            axeParent={PEPAxe}
            domainParent={PEPDomain}
            handleAddNewSkill={handleAddNewPepSkill}
          />
        );
      }
      case MODALACTIONS.addNewAction: {
        return (
          <AddNewActionConfigForm
            handleCloseModal={handleCloseModal}
            handleAddNewActionConfig={handleAddNewActionConfig}
            axeParent={PEPAxe}
            domainParent={PEPDomain}
            skillParent={PEPSkill}
          />
        );
      }
      case MODALACTIONS.viewEditAction: {
        return (
          <ViewEditActionConfigForm
            handleCloseModal={handleCloseModal}
            handleEditActionConfig={handleEditActionConfig}
            actionConfig={PEPAction}
            axeParent={PEPAxe}
            domainParent={PEPDomain}
            skillParent={PEPSkill}
          />
        );
      }
      default:
        return <div>{PEPAction}</div>;
    }
  }, [PEPAction, modalActionType, PEPAxe, PEPDomain, PEPSkill]);

  const { closeModal, ModalComponent, openModal } = useModalWithContent(renderActionModalContent(), renderPepModalTitle(), "md");

  return (
    <div>
      <span className="text-body-secondary small">1- Séléctionner un axe &gt; 2 - ensuite un domaine &gt; 3- puis une compétence &gt; 4- puis une action </span>
      <div className="pep-config row pt-2">
        <div className="col-3">{renderPepAxe()}</div>
        <div className="col-3">{renderPepDomain()}</div>
        <div className="col-3">{renderPepSkill()}</div>
        <div className="col-3">{renderPepAction()}</div>
        <ModalComponent />
      </div>
    </div>
  );
};

export default PepConfigComponent;
