import React from "react";
import { Alert } from "reactstrap";

const PageLoadingFailNotification = ({ text }) => {
  return (
    <div className="py-5 px-5 row text-center">
      <Alert color="danger">{text}</Alert>
    </div>
  );
};

export default PageLoadingFailNotification;
