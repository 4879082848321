import http from "./http-common";

class SeasonDataService {
  getSeasonInfos(data) {
    return http.get("/season", { params: { seasonId: data } });
  }

  getSeasonList() {
    return http.get("/season/list");
  }

  editSeason(data) {
    return http.put("/season", data);
  }

  launchNewSeason() {
    return http.put("/season/launch");
  }

  closeCurrentSeason() {
    return http.put("/season/close");
  }

  updateTeamName(data) {
    return http.put("/season/team/name?teamId=" + data.teamId + "&newName=" + data.newName);
  }

  refreshTeam() {
    return http.put("/season/team/refresh");
  }

  postTeamPicture(data) {
    const formData = new FormData();
    formData.append("File", data.pictureData);
    return http.post("/season/team/picture?teamId=" + data.teamId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new SeasonDataService();
