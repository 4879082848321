import { React, useState } from "react";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { FormGroup, Input, Label, Row, Col, CloseButton, Button, Alert } from "reactstrap";

const RewardTemplateForm = ({ handleAddRewardTemplate, closeModal }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertTextContent, setAlertTextContent] = useState("");
  const [postingRewardTemplate, setPostingRewardTemplate] = useState(false);
  const [rewardsList, setRewardsList] = useState([
    {
      position: -1,
      coins: "",
      score: "",
      gift: false,
    },
  ]);
  const [formValue, setformValue] = useState({
    rewardTemplateId: uuid(),
    name: null,
    archived: false,
    rewards: null,
  });

  const handleAddRewards = () => {
    let newRewardsList = [
      ...rewardsList,
      {
        position: rewardsList.length === 0 ? -1 : rewardsList.length,
        coins: "",
        score: "",
        gift: false,
      },
    ];
    setRewardsList(newRewardsList);
    setformValue({
      ...formValue,
      rewards: newRewardsList,
    });
  };

  const handleDeleteRewards = (rewardIndex) => {
    const newRewardsList = rewardsList.filter((_, index) => index !== rewardIndex);
    setRewardsList(newRewardsList);
    setformValue({
      ...formValue,
      rewards: newRewardsList,
    });
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSwitchButton = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRewardChange = (index, event) => {
    let newRewardsList = _.cloneDeep(rewardsList);
    newRewardsList[index][event.target.name] = event.target.value;
    setRewardsList(newRewardsList);
    setformValue({
      ...formValue,
      rewards: newRewardsList,
    });
  };

  const handleRewardChangeSwitch = (index, event) => {
    let newRewardsList = _.cloneDeep(rewardsList);
    newRewardsList[index][event.target.name] = event.target.checked;
    setRewardsList(newRewardsList);
    setformValue({
      ...formValue,
      rewards: newRewardsList,
    });
  };

  const onDismiss = () => setIsAlertVisible(!isAlertVisible);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isFormCorrectlyFilled()) {
      setPostingRewardTemplate(true);
      handleAddRewardTemplate(formValue);
    }
  };

  const handleReset = () => {
    closeModal();
  };

  const isFormCorrectlyFilled = () => {
    // nous avons une seule contrainte à vérifier :  l'odre concernant les
    //récompenses : on commence par autres (-1), puis 1, 2 , 3 ...
    // l'ordre est primordiale
    for (let i = 0; i < formValue.rewards.length; i++) {
      if (i === 0 && formValue.rewards[i].position !== -1) {
        setAlertTextContent("Les positions pour les récompenses doivent être croissantes !");
        setIsAlertVisible(true);
        return false; // Le tableau n'est pas ordonné de manière croissante
      }
      if (i !== 0 && formValue.rewards[i].position !== i) {
        setAlertTextContent("Les positions pour les récompenses doivent être croissantes !");
        setIsAlertVisible(true);
        return false; // Le tableau n'est pas ordonné de manière croissante
      }
    }

    if (formValue.rewards?.length === 0) {
      setAlertTextContent("Vous devez ajouter au moins une récompense !");
      setIsAlertVisible(true);
      return false; // Le tableau n'est pas ordonné de manière croissante
    }
    setAlertTextContent("");
    setIsAlertVisible(false);
    return true;
  };
  return (
    <div>
      <form onSubmit={handleSubmit} onReset={handleReset} className="add-new-template-form">
        <FormGroup>
          <Label for="name">Nom</Label>
          <Input type="text" name="name" id="name" onChange={handleChange} value={formValue.name} required />
        </FormGroup>
        <FormGroup>
          <Label check className="fs-6">
            Archiver : &nbsp;
          </Label>
          <Input type="switch" className="fs-4" name="archived" id="archived" onChange={handleSwitchButton} />
        </FormGroup>
        <hr />
        <div className="d-flex justify-content-between">
          <div>
            <h6>Récompenses</h6>
          </div>
          <div>
            <button className="add-questConf-btn " type="button" onClick={() => handleAddRewards()}>
              <i className="bx bxs-plus-circle fs-1"></i>
            </button>
          </div>
        </div>
        {rewardsList.map((item, index) => (
          <div key={index} className="list-group-item rounded-3 py-2">
            <fieldset className="border px-3 py-2 mx-1">
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="position">Position</Label>
                    <Input type="text" name="position" id="position" readOnly value={item.position === -1 ? "Autres" : item.position} required />
                  </FormGroup>
                </Col>
                <Col md={7}>
                  <div className="d-flex justify-content-center">
                    <FormGroup switch>
                      <br />
                      <Label check className="fs-6">
                        cadeau
                      </Label>
                      <Input type="switch" className="fs-4" name="gift" id="gift" onChange={(event) => handleRewardChangeSwitch(index, event)} />
                    </FormGroup>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="d-flex justify-content-end">
                    <CloseButton onClick={() => handleDeleteRewards(index)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="coins">Coins</Label>
                    <Input type="number" min={0} name="coins" id="coins" onChange={(event) => handleRewardChange(index, event)} value={item.coins} required />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="score">Score</Label>
                    <Input type="number" min={0} name="score" id="score" onChange={(event) => handleRewardChange(index, event)} value={item.score} required />
                  </FormGroup>
                </Col>
              </Row>
            </fieldset>
          </div>
        ))}
        <Alert color="danger" isOpen={isAlertVisible} toggle={onDismiss}>
          {alertTextContent}
        </Alert>
        <Row className="mt-2">
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block disabled={postingRewardTemplate}>
              {postingRewardTemplate ? <i className="bx bx-loader-alt bx-spin" /> : "Ajouter"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default RewardTemplateForm;
