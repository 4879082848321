import { React, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { formatDateUtcToLocalTimeUtcFormat, compareDates } from "../../assets/utils/UtilsFunctons";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import { Button, Form, FormGroup, Label, Input, Row, Col, Alert } from "reactstrap";

/* eslint react/no-unescaped-entities: "off", react/jsx-key: "off" */
const EditQuestSeasonConfigForm = (props) => {
  const { seasonList } = useSelector((state) => ({ ...state.seasonList }));
  const { questConfigs } = useSelector((state) => ({ ...state.questConfigs }));
  const { isConfirmed } = useConfirm();
  const [illimitedIteration, setIllimitedIteration] = useState(!props.item.maxIteration);
  const [formValue, setformValue] = useState({
    questSeasonConfigId: props.item.questSeasonConfigId,
    questConfigId: props.questConfig.questConfigId,
    seasonId: props.item.seasonId ?? "null",
    role: props.item.role,
    coins: props.item.coins,
    score: props.item.score,
    maxIteration: props.item.maxIteration,
    gift: props.item.gift,
    expirationDate: props.item.expirationDate,
    expirationDuration: props.item.expirationDuration,
    months: props.item.expirationDuration?.months,
    days: props.item.expirationDuration?.days,
    hours: props.item.expirationDuration?.hours,
    expirationType: props.item.expirationDate ? 1 : props.item.expirationDuration ? 2 : 0 /* 0 : null, 1: Date, 2 : Duration */,
  });

  const getExistingClassAndSeasonIdList = () => {
    let list = [];
    props.questConfig.questSeasonConfigContracts.map((item) => {
      let element = { role: item.role, seasonId: item.seasonId };
      list.push(element);
    });

    return list;
  };

  const doesQuestConfigHasQuestConfigParent = useMemo(() => {
    if (questConfigs && questConfigs.data) {
      let questConfigParent = questConfigs.data?.find((qc) => qc.questConfigId === formValue.questConfigId);
      return questConfigParent ? questConfigParent.unlockedBy !== null : false;
    }
    return false;
  }, [questConfigs, formValue]);

  const isSeasonOfType = (seasonId, seasonType) => {
    var found = false;

    if (seasonList && seasonList.data) {
      const tabLength = seasonList.data.length;
      var counter = 0;
      while (tabLength > counter && found === false) {
        if (seasonList.data[counter].seasonId === seasonId && seasonList.data[counter].seasonType === seasonType) found = true;
        counter++;
      }
    }
    return found;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = checkQuestSeasonConfigFormEntriesValidity();
    if (isValid === 1) {
      const confirmed = await isConfirmed("Souhaitez-vous modifier cette quête ?");
      if (confirmed) {
        props.updateQuestSeasonConfig(formValue);
        props.modalVisibility(false);
      }
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });

    if (formValue.expirationType === 1) {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["expirationDuration"]: null,
        };
      });
    }

    if (formValue.expirationType === 2) {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["expirationDate"]: null,
        };
      });
    }

    if (formValue.expirationType === 3) {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["expirationDate"]: null,
          ["expirationDuration"]: null,
        };
      });
    }
  };

  const handleCheckBoxChange = (event) => {
    if (event.target.name === "illimitedIteration") {
      setIllimitedIteration(event.target.checked);
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["maxIteration"]: event.target.checked ? null : "",
        };
      });
    }
  };

  const handleReset = () => {
    props.modalVisibility(false);
  };

  const questExpirationTypeOptions = () => {
    let choices = [];

    choices.push({ value: 0, label: "Vide" });
    choices.push({ value: 1, label: "Date" });
    choices.push({ value: 2, label: "Durée" });

    return choices;
  };

  const getNumberOptionsFromGivenInterval = (max) => {
    let choices = [];

    for (let index = 0; index <= max; index++) {
      choices.push({ value: index, label: index });
    }
    return choices;
  };

  const [alertVisiblity, setAlertVisiblity] = useState(false);
  const [alertText, setAlertText] = useState("");
  const handleAlertVisiblity = () => {
    setAlertVisiblity(!alertVisiblity);
  };

  const doesQuestSeasonTypeAlreadyExist = (existingClassAndSeasonIdList, seasonType) => {
    if (existingClassAndSeasonIdList?.length <= 0) return false;
    else {
      const tabLength = existingClassAndSeasonIdList.length;
      var counter = 0;
      var found = false;
      while (tabLength > counter && found === false) {
        if (isSeasonOfType(existingClassAndSeasonIdList[counter].seasonId, seasonType)) found = true;
        counter++;
      }
      return found;
    }
  };

  const doesClassAndSeasonIdAlreadyExist = (existingClassAndSeasonIdList, ClassAndSeasonId) => {
    if (existingClassAndSeasonIdList === false) return false;
    else return existingClassAndSeasonIdList.find((item) => item.seasonId === ClassAndSeasonId.seasonId && item.role === ClassAndSeasonId.role) != undefined;
  };

  const checkQuestSeasonConfigFormEntriesValidity = () => {
    const existingClassAndSeasonIdList = getExistingClassAndSeasonIdList();

    // on ne peut pas avoir deux fois une même quête pour la même rolee et de même seasonId ( unicité role & SeasonId)
    const newClassAndSeasonId = { role: parseInt(formValue.role), seasonId: formValue.seasonId };

    if (doesClassAndSeasonIdAlreadyExist(existingClassAndSeasonIdList, newClassAndSeasonId) && formValue.seasonId !== props.item.seasonId) {
      setAlertVisiblity(true);
      setAlertText("Type d'expédition et role répétés !");
      return 0;
    }

    //s'il existe déjà une quête d'expédition pour une role d'utilisateur donnée, alors impossible d'ajouter pour cette rolee une quête de type inter-expédition
    // on a fait le test uniquement avec une expédition courante à verif pour les autres expédition si le besoin est
    if (isSeasonOfType(formValue.seasonId, "null") && doesQuestSeasonTypeAlreadyExist(existingClassAndSeasonIdList, 2)) {
      setAlertVisiblity(true);
      setAlertText(" Ajout Impossible !, il y'a une quête d'expédition existante  .");
      return 0;
    }

    // s'il existe déjà une quête de type inter-expédition destinée à tout le monde alors imposible de d'ajouter une nouvelle quête de expédition
    if (existingClassAndSeasonIdList.some((exped) => exped.seasonId === null && exped.role === 0)) {
      setAlertVisiblity(true);
      setAlertText("Impossible !, une quête inter-expédition générale existe déjà.");
      return 0;
    }
    // s'il existe déjà une quête de type inter-expédition destinée à un rôle specifique, alors imposible de d'ajouter une nouvelle quête pour le rôle "commun"
    if (existingClassAndSeasonIdList.some((exped) => exped.seasonId === null) && newClassAndSeasonId.role === 0) {
      setAlertVisiblity(true);
      setAlertText("Impossible !, une quête inter-expédition existe déjà.");
      return 0;
    }

    //checking if expiration field is correctly filled
    if (formValue.expirationType === 1 && !formValue.expirationDate) {
      setAlertText("Veuillez donner une date d'expiration correcte !");
      setAlertVisiblity(true);
      return 0;
    }

    //checking if expiration date is greater than today's date
    if (formValue.expirationType === 1 && formValue.expirationDate && !compareDates(formValue.expirationDate, new Date())) {
      setAlertText("Veuillez donner une date d'expiration postérieure à aujourd'hui !");
      setAlertVisiblity(true);
      return 0;
    }

    if (formValue.expirationType === 2 && !formValue.months && !formValue.days && !formValue.hours) {
      setAlertText("Veuillez donner une durée d'expiration correcte !");
      setAlertVisiblity(true);
      return 0;
    }
    return 1;
  };

  return (
    <div>
      <ConfirmModal />
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Alert color="danger" isOpen={alertVisiblity} toggle={handleAlertVisiblity}>
            {alertText}
          </Alert>
          {isSeasonOfType(formValue.seasonId, 0) ? (
            <Alert color="danger" isOpen={true}>
              Modifications impossibles pour les expéditions passées !
            </Alert>
          ) : (
            ""
          )}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="expirationType">
            Expiration type<sup className="required-field-mark">*</sup>
          </Label>
          <Select
            name="expirationType"
            id="expirationType"
            autoComplete="off"
            required
            defaultValue={questExpirationTypeOptions().filter((opt) => opt.value === formValue.expirationType)}
            isClearable
            isSearchable
            onChange={handleReactSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            placeholder="choisir un type"
            classNamePrefix="choisir un type"
            options={questExpirationTypeOptions()}
          />
        </FormGroup>

        <FormGroup>
          {formValue.expirationType === 1 ? (
            <FormGroup>
              <Label htmlFor="expirationDate">Date</Label>
              <Input
                type="datetime-local"
                name="expirationDate"
                id="expirationDate"
                onChange={handleChange}
                defaultValue={formatDateUtcToLocalTimeUtcFormat(formValue.expirationDate)}
              />
            </FormGroup>
          ) : formValue.expirationType === 2 ? (
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="months">Mois</Label>
                  <Select
                    name="months"
                    id="months"
                    autoComplete="off"
                    required
                    onChange={handleReactSelectChange}
                    defaultValue={getNumberOptionsFromGivenInterval(12).filter((month) => month.value === formValue.expirationDuration?.months)}
                    isSearchable
                    placeholder="00"
                    classNamePrefix="00"
                    options={getNumberOptionsFromGivenInterval(12)}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="days">Jours</Label>
                  <Select
                    name="days"
                    id="days"
                    autoComplete="off"
                    required
                    defaultValue={getNumberOptionsFromGivenInterval(30).filter((day) => day.value === formValue.expirationDuration?.days)}
                    onChange={handleReactSelectChange}
                    isSearchable
                    placeholder="00"
                    classNamePrefix="00"
                    options={getNumberOptionsFromGivenInterval(30)}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="hours">Heures</Label>
                  <Select
                    name="hours"
                    id="hours"
                    autoComplete="off"
                    required
                    defaultValue={getNumberOptionsFromGivenInterval(23).filter((hour) => hour.value === formValue.expirationDuration?.hours)}
                    onChange={handleReactSelectChange}
                    isSearchable
                    placeholder="00"
                    classNamePrefix="00"
                    options={getNumberOptionsFromGivenInterval(23)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="role">Rôle</Label>
          <Input
            type="select"
            name="role"
            id="role"
            onChange={handleChange}
            disabled
            readOnly
            placeholder="Catégorie"
            autoComplete="off"
            defaultValue={formValue.role}
          >
            <option value="0"> Tout le monde</option>
            <option value="1"> Explorateur</option>
            <option value="2"> Guide</option>
            <option value="4"> Personnel</option>
          </Input>
        </FormGroup>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="coins">Coins</Label>
              <Input
                type="number"
                min="0"
                name="coins"
                id="coins"
                defaultValue={formValue.coins}
                onChange={handleChange}
                required
                disabled={isSeasonOfType(formValue.seasonId, 0)}
                placeholder="Coins"
                autoComplete="off"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="score">Score</Label>
              <Input
                type="number"
                min="0"
                name="score"
                id="score"
                defaultValue={formValue.score}
                onChange={handleChange}
                required
                disabled={isSeasonOfType(formValue.seasonId, 0)}
                placeholder="Score"
                autoComplete="off"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="maxIteration">Max Iteration</Label>
              <Input
                type="number"
                id="maxIteration"
                name="maxIteration"
                placeholder="Max Iteration"
                onChange={handleChange}
                disabled={illimitedIteration || doesQuestConfigHasQuestConfigParent}
                value={illimitedIteration ? "" : formValue.maxIteration}
              />
              <small>
                <div className="form-check py-2 align-item-center">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="illimitedIteration"
                    name="illimitedIteration"
                    checked={illimitedIteration}
                    onChange={handleCheckBoxChange}
                    disabled={doesQuestConfigHasQuestConfigParent}
                  />
                  <label className="form-check-label" htmlFor="illimitedIteration">
                    illimitée
                  </label>
                </div>
              </small>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="gift">Cadeau</Label>
              <Input type="select" name="gift" id="gift" autoComplete="off" required onChange={handleChange} defaultValue={formValue.gift}>
                <option value={false}>Non</option>
                <option value={true}>Oui</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <br />
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block disabled={isSeasonOfType(formValue.seasonId, 0)}>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="warning" type="submit" block disabled={isSeasonOfType(formValue.seasonId, 0)}>
              Modifier
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditQuestSeasonConfigForm;
