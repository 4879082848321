import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EnumsDataService from "../../services/EnumsDataService";

export const getAllEnums = createAsyncThunk("utils/enums", async () => {
  try {
    const response = await EnumsDataService.getAllEnums();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const EnumsSlice = createSlice({
  name: "enums",
  initialState: {
    enumList: null,
    loading_getAllEnums: true,
  },
  reducers: {},
  extraReducers: {
    [getAllEnums.pending]: (state) => {
      state.loading_getAllEnums = true;
    },
    [getAllEnums.fulfilled]: (state, { payload }) => {
      state.loading_getAllEnums = false;
      state.enumList = payload;
    },
  },
});

export default EnumsSlice.reducer;
