import React from "react";
import { Table } from "reactstrap";
import { convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";

const MissionSheetDetailsTable = ({ missionSheetDetail, consultantName }) => {
  return (
    <div>
      {missionSheetDetail && (
        <Table size="sm">
          <thead></thead>
          <tbody>
            <tr>
              <td className="text-muted">Debut de mission</td>
              <td>{convertDateToLocalTime(missionSheetDetail.startDate)}</td>
            </tr>
            <tr>
              <td className="text-muted">Fin de mission</td>
              <td>{convertDateToLocalTime(missionSheetDetail.endDate)}</td>
            </tr>
            <tr>
              <td className="text-muted"> Collaborateur</td>
              <td>{consultantName}</td>
            </tr>
            <tr>
              <td className="text-muted">Mission</td>
              <td>{missionSheetDetail.consultantRole}</td>
            </tr>
            <tr>
              <td className="text-muted"> Société client</td>
              <td>{missionSheetDetail.clientCompany}</td>
            </tr>
            <tr>
              <td className="text-muted"> Equipe</td>
              <td></td>
            </tr>
            <tr>
              <td className="text-muted"> Responsable client</td>
              <td>{missionSheetDetail.clientMissionManager}</td>
            </tr>
            <tr>
              <td className="text-muted"> Responsable technique</td>
              <td>{missionSheetDetail.clientMonitoringManager}</td>
            </tr>
            <tr>
              <td className="text-muted"> Entité client</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default MissionSheetDetailsTable;
