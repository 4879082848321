import { React } from "react";
import EditableTable from "./EditableTable";
import { useDispatch, useSelector } from "react-redux";
import { addNewLeagueConfigs, editLeagueConfigs } from "../../redux/slices/LeagueConfigSlice";
import AddNewLeagueConfigModal from "../modals/AddNewLeagueConfigModal";
import EditLeagueConfigModal from "../modals/EditLeagueConfigModal";
import EditLeagueIconModal from "../league/EditLeagueIconModal";
import { postLeagueIconPicture } from "../../redux/slices/LeagueConfigSlice";
import { Button } from "reactstrap";
import style from "../../assets/css/leagueAndLevel.module.css";

const LeagueTable = ({ inStandBYPhaseOrNot }) => {
  const { leagueConfigs, loading_getLeagueConfigs } = useSelector((stateLeague) => ({ ...stateLeague.leagueConfig }));
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));

  const dispatch = useDispatch();
  const leagueConfTableHead = ["Index", "icone", "couleur", "Nom", "Boost", "Utilisateurs", "Action"];

  function sortLeagueId(data) {
    if (data) {
      let sortedData = [];
      let previousLeagueTreated = null;
      data.map(() => {
        for (let i in data) {
          if (data[i].previousLeagueId === previousLeagueTreated) {
            let element = { ...data[i], index: sortedData.length };
            sortedData.push(element);
            previousLeagueTreated = data[i].leagueId;
          }
        }
      });
      return sortedData;
    }
  }

  let data = null;
  if (sortLeagueId(leagueConfigs?.data)) {
    data = Object.values(sortLeagueId(leagueConfigs.data));
  }

  const addNewLeagueConfig = (formValue) => {
    const payload = {
      leagueId: formValue.leagueId,
      name: formValue.name,
      boost: formValue.boost,
      colorCode: formValue.colorCode,
      previousLeagueId: formValue.previousLeagueId,
    };
    dispatch(addNewLeagueConfigs(payload));
  };

  const editLeagueConfig = (formValue) => {
    const payload = {
      leagueId: formValue.leagueId,
      name: formValue.name,
      boost: formValue.boost,
      previousLeagueId: formValue.previousLeagueId,
    };
    dispatch(editLeagueConfigs(payload));
  };

  const handleUpdateLeagueIconPicture = (payload) => {
    dispatch(postLeagueIconPicture(payload));
  };

  const checkIfIsPreviousLeague = (data, item) => {
    var hasPreviousLeague = false;
    data.map((element) => {
      if (item.leagueId === element.previousLeagueId) {
        hasPreviousLeague = true;
      }
    });
    return hasPreviousLeague;
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderLeagueBody = (item, index, data, user) => (
    <tr key={index}>
      <td>{item.index + 1}</td>
      <td>
        <div className={style.transperent_block}>
          <img src={item.iconImageUrl ?? "..."} alt="pas d'icône" className={style.league_icon_image}></img>
          <div className={style.black_hover_block}>
            <div className={style.black_hover_block_text}>
              <EditLeagueIconModal handleUpdateLeagueIconPicture={handleUpdateLeagueIconPicture} leagueId={item.leagueId}></EditLeagueIconModal>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className={style.League_colorCode}>
          <div className={style.League_color_code_content} style={{ backgroundColor: item.colorCode.toLowerCase(), marginRight: "10px" }}></div>
        </div>
      </td>
      <td>{item.name}</td>
      <td>{item.boost} %</td>
      {user != null ? (
        <td>
          {user.reduce((i, element) => {
            if (item.leagueId === element.leagueId) {
              i = i + 1;
            }
            return i;
          }, 0)}
        </td>
      ) : (
        <td></td>
      )}
      <td>
        <div className="d-flex justify-content-start">
          <div className="mx-2">
            <EditLeagueConfigModal
              buttonLabel="Edit"
              item={item}
              editLeagueConfig={editLeagueConfig}
              inStandBYPhaseOrNot={inStandBYPhaseOrNot}
            ></EditLeagueConfigModal>
          </div>
          <div>
            {data != null ? (
              checkIfIsPreviousLeague(data, item) == true ? (
                <Button color="danger" style={{ float: "left", marginRight: "10px" }} disabled={true}>
                  <i className="bx bxs-trash"></i>
                </Button>
              ) : (
                <EditLeagueConfigModal
                  buttonLabel="Delete"
                  item={item}
                  editLeagueConfig={editLeagueConfig}
                  inStandBYPhaseOrNot={inStandBYPhaseOrNot}
                ></EditLeagueConfigModal>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </td>
    </tr>
  );

  return (
    <div>
      <EditableTable
        limit="10"
        loading={loading_getLeagueConfigs}
        striped={true}
        title="Liste des ligues"
        modal={<AddNewLeagueConfigModal postLeagueConfig={addNewLeagueConfig} sortedData={data}></AddNewLeagueConfigModal>}
        headData={leagueConfTableHead}
        renderHead={(item, index) => renderHead(item, index)}
        bodyData={leagueConfigs?.status === 200 && data ? data : []}
        renderBody={(item, index) => renderLeagueBody(item, index, data, usersInfos.data)}
      />
    </div>
  );
};

export default LeagueTable;
