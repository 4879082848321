import React from "react";
import RewardTemplateActionButton from "./RewardTemplateActionButton";

const RewardTemplateActions = ({ rewardTemplate, handleDeleteRewardTemplate }) => {
  return (
    <div className="d-flex justify-content-center">
      <RewardTemplateActionButton
        rewardTemplate={rewardTemplate}
        color={"danger"}
        icon={"bx bxs-trash"}
        tooltipText={"supprimer le template"}
        handleAction={handleDeleteRewardTemplate}
        decision={"delete"}
      >
        <span>
          Souhaitez-vous supprimer le template <strong>{rewardTemplate?.name}</strong>
        </span>
      </RewardTemplateActionButton>
    </div>
  );
};

export default RewardTemplateActions;
