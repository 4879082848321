import { useState, useEffect } from "react";
import axios from "axios";

const useHolidaysCalendar = () => {
  const [holidays, setHolidays] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchHolidays = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const reponse = await axios.get(`https://calendrier.api.gouv.fr/jours-feries/metropole/${currentYear}.json`);
        setHolidays(reponse.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchHolidays();

    //clean up
    return () => {
      source.cancel("aborted");
    };
  }, []);

  const isHoliday = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate in holidays;
  };

  return { error, isHoliday };
};
export default useHolidaysCalendar;
