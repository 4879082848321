import React, { useState, useCallback } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import PepActionContent from "./PepActionContent";
import DPlusButtonAdd from "../../core/component/buttons/add-button/DPlusButtonAdd";

const NewPepActionModal = ({ updatePage, setUpdatePage }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const closeBtn = (
    <Button
      className="close"
      onClick={() => {
        toggle();
      }}
    >
      &times;
    </Button>
  );

  return (
    <div>
      <DPlusButtonAdd handleClick={toggle} />
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false} size="xl">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Sélectionner ou créer une action
        </ModalHeader>

        <ModalBody>
          <PepActionContent handleCloseModal={handleCloseModal} updatePage={updatePage} setUpdatePage={setUpdatePage} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NewPepActionModal;
