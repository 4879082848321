import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editLeagueConfigs, deleteLeagueConfigs } from "../../redux/slices/LeagueConfigSlice";
import EditLeagueConfigForm from "../forms/EditLeagueConfigForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const EditLeagueConfigModal = ({ inStandBYPhaseOrNot, item, buttonLabel }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );
  const label = buttonLabel;

  let button = "";
  let title = "";
  let form = "";

  const editButton = (
    <Button
      color="primary"
      onClick={toggle}
      outline
      disabled={!inStandBYPhaseOrNot}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={!inStandBYPhaseOrNot ? "modification impossible, expédition en cours" : "modifier les infos de la ligue"}
    >
      <i className="bx bxs-pencil"></i>
    </Button>
  );

  const deleteButton = (
    <Button color="danger" onClick={toggle}>
      <i className="bx bxs-trash"> </i>
    </Button>
  );

  const dispatch = useDispatch();

  const editLeagueConfig = (formValue) => {
    dispatch(editLeagueConfigs(formValue));
    toggle(modal);
  };

  const deleteLeagueConfig = (formValue) => {
    dispatch(deleteLeagueConfigs(formValue));
    toggle(modal);
  };

  if (label === "Edit") {
    button = editButton;
    title = "Modifier la configuration";
    form = <EditLeagueConfigForm item={item} modalConfig={setModal} editLeagueConfig={editLeagueConfig} label={label}></EditLeagueConfigForm>;
  } else {
    button = deleteButton;
    title = "Supprimer la ligue";
    form = <EditLeagueConfigForm item={item} modalConfig={setModal} deleteLeagueConfig={deleteLeagueConfig} label={label}></EditLeagueConfigForm>;
  }

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>{form}</ModalBody>
      </Modal>
    </div>
  );
};

export default EditLeagueConfigModal;
