import { React, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const MissionSheetDecisionForm = ({ decision, handleMissionSheetDecison, modalVisibility, missionId, missionSheet }) => {
  const [formValue, setformValue] = useState({
    missionId: missionId,
    missionSheetId: missionSheet.missionSheetId,
    decidedState: decision === "validation" ? 4 : decision === "adjustment" ? 3 : 1,
    message: undefined,
  });

  const submitButtonColor = decision === "validation" ? "success" : "warning";
  const submitButtonText = decision === "validation" ? "valider" : decision === "adjustment" ? "à ajuster" : "rejeter";
  const formTitle =
    decision === "validation"
      ? "Souhaitez-vous procéder à la validation de cette fiche mission ?"
      : decision === "adjustment"
      ? "Souhaitez-vous demander un changement de la part de l'utilisateur ?"
      : "Souhaitez-vous rejeter cette fiche mission ?";

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleMissionSheetDecison(formValue);
    modalVisibility(false);
  };

  const handleReset = () => {
    modalVisibility(false);
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <div>
      <div className="quest-validation-form-header">
        <div>
          <i className="bx bx-error-circle bx-tada quest-validation-form-icon"></i>
        </div>
        <div className="quest-validation-form-title">{formTitle}</div>
      </div>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Label for="message">Message</Label>
          <Input type="textarea" name="message" id="message" autoComplete="off" onChange={handleChange} />
        </FormGroup>
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color={submitButtonColor} type="submit" block>
              {submitButtonText}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MissionSheetDecisionForm;
