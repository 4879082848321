import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlocConfigs } from "../redux/slices/BlocConfigSlice";
import { getAllQuestConfigs } from "../redux/slices/QuestConfigSlice";
import BlocTable from "../components/table/BlocTable";
import { Toaster } from "react-hot-toast";

const BlocPage = () => {
  const dispatch = useDispatch();
  const { refreshPage } = useSelector((state) => ({ ...state.blocConfig }));

  useEffect(() => {
    dispatch(getAllBlocConfigs());
    dispatch(getAllQuestConfigs());
  }, [refreshPage]);
  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div className="pb-2 mb-2">
        <h4>Gestion des blocs</h4>
      </div>
      <div className="row">
        <div className="myCard">
          <BlocTable></BlocTable>
        </div>
      </div>
    </div>
  );
};

export default BlocPage;
