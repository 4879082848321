import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserDataService from "../../services/UserDataService";
import toast from "react-hot-toast";

const notify = (notif, state) => {
  switch (state) {
    case 1:
      toast.success(notif, { position: "top-right" });
      break;
    case 0:
      toast.error(notif, { position: "top-right" });
      break;
    default:
      toast.error(" La récuperation des données a échoué !");
      break;
  }
};

export const getUserInfosById = createAsyncThunk("getUserInfosById", async (data) => {
  try {
    const response = await UserDataService.getUserInfoById(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.message, 0);
    return err.message;
  }
});

export const getUserCoinVariations = createAsyncThunk("getUserCoinVariations", async (data) => {
  try {
    const response = await UserDataService.getUserCoinVariations(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.message, 0);
    return err.message;
  }
});

export const getUserScoreVariations = createAsyncThunk("getUserScoreVariations", async (data) => {
  try {
    const response = await UserDataService.getUserScoreVariations(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.message, 0);
    return err.message;
  }
});
export const getUserSession = createAsyncThunk("getUserSession", async (data) => {
  try {
    const response = await UserDataService.getUserSession(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.message, 0);
    return err.message;
  }
});

export const getUsersInfos = createAsyncThunk("user/getUsersInfos", async () => {
  try {
    const response = await UserDataService.getUsersInfos();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.message, 0);
    return err.message;
  }
});

export const addNewUser = createAsyncThunk("user/addNewUser", async (data) => {
  try {
    const response = await UserDataService.addNewUser(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const updateUserInfos = createAsyncThunk("user/updateUserInfo", async (data) => {
  try {
    const response = await UserDataService.updateUserInfos(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const desactivateUser = createAsyncThunk("user/desactivateUser", async (data) => {
  try {
    const response = await UserDataService.desactivateUser(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const deleteUser = createAsyncThunk("user/deleteUser", async (data) => {
  try {
    const response = await UserDataService.deleteUser(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const getUserProfilePictureById = createAsyncThunk("user/getUserProfilePictureById", async (userId) => {
  try {
    const response = await UserDataService.getUserProfilePictureById(userId);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const postUserProfilePicture = createAsyncThunk("user/postUserProfilePicture", async (payload) => {
  try {
    const response = await UserDataService.postUserProfilePicture(payload);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const deleteUserProfilePicture = createAsyncThunk("user/deleteUserProfilePicture", async (userId) => {
  try {
    const response = await UserDataService.deleteUserProfilePicture(userId);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUserInfos: null,
    userInfos: [],
    usersInfos: undefined,
    userCoinVariations: null,
    userScoreVariations: null,
    userSessions: null,
    loading_getUserInfoById: true,
    loading_getUsersInfos: true,
    loading_addNewUser: false,
    loading_updateUserInfos: false,
    loading_desactivateUser: false,
    loading_userCoinVariations: false,
    loading_userScoreVariations: false,
    loadind_userSessions: false,
    userDesactivation: [],
    userDeleteAction: [],
    userdataPost: [],
    refreshUserPage: false,
    loading_getUserProfilePicture: false,
    userProfilePicture: [],
  },
  reducers: {
    setCurrentUserId: (state, action) => {
      state.currentUserInfos = action.payload;
    },
  },
  extraReducers: {
    // get user infos by id
    [getUserInfosById.pending]: (state) => {
      state.loading_getUserInfoById = true;
    },
    [getUserInfosById.fulfilled]: (state, { payload }) => {
      state.loading_getUserInfoById = false;
      state.userInfos = payload;
      if (payload.status !== 200 && payload.statusText) {
        notify(payload.statusText, 0);
      }
    },
    // get user coin variations by id
    [getUserCoinVariations.pending]: (state) => {
      state.loading_userCoinVariations = true;
    },
    [getUserCoinVariations.fulfilled]: (state, { payload }) => {
      state.loading_userCoinVariations = false;
      state.userCoinVariations = payload;
      if (payload.status !== 200 && payload.statusText) {
        notify(payload.statusText, 0);
      }
    },
    // get user score variations by id
    [getUserScoreVariations.pending]: (state) => {
      state.loading_userScoreVariations = true;
    },
    [getUserScoreVariations.fulfilled]: (state, { payload }) => {
      state.loading_userScoreVariations = false;
      state.userScoreVariations = payload;
      if (payload.status !== 200 && payload.statusText) {
        notify(payload.statusText, 0);
      }
    },
    // get user session by id
    [getUserSession.pending]: (state) => {
      state.loadind_userSessions = true;
    },
    [getUserSession.fulfilled]: (state, { payload }) => {
      state.loadind_userSessions = false;
      state.userSessions = payload;
      if (payload.status !== 200 && payload.statusText) {
        notify(payload.statusText, 0);
      }
    },
    // get user infos by id
    [getUserInfosById.pending]: (state) => {
      state.loading_getUserInfoById = true;
    },
    [getUserInfosById.fulfilled]: (state, { payload }) => {
      state.loading_getUserInfoById = false;
      state.userInfos = payload;
      if (payload.status !== 200 && payload.statusText) {
        notify(payload.statusText, 0);
      }
    },

    //get all users infos
    [getUsersInfos.pending]: (state) => {
      state.loading_getUsersInfos = true;
      state.currentUserItem = [];
    },
    [getUsersInfos.fulfilled]: (state, { payload }) => {
      state.loading_getUsersInfos = false;
      state.usersInfos = payload;
      if (payload.status !== 200 && payload.statusText) {
        notify(payload.statusText, 0);
      }
    },

    [addNewUser.pending]: (state) => {
      state.loading_addNewUser = true;
    },
    [addNewUser.fulfilled]: (state, { payload }) => {
      state.refreshUserPage = !state.refreshUserPage;
      state.loading_addNewUser = false;
      state.userDataPost = payload;
      if (state.userDataPost.status === 200 && state.userDataPost.statusText === "OK") {
        notify("Utilisateur crée avec success !", 1);
      } else if (state.userDataPost.statusText) {
        notify(state.userDataPost.statusText, 0);
      }
    },

    [updateUserInfos.pending]: (state) => {
      state.loading_updateUserInfos = true;
    },
    [updateUserInfos.fulfilled]: (state, { payload }) => {
      state.loading_updateUserInfos = false;
      state.refreshUserPage = !state.refreshUserPage;
      state.userDataPost = payload;
      if (state.userDataPost.status === 200 && state.userDataPost.statusText === "OK") {
        notify("Infos modifiées avec success !", 1);
      } else if (state.userDataPost.statusText) {
        notify(state.userDataPost.statusText, 0);
      }
    },

    [desactivateUser.pending]: (state) => {
      state.loading_desactivateUser = true;
    },
    [desactivateUser.fulfilled]: (state, { payload }) => {
      state.refreshUserPage = !state.refreshUserPage;
      state.loading_desactivateUser = false;
      state.userDesactivation = payload;
      if (state.userDesactivation.status === 200 && state.userDesactivation.statusText === "OK") {
        notify("Utilisateur désactivée avec success !", 1);
      } else if (state.userDesactivation.statusText) {
        notify(state.userDesactivation.statusText, 0);
      }
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.refreshUserPage = !state.refreshUserPage;
      state.userDeleteAction = payload;
      if (state.userDeleteAction.status === 200 && state.userDeleteAction.statusText === "OK") {
        notify("Utilisateur supprimé avec success !", 1);
      } else if (state.userDeleteAction.statusText) {
        notify(state.userDeleteAction.statusText, 0);
      }
    },
    [getUserProfilePictureById.pending]: (state) => {
      state.loading_getUserProfilePicture = true;
    },
    [getUserProfilePictureById.fulfilled]: (state, { payload }) => {
      state.loading_getUserProfilePicture = false;
      state.userProfilePicture = payload;
      if (payload.status !== 200 && payload.statusText) {
        /* No action if status === 200, so we check for errors only */
        notify(payload.statusText, 0);
      }
    },
    [postUserProfilePicture.fulfilled]: (state, { payload }) => {
      if (payload.status === 200 && payload.statusText === "OK") {
        notify("Photo ajoutée avec succès !", 1);
        state.refreshUserPage = !state.refreshUserPage;
      } else if (payload.statusText) {
        notify(payload.statusText, 0);
      }
    },
    [deleteUserProfilePicture.fulfilled]: (state, { payload }) => {
      if (payload.status === 200 && payload.statusText === "OK") {
        notify("Photo supprimée avec succès !", 1);
        state.refreshUserPage = !state.refreshUserPage;
      } else if (payload.statusText) {
        notify(payload.statusText, 0);
      }
    },
  },
});

export const { setCurrentUserId } = userSlice.actions;
export default userSlice.reducer;
