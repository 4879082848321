import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSeasonList, changeSelectedSeasonIdForRank } from "../redux/slices/SeasonSlice";
import { getAllLeagueConfigs } from "../redux/slices/LeagueConfigSlice";
import { getUsersInfos } from "../redux/slices/UserSlice";
import Enums from "../assets/JsonData/Enums";
import Loader from "../components/loader/Loader";
import TeamRankTable from "../components/ranking/TeamRankTable";
import ConsultantAndGuideRankTable from "../components/ranking/ConsultantAndGuideRankTable";
import { Input } from "reactstrap";
import { Toaster } from "react-hot-toast";
import "../components/ranking/ranking.css";

const RankingPage = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [tabs, setTabs] = useState([]);
  const { loading_getSeasonList, seasonList, currentSeason, refreshRankingPageAfterUpdate } = useSelector((state) => ({ ...state.seasonList }));
  const { loading_getUsersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const dispatch = useDispatch();

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  useEffect(() => {
    dispatch(getUsersInfos());
    sleep(100);
    dispatch(getAllLeagueConfigs());
    dispatch(getSeasonList());
    setTabs([
      { id: "tab1", label: "Equipes", display: <TeamRankTable></TeamRankTable> },
      { id: "tab2", label: "Explorateurs", display: <ConsultantAndGuideRankTable role="consultant"></ConsultantAndGuideRankTable> },
      { id: "tab3", label: "Guides", display: <ConsultantAndGuideRankTable role="guide"></ConsultantAndGuideRankTable> },
    ]);
  }, [refreshRankingPageAfterUpdate]);

  const handleSeasonChanged = (event) => {
    dispatch(changeSelectedSeasonIdForRank(event.target.value));
  };
  return (
    <div>
      <h2 className="page-header">Classement</h2>
      <div>
        <Toaster></Toaster>
      </div>
      <div>
        <div>
          <ul className=" myCard d-flex flex-row ranking-nav" id="myTab" role="tablist">
            {tabs.map(({ id, label }) => (
              <li className="nav-item px-3 ranking-nav-item" key={id}>
                <p
                  className={`mb-0 nav-link ${activeTab === id ? "active" : ""}`}
                  role="tab"
                  aria-selected={activeTab === id}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(id);
                  }}
                >
                  <a href="">{label}</a>
                </p>
              </li>
            ))}
          </ul>
        </div>
        {loading_getSeasonList || !seasonList || loading_getUsersInfos ? (
          <Loader></Loader>
        ) : (
          <div className=" myCard ranking-tab-display tab-content " id="myTabContent">
            <div className="mb-3">
              <Input type="select" onChange={handleSeasonChanged} defaultValue={currentSeason !== undefined ? currentSeason.seasonId : ""}>
                <option value="" disabled={true}>
                  Choisir une expédition
                </option>
                {!seasonList || !seasonList.data
                  ? ""
                  : seasonList.data?.map((item, index) => {
                      return (
                        <option value={item.seasonId} key={index}>
                          {item.title} &nbsp; &rArr;&nbsp; ({Enums.matchSeasonType(item.seasonType)})
                        </option>
                      );
                    })}
              </Input>
            </div>

            {tabs.map(({ id, display }) => (
              <div className={` tab-pane fade ${activeTab === id ? "show active" : ""}`} id={id} role="tabpanel" aria-labelledby={`${id}-tab`} key={id}>
                {display}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RankingPage;
