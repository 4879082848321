import http from "./http-common";

class QuizConfigDataService {
  getAllRewardTemplates() {
    return http.get("/rewardtemplate/list?withArchived=true");
  }

  addNewRewardTemplate(data) {
    return http.post("/rewardtemplate", data);
  }

  updateRewardTemplate(data) {
    return http.put("/rewardtemplate", data);
  }

  archiveRewardTemplate(data) {
    return http.put("rewardtemplate/archive?rewardTemplateId=" + data);
  }

  deleteRewardTemplate(data) {
    return http.delete("/rewardtemplate?rewardTemplateId=" + data);
  }
}

export default new QuizConfigDataService();
