import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import { convertDateToLocalTime, daysBetweenTwoDates } from "../../assets/utils/UtilsFunctons";
import Select from "react-select";
import { Button, Form, FormGroup, Label, Input, Row, Col, Alert, ListGroup, ListGroupItem } from "reactstrap";
import useGetAllUserListOption from "../../hooks/useGetAllUserListOption";
import { useCallback } from "react";

const AddNewMissonForm = ({ handleAddNewMission, modalVisibility }) => {
  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();
  const { userOptions, loaded } = useGetAllUserListOption();
  const { loading_getUsersInfos, usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { loading_addNewMission } = useSelector((state) => ({ ...state.missionSheet }));
  const [formValue, setformValue] = useState({
    missionId: undefined,
    userId: undefined,
    startDate: undefined,
    boondProjectId: undefined,
    missionNumber: undefined,
    clientCompany: undefined,
    consultantRole: undefined,
    clientMissionManager: undefined,
    clientMonitoringManager: undefined,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    let confirmed = await isConfirmed(InputsRecapForConfirmation());
    if (confirmed) {
      handleAddNewMission(formValue);
      modalVisibility(false);
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleReactSelectChange = (choice) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        userId: choice?.value ?? null,
      };
    });
  };

  const handleReset = () => {
    modalVisibility(false);
  };
  const usersListOption = useCallback(() => {
    if (userOptions) {
      return userOptions;
    }
    return [];
  }, [userOptions]);

  const isOneMonthDelayPast = () => {
    let todayDate = new Date();
    return daysBetweenTwoDates(todayDate, formValue.startDate) >= 30;
  };

  const InputsRecapForConfirmation = () => {
    return (
      <div>
        {isOneMonthDelayPast() && <Alert color="danger"> Attention : création d’une mission avec plus d’un mois de retard </Alert>}
        <span className="mb-2"> Souhaitez-vous créer cette nouvelle mission ?</span>
        <br />
        <ListGroup>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div>Consultant </div>
              <div>{usersListOption().find((user) => user.value === formValue.userId)?.label}</div>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div>Début </div>
              <div>{convertDateToLocalTime(formValue.startDate)}</div>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div>Id projet </div>
              <div>{formValue.boondProjectId}</div>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex justify-content-between">
              <div> Client </div>
              <div>{formValue.clientCompany}</div>
            </div>
          </ListGroupItem>
        </ListGroup>
      </div>
    );
  };

  return (
    <div>
      {!loaded ? (
        "chargement..."
      ) : (
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <div>
            <ConfirmModal></ConfirmModal>
          </div>
          <FormGroup>
            <Label for="userId">
              Nom du consultant <sup>*</sup>
            </Label>
            <Select
              name="userId"
              required
              isClearable
              isSearchable
              options={usersListOption()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
            />
          </FormGroup>
          <FormGroup>
            <Label for="startDate">
              Date de début<sup>*</sup>
            </Label>
            <Input type="date" name="startDate" id="startDate" onChange={handleChange} required autoComplete="off" />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="boondProjectId">
                  Id projet BoondManager <sup>*</sup>
                </Label>
                <Input type="number" name="boondProjectId" id="boondProjectId" onChange={handleChange} required autoComplete="off" min={0}></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="missionNumber"> N° mission</Label>
                <Input type="text" name="missionNumber" id="missionNumber" onChange={handleChange} autoComplete="off"></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="clientCompany">
                  Nom client<sup>*</sup>
                </Label>
                <Input type="text" name="clientCompany" id="clientCompany" onChange={handleChange} required autoComplete="off"></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="consultantRole">
                  Rôle du consultant <sup>*</sup>
                </Label>
                <Input type="text" name="consultantRole" id="consultantRole" onChange={handleChange} required autoComplete="off"></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="clientMissionManager">
                  Responsable client <sup>*</sup>
                </Label>
                <Input type="text" name="clientMissionManager" id="clientMissionManager" onChange={handleChange} required autoComplete="off"></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="clientMonitoringManager">Resp. technique client</Label>
                <Input type="text" name="clientMonitoringManager" id="clientMonitoringManager" onChange={handleChange} autoComplete="off"></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button color="danger" type="reset" block>
                Annuler
              </Button>
            </Col>
            <Col md={6}>
              <Button color="success" type="submit" block disabled={loading_addNewMission}>
                {loading_addNewMission ? <i className="bx bx-dots-horizontal-rounded bx-flashing"></i> : "Ajouter"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default AddNewMissonForm;
