import { React, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, ModalHeader } from "reactstrap";
import AddNewQuestSeasonConfigModal from "../modals/AddNewQuestSeasonConfigModal";
import "bootstrap/dist/css/bootstrap.min.css";
import QuestSeasonItemTable from "../QuestSeasonItem/QuestSeasonItemTable";
import "./questConfigDetailPage.css";
import DeleteButtonWithConfirmation from "../buttons/DeleteButtonWithConfirmation";
import MyBreadcrumb from "../breadcrumb/MyBreadcrumb";
import NewQuestConfigForm from "../newQuestConfigPage/NewQuestConfigForm";

/* eslint react/no-unescaped-entities: "off" */
const QuestConfigDetailPage = ({ item, updateQuestConfig, handleDeleteQuestconfig, handleActivateOrDesactivateQuestSeasonconfig }) => {
  const navigate = useNavigate();
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { loading_getSeasonList } = useSelector((state) => ({ ...state.seasonList }));

  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal(!modal);
    navigate(-1);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      retour
    </Button>
  );

  let questSeasonConfigsData = item === undefined ? [] : item.questSeasonConfigContracts;

  return (
    <>
      {item === undefined ? (
        ""
      ) : (
        <div>
          <ModalHeader toggle={toggle} close={closeBtn} className="modalHeaderQuestConfigDetail">
            <MyBreadcrumb pageTitle={"Administration des quêtes"} subPageTitle={"Edition de quête"}></MyBreadcrumb>
          </ModalHeader>
          <div className="modalBodyQuestConfigDetail">
            <div className="display-row">
              <div className="questConfig-title">
                <h4>{item.title}</h4>
                <div className="d-flex flex-row">
                  {/* <div>
                    <EditQuestConfigModal
                      buttonLabel="Edit"
                      item={item}
                      updateQuestConfig={updateQuestConfig}
                      setRefreshPage={setRefreshPage}
                      refreshPage={refreshPage}
                    ></EditQuestConfigModal>
                  </div> */}
                  <div>
                    <DeleteButtonWithConfirmation handleDeleteConfirm={handleDeleteQuestconfig} payload={""}>
                      <div>
                        Souhaitez-vous supprimer la configuration de quête : <strong>{item.title}</strong>
                      </div>
                    </DeleteButtonWithConfirmation>
                  </div>
                </div>
              </div>
              <div className="top-side">
                <div className="top-side-content">
                  <div className="myCard">
                    {/* <QuestConfigDetailAccordion item={item}></QuestConfigDetailAccordion> */}
                    <NewQuestConfigForm isEditFormat={true} questConfigItem={item} handleAddNewQuestConfig={updateQuestConfig} />
                  </div>
                </div>
              </div>

              <div className="display-column">
                <div className="left-side content">
                  <div className="questSeasons-header">
                    <div>
                      <AddNewQuestSeasonConfigModal questConfig={item}></AddNewQuestSeasonConfigModal>
                    </div>
                  </div>
                  <div className="questSeasonItems myCard">
                    {!questSeasonConfigsData || loading_getSeasonList ? (
                      ""
                    ) : (
                      <QuestSeasonItemTable
                        data={questSeasonConfigsData}
                        questConfig={item}
                        handleActivateOrDesactivateQuestSeasonconfig={handleActivateOrDesactivateQuestSeasonconfig}
                        users={usersInfos?.data}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestConfigDetailPage;
