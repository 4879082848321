import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RewardTemplateDataService from "../../services/RewardTemplateDataService";
import { notify } from "../../assets/utils/UtilsFunctons";

export const getAllRewardTemplates = createAsyncThunk("RewardTemplate/getAllRewardTemplates", async () => {
  try {
    const response = await RewardTemplateDataService.getAllRewardTemplates();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const RewardTemplateSlice = createSlice({
  name: "rewardTemplate",
  initialState: {
    rewardTemplates: undefined,
    loading_getAllRewardTemplates: false,
  },
  reducers: {},
  extraReducers: {
    [getAllRewardTemplates.pending]: (state) => {
      state.loading_getAllRewardTemplates = true;
    },
    [getAllRewardTemplates.fulfilled]: (state, { payload }) => {
      state.loading_getAllRewardTemplates = false;
      state.rewardTemplates = payload;
      if (payload?.status !== 200 && payload?.title) {
        /* No action if status === 200, so we check for errors only */
        notify(0, payload.title);
      }
    },
  },
});

export default RewardTemplateSlice.reducer;
