import { React, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const EditLevelConfigForm = ({ item, label, editLevelConfig, deleteLevelConfig, modalConfig }) => {
  const [formValue, setformValue] = useState({
    levelId: item.levelId,
    boost: item.boost,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (label === "Edit") {
      formValue.boost = parseInt(formValue.boost);
      editLevelConfig(formValue);
    } else if (label === "Delete") {
      deleteLevelConfig(formValue.levelId);
    }
  };

  const handleChange = (e) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleReset = () => {
    modalConfig(false);
  };

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <Row>
        <Col md={6}>
          <h6>ID du niveau</h6>
          {formValue.levelId}
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          <h6>Boost du niveau (%)</h6>
          {label === "Edit" ? (
            <FormGroup>
              <Label for="boost">Boost (%)</Label>
              <Input
                type="text"
                name="boost"
                id="boost"
                autoComplete="off"
                placeholder="boost"
                required
                onChange={handleChange}
                defaultValue={formValue.boost}
              />
            </FormGroup>
          ) : (
            formValue.boost
          )}
        </Col>
      </Row>
      <br />
      <Row></Row>
      <br />
      {label === "Edit" ? (
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block>
              Modifier
            </Button>
          </Col>
        </Row>
      ) : (
        <Button color="danger" type="submit">
          Supprimer
        </Button>
      )}
    </Form>
  );
};

export default EditLevelConfigForm;
