import React, { useCallback } from "react";
import Enums from "../../../assets/JsonData/Enums";
import { convertDateToLocalTime, truncate } from "../../../assets/utils/UtilsFunctons";
import { Progress } from "reactstrap";
import "./digDaysPepActionCard.css";

const DigDaysPepActionCard = ({ pepAction }) => {
  const renderPepAxis = useCallback((axis) => {
    return (
      <div className="digday-pep-action-card-axis-title">
        <div>{truncate(axis?.title, 27)}</div>
      </div>
    );
  }, []);

  const renderPepDomain = useCallback((domain) => {
    return (
      <div className="digday-pep-action-card-domain-title">
        <div>{truncate(domain?.title, 27)}</div>
      </div>
    );
  }, []);

  const renderPepSkill = useCallback((skill) => {
    return (
      <div className="digday-pep-action-card-skill-title">
        <div>{truncate(skill?.title, 27)}</div>
      </div>
    );
  }, []);
  return (
    <div className="d-flex flex-column w-90 p-2 digday-pep-action-card">
      <div className="d-flex flex-column gap-1">
        <div className="d-flex justify-content-between gap-2">
          <div className="d-flex justify-content-start gap-2">
            <div>{renderPepAxis(pepAction?.axis)}</div>
            <div>{renderPepDomain(pepAction?.domain)}</div>
            <div>{renderPepSkill(pepAction?.skill)}</div>
          </div>
          <div>{Enums.matchPepActionStatus(pepAction?.status)}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>{truncate(pepAction?.title, 33)}</div>
          <div>{Enums.matchPepActionConfigLevelText(pepAction?.level)}</div>
        </div>
        <div className="d-flex justify-content-between align-items-baseline ">
          <div className="">{Enums.matchPepActionPriority(pepAction?.priority)}</div>
          <div className="mx-2 w-100">
            <Progress className="my-2" value={pepAction?.progress}>
              {pepAction?.progress}
            </Progress>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Date de début: {convertDateToLocalTime(pepAction?.startDate)}</div>
        {/* <div>1/2 DigDays, a completer une fois la donnée dispo</div> */}
      </div>
    </div>
  );
};

export default DigDaysPepActionCard;
