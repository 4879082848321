import sortBy from "lodash/sortBy";
class QuestConfigUtils {
  getExistingTheme = (questConfigs) => {
    let choices = [];
    if (questConfigs && questConfigs.data) {
      questConfigs.data.map((item) => {
        if (item.theme.length > 0 && !choices.some((theme) => theme.value === item.theme)) {
          choices.push({ value: item.theme, label: item.theme });
        }
      });
    }
    return sortBy(choices, "label");
  };

  getQuestFunctionOptions = (enumList) => {
    if (enumList && enumList.data) {
      let dataLength = Object.keys(enumList.data?.questFunction)?.length;
      let options = [];
      let i = 0;
      for (i = 0; i < dataLength; i++) {
        options.push({ value: i, label: enumList.data.questFunction[i] });
      }
      return options;
    }
    return [];
  };

  getExistingQuestConfigTitle = (questConfigs) => {
    let choices = [];

    if (questConfigs && questConfigs.data) {
      let orderedQuestConfigs = [...questConfigs.data];
      orderedQuestConfigs.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      orderedQuestConfigs.map((questConf) => {
        {
          choices.push({ value: questConf.questConfigId, label: questConf.title });
        }
      });
    }
    return choices;
  };
  getExistingQuestConfigByBlocId = (questConfigs, blocId) => {
    let choices = [];

    if (questConfigs && questConfigs.data) {
      let orderedQuestConfigs = questConfigs.data?.filter((questConfig) => questConfig.blocId === blocId);
      orderedQuestConfigs.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      orderedQuestConfigs.map((questConf) => {
        {
          choices.push({ value: questConf.questConfigId, label: questConf.title });
        }
      });
    }
    return choices;
  };

  getExistingBlocName = (blocs) => {
    let choices = [];

    if (blocs && blocs.data) {
      let orderedBlocs = [...blocs.data];
      orderedBlocs.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      orderedBlocs.map((bloc) => {
        {
          choices.push({ value: bloc.blocId, label: bloc.name });
        }
      });
    }
    return choices;
  };

  getQuestConfigUnlockedByGivenQuestId = (questConfigs, questId) => {
    if (questConfigs) {
      return questConfigs.find((questConfig) => questConfig.unlockedBy === questId);
    } else {
      return null;
    }
  };

  getBlocLastQuestConfig = (questConfigs, blocId) => {
    if (questConfigs && questConfigs.data && blocId) {
      let blocQuestConfigs = questConfigs?.data?.filter((questConfig) => questConfig.blocId === blocId);
      let lastElement = null;
      let currentQuestConfig = this.getQuestConfigUnlockedByGivenQuestId(blocQuestConfigs, null);
      while (currentQuestConfig) {
        lastElement = currentQuestConfig;
        currentQuestConfig = this.getQuestConfigUnlockedByGivenQuestId(blocQuestConfigs, currentQuestConfig.questConfigId);
      }
      return lastElement;
    }
    return null;
  };
}

export default new QuestConfigUtils();
