import { React, useCallback, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import GlobalFilter from "../sortAndSearchTable/GlobalFilter";
import TablePaginationFilter from "./TablePaginationFilter";
import { Table, Row, Col } from "reactstrap";
import "../table.css";
import { useSearchParams } from "react-router-dom";

import { filterTableItems, getFilters } from "../../../assets/utils/Table.helpers";

/* eslint react/jsx-key: "off" */
const TablePagination = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [Alldata] = useState(props.data);
  const [currentData, setCurrentData] = useState(Alldata);

  const data = useMemo(() => currentData, [currentData || searchParams]);
  const columns = useMemo(() => props.columns, [props.refresh || searchParams]);

  const filterUpdated = useCallback((filterConfiguration) => {
    const currentHash = window.location.hash;
    setSearchParams(filterConfiguration);
    if (currentHash && currentHash.length > 1) {
      window.location.hash = currentHash;
    }
  }, []);

  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, props.tableHooks, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setCurrentData(filterTableItems(getFilters(searchParams), Alldata));
  }, [searchParams]);

  return (
    <div>
      {props.enableFilter && (
        <div className="pt-3 pb-2 ">
          <Row>
            <Col md="10">{props.type === "default" && <TablePaginationFilter rows={Alldata} onFilter={filterUpdated} options={props.children} />}</Col>
            <Col md="2" className="align-self-end">
              <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter}></GlobalFilter>
            </Col>
          </Row>
        </div>
      )}
      <Table {...getTableProps()} striped responsive hover size="md">
        <thead className="table-secondary">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-nowrap">
                  {column.render("Header")}
                  {column.isSorted ? column.isSortedDesc ? <i className=" bx bx-sort-down">&nbsp;</i> : <i className=" bx bx-sort-up">&nbsp;</i> : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="align-middle">
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onDoubleClick={() => props.handleRowClicked(row)} className={props.noClickableRow ? "" : "clickableRow"}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="table-limited-td text-truncate" title={cell?.value}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {data?.length === 0 && <div>Aucune entrée à afficher</div>}
      <Pagination className="pt-2">
        <PaginationItem>
          <PaginationLink first onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink previous onClick={() => previousPage()} disabled={!canPreviousPage} />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink disabled>-</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink next onClick={() => nextPage()} disabled={!canNextPage} />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
        </PaginationItem>
        <div>
          <span className="py-1 px-2">
            Page{" "}
            <strong>
              {pageIndex + 1} / {pageOptions.length}
            </strong>{" "}
          </span>
          <select
            className="p-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} lignes
              </option>
            ))}
          </select>
        </div>
      </Pagination>
    </div>
  );
};

export default TablePagination;
