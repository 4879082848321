import { React, useEffect } from "react";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import { useDispatch, useSelector } from "react-redux";
import AddNewMissionModal from "../components/missionSheet/AddNewMissionModal";
import { getMissionsList, addNewMission } from "../redux/slices/MissionSheetSlice";
import { MissionSheetListTable } from "../components/missionSheet/MissionSheetListTable";
import { v4 as uuid } from "uuid";
import { formatToISOString } from "../assets/utils/UtilsFunctons";
import Loader from "../components/loader/Loader";
import { Toaster } from "react-hot-toast";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import useUserRole from "../hooks/useUserRole";

const MissionSheetPage = () => {
  const { isAdmin, hasAccessAsDeveloper, isManager } = useUserRole();
  const dispatch = useDispatch();
  const { loading_getMissionsList, missionsList, refreshMissionPage } = useSelector((state) => ({ ...state.missionSheet }));

  const handleAddNewMission = (formValue) => {
    formValue.missionId = uuid();
    formValue.startDate = formatToISOString(formValue.startDate);
    dispatch(addNewMission(formValue));
  };

  useEffect(() => {
    dispatch(getMissionsList());
  }, [refreshMissionPage]);

  return (
    <div>
      <Toaster></Toaster>
      <ConfirmContextProvider>
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="page-header">Liste des fiches mission</h2>
          </div>
          <div>{(isAdmin || hasAccessAsDeveloper || isManager) && <AddNewMissionModal handleAddNewMission={handleAddNewMission}></AddNewMissionModal>}</div>
        </div>

        {loading_getMissionsList ? (
          <Loader></Loader>
        ) : (
          <div>
            {missionsList?.data && missionsList?.status === 200 ? (
              <div className="myCard">
                <MissionSheetListTable></MissionSheetListTable>
              </div>
            ) : (
              <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
            )}
          </div>
        )}
      </ConfirmContextProvider>
    </div>
  );
};

export default MissionSheetPage;
