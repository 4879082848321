import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BlocConfigDataService from "../../services/BlocConfigDataService";
import toast from "react-hot-toast";

const notify = (choix) => {
  switch (choix) {
    case 10:
      toast.success(" ok !", { position: "buttom-right" });
      break;
    case 20:
      toast.success(" Données enregistrées avec succes !", { position: "top-right" });
      break;
    case 30:
      toast.success(" Mise à jour avec succes !", { position: "top-right" });
      break;
    case 40:
      toast.success(" Suppression avec succes !", { position: "top-right" });
      break;
    case 21:
      toast.error(" La requête a échoué !", { position: "top-right" });
      break;
    //case 41: toast.error(" La requête a échoué !",{position: 'top-right'});break;
    default:
      toast.error(" La récuperation des données a échoué !");
      break;
  } //! don't forget you can add a text message to your notification
};

export const getAllBlocConfigs = createAsyncThunk("BlocConfigs/getAllBlocConfigs", async () => {
  try {
    const response = await BlocConfigDataService.getAllBlocConfigs();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const addNewBlocConfigs = createAsyncThunk("bloc/addNewBlocConfig", async (data) => {
  try {
    const response = await BlocConfigDataService.addNewBlocConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const updateBlocConfigs = createAsyncThunk("bloc/updateBlocConfigs", async (data) => {
  try {
    const response = await BlocConfigDataService.updateBlocConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const deleteBlocConfigs = createAsyncThunk("bloc/deleteBlocConfigs", async (data) => {
  try {
    const response = await BlocConfigDataService.deleteBlocConfigs(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const BlocConfigSlice = createSlice({
  name: "blocConfigs",
  initialState: {
    blocConfigs: null,
    loading_getBlocConfigs: true,
    loading_addBlocConfigs: true,
    refreshPage: false,
    dataPost: [],
  },
  reducers: {},
  extraReducers: {
    [getAllBlocConfigs.pending]: (state) => {
      state.loading_getBlocConfigs = true;
      state.refreshPage = false;
      console.log("pending");
    },
    [getAllBlocConfigs.fulfilled]: (state, { payload }) => {
      state.loading_getBlocConfigs = false;
      state.refreshPage = false;
      state.blocConfigs = payload;
    },

    [addNewBlocConfigs.pending]: (state) => {
      state.loading_addBlocConfigs = true;
      state.refreshPage = false;
      console.log("pending");
    },
    [addNewBlocConfigs.fulfilled]: (state, { payload }) => {
      state.loading_addBlocConfigs = false;
      state.dataPost = payload;
      if (state.dataPost.status === 200 && state.dataPost.statusText === "OK") {
        notify(20);
        state.refreshPage = true;
      }
    },

    [updateBlocConfigs.pending]: (state) => {
      state.refreshPage = false;
      console.log("pending");
    },
    [updateBlocConfigs.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200 && state.dataPost.statusText === "OK") {
        notify(30);
        state.refreshPage = true;
      }
    },

    [deleteBlocConfigs.pending]: (state) => {
      state.refreshPage = false;
      console.log("pending");
    },
    [deleteBlocConfigs.fulfilled]: (state, { payload }) => {
      state.dataPost = payload;
      if (state.dataPost.status === 200 && state.dataPost.statusText === "OK") {
        notify(40);
        state.refreshPage = true;
      }
    },
  },
});

export default BlocConfigSlice.reducer;
