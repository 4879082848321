import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseModalWithContent from "../useModalWithContent/UseModalWithContent";
import UserDataService from "../../services/UserDataService";
import { getUserCoinVariations, getUserScoreVariations } from "../../redux/slices/UserSlice";
import { convertDateToLocalTimeWithHour } from "../../assets/utils/UtilsFunctons";
import TablePagination from "../../components/table/table-pagination/TablePagination";
import AddManualUserGainForm from "./AddManualUserGainForm";
import { notify } from "../../assets/utils/UtilsFunctons";
import sortBy from "lodash/sortBy";

const UserCoinAndScoreVariations = () => {
  const [userGains, setUserGains] = useState();
  const { loading_userScoreVariations, loading_userCoinVariations, userCoinVariations, userScoreVariations, currentUserInfos } = useSelector((state) => ({
    ...state.userInfos,
  }));
  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Date ",
      accessor: "date",
      Cell: ({ value }) => {
        return value && convertDateToLocalTimeWithHour(value);
      },
    },
    {
      Header: "Expédition",
      accessor: "seasonTitle",
    },
    {
      Header: "événement",
      accessor: "eventType",
    },
    {
      Header: "Détail",
      accessor: "message",
    },
    {
      Header: "Type de gain",
      accessor: "type",
    },
    {
      Header: "Montant",
      accessor: "variation",
    },
    {
      Header: "Solde",
      accessor: "balance",
    },
  ];
  const tableHooks = () => {
    "";
  };
  const handleRowClicked = () => {
    "";
  };

  const getAllData = () => {
    if (userCoinVariations && userCoinVariations.data && userScoreVariations && userScoreVariations.data) {
      return [
        ...userCoinVariations.data.coinVariations.map((coin) => ({
          type: "Or",
          seasonTitle: "InterExpédition",
          variation: coin.coinVariation,
          balance: coin.coinBalance,
          ...coin,
        })),
        ...userScoreVariations.data.scoreVariations.map((score) => ({
          type: "Cristaux",
          variation: score.scoreVariation,
          balance: score.scoreBalance,
          ...score,
        })),
      ];
    }
    if (userCoinVariations && userCoinVariations.data) {
      return userCoinVariations.data;
    }
    if (userScoreVariations && userScoreVariations.data) {
      return userScoreVariations.data;
    }
    return [];
  };

  const handleUserGainUpdate = async (formValue) => {
    try {
      if (formValue) {
        let newCoinsEntry = null;
        let newScoreEntry = null;

        if (formValue.coinsAmount) {
          let payload = {
            userId: currentUserInfos?.userId,
            coinsAmount: formValue.coinsAmount,
            message: formValue.message,
          };

          let resp = await UserDataService.updateUserCoins(payload);
          if (resp && resp.status === 200 && resp.data) {
            let data = resp.data;
            newCoinsEntry = {
              balance: data.coinBalance,
              coinBalance: data.coinBalance,
              coinVariation: data.coinVariation,
              date: data.date,
              eventType: data.eventType,
              message: data.message,
              seasonTitle: "InterExpédition",
              type: "Or",
              variation: data.coinVariation,
            };
          }
        }
        if (formValue.scoreAmount) {
          let payload = {
            userId: currentUserInfos?.userId,
            scoreAmount: formValue.scoreAmount,
            message: formValue.message,
          };
          let resp = await UserDataService.updateUserScore(payload);
          if (resp && resp.status === 200 && resp.data) {
            let data = resp.data;
            newScoreEntry = {
              balance: data.scoreBalance,
              scoreBalance: data.scoreBalance,
              scoreVariation: data.scoreVariation,
              date: data.date,
              eventType: data.eventType,
              message: data.message,
              seasonTitle: data.seasonTitle,
              type: "Cristaux",
              variation: data.scoreVariation,
            };
          }
        }
        notify(1, `Modification apportée avec succès !`);

        // updating data
        if (newCoinsEntry && newScoreEntry) {
          setUserGains((prevUserGains) => sortBy([...prevUserGains, newCoinsEntry, newScoreEntry], "date").reverse());
        } else {
          if (newCoinsEntry) {
            setUserGains((prevUserGains) => sortBy([...prevUserGains, newCoinsEntry], "date").reverse());
          } else if (newScoreEntry) {
            setUserGains((prevUserGains) => sortBy([...prevUserGains, newScoreEntry], "date").reverse());
          }
        }
      }
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    dispatch(getUserCoinVariations(currentUserInfos?.userId));
    dispatch(getUserScoreVariations(currentUserInfos?.userId));
  }, [userGains, currentUserInfos]);

  const getAllUserGains = () => {
    const sortedArray = sortBy(getAllData(), "date").reverse();
    setUserGains(sortedArray);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const { closeModal, ModalComponent, ModalButton } = UseModalWithContent(
    <AddManualUserGainForm handleUserGainUpdate={handleUserGainUpdate} userInfos={currentUserInfos} handleCloseModal={handleCloseModal} />,
    "Ajout d'une correction manuelle de gains"
  );

  return (
    <>
      {loading_userScoreVariations || loading_userCoinVariations ? (
        "chargement ..."
      ) : userCoinVariations && userScoreVariations ? (
        <div className="test-table-pagination text-center">
          <div className="mt-1 d-flex justify-content-end fs-2">
            <ModalButton buttonText="" buttonColor="success" icon={"bx bx-plus-circle"} />
            <ModalComponent />
          </div>
          {userGains ? (
            <TablePagination
              data={userGains}
              columns={columns}
              tableHooks={tableHooks}
              refresh={userGains}
              handleRowClicked={handleRowClicked}
              enableFilter={true}
              type={"default"}
            >
              <element key="seasonTitle" filterKey="seasonTitle" className="cell" casesensitive={"true"} showsearch={"true"}>
                Expédition
              </element>
              <element key="type" filterKey="type" className="cell" casesensitive={"true"} showsearch={"true"}>
                Type de gain
              </element>
            </TablePagination>
          ) : (
            getAllUserGains()
          )}
        </div>
      ) : (
        "erreur"
      )}
    </>
  );
};

export default UserCoinAndScoreVariations;
