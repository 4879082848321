import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTeamName, postTeamPicture } from "../../redux/slices/SeasonSlice";
import { Button, Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Input, Label } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
export const UpdateTeamInfos = ({ data, seasonType }) => {
  const [modal, setModal] = useState(false);
  const [formType, setFormType] = useState(null);
  const [newTeamName, setNewTeamName] = useState("");
  const [pictureData, setPictureData] = useState(null);
  const dispatch = useDispatch();

  const toggle = () => {
    setModal(!modal);
    setFormType(null);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <button type="button" className="btn" onClick={toggle}>
      modifier
    </button>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formType === "updateTeamName") {
      dispatch(
        updateTeamName({
          teamId: data.teamId,
          newName: newTeamName,
        })
      );
    }
    if (formType === "updateTeamImage") {
      dispatch(
        postTeamPicture({
          teamId: data.teamId,
          pictureData: pictureData,
        })
      );
    }
    setModal(false);
  };
  const handleReset = () => {
    setModal(false);
  };

  return (
    <div>
      {seasonType === 1 && myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Modifier les infos de l'équipe
        </ModalHeader>
        <ModalBody>
          {!formType ? (
            <div className="d-grid gap-2 col-6 mx-auto">
              <button className="btn btn-primary" type="button" onClick={() => setFormType("updateTeamName")}>
                Nom de l'équipe
              </button>
              <button className="btn btn-primary" type="button" onClick={() => setFormType("updateTeamImage")}>
                Image
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              {formType === "updateTeamName" && (
                <div className="d-grid gap-2 col-9 py-3 mx-auto">
                  <FormGroup floating>
                    <h6>Ancien nom : </h6>
                    {data.name}
                    {/* <Input type="text" name="name" id="name" required placeholder="Nom Bloc" autoComplete="off" defaultValue={data.name} readOnly />
										<Label for="name">Ancien nom</Label> */}
                  </FormGroup>
                  <FormGroup floating>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => setNewTeamName(e.target.value)}
                      required
                      placeholder="Nom Bloc"
                      autoComplete="off"
                    />
                    <Label for="name">Nouveau nom</Label>
                  </FormGroup>
                </div>
              )}
              {formType === "updateTeamImage" && (
                <div className=" py-3">
                  <FormGroup className="mb-4">
                    <Label for="exampleFile">Image</Label>
                    <Input
                      id="exampleFile"
                      name="file"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => setPictureData(e.target.files[0])}
                      required
                    />
                  </FormGroup>
                </div>
              )}
              <Row>
                <Col md={6}>
                  <Button color="danger" type="reset" block>
                    Annuler
                  </Button>
                </Col>
                <Col md={6}>
                  <Button color="success" type="submit" block>
                    Ajouter
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateTeamInfos;
