import React from "react";
import { formatDateUtcToLocalTime } from "../../assets/utils/UtilsFunctons";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import "./topnav.css";
import user_image from "../../assets/images/profile.png";
import { Badge } from "reactstrap";

const Topnav = () => {
  const { instance, accounts } = useMsal();
  const { currentMaintenance } = useSelector((state) => ({ ...state.maintenanceConfig }));

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });

    localStorage.clear();
  };

  const curr_user = {
    display_name: accounts[0]?.name,
    image: user_image,
  };

  const renderUserToggle = (user) => (
    <button onClick={handleLogout} className="topnav__right-user logout_button" data-toggle="tooltip" data-placement="bottom" title="Se déconnecter">
      <div className="topnav__right-user__image">
        <i className="bx bx-power-off fs-4"></i>
      </div>
      <div className="topnav__right-user__name">{user.display_name}</div>
    </button>
  );

  return (
    <div className="topnav">
      {currentMaintenance?.data?.isMaintenanceRunning && (
        <div className="mx-3">
          <Badge color="danger">
            Maintenance en cours -
            {currentMaintenance?.data?.estimatedEnd && <span> Reprise estimée vers : {formatDateUtcToLocalTime(currentMaintenance?.data.estimatedEnd)}</span>}
          </Badge>
        </div>
      )}
      <div className=" top_nav_container">
        <div>
          <div className="topnav__right-item ">{renderUserToggle(curr_user)}</div>
          {/* this part handle the notification about the application
         <div className="topnav__right-item">
          <Dropdown
            icon="bx bx-bell"
            badge="12"
            contentData={notifications}
            renderItems={(item, index) => renderNotificationItem(item, index)}
            renderFooter={() => <Link to="/NotificationPage">View All</Link>}
          />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Topnav;
