import { React, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { truncate, convertDateToLocalTime } from "../../assets/utils/UtilsFunctons";
import { Badge } from "reactstrap";
import Enums from "../../assets/JsonData/Enums";

const MissionInfosCard = ({ mission, setSelectedMission, selectedMissionId }) => {
  const navigate = useNavigate();

  const handleSeeAllUserMissionSheets = () => {
    navigate("/MissionSheetDetailPage/" + mission?.missionId);
  };

  const getLastMissionSheet = useCallback(() => {
    if (mission?.missionSheets?.length === 0) {
      return null;
    }

    return mission?.missionSheets?.reduce((latestMissionSheet, currentMissionSheet) => {
      const latestVersion = parseInt(latestMissionSheet.versionNumber);
      const currentVersion = parseInt(currentMissionSheet.versionNumber);

      return currentVersion > latestVersion ? currentMissionSheet : latestMissionSheet;
    });
  }, []);

  return (
    <div>
      <div
        style={{
          maxWidth: "352px",
          minWidth: "315px",
          maxHeight: "200px",
          border: mission.missionId === selectedMissionId ? "2px solid #2F528F" : " 0.001px solid grey",
        }}
        className="list-group-item rounded-3 p-3"
        onClick={() => setSelectedMission(mission?.missionId)}
      >
        <div className="d-flex justify-content-between">
          <div>
            <strong>{truncate(mission.clientCompany, 65)}</strong>
          </div>

          <div className="d-flex align-items-end flex-column">
            <div className="pb-1">
              <Badge color={!mission.endDate ? "success" : "secondary"}>{mission.endDate ? "Terminé" : "En cours"}</Badge>
            </div>
            <div>
              <i class="bx bx-file-blank"></i>

              {Enums.matchMissionSheetState(getLastMissionSheet()?.state)}
            </div>
          </div>
        </div>

        <div> {truncate(mission.consultantRole, 113)}</div>

        <div className="d-flex justify-content-between pt-1">
          <div style={{ fontSize: "14px" }}>
            <i className="bx bx-calendar-alt"></i>&nbsp;
            <span>
              {convertDateToLocalTime(mission.startDate)}
              <span>&nbsp;-&nbsp;</span>
              {mission.endDate ? convertDateToLocalTime(mission.endDate) : "..."}
            </span>
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleSeeAllUserMissionSheets}>
            <i className="bx bx-link-external"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionInfosCard;
