import React from "react";
import { Badge } from "reactstrap";
class Enums {
  matchClass(questSeasonClass) {
    switch (questSeasonClass) {
      case 0:
        return "Commun";
      case 1:
        return "Explorateur";
      case 2:
        return "Guide";
      case 3:
        return "Directeur";
      default:
        return "Personnel";
    }
  }

  matchSeasonType(seasonType) {
    switch (seasonType) {
      case 0:
        return "Passée";
      case 1:
        return "Courante";
      case 2:
        return "Future";
      default:
        return "Permanente";
    }
  }
  matchQuestStatut(questStatut) {
    switch (questStatut) {
      case 0:
        return "En cours";
      case 1:
        return "A valider";
      case 2:
        return "Ajustement";
      case 3:
        return "Validé";
      case 4:
        return "Fermé";
      default:
        return "";
    }
  }
  matchTransactionState(transactionState) {
    switch (transactionState) {
      case 0:
        return <Badge color="primary">En traitement</Badge>;
      case 1:
        return <Badge color="warning">En livraison</Badge>;
      case 2:
        return <Badge color="success">Livré</Badge>;
      case 3:
        return <Badge color="danger">Annulé</Badge>;
      default:
        return <Badge>Indéfini</Badge>;
    }
  }

  matchMissionSheetState(missionSheetState) {
    switch (missionSheetState) {
      case 0:
        return (
          <Badge className="text-dark" color="light">
            Modifiable
          </Badge>
        );
      case 1:
        return <Badge color="secondary">Prêt à être soumise</Badge>;
      case 2:
        return <Badge color="primary">En validation</Badge>;
      case 3:
        return <Badge color="warning">En attente d&apos;ajustement</Badge>;
      case 4:
        return <Badge color="success">Validée</Badge>;
      case 5:
        return <Badge color="danger">Non soumise</Badge>;
      default:
        return <Badge color="secondary">Indéfinie</Badge>;
    }
  }
  matchDigDayState(digDayState) {
    switch (digDayState) {
      case 0:
        return <Badge color="secondary">Planifié</Badge>;
      case 1:
        return <Badge color="warning">A valider (App)</Badge>;
      case 2:
        return <Badge color="primary">En validation (BO)</Badge>;
      case 3:
        return <Badge color="warning">En ajustement</Badge>;
      case 4:
        return <Badge color="success">Validé</Badge>;
      case 5:
        return <Badge color="danger">Annulé</Badge>;
      default:
        return "";
    }
  }

  matchQuestStateWithBadge(questState) {
    switch (questState) {
      case 0:
        return <Badge color="secondary">En cours</Badge>;
      case 1:
        return <Badge color="primary">A valider</Badge>;
      case 2:
        return <Badge color="warning">Ajustement</Badge>;
      case 3:
        return <Badge color="success">Validé</Badge>;
      case 4:
        return <Badge color="danger">Fermé</Badge>;

      default:
        return "";
    }
  }

  matchRoleType(role) {
    switch (role) {
      case null:
        return "Tous";
      case 0:
        return "Tous";
      case 1:
        return "Consultant";
      case 2:
        return "Guide";
      case 3:
        return "Directeur";
      case 4:
        return "Personnel";
      default:
        return "";
    }
  }
  matchCategoryType(category) {
    switch (category) {
      case 0:
        return "Action";
      case 1:
        return "Carrière";
      case 2:
        return "Evénement";
      default:
        return "";
    }
  }

  matchPepActionConfigLevel(level) {
    switch (level) {
      case 1:
        return (
          <Badge className="text-dark" color="info">
            Débutant
          </Badge>
        );
      case 2:
        return (
          <Badge className="text-dark" color="info">
            Intermédiaire
          </Badge>
        );
      case 3:
        return (
          <Badge className="text-dark" color="info">
            Confirmé
          </Badge>
        );
    }
  }
  matchPepActionConfigLevelText(level) {
    switch (level) {
      case 1:
        return "Débutant";
      case 2:
        return "Intermédiaire";
      case 3:
        return "Confirmé";
    }
  }

  matchPepActionPriority(priority) {
    switch (priority) {
      case 0:
        return <i className="bx bx-chevron-down fs-4 text-primary " />;
      case 1:
        return <i className="bx bx-minus fs-5 text-warning"></i>;
      case 2:
        return <i className="bx bx-chevron-up fs-4 text-danger " />;
    }
  }

  matchPepActionStatus(status) {
    switch (status) {
      case 0:
        return <Badge color="primary">En cours</Badge>;
      case 1:
        return <Badge color="success">Terminée</Badge>;
      case 2:
        return <Badge color="danger">Annulée</Badge>;
    }
  }

  getIscheckedIcon(isVisible) {
    return isVisible ? <i className="bx bx-checkbox-checked fs-3" /> : <i className="bx bx-checkbox fs-3"></i>;
  }
  /* options list*/
  getQuestConfigCategoryOptions(choice) {
    const options = [
      { value: 0, label: "Action" },
      { value: 1, label: "Carrière" },
      { value: 2, label: "Evénement" },
    ];
    if (choice != null) {
      return options[choice].label;
    }
    return options;
  }

  getQuestConfigValidationTypeOptions() {
    const options = [
      { value: true, label: "Manuelle" },
      { value: false, label: "Automatique" },
    ];

    return options;
  }

  getYesOrNoOptions() {
    const options = [
      { value: true, label: "Oui" },
      { value: false, label: "Non" },
    ];

    return options;
  }
}

export default new Enums();
