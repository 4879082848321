import { React, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { v4 as uuid } from "uuid";
import { getFileTypeFromFileName } from "../../assets/utils/UtilsFunctons";
import RewardTemplateCard from "../rewardTemplate/RewardTemplateCard";
import { Button, Form, FormGroup, Label, Input, Row, Col, CloseButton } from "reactstrap";

const AddNewQuizForm = ({ handleAddNewQuizConfig, handleCloseModal }) => {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const { rewardTemplates } = useSelector((state) => ({ ...state.rewardTemplate }));
  const [currentRewardTemplate, setCurrentRewardTemplate] = useState(null);
  const [mediaInputKey, setMediaInputKey] = useState(0);
  const [preview, setPreview] = useState(false);
  const [formValue, setformValue] = useState({
    quizConfigId: uuid(),
    title: "",
    theme: "",
    rewardTemplateId: "",
    mediaUrl: "",
  });

  const rewardTemplateListOption = () => {
    let list = [];

    if (rewardTemplates && rewardTemplates.data) {
      rewardTemplates.data.map((rewardTemplate) => {
        if (!rewardTemplate.archived) {
          list.push({ value: rewardTemplate.rewardTemplateId, label: rewardTemplate.name });
        }
      });
    }
    return list;
  };

  const handleSubmit = async (event) => {
    setSubmitButtonDisabled(true);
    event.preventDefault();
    handleAddNewQuizConfig(formValue);
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setformValue({
      ...formValue,
      mediaUrl: file,
    });

    const fileFormat = getFileTypeFromFileName(file);
    if (fileFormat !== "Inconnu") {
      if (file.type.match("image/*")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          setPreview(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });

    if (event.name === "rewardTemplateId" && rewardTemplates && rewardTemplates.data && choice?.value) {
      let reward = rewardTemplates.data.filter((item) => item.rewardTemplateId === choice?.value);
      setCurrentRewardTemplate(reward ? reward[0] : undefined);
    }
    if (event.name === "rewardTemplateId" && !choice?.value) {
      setCurrentRewardTemplate(undefined);
    }
  };

  const handleReset = () => {
    handleCloseModal();
  };

  const handleRemoveMedia = () => {
    setformValue({
      ...formValue,
      mediaUrl: null,
    });
    setPreview(null);
    setMediaInputKey((prevKey) => prevKey + 1);
  };

  let getThemeOptions = () => {
    return JSON.parse(localStorage.getItem("quizThemeOptions"));
  };

  return (
    <Row>
      <Col md={6}>
        <Form onSubmit={handleSubmit} onReset={handleReset} className="myCard">
          <FormGroup>
            <Label for="digDaysCurrentStock">
              Titre <sup>*</sup>
            </Label>
            <Input type="text" name="title" id="title" onChange={handleChange} required placeholder="titre du quiz" autoComplete="off" />
          </FormGroup>

          <FormGroup>
            <Label for="digDaysCurrentStock">
              Thème <sup>*</sup>
            </Label>
            <CreatableSelect
              name="theme"
              isClearable
              isSearchable
              options={getThemeOptions()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
              formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
              placeholder="Thème ..."
            />
          </FormGroup>
          <FormGroup>
            <Label for="digDaysCurrentStock">
              Modèle de gain <sup>*</sup>
            </Label>
            <Select
              name="rewardTemplateId"
              required
              isClearable
              isSearchable
              options={rewardTemplateListOption()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
            />
          </FormGroup>

          <FormGroup>
            <Label for="mediaUrl">Media du quiz &nbsp;</Label>
            {formValue.mediaUrl ? (
              <span data-toggle="tooltip" data-placement="top">
                <CloseButton
                  title="Supprimer le média existant"
                  onClick={() => {
                    handleRemoveMedia();
                  }}
                />
              </span>
            ) : (
              ""
            )}
            <Input
              type="file"
              name="mediaUrl"
              id="mediaUrl"
              onChange={handleFileInputChange}
              autoComplete="off"
              key={mediaInputKey}
              accept=".jpg, .jpeg, .png, .gif, .webp"
            />
          </FormGroup>
          <div>
            {preview && (
              <div>
                <img src={preview} alt="Aperçu du fichier" className="quiz-img-preview" />
              </div>
            )}
          </div>

          <Row className="mt-2">
            <Col md={6}>
              <Button color="danger" type="reset" block>
                Annuler
              </Button>
            </Col>
            <Col md={6}>
              <Button color="success" type="submit" block disabled={submitButtonDisabled}>
                {submitButtonDisabled ? (
                  <span>
                    Ajouter <i className="bx bx-loader-alt bx-spin"></i>
                  </span>
                ) : (
                  <span> Ajouter</span>
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
      {currentRewardTemplate && (
        <Col md={6}>
          <RewardTemplateCard reward={currentRewardTemplate}></RewardTemplateCard>
        </Col>
      )}
    </Row>
  );
};

export default AddNewQuizForm;
