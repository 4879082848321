import { jwtDecode } from "jwt-decode";
import config from "../config/config";
import { ENV_NAME } from "../core/constant/EnvName";

const useUserRole = () => {
  let isAdmin = false;
  let isValidator = false;
  let hasAccessAsDeveloper = false;
  let isReadOnly = false;
  let isManager = false;
  let haveAccessToBo = false;

  const token = localStorage.getItem("token");
  const decoded = (token !== "null") & (token !== "undefined") && token ? jwtDecode(token) : null;

  if (decoded) {
    isAdmin = decoded?.roles.includes("admin");
    isValidator = decoded?.roles.includes("validator");
    hasAccessAsDeveloper = decoded?.roles.includes("developer") && config.env !== ENV_NAME.prod && config.env !== ENV_NAME.prod.toLowerCase();
    isReadOnly = decoded?.roles.includes("actionsReadOnly");
    isManager = decoded?.roles.includes("operationalManager");
    haveAccessToBo = isAdmin || isValidator || isManager || hasAccessAsDeveloper || isReadOnly;
  }

  return { isAdmin, isValidator, hasAccessAsDeveloper, isReadOnly, haveAccessToBo, isManager };
};

export default useUserRole;
