import toast from "react-hot-toast";

export const convertDateToLocalTime = (date) => {
  return date ? new Date(date)?.toLocaleDateString("fr") : "-";
};
export const convertDateToLocalTimeWithHour = (date) => {
  return date ? new Date(date)?.toLocaleString("fr") : "-";
};

export const formatDateUtcToLocalTime = (inputDate) => {
  if (inputDate) {
    let dateLocal = new Date(inputDate).toLocaleString("fr");
    const dateObj = dateLocal.split(/[/ :]/);

    const day = dateObj[0];
    const month = dateObj[1]; // Mois (0-11, donc +1)
    const year = dateObj[2];
    const hours = dateObj[3];
    const minutes = dateObj[4];
    const seconds = dateObj[5];

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  } else return "-";
};
export const getHourFromUtcDate = (inputDate) => {
  if (inputDate) {
    let dateLocal = new Date(inputDate).toLocaleString("fr");
    const dateObj = dateLocal.split(/[/ :]/);

    const hours = dateObj[3];
    const minutes = dateObj[4];
    const seconds = dateObj[5];

    return `${hours}:${minutes}:${seconds}`;
  } else return "-";
};

export const formatDateUtcToLocalTimeUtcFormat = (inputDate) => {
  if (inputDate) {
    let dateLocal = new Date(inputDate).toLocaleString("fr");
    const dateObj = dateLocal.split(/[/ :]/);

    const day = dateObj[0];
    const month = dateObj[1]; // Mois (0-11, donc +1)
    const year = dateObj[2];
    const hours = dateObj[3];
    const minutes = dateObj[4];
    const seconds = dateObj[5];

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  } else return "-";
};

// format localTime date to UTC format time
export const formatToISOString = (dateTime) => {
  const date = new Date(dateTime);
  return date.toISOString();
};

export const compareDates = (date1, date2) => {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  if (parsedDate1 > parsedDate2) {
    return true; // date1 est postérieure à date2
  } else if (parsedDate1 < parsedDate2) {
    return false; // date1 est antérieure à date2
  } else {
    return false; // Les deux dates sont identiques
  }
};

export const daysBetweenTwoDates = (date1, date2) => {
  let d1 = new Date(date1).getTime();
  let d2 = new Date(date2).getTime();

  let differenceMs = Math.abs(d1 - d2);

  var differenceDays = Math.round(differenceMs / (1000 * 60 * 60 * 24));

  return differenceDays;
};

export const notify = (state, text) => {
  switch (state) {
    case 1:
      toast.success(text, { position: "top-right" });
      break;
    case 0:
      toast.error(text, { position: "top-right" });
      break;
  }
};

export const truncate = (str, maxlength) => {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + "…" : str;
};

export const isQuizQuestionCorrectlyFilled = (questionConfig) => {
  let correctProposal =
    questionConfig.questionType === 0 || questionConfig.questionType === 1
      ? questionConfig.answerConfigs?.filter((question) => question.isCorrect === true)
      : 1;
  let numberOfAnswer = questionConfig.answerConfigs?.length;
  let numberOfCorrectAnswer = correctProposal ? correctProposal.length : 0;
  let timeLimit = questionConfig.timeLimit ? parseInt(questionConfig.timeLimit) : 0;

  // Vérifier qu'il y a une bonne réponse associée
  if ((questionConfig.questionType === 0 || questionConfig.questionType === 1) && numberOfCorrectAnswer === 0) {
    return 0;
  }

  // si nombre ou string une et seule prosition correcte + min proposition 1 + max propositions 1
  if ((questionConfig.questionType === 2 || questionConfig.questionType === 3) && numberOfAnswer === 0) {
    return 0;
  }

  // pour les QCU et QCM on a min proposition = 2 et max proposition = 4
  if ((questionConfig.questionType === 0 || questionConfig.questionType === 1) && (numberOfAnswer < 2 || numberOfAnswer > 4)) {
    return 0;
  }

  // si QCU alors une et une seule proposition de réponse correcte + au moins 2 propositions + max propositions 4
  if (questionConfig.questionType === 0 && numberOfCorrectAnswer !== 1) {
    return 0;
  }

  // si QCM alors au moins une proposition de réponse correcte + au moins 2 propositions + max propositions 4
  if (questionConfig.questionType === 1 && numberOfCorrectAnswer < 0) {
    return 0;
  }

  if (timeLimit < 5) {
    return 0;
  }
  if (questionConfig.text.length < 3) {
    return 0;
  }

  // si tout est correctement remplie

  return 1;
};

export const generateTextAvatar = (initials, size = 100, bgColor = "#007BFF", textColor = "#FFFFFF") => {
  const canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d");
  // Dessiner le fond
  context.fillStyle = bgColor;
  context.fillRect(0, 0, size, size);
  // Dessiner les initiales
  context.font = `${size / 2}px Arial`;
  context.fillStyle = textColor;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(initials, size / 2, size / 2);

  return canvas.toDataURL();
};

export const getFileTypeFromFileName = (file) => {
  const fileExtension = file.name?.split(".").pop().toLowerCase();
  const fileTypeMappings = {
    pdf: "PDF",
    jpg: "Image",
    jpeg: "Image",
    png: "Image",
    gif: "Image",
    doc: "Word",
    docx: "Word",
    mp4: "Video",
    avi: "Video",
    mov: "Video",
    xlsx: "Excel",
    csv: "CSV",
    webp: "Image",
    mp3: "Audio",
  };
  const fileType = fileTypeMappings[fileExtension ?? ""];
  return fileType || "Inconnu";
};

export const compareObjectArray = (tab1, tab2) => {
  // Vérifier si les tableaux ont la même longueur
  if (tab1?.length !== tab2?.length) {
    return false;
  }

  // Parcourir les objets dans le premier tableau
  for (let i = 0; i < tab1?.length; i++) {
    // Vérifier si les propriétés des objets sont égales dans les deux tableaux
    for (let prop in tab1[i]) {
      if (tab1[i][prop] !== tab2[i][prop]) {
        return false; // Les objets sont différents
      }
    }
  }

  return true; // Les tableaux sont identiques
};

// Fonction pour comparer deux tableaux d'objets et récupérer les différences ou les éléments ajoutés
export const getAddedOrChangedValues = (tab1, tab2) => {
  let result = [];
  // Parcourir les objets dans tab2
  for (let obj2 of tab2) {
    let found = false;
    // Vérifier si l'objet existe dans tab1
    for (let obj1 of tab1) {
      if (obj1 === obj2) {
        found = true;
        break;
      }
    }
    // Si l'objet n'est pas trouvé dans tab1, l'ajouter aux résultats
    if (!found) {
      result.push(obj2);
    }
  }
  return result;
};
