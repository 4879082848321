import { React, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { FormGroup, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";

/* eslint react/no-unescaped-entities: off */
const UpdateUserGeneralInfo = ({ currentUser, updateUser, currentSeason, setCurrentUserName }) => {
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { isConfirmed } = useConfirm();
  const [formValue, setformValue] = useState({
    userId: currentUser.userId,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.email.substring(0, currentUser.email.indexOf("@")),
    jobDescription: currentUser.jobDescription,
    biographie: currentUser.biographie,
    birthDay: currentUser.birthDay,
    contractAnniversary: currentUser.experience.contractAnniversary,
    isGhost: currentUser.isGhost,
    role: currentUser.role,
    operatingManagerId: currentUser.operatingManagerId,
    allowProcessPush: currentUser.allowProcessPush,
    allowFunPush: currentUser.allowFunPush,
    allowTeamChatPush: currentUser.allowTeamChatPush,
  });

  setCurrentUserName(currentUser.firstName + " " + currentUser.lastName);

  const [desactivateChange, setDesactivateChange] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateUser(formValue);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setformValue({
      userId: currentUser.userId,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email.substring(0, currentUser.email.indexOf("@")),
      jobDescription: currentUser.jobDescription,
      biographie: currentUser.biographie,
      birthDay: currentUser.birthDay,
      contractAnniversary: currentUser.experience.contractAnniversary,
      isGhost: currentUser.isGhost,
      role: currentUser.role,
      operatingManagerId: currentUser.operatingManagerId,
      allowProcessPush: currentUser.allowProcessPush,
      allowFunPush: currentUser.allowFunPush,
      allowTeamChatPush: currentUser.allowTeamChatPush,
    });
    desactivateChangeFunc();
  };

  const handleChange = (e) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });
  };

  const handleRoleRadioChange = (e) => {
    setformValue({
      ...formValue,
      role: parseInt(e.target.value, 10),
    });
  };

  const hangleCheckBoxChange = async (event) => {
    const confirmed = await isConfirmed("Souhaitez vous ghoster/dé-ghoster l'utilisateur ?");
    if (event.target.name === "isGhost" && confirmed) {
      setformValue({
        ...formValue,
        isGhost: !formValue.isGhost,
      });
    }
  };

  const desactivateChangeFunc = () => {
    setDesactivateChange(!desactivateChange);
  };
  const desactivateChangeBtn = (
    <div>
      <Button
        color="primary"
        onClick={desactivateChangeFunc}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title=" Modifier les informations de l'utilisateur"
      >
        Modifier les informations
      </Button>
    </div>
  );

  const isSeasonInProgress = () => {
    return new Date() < new Date(currentSeason?.submissionEndDate);
  };

  const disableisGhost = () => {
    return formValue.role === 2 && isSeasonInProgress();
  };

  const getOperationalManagerOptions = useCallback(() => {
    let choices = [];

    if (usersInfos && usersInfos.data) {
      usersInfos.data.map((user) => {
        {
          choices.push({ value: user.userId, label: `${user.firstName} ${user.lastName}` });
        }
      });
    }
    return choices;
  }, [usersInfos]);

  return (
    <div>
      <ConfirmModal></ConfirmModal>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <h5>Informations générales</h5>
        </div>
        <div>{desactivateChangeBtn}</div>
      </div>
      <form className=" p-md-2 update-user-info-form " onSubmit={handleSubmit} onReset={handleReset} id="myform">
        <Row>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="firsName">Prénom</label>
              <input
                type="text"
                className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
                id="firsName"
                name="firstName"
                placeholder="Prénom"
                value={formValue.firstName}
                onChange={handleChange}
                readOnly={desactivateChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="lastName"> Nom</label>
              <input
                type="text"
                className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
                id="lastName"
                name="lastName"
                placeholder=" Nom"
                value={formValue.lastName}
                onChange={handleChange}
                readOnly={desactivateChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Email</label>
              <div className="input-group mb-3 ">
                <input
                  type="text"
                  className={`form-control ${desactivateChange === false ? "" : "readOnly-input-email"}`}
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formValue.email}
                  onChange={handleChange}
                  readOnly={desactivateChange}
                  required
                />
                <span className="input-group-text" id="basic-addon2">
                  @diggers-consulting.com
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="operatingManagerId">Manager opérationnel</label>
              <Select
                name="operatingManagerId"
                id="operatingManagerId"
                autoComplete="off"
                isDisabled={desactivateChange}
                isClearable
                isSearchable
                value={getOperationalManagerOptions().find((mng) => mng.value === formValue.operatingManagerId)}
                onChange={handleReactSelectChange}
                noOptionsMessage={() => "Pas d'options"}
                placeholder="Manager opérationel"
                options={getOperationalManagerOptions()}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="userId">Azure Id</label>
              <input
                type="text"
                className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
                id="userId"
                name="userId"
                placeholder="Azure Id"
                value={formValue.userId}
                onChange={handleChange}
                readOnly={desactivateChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="form-floating mb-3">
              <input
                type="text"
                className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
                id="jobDescription"
                name="jobDescription"
                placeholder="Poste"
                value={formValue.jobDescription}
                onChange={handleChange}
                readOnly={desactivateChange}
                required
              />
              <label htmlFor="jobDescription">Poste</label>
            </div>
          </Col>
        </Row>
        <div className="form-floating mb-3">
          <input
            type="text"
            className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
            id="biographie"
            name="biographie"
            placeholder="biographie"
            value={formValue.biographie}
            onChange={handleChange}
            readOnly={desactivateChange}
          />
          <label htmlFor="biographie">Biographie</label>
        </div>

        <Row>
          <Col md={6}>
            <label htmlFor="birthDay">Anniversaire</label>
            <FormGroup>
              {desactivateChange ? (
                <input
                  type="text"
                  className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
                  id="birthDay"
                  name="birthDay"
                  value={new Date(formValue.birthDay.slice(0, 10)).toLocaleDateString("fr-FR").slice(0, 5)}
                  onChange={handleChange}
                  readOnly={desactivateChange}
                />
              ) : (
                <input
                  type="date"
                  className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
                  id="birthDay"
                  name="birthDay"
                  value={new Date(formValue.birthDay.slice(0, 10)).toISOString().slice(0, 10)}
                  onChange={handleChange}
                  readOnly={desactivateChange}
                  required
                />
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <label htmlFor="contractAnniversary">Date de contrat</label>
            <FormGroup>
              <input
                type="date"
                className={`form-control ${desactivateChange === false ? "" : "readOnly-input"}`}
                id="contractAnniversary"
                name="contractAnniversary"
                value={new Date(formValue.contractAnniversary.slice(0, 10)).toISOString().slice(0, 10)}
                onChange={handleChange}
                readOnly={desactivateChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="row">
          <Col md={6}>
            <FormGroup id="roleRadioButton">
              <fieldset className="border px-1">
                <legend className="float-none  w-auto small ">Role</legend>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Role"
                    id="Role2"
                    required
                    value={2}
                    checked={formValue.role == "2"}
                    onClick={handleRoleRadioChange}
                    readOnly={desactivateChange}
                  />
                  <label className="form-check-label" htmlFor="Role2">
                    Guide
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Role"
                    id="Role1"
                    value={1}
                    checked={formValue.role == "1"}
                    onClick={handleRoleRadioChange}
                    readOnly={desactivateChange}
                  />
                  <label className="form-check-label" htmlFor="Role1">
                    Explorateur
                  </label>
                </div>
              </fieldset>
            </FormGroup>
            {desactivateChange ? (
              <></>
            ) : isSeasonInProgress() ? (
              <UncontrolledTooltip placement="left" target="roleRadioButton">
                Impossible de modifier le rôle d'un utilisateur au cours d'une expédition
              </UncontrolledTooltip>
            ) : (
              <></>
            )}
          </Col>
          <Col md={6}>
            <FormGroup id="isGhostCheckBox">
              <div className="form-check py-2 align-item-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isGhost"
                  name="isGhost"
                  disabled={desactivateChange || disableisGhost()}
                  onChange={hangleCheckBoxChange}
                  checked={formValue.isGhost}
                  readOnly={desactivateChange}
                />
                <label className="form-check-label" htmlFor="isGhost">
                  Ghost
                </label>
              </div>
            </FormGroup>
            {desactivateChange ? (
              <></>
            ) : disableisGhost() ? (
              <UncontrolledTooltip placement="bottom" target="isGhostCheckBox">
                Impossible de ghost/dé-ghost un guide au cours d'une expédition
              </UncontrolledTooltip>
            ) : (
              <></>
            )}
          </Col>
        </div>
        {!desactivateChange && (
          <div>
            <button className="w-90 btn btn-lg btn-primary" type="submit" disabled={desactivateChange}>
              Enregistrer
            </button>
            &nbsp;
            <button className="w-90 btn btn-lg btn-secondary" type="reset" value="reset" disabled={desactivateChange}>
              Annuler
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UpdateUserGeneralInfo;
