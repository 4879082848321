import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserRole from "../../hooks/useUserRole";

const RestrictedAccess = ({ children }) => {
  const navigate = useNavigate();
  const { isAdmin, hasAccessAsDeveloper } = useUserRole();

  const hasAccessTorestrictedContent = isAdmin || hasAccessAsDeveloper;

  useEffect(() => {
    if (!hasAccessTorestrictedContent) {
      navigate("/");
    }
  }, [navigate, hasAccessTorestrictedContent]);

  return <>{hasAccessTorestrictedContent && children}</>;
};

export default RestrictedAccess;
