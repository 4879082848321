import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader/Loader";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import SeasonTable from "../components/table/SeasonTable";
import { getSeasonList, launchNewSeason, closeCurrentSeason } from "../redux/slices/SeasonSlice";
import { getQuestList } from "../redux/slices/QuestSlice";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import { Toaster } from "react-hot-toast";

const SeasonManagement = () => {
  let { seasonList, loading_getSeasonList, refreshSeasonPageAfterUpdate } = useSelector((state) => ({ ...state.seasonList }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSeasonList());
    dispatch(getQuestList());
  }, [refreshSeasonPageAfterUpdate]);

  const startNewSeason = () => {
    dispatch(launchNewSeason());
  };

  const closeSeason = () => {
    dispatch(closeCurrentSeason());
  };

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-8">
          <h2 className="page-header">Gestion des expéditions</h2>
        </div>
        <div className="col-4"></div>
      </div>
      <div className="row">
        <ConfirmContextProvider>
          <div>
            {loading_getSeasonList ? (
              <Loader></Loader>
            ) : !loading_getSeasonList && seasonList && seasonList.data ? (
              <SeasonTable seasonList={seasonList.data} startNewSeason={startNewSeason} closeSeason={closeSeason}></SeasonTable>
            ) : (
              <div className="row">
                <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
              </div>
            )}
          </div>
        </ConfirmContextProvider>
      </div>
    </div>
  );
};

export default SeasonManagement;
