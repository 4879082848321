import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, Dstore } from "./redux/Store";
import Layout from "./components/layout/Layout";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./services/authConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./assets/css/grid.css";
import "./assets/css/theme.css";
import "./assets/css/index.css";

document.title = "DigApp";

ReactDOM.render(
  <Provider store={Dstore}>
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <Layout />
      </MsalProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/****************************************
 *          MUST DO                     *
 ****************************************/
/**
 * FORM VALIDATION REGEX
 *
 */
