import React, { useState } from "react";
import { Input, InputGroup } from "reactstrap";

function CheckDefaultIcon() {
  return <span style={{ marginLeft: "5px", color: "green", cursor: "pointer" }}>&#10004;</span>;
}

function CloseDefaultIcon() {
  return <span style={{ marginLeft: "5px", color: "red", cursor: "pointer" }}>&#10008;</span>;
}

const CustomInput = (props) => {
  const {
    defaultValue,
    saveText,
    cancelEdit,
    checkIcon = <CheckDefaultIcon />,
    closeIcon = <CloseDefaultIcon />,
    customStyles = {
      border: "1px solid black",
      borderRadius: "0.2rem",
      padding: "0.4rem",
    },
  } = props;
  const [value, setValue] = useState(defaultValue);

  const updateText = () => {
    saveText(value);
  };

  return (
    <InputGroup>
      <Input
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        styles={customStyles}
        type="textarea"
        maxLength="30"
      />
      <span onClick={updateText}>{checkIcon}</span>
      <span onClick={cancelEdit}>{closeIcon}</span>
    </InputGroup>
  );
};

export default CustomInput;
