import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { sortBy } from "lodash";
import DigDayInfosCard from "../DigDayInfosCard";
import DigDaysDecisionModal from "../DigDaysDecisionModal";
import DigDaysDataService from "../../../services/DigDaysDataService";
import { postLogByParentId } from "../../../redux/slices/LogSlice";
import DiscussionPanel from "../../discussionPanel/DiscussionPanel";
import { v4 as uuid } from "uuid";
import Enums from "../../../assets/JsonData/Enums";
import { notify } from "../../../assets/utils/UtilsFunctons";
import Loader from "../../loader/Loader";
import LogDataService from "../../../services/LogDataService";

const DigDaysSerialValidationComp = ({ digDays }) => {
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [currentDigDayIndex, setCurrentDigDayIndex] = useState();
  const [loadingDigDayUpdate, setLoadingDigDayUpdate] = useState(false);
  const containerRef = useRef(null);

  const groupDigDaysByUserId = useMemo(() => {
    if (digDays && digDays?.data) {
      let digDaysByUserId = [];
      const sortedDigDaysList = sortBy(digDays?.data, "date").reverse();

      sortedDigDaysList?.forEach((digDay) => {
        let cle = digDay.userId;

        if (!digDaysByUserId[cle]) {
          digDaysByUserId[cle] = [];
        }

        digDaysByUserId[cle].push(digDay);
      });

      let result = Object.values(digDaysByUserId);
      result.forEach((subTab) => {
        //subTab.sort((a, b) => compareDates(a?.date, b?.date));
        return sortBy(subTab, "date").reverse();
      });

      const groupedDigDays = result.filter((digDaysList) => {
        return digDaysList.some((digDay) => {
          return digDay.state === 2;
        });
      });

      if (groupedDigDays && groupedDigDays.length > 0) {
        const firstIndex = groupedDigDays[0].findIndex((dd) => dd.state === 2);
        setCurrentDigDayIndex(firstIndex >= 0 ? firstIndex : 0);
      }

      return groupedDigDays;
    } else {
      return undefined;
    }
  }, [digDays]);

  const [digDayGroupedByUserId, setDigDayGroupedByUserId] = useState(groupDigDaysByUserId);

  const updateDigDaysList = (digDayId, finalState) => {
    let updatedDigdays = digDayGroupedByUserId?.map((subArray, subArrayIndex) => {
      if (subArrayIndex === currentUserIndex) {
        // Utilisation de  .map() pour créer un nouveau sous-tableau avec l'élément modifié
        const updatedSubArray = subArray.map((objet) => {
          if (objet.digDayId === digDayId) {
            return {
              ...objet,
              state: finalState,
            };
          }
          return objet;
        });
        return updatedSubArray;
      }
      return subArray;
    });
    setDigDayGroupedByUserId(updatedDigdays);
  };

  const handleDigDayDecision = async (decision, data) => {
    let payload = undefined;
    if (decision === "cancel") {
      payload = {
        digDayId: data.digDayId,
        message: {
          messageId: uuid(),
          parentId: data.digDayId,
          userId: accounts[0]?.idTokenClaims?.oid,
          text: data.message,
        },
      };

      try {
        await DigDaysDataService.cancelDigDay(payload);
        setLoadingDigDayUpdate(true);
        notify(1, "digDay annulé avec succés !");
        updateDigDaysList(payload.digDayId, 5);
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        setLoadingDigDayUpdate(false);
      }
    }
    if (decision === "notification") {
      payload = {
        messageId: uuid(),
        parentId: data.digDayId,
        userId: accounts[0]?.idTokenClaims?.oid,
        text: data.message,
      };
      try {
        await LogDataService.postLogByParentId(payload);
        setLoadingDigDayUpdate(true);
        notify(1, "Rappel envoyé avec succés !");
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        setLoadingDigDayUpdate(false);
      }
      dispatch(postLogByParentId(payload));
    }
    if (decision === "validation" || decision === "rejection" || decision === "adjustment") {
      payload = {
        digDayId: data.digDayId,
        decidedDigDayState: data.decidedDigDayState,
        message: data.message
          ? {
              messageId: uuid(),
              parentId: data.digDayId,
              userId: accounts[0]?.idTokenClaims?.oid,
              text: data.message,
            }
          : undefined,
      };
      try {
        await DigDaysDataService.digDayDecision(payload);
        setLoadingDigDayUpdate(true);
        notify(1, "Modification apportée avec succés !");
        updateDigDaysList(payload.digDayId, payload.decidedDigDayState);
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      } finally {
        setLoadingDigDayUpdate(false);
      }
    }
  };

  const digDayActionsBasedOnState = (digDay) => {
    if (digDay) {
      if (digDay.state === 0) {
        // planifié
        return (
          <div className="d-flex justify-content-end">
            <DigDaysDecisionModal decision="cancel" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
          </div>
        );
      }

      if (digDay.state === 1 || digDay.state === 3) {
        // attente de soumission ou correction
        return (
          <div className="d-flex justify-content-end">
            <DigDaysDecisionModal decision="notification" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
          </div>
        );
      }

      if (digDay.state === 2) {
        // en attente de validation
        return (
          <div className="d-flex justify-content-end">
            <span className="mx-2">
              <DigDaysDecisionModal decision="validation" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
            </span>
            <span className="mx-2">
              <DigDaysDecisionModal decision="adjustment" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
            </span>
            <span className="mx-2">
              <DigDaysDecisionModal decision="rejection" digDay={digDay} handleDigDayDecision={handleDigDayDecision}></DigDaysDecisionModal>
            </span>
          </div>
        );
      }
    }
  };

  const renderDigDaysHistoryByUserId = useCallback(() => {
    if (!digDayGroupedByUserId || digDayGroupedByUserId?.length === 0) {
      return null;
    }

    return (
      <div className="list-group d-grid gap-2 border-0 w-auto p-2 " ref={containerRef} style={{ maxHeight: "80Vh", overflowY: "auto", overflowX: "hidden" }}>
        {digDayGroupedByUserId[currentUserIndex]?.map((digday, index) => {
          return (
            <div onClick={() => setCurrentDigDayIndex(index)} key={index}>
              <DigDayInfosCard digDay={digday} key={index} onFocus={currentDigDayIndex === index} isClickable={true} />
            </div>
          );
        })}
      </div>
    );
  }, [currentDigDayIndex, currentUserIndex, digDayGroupedByUserId]);

  const renderUserDigDayDetailContent = useCallback(() => {
    if (!digDayGroupedByUserId || digDayGroupedByUserId?.length === 0) {
      return null;
    }

    return digDayGroupedByUserId[currentUserIndex][currentDigDayIndex] ? (
      <div className="p-4">
        <div>
          <div className="fs-5 fw-bold">{digDayGroupedByUserId[currentUserIndex][currentDigDayIndex]?.fullName}</div>
          <small className="text-muted ">
            {currentUserIndex + 1} / {digDayGroupedByUserId?.length}
          </small>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <i
            className={`bx bxs-left-arrow fs-1  ${currentUserIndex === 0 ? "user-title-index-btn-disabled" : "previous-user-title-index-btn"}`}
            aria-disabled={currentUserIndex === 0}
            onClick={() => {
              if (currentUserIndex > 0) {
                setCurrentUserIndex(currentUserIndex - 1);
                const indexNextValidation = digDayGroupedByUserId[currentUserIndex - 1].findIndex((element) => element.state === 2);
                if (indexNextValidation >= 0) {
                  setCurrentDigDayIndex(indexNextValidation);
                } else {
                  setCurrentDigDayIndex(0);
                }
              }
            }}
          ></i>
          <div className=" list-group d-grid  border-0 w-auto  ">
            {<DigDayInfosCard digDay={digDayGroupedByUserId[currentUserIndex][currentDigDayIndex]} fullSize={true} />}
          </div>
          <i
            className={`bx bxs-right-arrow fs-1  ${
              currentUserIndex === digDayGroupedByUserId?.length - 1 ? "user-title-index-btn-disabled" : "next-user-title-index-btn"
            }`}
            onClick={() => {
              if (currentUserIndex < digDayGroupedByUserId?.length - 1) {
                setCurrentUserIndex(currentUserIndex + 1);
                const indexNextValidation = digDayGroupedByUserId[currentUserIndex + 1].findIndex((element) => element.state === 2);
                if (indexNextValidation >= 0) {
                  setCurrentDigDayIndex(indexNextValidation);
                } else {
                  setCurrentDigDayIndex(0);
                }
              }
            }}
          ></i>
        </div>

        <br />
        {digDayActionsBasedOnState(digDayGroupedByUserId[currentUserIndex][currentDigDayIndex])}
      </div>
    ) : null;
  }, [currentDigDayIndex, currentUserIndex, digDayGroupedByUserId]);

  const renderSelectedDigDayHistory = useCallback(() => {
    return (
      <DiscussionPanel
        logId={digDayGroupedByUserId[currentUserIndex][currentDigDayIndex]?.digDayId}
        parentType={1}
        stateTransition={Enums.matchDigDayState}
        refresh={loadingDigDayUpdate}
      />
    );
  }, [currentDigDayIndex, currentUserIndex, digDayGroupedByUserId]);

  useEffect(() => {
    if (containerRef?.current) {
      const digDayToScroll = containerRef?.current?.children[currentDigDayIndex];
      containerRef.current.scrollTop = digDayToScroll?.offsetTop - 120;
    }
  }, [currentDigDayIndex]);
  return (
    <div>
      <div className="d-flex justify-content-between ">
        <div className="py-5" style={{ flex: "1" }}>
          {renderDigDaysHistoryByUserId()}
        </div>
        <div className={`d-flex flex-column ${loadingDigDayUpdate ? "samatest" : ""} `} style={{ flex: "2" }}>
          <div>{renderUserDigDayDetailContent()}</div>
          {loadingDigDayUpdate && <Loader />}
          <hr className="mx-5" />
          <div className="px-4">{renderSelectedDigDayHistory()}</div>
        </div>
      </div>
    </div>
  );
};

export default DigDaysSerialValidationComp;
