import React from "react";
import { Button } from "reactstrap";

const ShopTransactionActionButton = ({ transactionItem, handleShopTransactionUpdate }) => {
  return (
    <div>
      {/* state === intreatment */}
      {transactionItem.transactionState === 0 && (
        <>
          <Button color="warning" outline onClick={() => handleShopTransactionUpdate("indelivery", transactionItem.transactionId)}>
            A livrer
          </Button>
          <Button color="danger" outline onClick={() => handleShopTransactionUpdate("cancel", transactionItem.transactionId)} className="mx-2">
            Annuler
          </Button>
        </>
      )}
      {/* state === indelivery */}
      {transactionItem.transactionState === 1 && (
        <>
          <Button outline color="success" onClick={() => handleShopTransactionUpdate("deliver", transactionItem.transactionId)} className="mx-2">
            Terminer
          </Button>
          <Button outline color="primary" onClick={() => handleShopTransactionUpdate("intreatment", transactionItem.transactionId)}>
            A traiter
          </Button>
          <Button outline color="danger" onClick={() => handleShopTransactionUpdate("cancel", transactionItem.transactionId)} className="mx-2">
            Annuler
          </Button>
        </>
      )}
      {/* state === delivered */}
      {transactionItem.transactionState === 2 && (
        <>
          <Button color="warning" outline onClick={() => handleShopTransactionUpdate("indelivery", transactionItem.transactionId)}>
            A livrer
          </Button>
        </>
      )}
    </div>
  );
};

export default ShopTransactionActionButton;
