import React from "react";
import AssignPersonalQuestForm from "./AssignPersonalQuestForm";
import QuestDataService from "../../services/QuestDataService";
import UseModalWithContent from "../useModalWithContent/UseModalWithContent";
import { notify } from "../../assets/utils/UtilsFunctons";

const AssignPersonalQuestComp = ({ users, item, title }) => {
  const handleAssingQuest = async (formValue, setAssigningQuest) => {
    try {
      if (formValue) {
        setAssigningQuest(true);
        await QuestDataService.assignQuestToUser(formValue);
        let assignedUser = users?.find((user) => user.userId === formValue?.userId);
        let fullUserName = assignedUser ? assignedUser.firstName + " " + assignedUser.lastName : "";
        notify(1, `La quête ${title} a été bien assignée à ${fullUserName}`);
      }
    } catch (error) {
      notify(0, error?.message ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };
  const handleCloseModal = () => {
    closeModal();
  };
  const { closeModal, ModalComponent, ModalButton } = UseModalWithContent(
    <AssignPersonalQuestForm users={users} item={item} questTitle={title} handleAssingQuest={handleAssingQuest} handleCloseModal={handleCloseModal} />,
    "Assignation quête personnelle"
  );
  return (
    <div>
      <>
        <div data-toggle="tooltip" data-placement="top" title="Assigner à un utilisateur">
          <ModalButton icon={"bx bx-plug"} buttonColor={"primary"} size={"sm"}></ModalButton>
        </div>
        <ModalComponent />
      </>
    </div>
  );
};

export default AssignPersonalQuestComp;
