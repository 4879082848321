import { React } from "react";
import { useSelector } from "react-redux";
import QuizRanksContentsTable from "./QuizRanksContentsTable";
import PageLoadingFailNotification from "../PageLoadingFailNotification/PageLoadingFailNotification";
import Loader from "../loader/Loader";

const QuizRanksContents = () => {
  const { loading_quizRanksContents, quizRanksContents } = useSelector((state) => ({ ...state.quiz }));

  return (
    <div>
      {loading_quizRanksContents ? (
        <Loader></Loader>
      ) : (
        <div>
          {quizRanksContents && quizRanksContents.data ? (
            <QuizRanksContentsTable data={quizRanksContents.data?.rankingContents} />
          ) : (
            <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
          )}
        </div>
      )}
    </div>
  );
};

export default QuizRanksContents;
