import React from "react";

const ErrorBoundaryPage = () => {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center" style={{ height: "60vh" }}>
        <div className="d-flex flex-column align-items-center">
          <div>
            <i className="bx bx-error-alt  text-danger" style={{ fontSize: "5em" }}></i>
          </div>
          <br />
          <div>
            <strong className="fs-4">Une erreur s&apos;est produite</strong>
          </div>
          <div>
            <strong className="fs-6 text-muted">Une erreur innatendue s&apos;est produite. Veuillez rafraîchir la page ou vous reconnecter</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryPage;
