import { React } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LoginPage from "../../pages/LoginPage";
import "./layout.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MainContainer from "./MainContainer";

const Layout = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <div>
              <AuthenticatedTemplate>
                <MainContainer />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <LoginPage></LoginPage>
              </UnauthenticatedTemplate>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Layout;
