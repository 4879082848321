import { React, useCallback, useState, useMemo } from "react";
import MissionInfosCard from "./MissionInfosCard";
import { orderBy } from "lodash";
import { Row, Col } from "reactstrap";
import UserMissionSheetTabs from "../userDetailledInfosPage/user-digDays-and-mission/UserMissionSheetTabs";

const UserMissionsHistory = ({ userMissions }) => {
  const sortedData = useCallback(() => {
    let data = orderBy(userMissions, "startDate", "desc");
    return data;
  }, [userMissions]);

  const [selectedMissionId, setSelectedMissionId] = useState();

  const missionSheets = useMemo(() => {
    if (selectedMissionId) {
      return userMissions.find((mission) => mission.missionId === selectedMissionId);
    }
    return undefined;
  }, [selectedMissionId]);

  return (
    <div className="list-group d-grid gap-2 border-0 ">
      <Row>
        <Col md="5" sm="12">
          {sortedData()?.map((mission, index) => (
            <div className="py-1" key={index}>
              <MissionInfosCard mission={mission} setSelectedMission={setSelectedMissionId} selectedMissionId={selectedMissionId} />
            </div>
          ))}
        </Col>

        <Col md="6" sm="12">
          <div className="d-flex justify-content-around">
            <div className="vr p-0 m-3"></div>

            <div>
              <UserMissionSheetTabs missionsSheets={missionSheets}></UserMissionSheetTabs>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserMissionsHistory;
