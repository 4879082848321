import { React, useState, useCallback } from "react";
import { Table, Input, Button } from "reactstrap";
import { useSelector } from "react-redux";
import DigDaysDataService from "../../../services/DigDaysDataService";
import { compareObjectArray, getAddedOrChangedValues } from "../../../assets/utils/UtilsFunctons";
import { notify } from "../../../assets/utils/UtilsFunctons";

const UserDigDaysStockTable = ({ userDigDayBalances, userRole }) => {
  const { userInfos } = useSelector((state) => ({ ...state.userInfos }));
  const [digDayBalances, setDigDayBalances] = useState(userInfos?.data?.userDigDayBalances);
  const [defaultBalances, setDefaultBalances] = useState(userInfos?.data?.userDigDayBalances);
  const [updatingBalance, setUpdatingBalance] = useState(false);

  const doesBalanceChanged = useCallback(() => {
    return !compareObjectArray(defaultBalances, digDayBalances);
  }, [defaultBalances, digDayBalances]);

  const onChange = (e, year) => {
    const { name, value } = e.target;
    let editData = [];
    let newValue = value ? parseFloat(value) : value;
    editData = digDayBalances.map((item) => (item.year === year && name ? { ...item, [name]: newValue } : item));
    setDigDayBalances(editData);
  };

  const nextYear = new Date().getFullYear() + 1;
  const doesNextYearExisted = useCallback(() => {
    return digDayBalances?.some((balance) => balance?.year === nextYear);
  }, [digDayBalances]);

  const getNewEmptyItem = () => {
    if (!doesNextYearExisted()) {
      return { year: nextYear, initialBalance: userRole === 2 ? 6 : 18, consumed: 0, extra: 0 };
    } else {
      return null;
    }
  };

  const handleAddRow = () => {
    const newEntry = getNewEmptyItem();
    if (newEntry) {
      setDigDayBalances([...digDayBalances, getNewEmptyItem()]);
    }
  };

  const handleReset = () => {
    setDigDayBalances(defaultBalances);
  };

  const handleSubmit = () => {
    setUpdatingBalance(true);
    handleUpdateUserDigDayBalances(getAddedOrChangedValues(userDigDayBalances, digDayBalances));
    setUpdatingBalance(false);
  };

  const handleUpdateUserDigDayBalances = async (formValue) => {
    try {
      if (formValue) {
        for (const item of formValue) {
          let payload = {
            userId: userInfos?.data?.userId,
            year: item.year,
            extraDigDays: item.extra,
          };
          await DigDaysDataService.updateUserDigDaysStock(payload);
          let editData = digDayBalances.map((ddItem) =>
            ddItem.year === item.year ? { ...item, balance: ddItem.initialBalance + item.extra - ddItem.consumed } : ddItem
          );
          setDigDayBalances(editData);
          setDefaultBalances(editData);
        }

        notify(1, "Modification apportée avec succés !");
      }
    } catch (error) {
      notify(0, error?.response?.data?.title ?? "une erreur est survenue");
    }
  };

  return (
    <div>
      <Table size="sm" responsive hover={false} className="nohover table-responsive-sm" striped>
        <thead>
          <tr>
            <th>Année</th>
            <th>Initial</th>
            <th>Conso</th>
            <th>Solde</th>
            <th>Extra</th>
          </tr>
        </thead>
        <tbody>
          {digDayBalances?.map(({ year, initialBalance, consumed, balance, extra }, index) => (
            <tr key={index} className="align-middle">
              <td>{year}</td>
              <td>{initialBalance}</td>
              <td>{consumed}</td>
              <td>{balance}</td>
              <td>
                <Input
                  style={{ maxWidth: "80px", border: `1px solid ${extra > 0 ? "green" : extra < 0 ? "red" : "grey"}` }}
                  name="extra"
                  type="number"
                  step="0.5"
                  value={extra}
                  onChange={(e) => onChange(e, year)}
                  placeholder="Extra"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-around">
        <button style={{ all: "unset", cursor: doesNextYearExisted() ? "not-allowed" : "pointer" }} onClick={handleAddRow} type="button">
          <i className="bx bx-plus-circle fs-5"> </i> ajouter une ligne
        </button>

        {doesBalanceChanged() && (
          <div>
            <Button size="sm" color="primary" role="button" onClick={handleSubmit} disabled={updatingBalance}>
              {updatingBalance ? <i className="bx bx-loader-alt bx-spin"></i> : "Valider"}
            </Button>
            &nbsp;
            <Button size="sm" color="danger" role="button" onClick={handleReset} disabled={updatingBalance}>
              Annuler
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDigDaysStockTable;
