import { React, useState } from "react";
import MissionSheetDecisionForm from "./MissionSheetDecisionForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const MissionSheetDecisionModal = ({ handleMissionSheetDecison, missionId, decision, missionSheet }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <div
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={
        missionSheet.state !== 2
          ? "La fiche mission n'est pas en état de validation"
          : decision === "validation"
          ? "valider la fiche mission"
          : decision === "adjustment"
          ? "demander un ajustement"
          : "rejeter la fiche mission"
      }
    >
      {decision === "validation" ? (
        <Button onClick={toggle} color="success" outline size="sm" disabled={missionSheet.state !== 2}>
          <i className="bx bx-check" style={{ fontSize: "1.5em" }}></i>
        </Button>
      ) : decision === "adjustment" ? (
        <Button onClick={toggle} color="warning" outline size="sm" disabled={missionSheet.state !== 2}>
          <i className="bx bx-refresh" style={{ fontSize: "1.5em" }}></i>
        </Button>
      ) : (
        <Button onClick={toggle} color="danger" outline size="sm" disabled={missionSheet.state !== 2}>
          <i className="bx bx-x" style={{ fontSize: "1.5em" }}></i>
        </Button>
      )}
    </div>
  );

  const modalTitle = (state) => {
    switch (state) {
      case "validation":
        return " Validation de la fiche mission";

      case "adjustment":
        return " réajustement de la fiche mission";

      case "rejection":
        return " rejet de la fiche mission";
      default:
        return "";
    }
  };

  return (
    <div>
      {myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {modalTitle(decision)}
        </ModalHeader>
        <ModalBody>
          <MissionSheetDecisionForm
            decision={decision}
            handleMissionSheetDecison={handleMissionSheetDecison}
            modalVisibility={setModal}
            missionId={missionId}
            missionSheet={missionSheet}
          ></MissionSheetDecisionForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MissionSheetDecisionModal;
