import { React, useState } from "react";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import { Button, FormGroup, Label, Row, Col } from "reactstrap";

const AssignPersonalQuestForm = ({ users, item, questTitle, handleAssingQuest, handleCloseModal }) => {
  const [assigningQuest, setAssigningQuest] = useState(false);
  const [formValue, setformValue] = useState({
    questId: uuid(),
    userId: "",
    questSeasonConfigId: item.questSeasonConfigId,
  });
  const handleReactSelectChange = (choice) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        userId: choice?.value ?? null,
      };
    });
  };

  const usersListOption = () => {
    let list = [];

    if (users) {
      users.map((user) => {
        if (user.role === 1) {
          list.push({ value: user.userId, label: user.firstName + " " + user.lastName });
        }
      });
    }
    return list;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleAssingQuest(formValue, setAssigningQuest);
  };
  const handleReset = () => {
    handleCloseModal();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div className="text-center font-weight-bold h5 my-2 pb-2">
          Vous allez attribuer la quête <span className="font-italic text-muted">{questTitle}</span> à un utilisateur
        </div>
        <FormGroup>
          <Label for="role">Utilisateur</Label>
          <Select
            name="role"
            id="role"
            autoComplete="off"
            required
            isClearable
            isSearchable
            onChange={handleReactSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            placeholder="choisir l'utilisateur"
            options={usersListOption()}
          />
        </FormGroup>
        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block disabled={!formValue.userId}>
              {assigningQuest ? <i className="bx bx-loader-alt bx-spin fs-5"></i> : "Ajouter"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AssignPersonalQuestForm;
