import { React, useCallback, useState } from "react";
import useModalWithContent from "../useModalWithContent/UseModalWithContent";
import RewardItem from "./RewardItem";
import TablePagination from "../table/table-pagination/TablePagination";
import RewardTemplateActions from "./reward-template-actions/RewardTemplateActions";
import RewardTemplateStateComp from "./reward-template-actions/RewardTemplateStateComp";
import EditRewardTemplateForm from "../rewardTemplate/EditRewardTemplateForm";
import { Badge } from "reactstrap";

const RewardTemplateTable = ({ rewardTemplates, handleAchiveRewardTemplate, handleUpdateRewardTemplate, handleDeleteRewardTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const displayRewardInfosByposition = (rewards, position) => {
    if (!rewards) {
      return null;
    } else {
      let rewardInfos = rewards.find((reward) => reward.position === position);
      return rewardInfos ? <RewardItem reward={rewardInfos} displayRow={false} /> : "";
    }
  };
  const columns = [
    {
      Header: "Nom",
      accessor: "name",
    },
    {
      Header: "Etat",
      Cell: ({ row }) => <RewardTemplateStateComp rewardTemplate={row.original} handleAchiveRewardTemplate={handleAchiveRewardTemplate} />,
    },
    {
      Header: "nb. quiz",
      accessor: "nbQuiz",
      Cell: ({ value }) => <Badge color="primary">{value}</Badge>,
    },
    {
      Header: "Positions",
      Cell: ({ row }) => row.original.rewards.length,
    },

    {
      Header: "Participation",
      Cell: ({ row }) => displayRewardInfosByposition(row.original.rewards, -1),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => <RewardTemplateActions rewardTemplate={row?.original} handleDeleteRewardTemplate={handleDeleteRewardTemplate} />,
    },
  ];

  const tableHooks = () => {
    "";
  };

  const handleRowClicked = (row) => {
    setSelectedTemplate(row.original);
    openModal();
  };

  const renderEditRewardTemplateForm = useCallback(() => {
    if (selectedTemplate) {
      return <EditRewardTemplateForm rewardTemplate={selectedTemplate} handleUpdateRewardTemplate={handleUpdateRewardTemplate} />;
    }
  }, [selectedTemplate]);

  const rewardTemplateStateDisplayValueFunc = useCallback((archived) => {
    return archived ? "archivé" : "actif";
  }, []);

  const { ModalComponent, openModal } = useModalWithContent(renderEditRewardTemplateForm(), "Détails du template", "md");

  return (
    <div>
      <div>
        <>
          <ModalComponent />
        </>
      </div>
      {rewardTemplates && (
        <TablePagination
          data={rewardTemplates}
          columns={columns}
          tableHooks={tableHooks}
          refresh={rewardTemplates}
          handleRowClicked={handleRowClicked}
          enableFilter={true}
          type={"default"}
        >
          <element
            key="archived"
            filterKey="archived"
            className="cell"
            casesensitive={"true"}
            showsearch={"false"}
            isMulti
            itemDisplayValueFunc={rewardTemplateStateDisplayValueFunc}
          >
            Etat
          </element>
        </TablePagination>
      )}
    </div>
  );
};

export default RewardTemplateTable;
