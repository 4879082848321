import { React } from "react";
import { postUserProfilePicture, deleteUserProfilePicture } from "../../redux/slices/UserSlice";
import { desactivateUser, deleteUser, updateUserInfos } from "../../redux/slices/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import UpdateUserGeneralInfo from "./UpdateUserGeneralInfo";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import PageLoadingFailNotification from "../PageLoadingFailNotification/PageLoadingFailNotification";
import DesactivateUserGuideModal from "./DesactivateUserGuide/DesactivateUserGuideModal";
import EditOrDeleteUserProfilePictureModal from "./EditOrDeleteUserProfilPicture/EditOrDeleteUserProfilePictureModal";

const GeneralUserInfos = ({ setCurrentUserName }) => {
  const { userInfos, loading_getUserInfoById, currentUserInfos } = useSelector((state) => ({ ...state.userInfos }));
  let { seasonList } = useSelector((state) => ({ ...state.seasonList }));

  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();

  let currentSeason;
  if (seasonList.data) {
    for (let i in seasonList.data) {
      if (seasonList.data[i].seasonType === 1) {
        currentSeason = seasonList.data[i];
        break;
      }
    }
  }

  const handleDesactivateUserConsultant = async () => {
    const confirmed = await isConfirmed(
      <span className="text-center">
        Etes-vous sûr de vouloir désactiver cet utilisateur ? <span className="fst-italic text-danger">Cette action est irréversible !</span>{" "}
      </span>
    );
    if (confirmed) dispatch(desactivateUser(`${currentUserInfos?.userId}`));
  };

  const handleDesactivateUserGuide = async (subsituteGuideId) => {
    dispatch(desactivateUser(`${currentUserInfos?.userId}&substituteId=${subsituteGuideId}`));
  };

  const handleDeleteUser = async () => {
    const confirmed = await isConfirmed("Souhaitez vous supprimer cet utilisateur ?");
    if (confirmed) dispatch(deleteUser(`${currentUserInfos?.userId}`));
  };

  const handleUpdateUserInfos = async (formvalue) => {
    const confirmed = await isConfirmed("Souhaitez vous modifier les informations de cet utilisateur ?");
    if (confirmed) {
      const payload = {
        userId: formvalue.userId,
        firstName: formvalue.firstName,
        lastName: formvalue.lastName,
        email: formvalue.email + "@diggers-consulting.com",
        jobDescription: formvalue.jobDescription,
        biographie: formvalue.biographie,
        birthDay: formvalue.birthDay,
        contractAnniversary: formvalue.contractAnniversary,
        isGhost: formvalue.isGhost,
        role: formvalue.role,
        operatingManagerId: formvalue.operatingManagerId,
        allowProcessPush: formvalue.allowProcessPush,
        allowFunPush: formvalue.allowFunPush,
        allowTeamChatPush: formvalue.allowTeamChatPush,
      };
      dispatch(updateUserInfos(payload));
    }
  };

  const handleAddOrUpdateUserProfilePicture = (file) => {
    const payload = {
      userId: currentUserInfos?.userId,
      file: file,
    };
    dispatch(postUserProfilePicture(payload));
  };

  const handleDeleteUserProfilePicture = () => {
    dispatch(deleteUserProfilePicture(currentUserInfos?.userId));
  };

  return (
    <>
      {!currentUserInfos || !userInfos || !userInfos.data ? (
        <PageLoadingFailNotification text={"La récupération des données a échoué"} />
      ) : (
        <div className=" py-5">
          <ConfirmModal></ConfirmModal>
          <div className="row align-items-md-stretch">
            <div className="col-md-2">
              <span>Photo de profil : </span>
              <div className="text">
                <EditOrDeleteUserProfilePictureModal
                  addOrUpdateUserProfilePicture={handleAddOrUpdateUserProfilePicture}
                  deleteUserProfilePicture={handleDeleteUserProfilePicture}
                ></EditOrDeleteUserProfilePictureModal>
              </div>
              <hr className="mt-2 mb-3" />
              <span>Utilisateur : </span>
              {userInfos.data.isActivated ? (
                <div>
                  <div>
                    {userInfos.data.role === 2 ? (
                      <DesactivateUserGuideModal
                        currentGuide={currentUserInfos?.userId}
                        desactivateUserGuide={handleDesactivateUserGuide}
                      ></DesactivateUserGuideModal>
                    ) : (
                      <button
                        className="w-90 btn btn-md btn-primary my-2"
                        type="submit"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=" Désactiver l'utilisateur"
                        onClick={handleDesactivateUserConsultant}
                      >
                        Désactiver
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <button
                      className="w-90 btn btn-md btn-danger my-2"
                      type="submit"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=" Supprimer l'utilisateur"
                      onClick={handleDeleteUser}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-10">
              <div className="h-100 p-4 bg-light border rounded-3">
                {loading_getUserInfoById ? (
                  "Chargement ..."
                ) : userInfos && userInfos.data ? (
                  <UpdateUserGeneralInfo
                    currentUser={userInfos.data}
                    updateUser={handleUpdateUserInfos}
                    currentSeason={currentSeason}
                    setCurrentUserName={setCurrentUserName}
                  ></UpdateUserGeneralInfo>
                ) : (
                  <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GeneralUserInfos;
