import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LogDataService from "../../services/LogDataService";
import { notify } from "../../assets/utils/UtilsFunctons";

export const getLogByParentId = createAsyncThunk("log/getLogByParentId", async (data) => {
  try {
    const response = await LogDataService.getLogByParentId(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const postLogByParentId = createAsyncThunk("log/postLogByParentId", async (data) => {
  try {
    const response = await LogDataService.postLogByParentId(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const LogSlice = createSlice({
  name: "log",
  initialState: {
    logContent: undefined,
    loading_getLogByParentId: false,
    logPost: undefined,
    loading_postLogByParentId: false,
    refreshPageAfterLogPost: false,
  },
  reducers: {
    refreshPageAfterPost(state) {
      state.refreshPageAfterLogPost = !state.refreshPageAfterLogPost;
    },
  },
  extraReducers: {
    [getLogByParentId.pending]: (state) => {
      state.loading_getLogByParentId = true;
    },
    [getLogByParentId.fulfilled]: (state, { payload }) => {
      state.loading_getLogByParentId = false;
      state.logContent = payload;
      if (payload?.status !== 200) {
        notify(0, payload.title);
      }
    },

    [postLogByParentId.pending]: (state) => {
      state.loading_postLogByParentId = true;
    },
    [postLogByParentId.fulfilled]: (state, { payload }) => {
      state.loading_postLogByParentId = false;
      state.logContent = payload;
      if (payload?.status === 200) {
        notify(1, "message enregistré avec succès");
        state.refreshPageAfterLogPost = !state.refreshPageAfterLogPost;
      } else if (payload?.title) {
        notify(0, payload.title);
      }
    },
  },
});
export const { refreshPageAfterPost } = LogSlice.actions;
export default LogSlice.reducer;
