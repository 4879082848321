import React from "react";
import "./ShopItemCard.css";

const ShopItemCard = (props) => {
  return (
    <div className="card shopCard">
      <div className="image-container">
        <div className="first">
          <div className="d-flex justify-content-between align-items-center">
            {props.item.theme ? <span className="discount">{props.item.theme}</span> : ""}
            <span></span>
          </div>
        </div>
        <img src={props.item.imageUrl} alt="" className="img-fluid rounded thumbnail-image justify-content-center" />
      </div>
      <div className="product-detail-container p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="dress-name">{props.item.name}</h5>
          <div className="d-flex flex-column mb-2">
            <span className="new-price">
              {props.item.cost} <i className="bx bx-coin-stack"></i>{" "}
            </span>
          </div>
        </div>
        <hr className="mt-2 mx-3" />
        <div className="d-flex flex-row justify-content-between px-3 pb-4">
          <div className="d-flex flex-column">
            <span className="text-muted">{props.item.description.length > 55 ? props.item.description.slice(0, 55) + "..." : props.item.description}</span>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between p-3 mid">
          <div className="d-flex flex-column">
            <small className="text-muted mb-1">Stock</small>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column ml-1">
                <small className="stock">
                  <b>{props.item.stock !== null ? props.item.stock : "illimité"}</b>
                </small>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <small className="text-muted mb-1">Achats</small>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column ml-1">
                <small className="stock">
                  <b>{props.item.numberOfTransactions}</b>
                </small>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <small className="text-muted ">{props.item.isActivated ? "produit actif" : "produit inactif"}</small>
            <div className="d-flex flex-row is-item-activated">
              <span>
                <button
                  onClick={() => props.handleShopItemActivationToggle(props.item)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={props.item.isActivated ? "désactiver cet article" : "activer cet article"}
                >
                  {props.item.isActivated ? <i className="bx bxs-toggle-right shop-activated-item-toggle"></i> : <i className="bx bx-toggle-left"></i>}
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center  pt-2">
          <div className="d-flex flex-column">{props.editItem}</div>&nbsp;
          <div className="d-flex flex-column">{props.deleteItem}</div>
        </div>
      </div>
    </div>
  );
};

export default ShopItemCard;
