import React from "react";
import { Table, Badge } from "reactstrap";
import RewardItem from "../reward template/RewardItem";

const RewardTemplateItemDetail = ({ rewardTemplate }) => {
  return (
    rewardTemplate && (
      <Table size="sm" hover={false} responsive className="align-middle nohover">
        <thead></thead>
        <tbody className="align-middle">
          <tr>
            <td className="text-muted">Nom</td>
            <td>{rewardTemplate.name}</td>
          </tr>
          <tr>
            <td className="text-muted">Etat</td>
            <td>
              <Badge color={rewardTemplate.archived ? "danger" : "success"}>{rewardTemplate.archived ? "Archivé" : "Actif"}</Badge>
            </td>
          </tr>
          <tr>
            <td className="text-muted"> quiz associés</td>
            <td>
              {" "}
              <span className="badge badge-primary badge-pill">{rewardTemplate.nbQuiz}</span>
            </td>
          </tr>
          <tr>
            <th colSpan="2" className="py-4">
              Récompenses
            </th>
          </tr>

          {rewardTemplate.rewards.map((reward, index) => {
            return (
              <tr key={index}>
                <td className="text-muted"> {reward.position === -1 ? "participation" : `n° ${reward.position}`} </td>
                <td>
                  <RewardItem reward={reward} displayRow={true} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    )
  );
};

export default RewardTemplateItemDetail;
