import React from "react";
import { Badge } from "reactstrap";

const RessourceStatusComp = ({ status }) => {
  switch (status) {
    case 0:
      return <Badge color="primary"> En validation</Badge>;
    case 1:
      return "";
    case 2:
      return <i className="bx bxs-check-circle fs-4 text-success" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="ressource certifiée" />;
    case 3:
      return <i className="bx bx-lock-alt fs-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="ressource privée" />;
    case 4:
      return <i className="bx bx-hide fs-4 " data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="ressource désactivée" />;
    default:
      return "";
  }
};

export default RessourceStatusComp;
