import React from "react";
import DiscussionFileRender from "./DiscussionFileRender";
import { convertDateToLocalTimeWithHour, convertDateToLocalTime, generateTextAvatar, formatDateUtcToLocalTime } from "../../assets/utils/UtilsFunctons";
import { Col, Badge } from "reactstrap";
import Enums from "../../assets/JsonData/Enums";
import "./discussionThread.css";

const DiscussionThread = ({ log, userId, stateTransition }) => {
  const getInitialsFromUserName = (firstName, lastName) => {
    let first = firstName?.length > 0 ? firstName[0] : "";
    let last = lastName?.length > 0 ? lastName[0] : "";

    return first + last;
  };
  const createListofStateTransitionsAndMessage = (transitions, messages) => {
    let list = [];

    transitions?.map((transition) => {
      list.push({ element: transition, date: transition.date, type: "transition" });
    });

    messages?.map((message) => {
      list.push({ element: message, date: message.date, type: "message" });
    });

    return list.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  };
  const renderStateTransitionItem = (transition, index) => {
    return (
      <div className="d-flex justify-content-center" key={index}>
        <div className="d-flex justify-content-around align-items-center  mb-3 p-2  transition-container border border-secondary  border-opacity-75 rounded-pill">
          <Col md={12} className="d-flex  align-items-center ">
            <span>
              {stateTransition ? (
                <div>
                  {stateTransition(transition.initialState)} <i className="bx bx-chevron-right"></i> {stateTransition(transition.finalState)}
                </div>
              ) : (
                <Badge color="info">
                  {Enums.matchQuestStatut(transition.initialState)} &rarr; &nbsp; {Enums.matchQuestStatut(transition.finalState)}{" "}
                </Badge>
              )}
            </span>
            <span className="mx-2">
              <span className="transition_msg_time">{convertDateToLocalTimeWithHour(transition.date)}</span>
            </span>
          </Col>
          <br />
        </div>
      </div>
    );
  };

  const renderMessageItem = (message, index) => {
    return (
      <div className="card-body msg_card_body" key={index}>
        {message?.userId === userId ? (
          <div className="d-flex justify-content-start mb-4">
            <div className="img_cont_msg">
              <img
                src={generateTextAvatar(getInitialsFromUserName(message.firstName, message.lastName))}
                alt="user-icon"
                className="rounded-circle user_img_msg"
              />
            </div>
            {message.fileUrl ? (
              <DiscussionFileRender message={message} />
            ) : (
              <div className="msg_cotainer">
                <div className="d-flex flex-column">
                  <span>
                    <i className="fs-6 ">{message.firstName + " " + message.lastName}</i>
                  </span>
                  <span className="fw-light">{message.text}</span>
                </div>
                <span className="msg_time">{message.date.slice(11, 16) + " , " + convertDateToLocalTime(message.date)}</span>
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-end mb-4">
            {message.fileUrl ? (
              <DiscussionFileRender message={message} />
            ) : (
              <div className="msg_cotainer_send ">
                <div className="d-flex flex-column">
                  <span>
                    <b>
                      <i className="">{message.firstName + " " + message.lastName}</i>
                    </b>
                  </span>
                  <span className="fw-light">{message.text}</span>
                </div>
                <span className="msg_time_send">{formatDateUtcToLocalTime(message.date)}</span>
              </div>
            )}
            <div className="img_cont_msg">
              <img
                src={generateTextAvatar(getInitialsFromUserName(message.firstName, message.lastName))}
                alt="user-icon"
                className="rounded-circle user_img_msg"
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const listMessagesAndTransitions = createListofStateTransitionsAndMessage(log?.stateTransitions, log?.messages);

  return (
    <div>
      <div className="discussion-thread-container px-3">
        {listMessagesAndTransitions.length === 0 ? (
          <span>
            <i>Aucune transition à afficher !</i>
          </span>
        ) : (
          listMessagesAndTransitions.map((item, index) => {
            if (item.type === "transition") return renderStateTransitionItem(item.element, index);
            else return renderMessageItem(item.element, index);
          })
        )}
      </div>
    </div>
  );
};

export default DiscussionThread;
