import { React, useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { v4 as uuid } from "uuid";

const AddNewLeagueConfigForm = ({ postLeagueConfig, modalConfig, sortedData }) => {
  const [validInputs, setValidInputs] = useState(false);
  const [formValue, setformValue] = useState({
    leagueId: uuid(),
    name: "",
    boost: 0,
    previousLeagueId: sortedData.at(-1).leagueId,
    colorCode: null,
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    formValue.boost = parseInt(formValue.boost);
    postLeagueConfig(formValue);
    modalConfig(false);
  };

  const checkInputs = () => {
    setValidInputs(formValue.name.length >= 1 && formValue.colorCode);
  };

  const handleChanges = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event) => {
    handleChanges(event);
    checkInputs();
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Nom</Label>
          <Input type="text" name="name" id="name" required placeholder="Name" autoComplete="off" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="boost">Boost (%)</Label>
          <Input type="number" name="boost" id="boost" required placeholder="Boost" autoComplete="off" onChange={handleChange} min="0" />
        </FormGroup>
        <FormGroup>
          <Label for="boost">Code couleur </Label>
          <Input type="color" name="colorCode" id="colorCode" required onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="previousLeague">Ligue précédente</Label>
          <Input
            type="select"
            name="previousLeagueId"
            id="previousLeagueId"
            autoComplete="off"
            onChange={handleChange}
            defaultValue={sortedData.at(-1).leagueId}
          >
            <option value={null}>Pas de ligue précédente</option>
            {!sortedData || formValue.leagueId === "null"
              ? ""
              : sortedData.map((item) => {
                  return (
                    <option key={item.leagueId} value={item.leagueId}>
                      {item.name}
                    </option>
                  );
                })}
          </Input>
        </FormGroup>
        <Button color="success" type="submit" block disabled={!validInputs}>
          Ajouter
        </Button>
      </Form>
    </div>
  );
};

export default AddNewLeagueConfigForm;
