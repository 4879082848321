import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ShopDataService from "../../services/ShopDataService";
import toast from "react-hot-toast";

const notify = (notif, state) => {
  switch (state) {
    case 1:
      toast.success(notif, { position: "top-right" });
      break;
    case 0:
      toast.error(notif, { position: "top-right" });
      break;
    default:
      toast.error("La requête a échoué !");
      break;
  }
};

export const getShopItem = createAsyncThunk("shopConfigs/getShopItem", async () => {
  try {
    const response = await ShopDataService.getShopItem();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const addNewShopItem = createAsyncThunk("shopConfigs/addNewShopItem", async (data) => {
  try {
    const response = await ShopDataService.addNewShopItem(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const editShopItem = createAsyncThunk("shopConfigs/editShopItem", async (data) => {
  try {
    const response = await ShopDataService.editShopItem(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const deleteShopItem = createAsyncThunk("shopConfigs/deleteShopItem", async (data) => {
  try {
    const response = await ShopDataService.deleteShopItem(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const getShopTransactions = createAsyncThunk("shopConfigs/getShopTransactions", async () => {
  try {
    const response = await ShopDataService.getShopTransactions();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const getShopTransactionsById = createAsyncThunk("shopConfigs/getShopTransactionsById", async (data) => {
  try {
    const response = await ShopDataService.getShopTransactionsById(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const intreatmentShopTransaction = createAsyncThunk("shopConfigs/intreatmentShopTransaction", async (data) => {
  try {
    const response = await ShopDataService.intreatmentShopTransaction(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const deliverShopTransaction = createAsyncThunk("shopConfigs/deliverShopTransaction", async (data) => {
  try {
    const response = await ShopDataService.deliverShopTransaction(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});
export const indeliveryShopTransaction = createAsyncThunk("shopConfigs/indeliveryShopTransaction", async (data) => {
  try {
    const response = await ShopDataService.indeliveryShopTransaction(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const cancelShopTransaction = createAsyncThunk("shopConfigs/cancelShopTransaction", async (data) => {
  try {
    const response = await ShopDataService.cancelShopTransaction(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

const shopSlice = createSlice({
  name: "shop",
  initialState: {
    shopItemConfigs: [],
    shopTransactions: [],
    shopTransactionsById: null,
    loading_getShopConfig: true,
    loading_editShopItem: true,
    loading_getshopTransactions: true,
    loading_getshopTransactionsById: true,
    loading_deleteShopItem: true,
    refreshAfterTransactionStateUpdate: true,
    dataPost: [],
    majPage: false,
    refreshShopPageAfterUpdate: false,
  },
  reducers: {},
  extraReducers: {
    [getShopItem.pending]: (state) => {
      state.loading_getShopConfig = true;
    },
    [getShopItem.fulfilled]: (state, { payload }) => {
      state.loading_getShopConfig = false;
      state.shopItemConfigs = payload;
      if (state.shopItemConfigs.status !== 200 && state.dataPost.title) {
        /* No action if status === 200, so we check for errors only */
        notify(state.dataPost.title, 0);
      }
    },
    // get shop transaction
    [getShopTransactions.pending]: (state) => {
      state.loading_getshopTransactions = true;
    },
    [getShopTransactions.fulfilled]: (state, { payload }) => {
      state.loading_getshopTransactions = false;
      state.shopTransactions = payload;
      if (state.shopItemConfigs.status !== 200 && state.dataPost.title) {
        /* No action if status === 200, so we check for errors only */
        notify(state.dataPost.title, 0);
      }
    },
    // get shop transaction by userId
    [getShopTransactionsById.pending]: (state) => {
      state.getShopTransactionsById = true;
    },
    [getShopTransactionsById.fulfilled]: (state, { payload }) => {
      state.loading_getshopTransactionsById = false;
      state.shopTransactionsById = payload;
      if (state.shopItemConfigs.status !== 200 && state.dataPost.title) {
        /* No action if status === 200, so we check for errors only */
        notify(state.dataPost.title, 0);
      }
    },
    // add shop item
    [addNewShopItem.fulfilled]: (state, { payload }) => {
      state.refreshShopPageAfterUpdate = !state.refreshShopPageAfterUpdate;
      if (payload.status === 200) {
        notify("article ajouté avec succès !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [editShopItem.fulfilled]: (state, { payload }) => {
      state.loading_editShopItem = false;
      state.dataPost = payload;
      state.refreshShopPageAfterUpdate = !state.refreshShopPageAfterUpdate;
      if (state.dataPost.status === 200) {
        notify("article modifié avec succès !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [deleteShopItem.fulfilled]: (state, { payload }) => {
      state.loading_deleteShopItem = false;
      state.dataPost = payload;
      state.refreshShopPageAfterUpdate = !state.refreshShopPageAfterUpdate;
      if (state.dataPost.status === 200) {
        notify("Article supprimé avec succès !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },

    [intreatmentShopTransaction.fulfilled]: (state, { payload }) => {
      if (payload.status === 200) {
        state.refreshAfterTransactionStateUpdate = !state.refreshAfterTransactionStateUpdate;
        notify("statut de transaction modifié avec succès !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [deliverShopTransaction.fulfilled]: (state, { payload }) => {
      if (payload.status === 200) {
        state.refreshAfterTransactionStateUpdate = !state.refreshAfterTransactionStateUpdate;
        notify("statut de transaction modifié avec succès !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [indeliveryShopTransaction.fulfilled]: (state, { payload }) => {
      if (payload.status === 200) {
        state.refreshAfterTransactionStateUpdate = !state.refreshAfterTransactionStateUpdate;
        notify("statut de transaction modifié avec succès !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
    [cancelShopTransaction.fulfilled]: (state, { payload }) => {
      if (payload.status === 200) {
        state.refreshAfterTransactionStateUpdate = !state.refreshAfterTransactionStateUpdate;
        notify("statut de transaction modifié avec succès !", 1);
      } else if (state.dataPost.title) {
        notify(state.dataPost.title, 0);
      }
    },
  },
});

export default shopSlice.reducer;
