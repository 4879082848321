import { React, useState } from "react";
import AddNewBlocConfigForm from "../forms/AddNewBlocConfigForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const AddNewBlocConfigModal = (props) => {
  const [modal, setModal] = useState(props.addBlocFromQuestConfig ? props.addBlocFromQuestConfig : false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const myBtn = (
    <button className="add-questConf-btn" onClick={toggle}>
      <i className="bx bxs-plus-circle"></i>
    </button>
  );

  return (
    <div>
      {props.addBlocFromQuestConfig ? "" : myBtn}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Ajout Nouveau Bloc
        </ModalHeader>
        <ModalBody>
          <AddNewBlocConfigForm postBlocConfig={props.postBlocConfig} modalConfig={setModal} getNewBlocConfig={props.getNewBlocConfig} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewBlocConfigModal;
