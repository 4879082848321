import http from "./http-common";

class PepDataService {
  getPepActions() {
    return http.get("/pep/actions?fromBo=true");
  }

  getPepActionsByUserId(userId) {
    return http.get(`/pep/actions?userId=${userId}&fromBo=true`);
  }

  getActionConfigById(id) {
    return http.get(`/pepConfig/actionConfig/${id}`);
  }

  getPepConfigFullTree() {
    return http.get("/pepConfig/fulltree");
  }

  // pep
  addNewPepAction(data) {
    return http.post("/pep/actions", data);
  }

  // axis
  addNewPepAxis(data) {
    return http.post("/pepConfig/axis", data);
  }

  EditPepAxis(data) {
    return http.put("/pepConfig/axis", data);
  }

  DeletePepAxis(id) {
    return http.delete(`/pepConfig/axis/${id}`);
  }
  // domain
  addNewPepDomain(data) {
    return http.post("/pepConfig/domain", data);
  }

  EditPepDomain(data) {
    return http.put("/pepConfig/domain", data);
  }

  deletePepDomain(id) {
    return http.delete(`/pepConfig/domain/${id}`);
  }

  // skill

  addNewPepSkill(data) {
    return http.post("/pepConfig/skill", data);
  }

  EditPepSkill(data) {
    return http.put("/pepConfig/skill", data);
  }

  deletePepSkill(id) {
    return http.delete(`/pepConfig/skill/${id}`);
  }

  // actionConfig

  addNewPepActionConfig(data) {
    return http.post("/pepConfig/actionConfig", data);
  }

  editPepAction(id, data) {
    return http.patch(`/pepConfig/actionConfig/${id}`, data);
  }

  deletePepActionConfig(id) {
    return http.delete(`/pepConfig/actionConfig/${id}`);
  }

  // ressource

  addResource(data) {
    return http.post("/pepConfig/resource", data);
  }

  editResource(id, data) {
    return http.patch(`/pepConfig/resource/${id}`, data);
  }
}

export default new PepDataService();
