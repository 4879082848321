import { React, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoadingFailNotification from "../PageLoadingFailNotification/PageLoadingFailNotification";
import SortAndSearchTable from "./sortAndSearchTable/SortAndSearchTable";
import Enums from "../../assets/JsonData/Enums";
import { setCurrentUserId } from "../../redux/slices/UserSlice";
import { orderBy, deburr, toLower } from "lodash";
import userAdmin from "../../assets/images/user-admin.svg";

const UsersTable = () => {
  const { usersInfos, refreshUserPage } = useSelector((state) => ({ ...state.userInfos }));
  const { seasonInfos } = useSelector((state) => ({ ...state.seasonList }));
  const { leagueConfigs } = useSelector((state) => ({ ...state.leagueConfig }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getLeagueTitleFromLeagueId = (leagueId) => {
    let result;
    if (leagueConfigs?.length === 0 || leagueConfigs.data === undefined) return "vide";
    else {
      result = leagueConfigs.data.find((league) => league.leagueId === leagueId);
      return result === undefined ? " " : result.name;
    }
  };

  /* eslint @typescript-eslint/no-unused-vars: "off" */

  const getTeamNameFromTeamId = (teamId) => {
    let teamName = "";
    if (!seasonInfos) return teamName;
    else {
      teamName = seasonInfos?.data?.seasonTeams.find((item) => item.teamId === teamId);
      return teamName === undefined ? "" : teamName.name;
    }
  };
  const columns = [
    {
      Header: "Prénom & Nom",
      accessor: (row) => row.firstName + " " + row.lastName,
      Cell: ({ value, row }) => (
        <span>
          {value} {row.original.canChangeTeamDuringSeason && <img src={userAdmin} alt="AM" width={14} style={{ verticalAlign: "baseline" }} />}
        </span>
      ),
      sortType: (rowA, rowB, columnId) => {
        const nameA = rowA.values[columnId].toLowerCase();
        const nameB = rowB.values[columnId].toLowerCase();
        return nameA.localeCompare(nameB);
      },
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ row }) => Enums.matchClass(row.original.role) + (row.original.isGhost ? " (ghost)" : ""),
    },
    {
      Header: "Niveau",
      accessor: "experience",
      Cell: ({ value }) => value.levelId,
      sortType: (rowA, rowB, columnId) => {
        const levelA = rowA.values[columnId].levelId;
        const levelB = rowB.values[columnId].levelId;

        return levelA - levelB;
      },
    },
    {
      Header: "Ligue",
      accessor: "leagueId",
      Cell: ({ value }) => getLeagueTitleFromLeagueId(value),
    },
    {
      Header: "Boost total (%)",
      accessor: "totalBoost",
      Cell: ({ value }) => value ?? "",
    },
    {
      Header: "Equipe",
      accessor: "teamId",
      Cell: ({ value }) => getTeamNameFromTeamId(value),
    },
    {
      Header: "Cristaux",
      accessor: "score",
    },
    {
      Header: "Or",
      accessor: "coins",
    },
    {
      Header: "Activé",
      accessor: "isActivated",
      Cell: ({ value }) => (value === true ? "Oui" : "Non"),
      sortType: "basic",
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off" */
  const tableHooks = (hooks) => {};
  const handleRowClicked = (row) => {
    dispatch(setCurrentUserId(row.original));
    navigate("/UserDetailledInfosPage/" + row.original.firstName + "-" + row.original.lastName, { state: { currentUserInfos: row.original } });
  };

  const getOrderedData = useCallback(() => {
    return orderBy(usersInfos.data, [(user) => deburr(toLower(user.firstName))], ["asc"]);
  }, [usersInfos]);

  return (
    <div>
      {usersInfos && usersInfos.status === 200 && usersInfos.data ? (
        <SortAndSearchTable
          data={getOrderedData() ?? []}
          columns={columns}
          tableHooks={tableHooks}
          refresh={refreshUserPage}
          handleRowClicked={handleRowClicked}
        ></SortAndSearchTable>
      ) : (
        <PageLoadingFailNotification text={"La répuration des données a échoué !"} />
      )}
    </div>
  );
};

export default UsersTable;
