import { React, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input, Row, Col, Alert } from "reactstrap";
import Select from "react-select";
import Enums from "../../assets/JsonData/Enums";
import ToggleButton from "../buttons/toggleButton/ToggleButton";

/* eslint react/no-unescaped-entities: "off" */
const AddNewQuestSeasonConfigForm = ({ questConfig, addNewQuestSeasonConfig, modalConfig }) => {
  const { seasonList } = useSelector((state) => ({ ...state.seasonList }));
  const { questConfigs } = useSelector((state) => ({ ...state.questConfigs }));
  const [illimitedIteration, setIllimitedIteration] = useState(false);
  const [formValue, setformValue] = useState({
    questConfigId: questConfig.questConfigId,
    seasonId: "",
    role: "",
    coins: "",
    score: "",
    maxIteration: "",
    isEnabled: "",
    gift: "false",
    addToFutureSeasons: false,
    expirationDate: null,
    expirationDuration: null,
    months: null,
    days: null,
    hours: null,
    expirationType: null /* 0 : null, 1: Date, 2 : Duration */,
  });

  const getExistingClassAndSeasonIdList = () => {
    let list = [];
    questConfig.questSeasonConfigContracts.map((item) => {
      let element = { role: item.role, seasonId: item.seasonId };
      list.push(element);
    });

    return list;
  };

  const isSeasonOfType = (seasonId, seasonType) => {
    var found = false;

    if (seasonList !== undefined && seasonList.data !== undefined) {
      const tabLength = seasonList.data.length;
      var counter = 0;
      while (tabLength > counter && found === false) {
        if (seasonList.data[counter].seasonId === seasonId && seasonList.data[counter].seasonType === seasonType) found = true;
        counter++;
      }
    }
    return found;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = checkQuestSeasonConfigFormEntriesValidity();
    if (isValid === 1) {
      addNewQuestSeasonConfig(formValue);
      modalConfig(false);
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckBoxChange = (event) => {
    if (event.target.name === "illimitedIteration") {
      setIllimitedIteration(event.target.checked);
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["maxIteration"]: event.target.checked ? null : "",
        };
      });
    }
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });

    if (formValue.expirationType === 1) {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["expirationDuration"]: null,
        };
      });
    }

    if (formValue.expirationType === 2) {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["expirationDate"]: null,
        };
      });
    }

    if (formValue.expirationType === 0) {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["expirationDate"]: null,
          ["expirationDuration"]: null,
        };
      });
    }

    if (event.name === "role") {
      setformValue((prevalue) => {
        return {
          ...prevalue,
          ["maxIteration"]: getMaxIterationFromParentIfExists(choice?.value),
        };
      });
    }
  };

  const handleReset = () => {
    modalConfig(false);
  };

  const handleToggleButtonChange = () => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        addToFutureSeasons: !formValue.addToFutureSeasons,
      };
    });
  };

  const questExpirationTypeOptions = () => {
    let choices = [];

    choices.push({ value: 0, label: "Vide" });
    choices.push({ value: 1, label: "Date" });
    choices.push({ value: 2, label: "Durée" });

    return choices;
  };

  const questRoleTypeOptions = () => {
    let choices = [];

    choices.push({ value: 0, label: "Tout le monde" });
    choices.push({ value: 1, label: "Explorateur" });
    choices.push({ value: 2, label: "Guide" });
    choices.push({ value: 4, label: "Personnelle" });

    return choices;
  };

  const getNumberOptionsFromGivenInterval = (max) => {
    let choices = [];

    for (let index = 0; index <= max; index++) {
      choices.push({ value: index, label: index });
    }
    return choices;
  };

  const [alertVisiblity, setAlertVisiblity] = useState(false);
  const [alertText, setAlertText] = useState("");
  const handleAlertVisiblity = () => {
    setAlertVisiblity(!alertVisiblity);
  };

  const doesQuestSeasonTypeAlreadyExist = (existingClassAndSeasonIdList, seasonType) => {
    if (!existingClassAndSeasonIdList) return false;
    else {
      const tabLength = existingClassAndSeasonIdList.length;
      var counter = 0;
      var found = false;
      while (tabLength > counter && found === false) {
        if (isSeasonOfType(existingClassAndSeasonIdList[counter].seasonId, seasonType)) found = true;
        counter++;
      }
      return found;
    }
  };

  const doesGivenRoleIntersaisonQuestAlreadyExist = (existingClassAndSeasonIdList, role) => {
    if (!existingClassAndSeasonIdList) return false;
    else {
      const tabLength = existingClassAndSeasonIdList.length;
      var counter = 0;
      var found = false;
      while (tabLength > counter && found === false) {
        if (existingClassAndSeasonIdList[counter].seasonId === null && existingClassAndSeasonIdList[counter].role === role) found = true;
        counter++;
      }
      return found;
    }
  };

  const doesClassAndSeasonIdAlreadyExist = (existingClassAndSeasonIdList, ClassAndSeasonId) => {
    if (existingClassAndSeasonIdList === false) return false;
    else return existingClassAndSeasonIdList.find((item) => item.seasonId === ClassAndSeasonId.seasonId && item.role === ClassAndSeasonId.role) != undefined;
  };

  const doesQuestConfigHasQuestConfigParent = useMemo(() => {
    if (questConfigs && questConfigs.data) {
      let questConfigParent = questConfigs.data?.find((qc) => qc.questConfigId === formValue.questConfigId);
      return questConfigParent ? questConfigParent.unlockedBy !== null : false;
    }
    return false;
  }, [questConfigs, formValue]);

  const getMaxIterationFromParentIfExists = (role) => {
    let questConf = questConfigs?.data.find((qc) => qc.questConfigId === formValue.questConfigId);
    let questConfParent = questConf ? questConfigs?.data.find((qc) => qc.questConfigId === questConf.unlockedBy) : undefined;
    let correctSeasonId = formValue.seasonId === "null" ? null : formValue.seasonId;
    if (questConfParent) {
      let questSeasonConf = questConfParent?.questSeasonConfigContracts.find((qsc) => qsc.role === role && qsc.seasonId === correctSeasonId);
      return questSeasonConf ? questSeasonConf.maxIteration : 1;
    }

    return 1;
  };

  const checkQuestSeasonConfigFormEntriesValidity = () => {
    const existingClassAndSeasonIdList = getExistingClassAndSeasonIdList();

    // on ne peut pas avoir deux fois une même quête pour la même classe et de même seasonId ( unicité role & SeasonId)
    const newClassAndSeasonId = { role: parseInt(formValue.role), seasonId: formValue.seasonId };

    if (doesClassAndSeasonIdAlreadyExist(existingClassAndSeasonIdList, newClassAndSeasonId)) {
      setAlertVisiblity(true);
      setAlertText("Expedition  et rôle répétés !");
      return 0;
    }

    //s'il existe déjà une quête d'expédition pour une classe d'utilisateur donnée, alors impossible d'ajouter pour cette classe une quête de type inter-expédition
    // on a fait le test uniquement avec une expédition courante à verif pour les autres expédition si le besoin est
    if (isSeasonOfType(formValue.seasonId, "null") && doesQuestSeasonTypeAlreadyExist(existingClassAndSeasonIdList, 2)) {
      setAlertVisiblity(true);
      setAlertText(" Ajout Impossible !, il y'a une quête d'expédition existante  .");
      return 0;
    }

    // s'il existe déjà une quête de type inter-expédition destinée à tout le monde alors imposible de d'ajouter une nouvelle quête d'expédition
    if (existingClassAndSeasonIdList.some((exped) => exped.seasonId === null && exped.role === 0)) {
      setAlertVisiblity(true);
      setAlertText("Impossible !, une quête inter-expédition générale existe déjà.");
      return 0;
    }

    // s'il existe déjà une quête de type inter-expédition destinée à un rôle specifique, alors imposible de d'ajouter une nouvelle quête d'expédition pour ce rôle
    if (doesGivenRoleIntersaisonQuestAlreadyExist(existingClassAndSeasonIdList, newClassAndSeasonId.role)) {
      setAlertVisiblity(true);
      setAlertText("Impossible !, une quête inter-expédition pour ce rôle existe déjà.");
      return 0;
    }
    // s'il existe déjà une quête de type inter-expédition destinée à un rôle specifique, alors imposible de d'ajouter une nouvelle quête pour le rôle "commun"
    if (existingClassAndSeasonIdList.some((exped) => exped.seasonId === null) && newClassAndSeasonId.role === 0) {
      setAlertVisiblity(true);
      setAlertText("Impossible !, une quête inter-expédition existe déjà.");
      return 0;
    }

    //checking if expiration field is correctly filled
    if (formValue.expirationType === 1 && !formValue.expirationDate) {
      setAlertText("Veuillez donner une date d'expiration correcte !");
      setAlertVisiblity(true);
      return 0;
    }

    if (formValue.expirationType === 2 && !formValue.months && !formValue.days && !formValue.hours) {
      setAlertText("Veuillez donner une durée d'expiration correcte !");
      setAlertVisiblity(true);
      return 0;
    }

    if (formValue.maxIteration && (isNaN(formValue.maxIteration) || parseInt(formValue.maxIteration < 1))) {
      setAlertText("La valeur max Itération doit être illimitée ou > 1 !");
      setAlertVisiblity(true);
      return 0;
    }
    return 1;
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Alert color="danger" isOpen={alertVisiblity} toggle={handleAlertVisiblity}>
            {alertText}
          </Alert>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="seasonId">Type d'expédition</Label>
          <Input type="select" name="seasonId" id="seasonId" onChange={handleChange} required placeholder="Catégorie" autoComplete="off" defaultValue="">
            <option value="" disabled={true}>
              Choisir une expédition
            </option>
            <option value="null">{"Interexpédition => (permanente)"}</option>
            {!seasonList || !seasonList.data
              ? ""
              : seasonList.data.map((item, index) => {
                  if (item.seasonType !== 0)
                    return (
                      <option value={item.seasonId} key={index}>
                        {item.title} &nbsp; &rArr;&nbsp; ({Enums.matchSeasonType(item.seasonType)})
                      </option>
                    );
                })}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="expirationType">
            Expiration type<sup className="required-field-mark">*</sup>
          </Label>
          <Select
            name="expirationType"
            id="expirationType"
            autoComplete="off"
            required
            defaultValue={formValue.expirationType}
            isClearable
            isSearchable
            onChange={handleReactSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            placeholder="choisir un type"
            classNamePrefix="choisir un type"
            options={questExpirationTypeOptions()}
          />
        </FormGroup>

        <FormGroup>
          {formValue.expirationType === 1 ? (
            <FormGroup>
              <Label htmlFor="expirationDate">Date</Label>
              <Input type="datetime-local" name="expirationDate" id="expirationDate" onChange={handleChange} />
            </FormGroup>
          ) : formValue.expirationType === 2 ? (
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="months">Mois</Label>
                  <Select
                    name="months"
                    id="months"
                    autoComplete="off"
                    required
                    defaultValue={0}
                    onChange={handleReactSelectChange}
                    isSearchable
                    placeholder="00"
                    classNamePrefix="00"
                    options={getNumberOptionsFromGivenInterval(12)}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="days">Jours</Label>
                  <Select
                    name="days"
                    id="days"
                    autoComplete="off"
                    required
                    defaultValue={0}
                    onChange={handleReactSelectChange}
                    isSearchable
                    placeholder="00"
                    classNamePrefix="00"
                    options={getNumberOptionsFromGivenInterval(30)}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="hours">Heures</Label>
                  <Select
                    name="hours"
                    id="hours"
                    autoComplete="off"
                    required
                    defaultValue={0}
                    onChange={handleReactSelectChange}
                    isSearchable
                    placeholder="00"
                    classNamePrefix="00"
                    options={getNumberOptionsFromGivenInterval(23)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="role">Rôle</Label>
          <Select
            name="role"
            id="role"
            autoComplete="off"
            required
            isClearable
            isSearchable
            onChange={handleReactSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            placeholder="choisir le rôle"
            options={questRoleTypeOptions()}
          />
        </FormGroup>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="coins">Coins</Label>
              <Input type="number" min="0" name="coins" id="coins" onChange={handleChange} required placeholder="Coins" autoComplete="off" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="score">Score</Label>
              <Input type="number" min="0" name="score" id="score" onChange={handleChange} required placeholder="Score" autoComplete="off" />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="maxIteration">Max Iteration</Label>
              <Input
                type="number"
                min={1}
                max={30}
                id="maxIteration"
                name="maxIteration"
                placeholder="Max Iteration"
                onChange={handleChange}
                value={formValue.maxIteration}
                disabled={doesQuestConfigHasQuestConfigParent || illimitedIteration}
              />

              <small>
                <div className="form-check py-2 align-item-center">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="illimitedIteration"
                    name="illimitedIteration"
                    checked={illimitedIteration}
                    onChange={handleCheckBoxChange}
                    disabled={doesQuestConfigHasQuestConfigParent}
                  />
                  <label className="form-check-label" htmlFor="illimitedIteration">
                    illimitée
                  </label>
                </div>
              </small>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="gift">Cadeau</Label>
              <Input type="select" name="gift" id="gift" autoComplete="off" required onChange={handleChange}>
                <option value={"false"}>Non</option>
                <option value={"true"}>Oui</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="isEnabled">Statut de la quête</Label>
              <Input type="select" name="isEnabled" id="isEnabled" onChange={handleChange} required autoComplete="off" defaultValue="">
                <option value="" disabled={true}>
                  - Actif / Inactif -
                </option>
                <option value={true}> Actif</option>
                <option value={false}> Inactif</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Ajouter aux futures expéditions</Label>
              <ToggleButton state={formValue.addToFutureSeasons} onClickHandler={handleToggleButtonChange}></ToggleButton>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Button color="danger" type="reset" block>
              Annuler
            </Button>
          </Col>
          <Col md={6}>
            <Button color="success" type="submit" block>
              Ajouter
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddNewQuestSeasonConfigForm;
