import React from "react";
import { useEffect } from "react";
import { getSeasonInfos, getSeasonList } from "../redux/slices/SeasonSlice";
import { getUsersInfos } from "../redux/slices/UserSlice";
import { addNewUser } from "../redux/slices/UserSlice";
import { getAllLeagueConfigs } from "../redux/slices/LeagueConfigSlice";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/loader/Loader";
import UsersTable from "../components/table/UsersTable";
import AddNewUserModal from "../components/modals/AddNewUserModal";
import { Toaster } from "react-hot-toast";

const UsersPage = () => {
  const { loading_getUsersInfos, refreshUserPage, usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { loading_getLeagueConfigs } = useSelector((state) => ({ ...state.leagueConfig }));
  const { seasonList, loading_getSeasonInfos } = useSelector((state) => ({ ...state.seasonList }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersInfos());
    dispatch(getSeasonInfos());
    dispatch(getSeasonList());
    dispatch(getAllLeagueConfigs());
  }, [refreshUserPage]);

  const addUser = (formvalue) => {
    const payload = {
      UserId: formvalue.UserId,
      FirstName: formvalue.FirstName,
      LastName: formvalue.LastName,
      Email: formvalue.Email + "@diggers-consulting.com",
      JobDescription: formvalue.JobDescription,
      Role: parseInt(formvalue.Role),
      DigDaysInitialStock: parseInt(formvalue.DigDaysInitialStock),
      BirthDay: new Date(formvalue.BirthDay).toISOString(),
      ContractAnniversary: new Date(formvalue.ContractAnniversary).toISOString(),
      isGhost: parseInt(formvalue.Role) === 1 ? false : formvalue.isGhost,
      operatingManagerId: formvalue.operatingManagerId,
    };
    dispatch(addNewUser(payload));
  };

  let currentSeason;
  if (seasonList?.data) {
    for (let i in seasonList.data) {
      if (seasonList.data[i].seasonType === 1) {
        currentSeason = seasonList.data[i];
        break;
      }
    }
  }

  return (
    <div>
      <div>
        <Toaster></Toaster>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="page-header">Liste des utilisateurs</h2>
        </div>
        <div className="float-right">{usersInfos && <AddNewUserModal addUser={addUser} currentSeason={currentSeason}></AddNewUserModal>}</div>
      </div>
      <div className="row">
        {loading_getUsersInfos || loading_getLeagueConfigs || !usersInfos || loading_getSeasonInfos ? <Loader></Loader> : <UsersTable></UsersTable>}
      </div>
    </div>
  );
};

export default UsersPage;
