import React from "react";
import QuestValidationButton from "./QuestValidationButton";

const QuestValidationActions = ({ quest, postQuestDecision }) => {
  return (
    <div className="d-flex justify-content-center">
      <QuestValidationButton
        quest={quest}
        color={"success"}
        icon={"bx bx-check-circle"}
        tooltipText={" Validation de la quête"}
        decision={"validation"}
        postQuestDecision={postQuestDecision}
      />
      <QuestValidationButton
        quest={quest}
        color={"warning"}
        icon={"bx bx-refresh"}
        tooltipText={"Demander un ajustement de la part de l'utilisateur"}
        decision={"adjustment"}
        postQuestDecision={postQuestDecision}
      />
      <QuestValidationButton
        quest={quest}
        color={"danger"}
        icon={"bx bx-x-circle"}
        tooltipText={"Rejeter la demande de validation"}
        decision={"rejection"}
        postQuestDecision={postQuestDecision}
      />
    </div>
  );
};

export default QuestValidationActions;
