import React from "react";
import { Button } from "reactstrap";

const DPlusButtonAdd = ({ title = "Ajouter", handleClick }) => {
  const handleButtonClick = () => {
    handleClick();
  };
  return (
    <div>
      <Button color={"success"} style={{ boxShadow: "3px 3px 3px grey" }} onClick={handleButtonClick}>
        {title}
      </Button>
    </div>
  );
};

export default DPlusButtonAdd;
