import { React, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Col, Row } from "reactstrap";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import CreatableSelect from "react-select/creatable";
const EditShopItemConfigForm = ({ label, shopItem, handleDeleteShopItemConfig, handlePostShopItemConfig, modalConfig, existingThemeList }) => {
  const { isConfirmed } = useConfirm();
  const [validInputs, setValidInputs] = useState(false);
  const [formValue, setformValue] = useState({
    shopItemId: shopItem.shopItemId,
    name: shopItem.name,
    description: shopItem.description,
    theme: shopItem.theme,
    cost: shopItem.cost,
    stock: shopItem.stock,
    stockIllimite: shopItem.stock === null,
    isActivated: shopItem.isActivated,
    pictureData: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (label === "Edit") {
      handlePostShopItemConfig(formValue);
    }
    if (label === "Delete") {
      const confirmed = await isConfirmed("Souhaitez vous supprimer cet article de la boutique ?");
      if (confirmed) {
        handleDeleteShopItemConfig(formValue);
      }
    }

    modalConfig(false);
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
    if (!validInputs) {
      setValidInputs(true);
    }
  };

  const handleCheckBoxChange = (event) => {
    if (event.target.name === "stockIllimite") {
      setformValue({
        ...formValue,
        stockIllimite: event.target.checked,
        stock: event.target.checked ? "" : formValue.stock,
      });
    }
  };

  const handleFileInputChange = (event) => {
    const fileList = event.target.files;
    setformValue({
      ...formValue,
      pictureData: fileList[0],
    });
    if (!validInputs) {
      setValidInputs(true);
    }
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });
  };

  const getThemeOptions = () => {
    let options = [];
    existingThemeList?.map((theme) => options.push({ value: theme, label: theme }));
    return options;
  };

  return (
    <div>
      <ConfirmModal></ConfirmModal>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Nom</Label>
          <Input
            type="text"
            name="name"
            id="name"
            required
            placeholder="Name"
            autoComplete="off"
            onChange={handleChange}
            defaultValue={formValue.name}
            readOnly={label !== "Edit"}
          />
        </FormGroup>

        <FormGroup>
          <Label for="description">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            required
            placeholder="Description"
            autoComplete="off"
            onChange={handleChange}
            defaultValue={formValue.description}
            readOnly={label !== "Edit"}
          />
        </FormGroup>

        <FormGroup>
          <Label for="theme">Thème</Label>
          <CreatableSelect
            name="theme"
            isClearable
            isSearchable
            options={getThemeOptions()}
            onChange={handleReactSelectChange}
            noOptionsMessage={() => "Pas d'options"}
            formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
            placeholder="Thème ..."
            defaultValue={getThemeOptions().filter((opt) => opt.value === formValue.theme)}
            readOnly={label !== "Edit"}
          />
        </FormGroup>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="cost">Prix</Label>
              <Input
                type="number"
                min="0"
                name="cost"
                id="cost"
                required
                placeholder="Cost"
                autoComplete="off"
                onChange={handleChange}
                defaultValue={formValue.cost}
                readOnly={label !== "Edit"}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="stock">Stock</Label>
              <Input
                type="number"
                min="0"
                name="stock"
                id="stock"
                required={!formValue.stockIllimite}
                placeholder="Stock"
                autoComplete="off"
                onChange={handleChange}
                value={formValue.stock}
                disabled={formValue.stockIllimite || label !== "Edit"}
              />
              <small>
                <div className="form-check py-2 align-item-center">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="stockIllimite"
                    name="stockIllimite"
                    onChange={handleCheckBoxChange}
                    disabled={label !== "Edit"}
                    defaultChecked={formValue.stockIllimite}
                  />
                  <label className="form-check-label" for="stockIllimite">
                    Stock illimité
                  </label>
                </div>
              </small>
            </FormGroup>
          </Col>
        </Row>
        {label === "Edit" ? (
          <div>
            <FormGroup className="mb-4">
              <Label for="exampleFile">Image</Label>
              <Input id="exampleFile" name="file" type="file" accept="image/png, image/jpeg" onChange={handleFileInputChange} readOnly={label !== "Edit"} />
            </FormGroup>
          </div>
        ) : (
          ""
        )}

        {label === "Edit" ? (
          <Button color="success" type="submit" block>
            Modifier
          </Button>
        ) : (
          <Button color="danger" type="submit">
            Supprimer
          </Button>
        )}
      </Form>
    </div>
  );
};

export default EditShopItemConfigForm;
