import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import QuestDataService from "../../services/QuestDataService";
import toast from "react-hot-toast";

const notify = (notif, state) => {
  switch (state) {
    case 1:
      toast.success(notif, { position: "top-right" });
      break;
    case 0:
      toast.error(notif, { position: "top-right" });
      break;
    default:
      toast.error(" La récuperation des données a échoué !");
      break;
  }
};

export const getQuestList = createAsyncThunk("quest/getQuestList", async () => {
  try {
    const response = await QuestDataService.getQuestList();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const getQuestListById = createAsyncThunk("quest/getQuestListById", async (data) => {
  try {
    const response = await QuestDataService.getQuestListById(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const getCurrentQuestList = createAsyncThunk("quest/getCurrentQuestList", async () => {
  try {
    const response = await QuestDataService.getCurrentQuestList();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

export const postQuestDecision = createAsyncThunk("quest/postQuestDecision", async (data) => {
  try {
    const response = await QuestDataService.postQuestDecision(data);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(err.response.data.title, 0);
    return err.response.data;
  }
});

const questInfosSlice = createSlice({
  name: "quest",
  initialState: {
    questList: [],
    questListById: null,
    curretQuestList: [],
    questDecision: "",
    loading_getQuestList: true,
    loading_getQuestListById: true,
    loading_getcurrentQuestList: true,
    loading_postQuestDecision: true,
    dataPost: [],
    refreshQuestPageAfterDecisionUpdating: false,
  },
  reducers: {},
  extraReducers: {
    [getQuestList.pending]: (state) => {
      state.loading_getQuestList = true;
    },
    [getQuestList.fulfilled]: (state, { payload }) => {
      state.loading_getQuestList = false;
      state.questList = payload;
    },
    //get quest list by id
    [getQuestListById.pending]: (state) => {
      state.loading_getQuestListById = true;
    },
    [getQuestListById.fulfilled]: (state, { payload }) => {
      state.loading_getQuestListById = false;
      state.questListById = payload;
    },

    [getCurrentQuestList.pending]: (state) => {
      state.loading_getCurrentQuestList = true;
    },
    [getCurrentQuestList.fulfilled]: (state, { payload }) => {
      state.loading_getCurrentQuestList = false;
      state.currentQuestList = payload;
      if (state.currentQuestList.status === 200) notify(10);
    },

    [getCurrentQuestList.pending]: (state) => {
      state.loading_postQuestDecision = true;
    },
    [postQuestDecision.fulfilled]: (state, { payload }) => {
      state.loading_postQuestDecision = false;
      state.questDecision = payload;
      if (state.questDecision.status === 200) {
        notify("L'état de la quête a été modifié avec succès", 1);
        state.refreshQuestPageAfterDecisionUpdating = !state.refreshQuestPageAfterDecisionUpdating;
      }
    },
  },
});

export default questInfosSlice.reducer;
