import React, { useState } from "react";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form, FormGroup, Label, Input, Row, Col, InputGroup, InputGroupText } from "reactstrap";

/* eslint react/no-unescaped-entities: "off" */
const EditSeasonConfigForm = (props) => {
  const { isConfirmed } = useConfirm();

  const [formValue, setformValue] = useState({
    title: props.item.title,
    submissionEndDate: typeof props.item.submissionEndDate === "string" ? new Date(props.item.submissionEndDate) : props.item.submissionEndDate,
    minConsultantScoreUpgradingLeague: props.item.minConsultantScoreUpgradingLeague,
    maxConsultantScoreDowngradingLeague: props.item.maxConsultantScoreDowngradingLeague,
    consultantPercentageDowngradingLeague: props.item.consultantPercentageDowngradingLeague * 100,
    consultantPercentageUpgradingLeague: props.item.consultantPercentageUpgradingLeague * 100,
    minGuideScoreUpgradingLeague: props.item.minGuideScoreUpgradingLeague,
    maxGuideScoreDowngradingLeague: props.item.maxGuideScoreDowngradingLeague,
    guidePercentageDowngradingLeague: props.item.guidePercentageDowngradingLeague * 100,
    guidePercentageUpgradingLeague: props.item.guidePercentageUpgradingLeague * 100,
    seasonType: props.item.seasonType,
  });

  const [submissionDate, submissionDateChange] = useState(formValue.submissionEndDate);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let confirmed;
    let text = (
      <span>
        <p>Souhaitez vous modifier les configurations de l'expédition en cours ?</p>
        <div className="d-flex flex-column">
          <small> - Cette action peut impacter sur les règles du jeu </small>
        </div>
      </span>
    );
    formValue.seasonType === 1 ? (confirmed = await isConfirmed(text)) : (confirmed = true);
    if (confirmed) {
      formValue.submissionEndDate = submissionDate;
      props.editSeasonConfig(formValue);
      props.modalConfig(false);
    }
  };

  const handleChange = (e) => {
    setformValue((formValue) => {
      return {
        ...formValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleReset = () => {
    props.modalConfig(false);
  };

  return (
    <div>
      <ConfirmModal></ConfirmModal>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="title">Titre de l'expédition</Label>
              <Input
                type="text"
                name="title"
                id="title"
                autoComplete="off"
                placeholder="title"
                required
                onChange={handleChange}
                defaultValue={formValue.title}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Date et heure limite de soumission des quêtes</Label>
              <br />
              <fieldset className="border px-2">
                <DatePicker
                  selected={submissionDate}
                  onChange={submissionDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="dd/MM/y HH:mm"
                />
              </fieldset>
            </FormGroup>
          </Col>
        </Row>
        {/* input for Explorateur */}
        <FormGroup>
          <fieldset className="border px-1">
            <legend className="float-none  w-auto small ">Explorateur</legend>
            <Row className="d-flex justify-content-around align-items-center">
              <Col md={5} className="d-flex flex-column">
                <FormGroup>
                  <Label for="maxConsultantScoreDowngradingLeague">Relégation - cristaux</Label>
                  <Input
                    type="number"
                    name="maxConsultantScoreDowngradingLeague"
                    id="maxConsultantScoreDowngradingLeague"
                    required
                    min={1}
                    onChange={handleChange}
                    defaultValue={formValue.maxConsultantScoreDowngradingLeague}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="consultantPercentageDowngradingLeague">Relégation - rang</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="consultantPercentageDowngradingLeague"
                      id="consultantPercentageDowngradingLeague"
                      required
                      min={1}
                      max={100}
                      onChange={handleChange}
                      defaultValue={formValue.consultantPercentageDowngradingLeague}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={1} className="d-flex" style={{ height: "100px" }}>
                <div className="vr"></div>
              </Col>
              <Col md={5} className="d-flex flex-column">
                <FormGroup>
                  <Label for="minConsultantScoreUpgradingLeague">Promotion - cristaux</Label>
                  <Input
                    type="number"
                    name="minConsultantScoreUpgradingLeague"
                    id="minConsultantScoreUpgradingLeague"
                    required
                    min={1}
                    onChange={handleChange}
                    defaultValue={formValue.minConsultantScoreUpgradingLeague}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="consultantPercentageUpgradingLeague">Promotion - rang</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="consultantPercentageUpgradingLeague"
                      id="consultantPercentageUpgradingLeague"
                      required
                      min={1}
                      max={100}
                      onChange={handleChange}
                      defaultValue={formValue.consultantPercentageUpgradingLeague}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </FormGroup>
        {/* input for guide */}
        <FormGroup>
          <fieldset className="border px-1">
            <legend className="float-none  w-auto small ">Guide</legend>
            <Row className="d-flex justify-content-around align-items-center">
              <Col md={5} className="d-flex flex-column">
                <FormGroup>
                  <Label for="maxGuideScoreDowngradingLeague">Relégation - cristaux</Label>
                  <Input
                    type="number"
                    name="maxGuideScoreDowngradingLeague"
                    id="maxGuideScoreDowngradingLeague"
                    required
                    min={1}
                    onChange={handleChange}
                    defaultValue={formValue.maxGuideScoreDowngradingLeague}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="guidePercentageDowngradingLeague">Relégation - rang</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="guidePercentageDowngradingLeague"
                      id="guidePercentageDowngradingLeague"
                      required
                      min={1}
                      max={100}
                      onChange={handleChange}
                      defaultValue={formValue.guidePercentageDowngradingLeague}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={1} className="d-flex" style={{ height: "100px" }}>
                <div className="vr"></div>
              </Col>
              <Col md={5} className="d-flex flex-column">
                <FormGroup>
                  <Label for="minGuideScoreUpgradingLeague">Promotion - cristaux</Label>
                  <Input
                    type="number"
                    name="minGuideScoreUpgradingLeague"
                    id="minGuideScoreUpgradingLeague"
                    required
                    min={1}
                    onChange={handleChange}
                    defaultValue={formValue.minGuideScoreUpgradingLeague}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="guidePercentageUpgradingLeague">Promotion - rang</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="guidePercentageUpgradingLeague"
                      id="guidePercentageUpgradingLeague"
                      required
                      min={1}
                      onChange={handleChange}
                      defaultValue={formValue.guidePercentageUpgradingLeague}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </FormGroup>

        {props.label === "Edit" ? (
          <Row>
            <Col md={6}>
              <Button color="danger" type="reset" block>
                Annuler
              </Button>
            </Col>
            <Col md={6}>
              <Button color="success" type="submit" block>
                Modifier
              </Button>
            </Col>
          </Row>
        ) : (
          <Button color="danger" type="submit">
            Supprimer
          </Button>
        )}
      </Form>
    </div>
  );
};

export default EditSeasonConfigForm;
