export const getQuestListSimpleFormatFromApiData = (data) => {
  let newList = [];
  if (data) {
    newList = Array.from(
      data.filter((elt) => elt.userQuest),
      (item) => ({
        title: item.title,
        firstName: item.userQuest.firstName,
        lastName: item.userQuest.lastName,
        iteration: item.userQuest.iteration,
        questState: item.userQuest.questState,
        lastStateTransitionDate: item.userQuest.lastStateTransitionDate,
        questId: item.userQuest.questId,
        userId: item.userQuest.userId,
        seasonId: item.seasonId,
        seasonNumber: item.seasonNumber,
        role: item.role,
        coins: item.coins,
        score: item.score,
        expirationDate: item.userQuest.expirationDate,
        hasMessageUnread: item.userQuest.hasMessageUnread,
        maxProgress: item.maxProgress,
        progress: item.userQuest.progress,
      })
    );
  }
  return newList;
};

export const getQuestStateOptionsList = () => {
  let list = [];

  list.push({ value: 0, label: "En cours" });
  list.push({ value: 1, label: "A valider" });
  list.push({ value: 2, label: "En ajustement" });
  list.push({ value: 3, label: "Validée" });
  list.push({ value: 4, label: "Rejetée" });

  return list;
};

export const GetUsersOptionsList = (usersInfos) => {
  let list = [];

  if (usersInfos && usersInfos.data) {
    usersInfos.data.map((user) => {
      if (user.role !== 3) {
        list.push({ value: user.userId, label: user.firstName + " " + user.lastName });
      }
    });
  }
  return list;
};

export const questStateBadgeColor = (questState) => {
  switch (questState) {
    case 0:
      return "secondary";
    case 1:
      return "primary";
    case 2:
      return "warning";
    case 3:
      return "success";
    case 4:
      return "danger";
    default:
      return "";
  }
};
