import { React, useState } from "react";
import EditShopItemConfigForm from "../forms/EditShopItemConfigForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const EditShopItemConfigModal = ({ buttonLabel, shopItem, postShopItemConfig, deleteShopItemConfig, existingThemeList }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const label = buttonLabel;
  let button = "";
  let title = "";
  let form = "";

  const editButton = (
    <Button className="edit-leagueConf-btn" onClick={toggle} data-bs-toggle="tooltip" data-bs-placement="top" title="Modifier cet article">
      <i className="bx bxs-edit-alt"> </i>
    </Button>
  );

  const deleteButton = (
    <Button
      color="danger"
      onClick={toggle}
      style={{ float: "left", marginRight: "10px" }}
      disabled={shopItem.numberOfTransactions !== 0}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Supprimer cet article"
    >
      <i className="bx bxs-trash"></i>
    </Button>
  );

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );

  const handlePostShopItemConfig = (formValue) => {
    postShopItemConfig(formValue);
    toggle(modal);
  };

  const handleDeleteShopItemConfig = (formValue) => {
    deleteShopItemConfig(formValue);
    toggle(modal);
  };

  if (label === "Edit") {
    button = editButton;
    title = "Modifier cet article";
    form = (
      <EditShopItemConfigForm
        shopItem={shopItem}
        label={label}
        modalConfig={setModal}
        handlePostShopItemConfig={handlePostShopItemConfig}
        existingThemeList={existingThemeList}
      />
    );
  } else {
    button = deleteButton;
    title = "Supprimer cet article'";
    form = <EditShopItemConfigForm shopItem={shopItem} label={label} modalConfig={setModal} handleDeleteShopItemConfig={handleDeleteShopItemConfig} />;
  }

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>{form}</ModalBody>
      </Modal>
    </div>
  );
};

export default EditShopItemConfigModal;
