import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeasonInfos } from "../../redux/slices/SeasonSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import SortAndSearchTable from "../table/sortAndSearchTable/SortAndSearchTable";

const ConsultantAndGuideRankTable = ({ role }) => {
  const dispatch = useDispatch();
  const { currentSeason, loading_getSeasonInfos, seasonInfos, selectedSeasonIdForRank } = useSelector((state) => ({ ...state.seasonList }));
  const { usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const { leagueConfigs } = useSelector((state) => ({ ...state.leagueConfig }));
  const navigate = useNavigate();

  useEffect(() => {
    if (currentSeason && !selectedSeasonIdForRank) {
      dispatch(getSeasonInfos(currentSeason.seasonId));
    } else {
      dispatch(getSeasonInfos(selectedSeasonIdForRank));
    }
  }, [selectedSeasonIdForRank]);

  const getConsultantListInfos = (data) => {
    let consultantList = [];
    if (data) {
      consultantList = data.filter((user) => user.userGeneralInfo.role === 1);
      consultantList.sort((a, b) => a.userSeasonInfo.rank - b.userSeasonInfo.rank);
    }

    return consultantList;
  };

  const getGuideListInfos = (data) => {
    let guideList = [];
    if (data) {
      guideList = data.filter((user) => user.userGeneralInfo.role === 2);
      guideList.sort((a, b) => a.userSeasonInfo.rank - b.userSeasonInfo.rank);
    }
    return guideList;
  };

  const getGuideisGhostStatus = (userId) => {
    let guide = undefined;
    if (usersInfos && usersInfos.data) {
      guide = usersInfos.data.find((user) => user.userId === userId);
    }
    return guide === undefined ? "" : guide.isGhost === true ? "oui" : "non";
  };

  const getLeagueTitleFromLeagueId = (leagueId) => {
    let result;
    if (!leagueConfigs || !leagueConfigs.data) return "vide";
    else {
      result = leagueConfigs.data.find((league) => league.leagueId === leagueId);
      return result === undefined ? " " : result.name;
    }
  };

  const getTeamNameFromUserId = (userId) => {
    let teamName = undefined;
    if (seasonInfos.data.seasonTeams && seasonInfos.data.seasonTeams?.length > 0) {
      teamName = seasonInfos.data.seasonTeams.find((team) => team.users.includes(userId) === true);
    }
    return teamName === undefined ? "" : teamName.name;
  };

  let columns = [
    {
      Header: "Rang",
      accessor: (row) => row.userSeasonInfo.rank, // accessor is the "key" in the data
    },
    {
      Header: "Prénom & Nom",
      accessor: (row) => row.userGeneralInfo.firstName + " " + row.userGeneralInfo.lastName,
    },
    {
      Header: "Cristaux",
      accessor: (row) => row.userSeasonInfo.score,
    },
    {
      Header: "Ligue",
      accessor: (row) => row.userSeasonInfo.leagueId,
      Cell: ({ value }) => getLeagueTitleFromLeagueId(value),
    },
    {
      Header: "Equipe",
      accessor: (row) => row.userGeneralInfo.userId,
      Cell: ({ value }) => getTeamNameFromUserId(value),
    },
    {
      Header: "Ghost",
      Cell: ({ row }) => getGuideisGhostStatus(row.original.userGeneralInfo.userId),
    },
  ];

  /* eslint @typescript-eslint/no-empty-function: "off", @typescript-eslint/no-unused-vars: "off" */
  const tableHooks = (hooks) => {};
  const handleRowClicked = (row) => {
    navigate("/UserDetailledInfosPage/" + row.original.userGeneralInfo.userId);
  };

  return (
    <div>
      {loading_getSeasonInfos || !seasonInfos || !seasonInfos.data ? (
        <Loader></Loader>
      ) : (
        <SortAndSearchTable
          data={role === "consultant" ? getConsultantListInfos(seasonInfos.data.seasonUsers) : getGuideListInfos(seasonInfos.data.seasonUsers)}
          columns={columns}
          tableHooks={tableHooks}
          refresh={true}
          handleRowClicked={handleRowClicked}
        ></SortAndSearchTable>
      )}
    </div>
  );
};

export default ConsultantAndGuideRankTable;
