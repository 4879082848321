import { React, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { formatToISOString } from "../assets/utils/UtilsFunctons";
import { getMissionDetailById, updateMission, setMissionSheetDecision } from "../redux/slices/MissionSheetSlice";
import { getUsersInfos } from "../redux/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import UpdateMissionModal from "../components/missionSheet/UpdateMissionModal";
import MissionSheetDetailsTable from "../components/missionSheet/MissionSheetDetailsTable";
import MissionSheetVersionsTable from "../components/missionSheet/MissionSheetVersionsTable";
import Loader from "../components/loader/Loader";
import PageLoadingFailNotification from "../components/PageLoadingFailNotification/PageLoadingFailNotification";
import { v4 as uuid } from "uuid";
import { Toaster } from "react-hot-toast";
import { Button, Col, Row } from "reactstrap";
import CloseMissionModal from "../components/missionSheet/close mission/CloseMissionModal";
import useUserRole from "../hooks/useUserRole";

const MissionSheetDetailPage = () => {
  const { isAdmin, hasAccessAsDeveloper, isManager } = useUserRole();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accounts } = useMsal();

  const { loading_getMissionDetailById, missionDetailById, refreshMissionPage, refreshMissionSheetPage } = useSelector((state) => ({ ...state.missionSheet }));
  const { loading_getUsersInfos, usersInfos } = useSelector((state) => ({ ...state.userInfos }));
  const handleGoBackButton = () => {
    navigate(-1);
  };

  const handleUpdateMission = (data) => {
    data.startDate = formatToISOString(data.startDate);
    dispatch(updateMission(data));
  };

  const handleMissionSheetDecison = (data) => {
    if (!data.message) {
      dispatch(setMissionSheetDecision(data));
    } else {
      let payload = {
        missionId: data.missionId,
        missionSheetId: data.missionSheetId,
        decidedState: data.decidedState,
        message: {
          messageId: uuid(),
          parentId: data.missionSheetId,
          userId: accounts[0]?.idTokenClaims?.oid,
          text: data.message,
        },
      };
      dispatch(setMissionSheetDecision(payload));
    }
  };

  useEffect(() => {
    if (params.missionId) {
      dispatch(getMissionDetailById(params.missionId));
      dispatch(getUsersInfos());
    }
  }, [refreshMissionPage, refreshMissionSheetPage, dispatch, params.missionId]);

  const getUserNameById = (userId) => {
    if (!userId || !usersInfos || !usersInfos.data) {
      return "";
    }

    let currentUser = usersInfos.data?.find((user) => user.userId === userId);

    return currentUser ? currentUser.firstName + " " + currentUser.lastName : "";
  };
  return (
    <div>
      <Toaster></Toaster>
      <div className="d-flex justify-content-between">
        <h5 className="page-header">Détails de la fiche mission</h5>
        <div>
          <Button outline color="primary" onClick={handleGoBackButton}>
            <i className="bx bx-arrow-back"> Retour</i>
          </Button>
        </div>
      </div>
      {loading_getMissionDetailById || loading_getUsersInfos ? (
        <Loader></Loader>
      ) : (
        <div>
          <ConfirmContextProvider>
            {missionDetailById && missionDetailById.data && usersInfos ? (
              <div>
                <div className="myCard">
                  {(isAdmin || hasAccessAsDeveloper || isManager) && (
                    <div className="d-flex justify-content-end">
                      <div>
                        <UpdateMissionModal mission={missionDetailById.data[0]} handleUpdateMission={handleUpdateMission}></UpdateMissionModal>
                      </div>
                      <div className="mx-2">
                        <CloseMissionModal missionSheet={missionDetailById?.data[0]} consultantName={getUserNameById(missionDetailById?.data[0]?.userId)} />
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-end"></div>
                  <Row>
                    <Col md={8} sm={12}>
                      <MissionSheetDetailsTable
                        missionSheetDetail={missionDetailById.data[0]}
                        consultantName={getUserNameById(missionDetailById?.data[0]?.userId)}
                      />
                    </Col>
                  </Row>
                  <div className="divider"></div>
                  <Row>
                    &nbsp;
                    <h5>Liste des fiches mission</h5>
                  </Row>
                  <Row>
                    <MissionSheetVersionsTable
                      missionSheets={missionDetailById.data[0]?.missionSheets ?? []}
                      missionId={missionDetailById.data[0]?.missionId}
                      consultantName={getUserNameById(missionDetailById?.data[0]?.userId)}
                      clientCompany={missionDetailById.data[0]?.clientCompany}
                      handleMissionSheetDecison={handleMissionSheetDecison}
                    />
                  </Row>
                </div>
                <br />
              </div>
            ) : (
              <PageLoadingFailNotification text="la récupération des données a echoué, veuillez actualiser la page ou vous reconnecter !" />
            )}
          </ConfirmContextProvider>
        </div>
      )}
    </div>
  );
};

export default MissionSheetDetailPage;
