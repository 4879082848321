import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../components/modals/FormConfirmDialogModal";
import { getShopItem, addNewShopItem, editShopItem, deleteShopItem } from "../redux/slices/ShopSlice";
import ConfirmContextProvider from "../components/FormConFirmDialog/FormConfirmContextProvider";
import { v4 as uuid } from "uuid";
import { Toaster } from "react-hot-toast";
import Loader from "../components/loader/Loader";
import ShopCard from "../components/cards/shopCards";
import AddNewShopItemConfigModal from "../components/modals/AddNewShopItemConfigModal";

const ShopManagement = () => {
  const dispatch = useDispatch();
  let { shopItemConfigs, loading_getShopConfig, refreshShopPageAfterUpdate } = useSelector((state) => ({ ...state.shopConfig }));

  const addNewShopItemConfig = (formValue) => {
    const payload = {
      name: formValue.name,
      description: formValue.description,
      theme: formValue.theme,
      cost: formValue.cost,
      stock: formValue.stockIllimite ? null : formValue.stock,
      isActivated: formValue.isActivated,
      shopItemId: uuid(),
      pictureData: formValue.pictureData,
    };
    dispatch(addNewShopItem(payload));
  };

  const editShopItemConfig = (formValue) => {
    const payload = {
      name: formValue.name,
      description: formValue.description,
      theme: formValue.theme,
      cost: formValue.cost,
      stock: formValue.stock,
      isActivated: formValue.isActivated,
      shopItemId: formValue.shopItemId,
      pictureData: formValue.pictureData === undefined ? null : formValue.pictureData,
    };
    dispatch(editShopItem(payload));
  };

  const activateItem = (item) => {
    let formValue = { ...item };
    formValue.isActivated = !item.isActivated;
    editShopItemConfig(formValue);
  };

  const deleteShopItemConfig = (formValue) => {
    const payload = {
      shopItemId: formValue.shopItemId,
    };
    dispatch(deleteShopItem(payload));
  };

  const getShopItemExistingThemeList = () => {
    let themeList = [];
    if (shopItemConfigs === undefined || shopItemConfigs.status !== 200 || shopItemConfigs.data === undefined) {
      return themeList;
    } else {
      themeList = Array.from(shopItemConfigs.data, (item) => item.theme);
      return [...new Set(themeList)];
    }
  };
  useEffect(() => {
    dispatch(getShopItem());
  }, [refreshShopPageAfterUpdate]);

  return (
    <div>
      <h2 className="page-header">Page de gestion de la boutique</h2>
      <div>
        <Toaster></Toaster>
      </div>
      {loading_getShopConfig ? (
        <Loader></Loader>
      ) : (
        <ConfirmContextProvider>
          <ConfirmModal></ConfirmModal>
          <div className="row">
            <div className="col-2">
              <AddNewShopItemConfigModal
                postShopItemConfig={addNewShopItemConfig}
                existingThemeList={getShopItemExistingThemeList()}
              ></AddNewShopItemConfigModal>
            </div>
          </div>
          <ShopCard
            shopItems={shopItemConfigs !== undefined || shopItemConfigs.status === 200 || shopItemConfigs.data !== undefined ? shopItemConfigs.data : {}}
            activateItem={activateItem}
            postShopItemConfig={editShopItemConfig}
            deleteShopItemConfig={deleteShopItemConfig}
            existingThemeList={getShopItemExistingThemeList()}
          ></ShopCard>
        </ConfirmContextProvider>
      )}
    </div>
  );
};

export default ShopManagement;
