import React, { useEffect, useState } from "react";
import "../../../assets/css/verticalTab.css";
import MissionSheetVersionDetailsTab from "../../missionSheet/MissionSheetVersionDetailsTab";
import Enums from "../../../assets/JsonData/Enums";
import { convertDateToLocalTime } from "../../../assets/utils/UtilsFunctons";

const UserMissionSheetTabs = ({ missionsSheets }) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
  }, [missionsSheets]);

  const missionSheetList = missionsSheets?.missionSheets;
  const handleTabClick = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  };

  return (
    <div className="user-missionSheet-tabs">
      <section className="tabs-wrapper">
        <div className="tabs-container">
          <div className="tabs-block">
            <div id="tabs-section" className="tabs">
              <ul className="tab-head">
                {missionSheetList?.map((missionsSheet, index) => {
                  return (
                    <div key={index}>
                      <li key={index}>
                        <a href="#tab-1" className={activeTab === index ? "tab-link active" : "tab-link"} onClick={(e) => handleTabClick(e, index)}>
                          <span className="tab-label"> {`V${missionsSheet.versionNumber}.0`}</span>
                        </a>
                      </li>
                    </div>
                  );
                })}
              </ul>

              {missionSheetList?.map((missionsSheet, index) => {
                return (
                  <section key={index} id="tab-1" className={activeTab === index ? "tab-body entry-content active active-content" : "tab-body entry-content"}>
                    {missionsSheet && (
                      <small className="d-flex justify-content-between fs-6 fw-light">
                        {" (" +
                          convertDateToLocalTime(missionsSheet?.missionPeriodDetails?.startDate) +
                          " - " +
                          convertDateToLocalTime(missionsSheet?.missionPeriodDetails?.endDate) +
                          ")"}
                        {Enums.matchMissionSheetState(missionsSheet?.state)}
                      </small>
                    )}
                    <br />
                    <MissionSheetVersionDetailsTab missionVersion={missionsSheet} />
                  </section>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserMissionSheetTabs;
