import React from "react";
import MissionSheetDataService from "../../../services/MissionSheetDataService";
import useModalWithContent from "../../useModalWithContent/UseModalWithContent";
import CloseMissionForm from "./CloseMissionForm";
import { notify } from "../../../assets/utils/UtilsFunctons";

const CloseMissionModal = ({ missionSheet, consultantName }) => {
  const getLatestMissionSheetState = () => {
    if (!missionSheet) {
      return 110;
    } else {
      let missionSheetState = 0;
      let missionSheetNumber = 0;
      missionSheet.missionSheets?.map((mission) => {
        if (mission?.missionSheetNumber > missionSheetNumber) {
          missionSheetState = mission?.state;
        }
      });
      return missionSheetState;
    }
  };

  let IsMissionClosable = () => {
    let latestMissionSheetState = getLatestMissionSheetState();
    if (latestMissionSheetState === 2 || latestMissionSheetState === 3) {
      return false;
    } else {
      return true;
    }
  };

  const handleCloseMission = async (formValue) => {
    const payload = {
      missionId: missionSheet?.missionId,
      endDate: formValue,
    };
    try {
      if (formValue) {
        await MissionSheetDataService.closeMission(payload);
        notify(1, "Mission clôturée avec succès !");
      }
    } catch (error) {
      notify(0, error?.response.data.title ?? "une erreur est survenue");
    } finally {
      closeModal();
    }
  };
  const handleCloseModal = () => {
    closeModal();
  };
  const { closeModal, ModalComponent, ModalButton } = useModalWithContent(
    <CloseMissionForm
      handleCloseMission={handleCloseMission}
      handleCloseModal={handleCloseModal}
      missionSheet={missionSheet}
      consultantName={consultantName}
    />,
    "Clôturer la mission",
    "md"
  );
  return (
    <div>
      <>
        <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="clôturer la mission">
          <ModalButton buttonText="Clôturer" buttonColor="primary" icon={"bx bx-check-double"} disabled={!IsMissionClosable()} />
        </span>
        <ModalComponent />
      </>
    </div>
  );
};

export default CloseMissionModal;
