import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import QuizConfigDataService from "../../services/QuizConfigDataService";
import { notify } from "../../assets/utils/UtilsFunctons";

export const getAllQuizConfigs = createAsyncThunk("Quiz/getAllQuizConfigs", async () => {
  try {
    const response = await QuizConfigDataService.getAllQuizConfigs();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const getQuizConfigById = createAsyncThunk("Quiz/getQuizConfigById", async (id) => {
  try {
    const response = await QuizConfigDataService.getQuizConfigById(id);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

export const getQuizRanksContents = createAsyncThunk("Quiz/getQuizRanksContents", async (id) => {
  try {
    const response = await QuizConfigDataService.getQuizRanksContents(id);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    return err.response.data;
  }
});

const QuizSlice = createSlice({
  name: "quiz",
  initialState: {
    quizConfigs: undefined,
    loading_quizConfigs: false,
    quizConfigById: undefined,
    loading_quizConfigById: false,
    quizRanksContents: undefined,
    loading_quizRanksContents: false,
    selectedQuiz: undefined,
  },
  reducers: {
    setSelectedQuiz: (state, action) => {
      state.selectedQuiz = action.payload;
    },
  },
  extraReducers: {
    [getAllQuizConfigs.pending]: (state) => {
      state.loading_quizConfigs = true;
    },
    [getAllQuizConfigs.fulfilled]: (state, { payload }) => {
      state.loading_quizConfigs = false;
      state.quizConfigs = payload;
      if (payload?.status !== 200 && payload?.title) {
        notify(0, payload.title);
      }
    },
    [getQuizConfigById.pending]: (state) => {
      state.loading_quizConfigById = true;
    },
    [getQuizConfigById.fulfilled]: (state, { payload }) => {
      state.loading_quizConfigById = false;
      state.quizConfigById = payload;
      if (payload?.status !== 200 && payload?.title) {
        notify(0, payload.title);
      }
    },
    [getQuizRanksContents.pending]: (state) => {
      state.loading_quizRanksContents = true;
    },
    [getQuizRanksContents.fulfilled]: (state, { payload }) => {
      state.loading_quizRanksContents = false;
      state.quizRanksContents = payload;
      if (payload?.status !== 200 && payload?.title) {
        notify(0, payload.title);
      }
    },
  },
});

export const { setSelectedQuiz } = QuizSlice.actions;

export default QuizSlice.reducer;
