import http from "./http-common";

class UtilsDataService {
  getSettings() {
    return http.get("/utils/settings");
  }

  patchSettings(data) {
    return http.patch("/utils/settings", data);
  }
}

export default new UtilsDataService();
