import { React, useState } from "react";
import ConfirmModal from "../../modals/FormConfirmDialogModal";
import useConfirm from "../../FormConFirmDialog/UseFormConfirmDialog";
import { Button, Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const DesactivateUserGuideForm = (props) => {
  const [subsituteGuide, setSubsituteGuide] = useState(undefined);
  const { isConfirmed } = useConfirm();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const confirmed = await isConfirmed(
      <span className="text-center">
        Etes-vous sûr de vouloir désactiver cet utilisateur ? <span className="fst-italic text-danger">Cette action est irréversible !</span>{" "}
      </span>
    );
    if (confirmed) {
      props.desactivateUserGuide(subsituteGuide);
    }
    props.modalVisibility(false);
  };

  /* eslint @typescript-eslint/no-empty-function: "off" */
  const handleReset = () => {};

  const handleReactSelectChange = (choice) => {
    setSubsituteGuide(choice?.value);
  };

  const subsituteGuideListOptions = () => {
    let list = [];
    if (props.subsituteGuideList?.length > 0) {
      props.subsituteGuideList?.map((user) => {
        list.push({ value: user.userId, label: user.completeName });
      });
    }
    return list;
  };

  return (
    <div>
      <ConfirmModal></ConfirmModal>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormGroup>
          <Label> Selectionner un remplaçant</Label>
          <Select
            name="questionType"
            required
            isClearable
            isSearchable
            options={subsituteGuideListOptions()}
            onChange={handleReactSelectChange}
            placeholder="choisir le type de la question"
            noOptionsMessage={() => "Pas d'options"}
          />
        </FormGroup>
        <br />
        <Button className="btn-warning" type="submit" disabled={!subsituteGuide}>
          {" "}
          Remplacer
        </Button>
      </Form>
    </div>
  );
};

export default DesactivateUserGuideForm;
