import { React, useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Button } from "reactstrap";

const CustomOffCanva = ({ children }) => {
  const [isVisible, setIsvisible] = useState(false);
  const toggle = () => {
    setIsvisible(!isVisible);
  };
  return (
    <div>
      <Button color="primary" onClick={toggle} type="button">
        Détail
      </Button>
      <Offcanvas toggle={toggle} direction="end" isOpen={isVisible}>
        <OffcanvasHeader toggle={toggle}></OffcanvasHeader>
        <OffcanvasBody>{children}</OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default CustomOffCanva;
