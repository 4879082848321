import React, { useEffect, useCallback, useState, useMemo } from "react";
import { FormGroup, Label, Col, Button, Input, ButtonGroup, FormText, Alert } from "reactstrap";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import AddEditRessourceComp from "./ressources/AddEditRessourceComp";
import { getPepConfigFullTree } from "../../redux/slices/PepSlice";
import RessourcesRenderComp from "./ressources/RessourcesRenderComp";
import useGetAllUserListOption from "../../hooks/useGetAllUserListOption";
import Enums from "../../assets/JsonData/Enums";
import { ACTION_PRIORITIES } from "../../core/constant/pep/PepConstant";
import PepDataService from "../../services/PepDataService";
import { notify } from "../../assets/utils/UtilsFunctons";
import ToggleButton from "../buttons/toggleButton/ToggleButton";

const PepActionContent = ({ handleCloseModal, updatePage, setUpdatePage }) => {
  const { PepConfigFullTree, PepActionList } = useSelector((state) => ({ ...state.pep }));
  const { userOptions } = useGetAllUserListOption();
  const [isAddingExistingAction, setIsAddingExistingAction] = useState(true);
  const dispatch = useDispatch();
  const [addingPep, setAddingPep] = useState(false);
  const [newAddedAction, setNewAddedAction] = useState(null);
  const [showOnlyActivatedUsers, setShowOnlyActivatedUsers] = useState(true);

  const userActions = {
    step1_select_skill: 0,
    step1_add_action: 1,
    step3_actionPersonnalization: 3,
  };

  const PEP_Sections = {
    axe: "pepAxe",
    domaine: "pepDomain",
    competence: "pepSkill",
  };

  const [newDomain, setNewDomain] = useState(null);
  // const [newSkill, setNewSkill] = useState(null);
  const [userAction, setUserAction] = useState(userActions.step1_select_skill);

  const [alertVisiblity, setAlertVisiblity] = useState(false);
  const [alertText, setAlertText] = useState("");

  const handleAlertVisiblity = () => {
    setAlertVisiblity(!alertVisiblity);
  };

  const handleDispatch = useCallback(() => {
    dispatch(getPepConfigFullTree());
  }, [dispatch]);

  useEffect(() => {
    handleDispatch();
  }, []);

  // formik hooks

  const select_user_formik = useFormik({
    initialValues: {
      pepUser: null,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const formik = useFormik({
    initialValues: {
      pepAxe: "",
      pepDomain: "",
      pepSkill: "",
    },
    onSubmit: () => {
      setUserAction(userActions.step3_actionPersonnalization);
    },
  });

  const newActionFormik = useFormik({
    initialValues: {
      pepAxe: undefined,
      pepDomain: undefined,
      pepSkill: undefined,
      title: undefined,
      level: undefined,
      resources: undefined,
      instruction: undefined,
      isEnable: true,
    },
    onSubmit: (values) => {
      const payload = {
        title: values.title,
        instruction: values.instruction,
        level: values.level,
        isEnable: values.isEnable,
        pepSkillId: values.pepSkill,
        resources: values.resources,
      };
      handleAddNewActionConfig(payload);
    },
  });

  const select_action_formik = useFormik({
    initialValues: {
      pepAction: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const actionPersonalization_Formik = useFormik({
    initialValues: {
      priority: undefined,
      comments: "",
    },
    onSubmit: () => {
      const payload = {
        userId: select_user_formik.values.pepUser,
        actionConfigId: isAddingExistingAction ? select_action_formik.values.pepAction : newAddedAction?.actionConfigId,
        priority: actionPersonalization_Formik.values.priority,
        comments: actionPersonalization_Formik.values.comments,
      };
      handleAddNewPep(payload);
    },
  });

  const handleAddNewPep = useCallback(async (payload) => {
    if (payload && payload.userId && payload.actionConfigId && payload.priority !== undefined) {
      setAddingPep(true);
      try {
        const response = await PepDataService.addNewPepAction(payload);
        if (response?.status === 200) {
          setAddingPep(false);
          setUpdatePage(!updatePage);
          notify(1, "Pep ajouté avec succès");
          handleCloseModal();
        }
      } catch (error) {
        setAddingPep(false);
      }
    }
  }, []);

  const handleAddNewActionConfig = useCallback(async (payload) => {
    if (payload && payload.pepSkillId && payload.title && payload.level) {
      try {
        const response = await PepDataService.addNewPepActionConfig(payload);
        if (response?.status === 200) {
          notify(1, "Action ajoutée avec succès");
          setNewAddedAction(response.data);
          select_action_formik.setValues({ ...select_action_formik.values, ["pepAction"]: response?.data?.actionConfigId });
          formik.setValues({
            ...formik.values,
            ["pepAxe"]: newActionFormik.values.pepAxe,
            ["pepDomain"]: newActionFormik.values.pepDomain,
            ["pepSkill"]: newActionFormik.values.pepSkill,
          });
          setUserAction(userActions.step3_actionPersonnalization);
        }
      } catch (error) {
        notify(0, error?.response.data.title ?? "une erreur est survenue");
      }
    }
  }, []);

  // handling forms changes

  const handleUserSelectChange = (e) => {
    select_user_formik.setValues({ pepUser: e ? e.value : null });
  };

  const handleSelectChange = (e, fieldName) => {
    if (fieldName === PEP_Sections.axe) {
      formik.setValues({ ...formik.values, [fieldName]: e ? e.value : null, pepDomain: null, pepSkill: null });
      select_action_formik.setValues({ ...select_action_formik.values, ["pepAction"]: null });
    }
    if (fieldName === PEP_Sections.domaine) {
      formik.setValues({ ...formik.values, [fieldName]: e ? e.value : null, pepSkill: null });
      select_action_formik.setValues({ ...select_action_formik.values, ["pepAction"]: null });
    }
    if (fieldName === PEP_Sections.competence) {
      formik.setValues({ ...formik.values, [fieldName]: e ? e.value : null });
      select_action_formik.setValues({ ...select_action_formik.values, ["pepAction"]: null });
    }
  };

  const handleNewActionSelectChange = (e, fieldName) => {
    if (fieldName === PEP_Sections.axe) {
      newActionFormik.setValues({ ...newActionFormik.values, [fieldName]: e ? e.value : null, pepDomain: null, pepSkill: null });
    }
    if (fieldName === PEP_Sections.domaine) {
      newActionFormik.setValues({ ...newActionFormik.values, [fieldName]: e ? e.value : null, pepSkill: null });
    }
    if (fieldName === PEP_Sections.competence) {
      newActionFormik.setValues({ ...newActionFormik.values, [fieldName]: e ? e.value : null });
      select_action_formik.setValues({ ...select_action_formik.values, ["pepAction"]: null });
    }
  };

  const handleRadioButtonChange = (e) => {
    newActionFormik.setValues({ ...newActionFormik.values, ["level"]: e });
  };

  const handleAddEditRessource = (ressources) => {
    newActionFormik.setValues({ ...newActionFormik.values, resources: ressources });
  };

  const handleActionPersonalizationRadioButtonChange = (e) => {
    actionPersonalization_Formik.setValues({ ...actionPersonalization_Formik.values, priority: e });
  };

  // forms validity checks

  const isFormCorrect = useMemo(() => {
    const doesUserActionAlreadyExist = PepActionList?.data?.some(
      (action) => action.userId === select_user_formik.values.pepUser && action.actionConfigId === select_action_formik.values.pepAction
    );

    if (doesUserActionAlreadyExist) {
      setAlertText("Cette action existe déjà pour ce collaborateur");
      handleAlertVisiblity();
    } else {
      setAlertText("");
      setAlertVisiblity(false);
    }

    return (
      Object.values(formik.values).every((value) => typeof value === "string" && value.length > 1) &&
      select_user_formik.values.pepUser !== null &&
      !!select_action_formik.values.pepAction &&
      !doesUserActionAlreadyExist
    );
  }, [formik.values, select_user_formik.values, select_action_formik.values, PepActionList?.data]);

  const isNewActionFormCorrect = useMemo(() => {
    return (
      newActionFormik.values.pepAxe &&
      newActionFormik.values.pepDomain &&
      newActionFormik.values.pepSkill &&
      newActionFormik.values.title &&
      newActionFormik.values.level &&
      select_user_formik.values.pepUser
    );
  }, [newActionFormik.values, select_user_formik.values]);

  const isSelectActionConfigStepCorrect = useMemo(() => {
    return select_action_formik.values.pepAction && select_action_formik.values.pepAction !== "";
  }, [select_action_formik.values]);

  const isActionPersonalizationFormCorrect = useMemo(() => {
    return actionPersonalization_Formik.values.priority !== undefined;
  }, [actionPersonalization_Formik.values]);

  // data fetching and manipulation

  /** user options */

  const getUserListOption = useMemo(() => {
    if (showOnlyActivatedUsers) {
      return userOptions.filter((user) => user.isActivated);
    }
    return userOptions;
  }, [userOptions, showOnlyActivatedUsers]);

  /* axes*/
  const getPepAxisConfig = useMemo(() => {
    return PepConfigFullTree?.data ? Array.from(PepConfigFullTree?.data, (item) => ({ axisId: item.axisId, title: item.title })) : [];
  }, [PepConfigFullTree]);

  const getCurrentPepAxeConfig = useMemo(() => {
    return getPepAxisConfig?.find((item) => item.axisId === formik.values.pepAxe);
  }, [getPepAxisConfig, formik.values.pepAxe]);

  const getCurrentPepAxeConfigById = useCallback(
    (axeId) => {
      return getPepAxisConfig?.find((item) => item.axisId === axeId);
    },
    [getPepAxisConfig]
  );

  const getPepAxeList = useCallback(() => {
    let pepAxeList = [];
    if (getPepAxisConfig) {
      getPepAxisConfig?.map((axe) => {
        pepAxeList.push({ label: axe.title, value: axe.axisId });
      });
    }
    if (newDomain) {
      pepAxeList.push({ label: newDomain, value: newDomain });
    }
    return pepAxeList;
  }, [getPepAxisConfig]);

  /* domain*/
  const getPepDomainConfigByAxisId = useMemo(() => {
    const selectedAxis = PepConfigFullTree?.data.find((item) =>
      isAddingExistingAction ? item.axisId === formik.values.pepAxe : item.axisId === newActionFormik.values.pepAxe
    );
    return selectedAxis?.domains
      ? Array.from(selectedAxis?.domains, (item) => ({ axisId: selectedAxis?.axisId, domainId: item.domainId, title: item.title }))
      : [];
  }, [PepConfigFullTree, formik.values.pepAxe, newActionFormik.values.pepAxe, isAddingExistingAction]);

  const getCurrentPepDomainConfig = useMemo(() => {
    return getPepDomainConfigByAxisId?.find((item) => item.domainId === formik.values.pepDomain);
  }, [getPepDomainConfigByAxisId, formik.values.pepDomain]);

  const getCurrentPepDomainConfigById = useCallback(
    (domainId) => {
      return getPepDomainConfigByAxisId?.find((item) => item.domainId === domainId);
    },
    [getPepDomainConfigByAxisId]
  );

  const getPepDomainList = useCallback(() => {
    let pepDomainList = [];
    if (getPepDomainConfigByAxisId) {
      getPepDomainConfigByAxisId?.map((domain) => {
        pepDomainList.push({ label: domain.title, value: domain.domainId });
      });
    }
    if (newDomain) {
      pepDomainList.push({ label: newDomain, value: newDomain });
    }
    return pepDomainList;
  }, [getPepDomainConfigByAxisId]);

  /* skills*/
  const getPepSkillConfigByaxisDomainId = useMemo(() => {
    const selectedAxis = PepConfigFullTree?.data.find((item) =>
      isAddingExistingAction ? item.axisId === formik.values.pepAxe : item.axisId === newActionFormik.values.pepAxe
    );
    const selectedDomain = selectedAxis?.domains.find((item) =>
      isAddingExistingAction ? item.domainId === formik.values.pepDomain : item.domainId === newActionFormik.values.pepDomain
    );
    return selectedDomain?.skills
      ? Array.from(selectedDomain?.skills, (item) => ({
          axisId: selectedAxis?.axisId,
          domainId: selectedDomain?.domainId,
          skillId: item.skillId,
          title: item.title,
        }))
      : [];
  }, [
    PepConfigFullTree,
    formik.values.pepAxe,
    formik.values.pepDomain,
    newActionFormik.values.pepAxe,
    newActionFormik.values.pepDomain,
    isAddingExistingAction,
  ]);

  const getCurrentPepSkillConfig = useMemo(() => {
    return getPepSkillConfigByaxisDomainId?.find((item) => item.skillId === formik.values.pepSkill);
  }, [getPepSkillConfigByaxisDomainId, formik.values.pepSkill]);

  const getCurrentPepSkillConfigById = useCallback(
    (skillId) => {
      return getPepSkillConfigByaxisDomainId?.find((item) => item.skillId === skillId);
    },
    [getPepSkillConfigByaxisDomainId]
  );

  const getPepSkillList = useCallback(() => {
    let pepSkillList = [];
    if (getPepSkillConfigByaxisDomainId) {
      getPepSkillConfigByaxisDomainId?.map((skill) => {
        pepSkillList.push({ label: skill.title, value: skill.skillId });
      });
    }
    return pepSkillList;
  }, [getPepSkillConfigByaxisDomainId]);

  /* actionConfigs*/
  const getPepActionConfigByAxisDomainSkillId = useMemo(() => {
    const selectedAxis = PepConfigFullTree?.data.find((item) =>
      isAddingExistingAction ? item.axisId === formik.values.pepAxe : item.axisId === newActionFormik.values.pepAxe
    );
    const selectedDomain = selectedAxis?.domains.find((item) =>
      isAddingExistingAction ? item.domainId === formik.values.pepDomain : item.domainId === newActionFormik.values.pepDomain
    );
    const selectedSkill = selectedDomain?.skills.find((item) =>
      isAddingExistingAction ? item.skillId === formik.values.pepSkill : item.skillId === newActionFormik.values.pepSkill
    );
    return selectedSkill?.actionConfigs
      ? Array.from(selectedSkill?.actionConfigs, (item) => ({
          axisId: selectedAxis?.axisId,
          domainId: selectedDomain?.domainId,
          skillId: selectedSkill?.skillId,
          actionConfigId: item.actionConfigId,
          title: item.title,
          level: item.level,
          instruction: item.instruction,
        }))
      : [];
  }, [
    PepConfigFullTree,
    formik.values.pepAxe,
    formik.values.pepDomain,
    formik.values.pepSkill,
    newActionFormik.values.pepAxe,
    newActionFormik.values.pepDomain,
    newActionFormik.values.pepSkill,
    isAddingExistingAction,
  ]);

  const getCurrentPepActionConfig = useMemo(() => {
    return getPepActionConfigByAxisDomainSkillId?.find((item) => item.actionConfigId === select_action_formik.values.pepAction);
  }, [getPepActionConfigByAxisDomainSkillId, select_action_formik.values.pepAction]);

  const getPepActionList = useCallback(() => {
    let pepActionList = [];
    let actionLabel = "";
    if (getPepActionConfigByAxisDomainSkillId) {
      getPepActionConfigByAxisDomainSkillId?.map((action) => {
        actionLabel = action.title + ", " + Enums.matchPepActionConfigLevelText(action.level);
        pepActionList.push({ label: actionLabel, value: action.actionConfigId });
      });
    }
    return pepActionList;
  }, [getPepActionConfigByAxisDomainSkillId]);

  const getPepActionConfigByActionConfigId = useMemo(() => {
    return getPepActionConfigByAxisDomainSkillId?.find((item) => item.actionConfigId === select_action_formik.values.pepAction);
  }, [getPepActionConfigByAxisDomainSkillId, select_action_formik.values.pepAction]);

  // render functions

  const renderDefaultAddActionContent = useCallback(() => {
    return (
      <div>
        <div className="fs-5 text-center py-3">Selectionner une action existante</div>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup row>
            <Label htmlFor="axe" sm={2}>
              Collaborateur
            </Label>
            <Col sm={10}>
              <Select
                onChange={(e) => handleUserSelectChange(e)}
                placeholder="-- sélectionner un collaborateur"
                options={getUserListOption}
                isClearable
                value={userOptions.find((user) => user.value === select_user_formik.values.pepUser)}
              />
              <FormText>
                <FormGroup check>
                  <Input type="checkbox" checked={showOnlyActivatedUsers} onChange={() => setShowOnlyActivatedUsers(!showOnlyActivatedUsers)} />{" "}
                  <Label check>Collaborateurs actifs uniquement</Label>
                </FormGroup>
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="axe" sm={2}>
              Axe
            </Label>
            <Col sm={10}>
              <Select
                onChange={(e) => handleSelectChange(e, PEP_Sections.axe)}
                placeholder="-- sélectionner un axe"
                options={getPepAxeList()}
                value={getPepAxeList()?.find((axe) => axe.value === formik.values.pepAxe)}
                isClearable
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="domaine" sm={2}>
              Domaine
            </Label>
            <Col sm={10}>
              <Select
                onChange={(e) => handleSelectChange(e, PEP_Sections.domaine)}
                placeholder="-- sélectionner un domaine"
                options={getPepDomainList()}
                value={formik.values.pepDomain ? getPepDomainList().find((domain) => domain.value === formik.values.pepDomain) : null}
                isClearable
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="action" sm={2}>
              Compétence
            </Label>
            <Col sm={10}>
              <Select
                onChange={(e) => handleSelectChange(e, PEP_Sections.competence)}
                placeholder="-- sélectionner une compétence"
                options={getPepSkillList()}
                value={formik.values.pepSkill ? getPepSkillList().find((skill) => skill.value === formik.values.pepSkill) : null}
                isClearable
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label htmlFor="action" sm={2}>
              Sélection Action
            </Label>
            <Col sm={10}>
              <Select
                isClearable
                onChange={(e) => select_action_formik.setValues({ ...select_action_formik.values, ["pepAction"]: e ? e.value : null })}
                placeholder="-- sélectionner une action"
                options={getPepActionList()}
                value={
                  select_action_formik.values.pepAction ? getPepActionList().find((action) => action.value === select_action_formik.values.pepAction) : null
                }
              />
            </Col>
          </FormGroup>

          {select_action_formik.values.pepAction && (
            <>
              <FormGroup row>
                <Label htmlFor="axe" sm={2}>
                  Ressources
                </Label>
                <Col sm={10}>{select_action_formik.values.pepAction && <RessourcesRenderComp resourceConfigId={select_action_formik.values.pepAction} />}</Col>
              </FormGroup>
              <FormGroup row>
                <Label htmlFor="axe" sm={2}>
                  Instructions
                </Label>
                <Col sm={10}>
                  <span className="text-break"> {getPepActionConfigByActionConfigId?.instruction}</span>
                </Col>
              </FormGroup>
            </>
          )}

          <div>
            <Alert color="danger" isOpen={alertVisiblity} toggle={handleAlertVisiblity}>
              {alertText}
            </Alert>
          </div>

          <div className="d-flex justify-content-center">
            <Button className="m-2" size="md" color="danger" role="button" onClick={handleCloseModal}>
              Annuler
            </Button>
            &nbsp;
            <Button className="m-2" size="md" color="primary" role="button" disabled={!isFormCorrect} type="submit">
              Valider
            </Button>
          </div>
        </form>
        <div>
          <span className="d-flex align-items-center">
            <span className="link-opacity-75 ">ou Créer une nouvelle action &nbsp;</span>
            <Button
              color="link"
              onClick={() => {
                setUserAction(userActions.step1_add_action);
                setIsAddingExistingAction(false);
              }}
            >
              <i className="bx bx-plus-circle text-success fs-2"></i>
            </Button>
          </span>
        </div>
      </div>
    );
  }, [
    isFormCorrect,
    formik.values,
    getPepAxeList,
    getPepDomainList,
    getPepSkillList,
    userOptions,
    select_action_formik.values,
    getPepActionConfigByActionConfigId,
    getPepActionList,
    isSelectActionConfigStepCorrect,
    alertVisiblity,
    alertText,
    getUserListOption,
    showOnlyActivatedUsers,
  ]);

  const renderAddNewActionContent = useCallback(() => {
    return (
      <div>
        <div className=" fs-5 text-center py-3">Nouvelle action </div>
        <form onSubmit={newActionFormik.handleSubmit}>
          <FormGroup row>
            <Label htmlFor="new-collab" sm={2}>
              Collaborateur
            </Label>
            <Col sm={8}>
              <Select onChange={(e) => handleUserSelectChange(e)} placeholder="-- sélectionner un collaborateur" options={getUserListOption} isClearable />
              <FormText>
                <FormGroup check>
                  <Input type="checkbox" checked={showOnlyActivatedUsers} onChange={() => setShowOnlyActivatedUsers(!showOnlyActivatedUsers)} />{" "}
                  <Label check>Collaborateurs actifs uniquement</Label>
                </FormGroup>
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="new-axe" sm={2}>
              Axe
            </Label>
            <Col sm={8}>
              <Select
                onChange={(e) => handleNewActionSelectChange(e, PEP_Sections.axe)}
                placeholder="-- sélectionner un axe"
                options={getPepAxeList()}
                value={newActionFormik.values.pepAxe ? getPepAxeList().find((axe) => axe.value === newActionFormik.values.pepAxe) : null}
                isClearable
                id="new-axe"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="domaine" sm={2}>
              Domaine
            </Label>
            <Col sm={8}>
              <Select
                onChange={(e) => handleNewActionSelectChange(e, PEP_Sections.domaine)}
                value={newActionFormik.values.pepDomain ? getPepDomainList().find((domain) => domain.value === newActionFormik.values.pepDomain) : null}
                placeholder="-- sélectionner un domaine"
                options={getPepDomainList()}
                isClearable
              />
            </Col>
            {/* <Col sm={2}>
              <NewDomainOrSkillsModalComp field="domain" handleAddNewDomain={handleAddNewDomain} axeParent={newActionFormik.values.pepAxe} />
            </Col> */}
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="pepSkill" sm={2}>
              Compétence
            </Label>
            <Col sm={8}>
              <Select
                onChange={(e) => handleNewActionSelectChange(e, PEP_Sections.competence)}
                placeholder="-- sélectionner une compétence"
                options={getPepSkillList()}
                value={newActionFormik.values.pepSkill ? getPepSkillList().find((domain) => domain.value === newActionFormik.values.pepSkill) : null}
                isClearable
              />
            </Col>
            {/* <Col sm={1}>
              <NewDomainOrSkillsModalComp
                field="skill"
                handleAddNewSkill={handleAddNewSkill}
                domainParent={newActionFormik.values.pepDomain}
                axeParent={newActionFormik.values.pepAxe}
              />
            </Col> */}
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="title" sm={2}>
              Titre
            </Label>
            <Col sm={8}>
              <Input
                id="title"
                name="title"
                onChange={newActionFormik.handleChange}
                value={newActionFormik.values.title}
                placeholder=" -- Ajouter un titre"
                maxLength="30"
              />
              <FormText>30 caractères max.</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="title" sm={3}>
              Actif
            </Label>
            <Col sm={8}>
              <ToggleButton state={newActionFormik.values.isEnable} isdisabled />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="level" sm={2}>
              Niveau (*)
            </Label>
            <Col sm={10}>
              <ButtonGroup style={{ zIndex: "0" }}>
                <Button className="px-5" color="primary" outline onClick={() => handleRadioButtonChange(1)} active={newActionFormik.values.level === 1}>
                  Débutant
                </Button>
                <Button className="px-5" color="primary" outline onClick={() => handleRadioButtonChange(2)} active={newActionFormik.values.level === 2}>
                  Intermédiaire
                </Button>
                <Button className="px-5" color="primary" outline onClick={() => handleRadioButtonChange(3)} active={newActionFormik.values.level === 3}>
                  Confirmé
                </Button>
              </ButtonGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="urls" sm={2}>
              Ressources
            </Label>
            <Col sm={10}>
              <AddEditRessourceComp ressource={newActionFormik.values.resources} handleAddEditRessource={handleAddEditRessource} creation={true} />
            </Col>
          </FormGroup>
          &nbsp;
          <FormGroup row>
            <Label htmlFor="instruction" sm={2}>
              Instructions
            </Label>
            <Col sm={8}>
              <Input
                rows="4"
                type="textarea"
                id="instruction"
                name="instruction"
                onChange={newActionFormik.handleChange}
                value={newActionFormik.values.instruction}
                placeholder="  Ajouter les  instructions"
              />
            </Col>
          </FormGroup>
          <div className="d-flex justify-content-center">
            <Button
              className="m-2"
              size="md"
              color="danger"
              role="button"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Annuler
            </Button>
            &nbsp;
            <Button className="m-2" size="md" color="primary" type="submit" disabled={!isNewActionFormCorrect}>
              Valider
            </Button>
          </div>
        </form>
      </div>
    );
  }, [newActionFormik.values, isNewActionFormCorrect, getPepAxeList, getPepDomainList, getPepSkillList, getUserListOption, showOnlyActivatedUsers]);

  const renderActionDetailsSummary = useCallback(() => {
    return (
      <div>
        <h5 className="pb-2">Détails de l&apos;action</h5>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Collaborateur
          </Label>
          <Col sm={10}>
            <span>{userOptions.find((user) => user.value === select_user_formik.values.pepUser)?.label}</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Axe
          </Label>
          <Col sm={10}>
            <span className="text-break">
              {isAddingExistingAction ? getCurrentPepAxeConfig?.title : getCurrentPepAxeConfigById(newActionFormik.values.pepAxe)?.title}
            </span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Domaine
          </Label>
          <Col sm={10}>
            <span className="text-break">
              {isAddingExistingAction ? getCurrentPepDomainConfig?.title : getCurrentPepDomainConfigById(newActionFormik.values.pepDomain)?.title}
            </span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Compétence
          </Label>
          <Col sm={10}>
            <span className="text-break">
              {" "}
              {isAddingExistingAction ? getCurrentPepSkillConfig?.title : getCurrentPepSkillConfigById(newActionFormik.values.pepSkill)?.title}
            </span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Action
          </Label>
          <Col sm={10}>
            <span className="text-break"> {isAddingExistingAction ? getCurrentPepActionConfig?.title : newAddedAction?.title}</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Niveau
          </Label>
          <Col sm={10}>
            <span className="text-break">
              {Enums.matchPepActionConfigLevelText(isAddingExistingAction ? getCurrentPepActionConfig?.level : newAddedAction?.level)}
            </span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Ressources
          </Label>
          <Col sm={10}>
            <RessourcesRenderComp resourceConfigId={isAddingExistingAction ? getCurrentPepActionConfig?.actionConfigId : newAddedAction?.actionConfigId} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="axe" sm={2}>
            Instructions
          </Label>
          <Col sm={10}>
            <span className="text-break"> {isAddingExistingAction ? getCurrentPepActionConfig?.instruction : newAddedAction?.instruction}</span>
          </Col>
        </FormGroup>
      </div>
    );
  }, [
    getCurrentPepAxeConfig,
    getCurrentPepDomainConfig,
    getCurrentPepSkillConfig,
    getCurrentPepActionConfig,
    newAddedAction,
    isAddingExistingAction,
    newActionFormik.values,
    getCurrentPepAxeConfigById,
    getCurrentPepDomainConfigById,
    getCurrentPepSkillConfigById,
    select_user_formik.values,
    userOptions,
  ]);

  const renderActionPersonalizationForm = useCallback(() => {
    return (
      <div>
        {renderActionDetailsSummary()}
        <hr className="pt-2" />
        <h5 className="pb-2">Prioriser et initialiser l&apos;action</h5>
        <form onSubmit={actionPersonalization_Formik.handleSubmit}>
          <FormGroup row>
            <Label htmlFor="level" sm={2}>
              Priorité (*)
            </Label>
            <Col sm={10}>
              <ButtonGroup style={{ zIndex: "0" }} size={"sm"}>
                {ACTION_PRIORITIES.map((priority, index) => {
                  return (
                    <Button
                      key={index}
                      className="px-3"
                      color="primary"
                      outline
                      onClick={() => handleActionPersonalizationRadioButtonChange(priority.value)}
                      active={actionPersonalization_Formik.values.priority === priority.value}
                    >
                      {priority.label}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label htmlFor="comment" sm={2}>
              Commentaire
            </Label>
            <Col sm={8}>
              <Input
                type="textarea"
                id="comments"
                name="comments"
                onChange={actionPersonalization_Formik.handleChange}
                value={actionPersonalization_Formik.values.comments}
                placeholder="-- commentaires"
              />
            </Col>
          </FormGroup>

          <div className="d-flex justify-content-center">
            <Button className="m-2" size="md" outline color="secondary" role="button" onClick={() => setUserAction(userActions.step1_select_skill)}>
              Etape précédente
            </Button>
            &nbsp;
            <Button className="m-2" size="md" color="primary" role="button" disabled={!isActionPersonalizationFormCorrect || addingPep} type="submit">
              {addingPep && <i className="bx bx-loader-alt bx-spin"></i>} Valider
            </Button>
          </div>
        </form>
      </div>
    );
  }, [actionPersonalization_Formik, isActionPersonalizationFormCorrect, renderActionDetailsSummary, addingPep]);

  const renderCurrentStepContent = useCallback(() => {
    switch (userAction) {
      case userActions.step1_select_skill:
        return renderDefaultAddActionContent();
      case userActions.step1_add_action:
        return renderAddNewActionContent();
      case userActions.step3_actionPersonnalization:
        return renderActionPersonalizationForm();
      default:
        return "";
    }
  }, [userAction, renderDefaultAddActionContent, renderAddNewActionContent, renderActionPersonalizationForm]);

  return <div> {renderCurrentStepContent()}</div>;
};

export default PepActionContent;
