import React, { useCallback, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import useUserRole from "../hooks/useUserRole";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import StatusCard from "../components/statusCard/StatusCard";
import Table from "../components/table/Table";
import Badge from "../components/badge/Badge";
import statusCards from "../assets/JsonData/statusCard-data.json";
import mascotImg from "../assets/images/mascot_wink.png";

const chartOptions = {
  series: [
    {
      name: "Participants",
      data: [10, 20, 30, 50, 50, 55, 59, 60, 61, 60],
    },
    {
      name: "Quêtes terminées",
      data: [0, 2, 10, 14, 20, 25, 40, 39, 50, 70],
    },
  ],
  options: {
    color: ["#6ab04c", "#2980b9"],
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    },
    legend: {
      position: "top",
    },
    grid: {
      show: false,
    },
  },
};

const topCustomers = {
  head: ["Player", "Quêtes", "Coins"],
  body: [
    {
      username: "Maxime ",
      order: "18",
      price: "870¢",
    },
    {
      username: "Stéphane",
      order: "15",
      price: "750¢",
    },
    {
      username: "Aurélien",
      order: "14",
      price: "746¢",
    },
    {
      username: "Olivier",
      order: "11",
      price: "351¢",
    },
    {
      username: "Baptiste",
      order: "10",
      price: "240¢",
    },
  ],
};

const renderCusomerHead = (item, index) => <th key={index}>{item}</th>;

const renderCusomerBody = (item, index) => (
  <tr key={index}>
    <td>{item.username}</td>
    <td>{item.order}</td>
    <td>{item.price}</td>
  </tr>
);

const latestOrders = {
  header: ["order id", "user", "Prix", "date", "statut"],
  body: [
    {
      id: "#OD1711",
      user: "Benjamin",
      date: "27 Mai 2022",
      price: "900¢",
      status: "Livraison",
    },
    {
      id: "#OD1712",
      user: "Franck ",
      date: "17 Fev 2022",
      price: "400¢",
      status: "Livré",
    },
    {
      id: "#OD1713",
      user: "Anthony ",
      date: "10 Jan 2022",
      price: "200¢",
      status: "A valider",
    },
    {
      id: "#OD1712",
      user: "Charles",
      date: "01 Jan 2022",
      price: "400¢",
      status: "Livré",
    },
    {
      id: "#OD1713",
      user: "Kristen",
      date: "30 Dec 2021",
      price: "200¢",
      status: "Refusé",
    },
  ],
};

const orderStatus = {
  Livraison: "primary",
  "A valider": "warning",
  Livré: "success",
  Refusé: "danger",
};

const renderOrderHead = (item, index) => <th key={index}>{item}</th>;

const renderOrderBody = (item, index) => (
  <tr key={index}>
    <td>{item.id}</td>
    <td>{item.user}</td>
    <td>{item.price}</td>
    <td>{item.date}</td>
    <td>
      <Badge type={orderStatus[item.status]} content={item.status} />
    </td>
  </tr>
);

const Dashboard = () => {
  const { accounts } = useMsal();
  const { isReadOnly, isManager, isAdmin } = useUserRole();

  const userName = useMemo(() => {
    if (accounts && accounts.length > 0) {
      return accounts[0]?.name;
    }
  }, [accounts]);

  const renderDashboardContent = useCallback(() => {
    return (
      <div>
        <h2 className="page-header">Dashboard</h2>
        <div className="row">
          <div className="col-6">
            <div className="row">
              {statusCards.map((item, index) => (
                <div className="col-6" key={index}>
                  <StatusCard icon={item.icon} count={item.count} title={item.title} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-6">
            <div className="card full-height">
              {/* chart */}
              <Chart
                options={{
                  ...chartOptions.options,
                  theme: { mode: "light" },
                }}
                series={chartOptions.series}
                type="line"
                height="100%"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card__header">
                <h3>top 5 Players</h3>
              </div>
              <div className="card__body">
                <Table
                  headData={topCustomers.head}
                  renderHead={(item, index) => renderCusomerHead(item, index)}
                  bodyData={topCustomers.body}
                  renderBody={(item, index) => renderCusomerBody(item, index)}
                />
              </div>
              <div className="card__footer">
                <Link to="/Classement">view all</Link>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="card">
              <div className="card__header">
                <h3>Dernieres Commandes</h3>
              </div>
              <div className="card__body">
                <Table
                  headData={latestOrders.header}
                  renderHead={(item, index) => renderOrderHead(item, index)}
                  bodyData={latestOrders.body}
                  renderBody={(item, index) => renderOrderBody(item, index)}
                />
              </div>
              <div className="card__footer">
                <Link to="/Boutique">view all</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const renderLandingPageContent = useCallback(() => {
    return (
      <div className="  d-flex justify-content-between row mt-5">
        <div className="col-md-8 ">
          <div className="h3 mb-4">
            Bienvenue à bord, <em> {userName}</em> !
          </div>
          <div className="mb-3 fs-6">
            Depuis votre espace dédié , vous avez accès à plusieurs fonctionnalités qui vous permettront de mieux suivre les informations de nos collaborateurs.
          </div>
          <div>
            <ul>
              <li> - les parcours d&apos;évolution personnalisés (PEP) </li>
              <li> - Les digDays</li>
              <li> - Les fiches mission</li>
            </ul>
          </div>
        </div>
        <div className="col-md-4">
          <img src={mascotImg} height={"300vh"} />
        </div>
      </div>
    );
  }, [userName]);

  const renderHomePageContent = useCallback(() => {
    return (
      <div className=" d-flex">
        {(isManager || isReadOnly) && !isAdmin ? <div className="container"> {renderLandingPageContent()} </div> : renderDashboardContent()}
      </div>
    );
  }, [renderLandingPageContent, renderDashboardContent, isManager, isReadOnly, isAdmin]);

  return renderHomePageContent();
};

export default Dashboard;
