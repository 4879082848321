import React, { useCallback } from "react";
import useModalWithContent from "../../useModalWithContent/UseModalWithContent";
import EditableTable from "../../table/EditableTable";
import { convertDateToLocalTime } from "../../../assets/utils/UtilsFunctons";

const ResourceRatingHistory = ({ resource, userList }) => {
  const ResourceHistoryTableHead = ["Collaborateur", "Eval.", "Date"];
  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderQuestSeasonBody = (item, index) => (
    <tr key={index}>
      <td className="">{userList?.find((user) => user.value === item?.userId)?.label || ""}</td>
      <td className="">{item?.rating}</td>
      <td className="">{convertDateToLocalTime(item?.date)}</td>
    </tr>
  );

  const renderResourceHistory = useCallback(() => {
    return (
      <EditableTable
        limit="10"
        loading={false}
        title=""
        headData={ResourceHistoryTableHead}
        renderHead={(item, index) => renderHead(item, index)}
        bodyData={resource || []}
        renderBody={(item, index) => renderQuestSeasonBody(item, index)}
        striped={true}
      />
    );
  }, [resource, userList]);

  const { ModalComponent, ModalButton } = useModalWithContent(renderResourceHistory(), "Historique des notations", "md");
  return (
    <div>
      <ModalButton buttonText="" buttonColor="" icon={"bx bx-search bx-rotate-90"} />
      <ModalComponent backdrop={true} />
    </div>
  );
};

export default ResourceRatingHistory;
