import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PepDataService from "../../services/PepDataService";
import { notify } from "../../assets/utils/UtilsFunctons";

export const getPepActions = createAsyncThunk("Pep/getPepActions", async () => {
  try {
    const response = await PepDataService.getPepActions();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(0, err?.response?.data?.Message);
    return err.response.data;
  }
});

export const getPepActionsByUserId = createAsyncThunk("Pep/getPepActionsByUserId", async (userId) => {
  try {
    const response = await PepDataService.getPepActionsByUserId(userId);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(0, err?.response?.data?.Message);
    return err.response.data;
  }
});

export const getPepConfigFullTree = createAsyncThunk("Pep/getPepConfigFullTree", async () => {
  try {
    const response = await PepDataService.getPepConfigFullTree();
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(0, err?.response?.data?.Message);
    return err.response.data;
  }
});

export const getActionConfigById = createAsyncThunk("Pep/getActionConfigById", async (id) => {
  try {
    const response = await PepDataService.getActionConfigById(id);
    return await { data: response.data, status: response.status, statusText: response.statusText };
  } catch (err) {
    console.log("=>erreur");
    console.log(err);
    notify(0, err?.response?.data?.Message);
    return err.response.data;
  }
});

const PepSlice = createSlice({
  name: "pep",
  initialState: {
    PepActionList: undefined,
    loading_getPepActions: false,
    PepActionListByUserId: undefined,
    loading_getPepActionsByUserId: false,
    PepConfigFullTree: undefined,
    loading_getPepConfigFullTree: false,
    PepActionConfigDetail: undefined,
    loading_getActionConfigById: false,
  },
  reducers: {},
  extraReducers: {
    [getPepActions.pending]: (state) => {
      state.loading_getPepActions = true;
    },
    [getPepActions.fulfilled]: (state, { payload }) => {
      state.loading_getPepActions = false;
      state.PepActionList = payload;
      if (payload?.status !== 200 && payload?.title) {
        notify(0, payload.title);
      }
    },
    // getPepActionsByUserId
    [getPepActionsByUserId.pending]: (state) => {
      state.loading_getPepActionsByUserId = true;
    },
    [getPepActionsByUserId.fulfilled]: (state, { payload }) => {
      state.loading_getPepActionsByUserId = false;
      state.PepActionListByUserId = payload;
      if (payload?.status !== 200 && payload?.title) {
        notify(0, payload.title);
      }
    },
    [getPepConfigFullTree.pending]: (state) => {
      state.loading_getPepConfigFullTree = true;
    },
    [getPepConfigFullTree.fulfilled]: (state, { payload }) => {
      state.loading_getPepConfigFullTree = false;
      state.PepConfigFullTree = payload;
      if (payload?.status !== 200 && payload?.title) {
        notify(0, payload.title);
      }
    },
    // getActionConfigById
    [getActionConfigById.pending]: (state) => {
      state.loading_getActionConfigById = true;
    },
    [getActionConfigById.fulfilled]: (state, { payload }) => {
      state.loading_getActionConfigById = false;
      state.PepActionConfigDetail = payload;
      if (payload?.status !== 200 && payload?.title) {
        notify(0, payload.title);
      }
    },
  },
});

export default PepSlice.reducer;
