import { React, useState } from "react";
import Select from "react-select";
import RewardTemplateCard from "../rewardTemplate/RewardTemplateCard";
import { getFileTypeFromFileName } from "../../assets/utils/UtilsFunctons";
import useConfirm from "../FormConFirmDialog/UseFormConfirmDialog";
import ConfirmModal from "../modals/FormConfirmDialogModal";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { Button, Form, FormGroup, Label, Input, Row, Col, CloseButton } from "reactstrap";
import CustomOffCanva from "../offCanva-sideModal/CustomOffCanva";

const EditQuizConfigForm = ({ quiz, handleUpdateQuizConfig, disableButton }) => {
  const { isConfirmed } = useConfirm();

  const [mediaInputKey, setMediaInputKey] = useState(0);
  const [preview, setPreview] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { rewardTemplates } = useSelector((state) => ({ ...state.rewardTemplate }));

  const [formValue, setformValue] = useState({
    quizConfigId: quiz.quizConfigId,
    title: quiz.title,
    theme: quiz.theme,
    rewardTemplateId: quiz.rewardTemplateId,
    mediaUrl: quiz.mediaUrl,
    fileName: quiz.fileName,
    deleteMedia: false,
    doesExistingMediachange: false,
  });

  const getCurrentRewardTemplate = () => {
    if (rewardTemplates && rewardTemplates.data) {
      let reward = rewardTemplates.data.find((item) => item.rewardTemplateId === formValue.rewardTemplateId);
      return reward;
    }
    return undefined;
  };

  const [currentRewardTemplate, setCurrentRewardTemplate] = useState(getCurrentRewardTemplate(formValue.rewardTemplateId));

  const rewardTemplateListOption = () => {
    let list = [];
    if (rewardTemplates && rewardTemplates.data) {
      rewardTemplates.data.map((rewardTemplate) => {
        if (!rewardTemplate.archived || rewardTemplate.rewardTemplateId === formValue.rewardTemplateId) {
          list.push({ value: rewardTemplate.rewardTemplateId, label: rewardTemplate.name });
        }
      });
    }
    return list;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let confirmed = await isConfirmed("Souhaitez-vous modifier les configurations du quiz ?");
    if (confirmed) {
      handleUpdateQuizConfig(formValue);
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setformValue({
      ...formValue,
      mediaUrl: file,
      deleteMedia: false,
      doesExistingMediachange: true,
    });

    const fileFormat = getFileTypeFromFileName(file);
    if (fileFormat !== "Inconnu") {
      if (file.type.match("image/*")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          setPreview(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleRemoveMedia = () => {
    setformValue({
      ...formValue,
      mediaUrl: null,
      deleteMedia: true,
    });
    setPreview(null);
    setMediaInputKey((prevKey) => prevKey + 1);
  };

  const handleReactSelectChange = (choice, event) => {
    setformValue((prevalue) => {
      return {
        ...prevalue,
        [event.name]: choice?.value ?? null,
      };
    });

    if (event.name === "rewardTemplateId" && rewardTemplates && rewardTemplates.data && choice?.value) {
      let reward = rewardTemplates.data.filter((item) => item.rewardTemplateId === choice?.value);
      setCurrentRewardTemplate(reward ? reward[0] : undefined);
    }
    if (event.name === "rewardTemplateId" && !choice?.value) {
      setCurrentRewardTemplate(undefined);
    }
  };

  const handleReset = () => {
    // toggleDisplayModal();
  };
  let getThemeOptions = () => {
    return JSON.parse(localStorage.getItem("quizThemeOptions"));
  };

  return (
    <Row>
      <ConfirmModal></ConfirmModal>
      <Col md={10}>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <FormGroup>
            <Label for="digDaysCurrentStock">
              Titre <sup>*</sup>
            </Label>
            <Input
              type="text"
              name="title"
              id="title"
              onChange={handleChange}
              required
              placeholder="titre du quiz"
              autoComplete="off"
              defaultValue={formValue.title}
              readOnly={disableButton}
            />
          </FormGroup>

          <FormGroup>
            <Label for="digDaysCurrentStock">
              Thème <sup>*</sup>
            </Label>

            <CreatableSelect
              name="theme"
              isClearable
              isSearchable
              options={getThemeOptions()}
              onChange={handleReactSelectChange}
              noOptionsMessage={() => "Pas d'options"}
              formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
              placeholder="Thème du quiz"
              defaultValue={getThemeOptions().filter((opt) => opt.value === formValue.theme)}
              readOnly={disableButton}
            />
          </FormGroup>
          <FormGroup>
            <Label for="digDaysCurrentStock">
              modèle de gain <sup>*</sup>
            </Label>
            <Row>
              <Col md={10}>
                <Select
                  name="rewardTemplateId"
                  required
                  isClearable
                  isSearchable
                  options={rewardTemplateListOption()}
                  onChange={handleReactSelectChange}
                  noOptionsMessage={() => "Pas d'options"}
                  defaultValue={rewardTemplateListOption().filter((opt) => opt.value === formValue.rewardTemplateId)}
                  readOnly={disableButton}
                />
              </Col>
              <Col md={2}>
                <CustomOffCanva>{currentRewardTemplate && <RewardTemplateCard reward={currentRewardTemplate}></RewardTemplateCard>}</CustomOffCanva>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label for="mediaUrl">Media du quiz &nbsp;</Label>
            {formValue.mediaUrl ? (
              <span data-toggle="tooltip" data-placement="top">
                <CloseButton
                  title="Supprimer le média existant"
                  onClick={() => {
                    handleRemoveMedia();
                  }}
                />
              </span>
            ) : (
              ""
            )}
            <Input
              type="file"
              name="mediaUrl"
              id="mediaUrl"
              onChange={handleFileInputChange}
              autoComplete="off"
              key={mediaInputKey}
              accept=".jpg, .jpeg, .png, .gif, .webp"
            />
          </FormGroup>
          <div>
            {preview && (
              <div>
                <img src={preview} alt="Aperçu du fichier" className="quiz-img-preview" />
              </div>
            )}
            {!preview && formValue.mediaUrl && (
              <div>
                <img
                  src={formValue.mediaUrl}
                  className="quiz-img-preview"
                  alt="aperçu indisponible"
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                  style={{ display: imageLoaded ? "block" : "none" }}
                />
                {formValue.mediaUrl && !imageLoaded && <span>chargement du media ...</span>}
              </div>
            )}
          </div>

          <Row className="mt-2">
            <Col md={5}>
              <Button color="primary" type="submit" block disabled={disableButton}>
                <span> Enregistrer</span>
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default EditQuizConfigForm;
