import React from "react";

const MyBreadcrumb = ({ pageTitle, subPageTitle }) => {
  return (
    <div className="d-flex flex-row align-self-center">
      <h5 className="page-header r">{pageTitle}</h5>
      <i className="bx bx-chevron-right fs-3 "></i>
      <h6 className="align-self-baseline mt-1">{subPageTitle}</h6>
    </div>
  );
};

export default MyBreadcrumb;
