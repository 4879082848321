import React, { useState } from "react";
import "./digDaysSerialValidation.css";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const DigDaysSerialValidationModal = ({ children, refreshOnModalClose, setRefreshOnModalClose }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button
      className="close"
      onClick={() => {
        toggle();
        setRefreshOnModalClose(!refreshOnModalClose);
      }}
    >
      &times;
    </Button>
  );

  return (
    <div>
      <i
        className="bx bx-check-circle"
        onClick={() => toggle()}
        style={{
          fontSize: "3em",
          color: "#0d6efd",
          cursor: "pointer",
          transition: "transform 0.2s",
        }}
      />
      <Modal className="quest-serial-validation-modal" isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false} size="xl">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Validation Dig Days en série
        </ModalHeader>
        {children}
        <ModalBody></ModalBody>
      </Modal>
    </div>
  );
};

export default DigDaysSerialValidationModal;
