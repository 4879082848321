import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editLevelConfigs, deleteLevelConfigs } from "../../redux/slices/LevelConfigSlice";
import EditLevelConfigForm from "../forms/EditLevelConfigForm";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const EditLevelConfigModal = ({ buttonLabel, item, inStandBYPhaseOrNot }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <Button className="close" onClick={toggle}>
      &times;
    </Button>
  );
  const label = buttonLabel;

  let button = "";
  let title = "";
  let form = "";

  const editButton = (
    <Button
      color="primary"
      onClick={toggle}
      outline
      disabled={!inStandBYPhaseOrNot}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={!inStandBYPhaseOrNot ? "modification impossible, expedition en cours" : "modifier les infos du niveau"}
    >
      <i className="bx bxs-pencil"></i>
    </Button>
  );

  const deleteButton = (
    <Button color="danger" onClick={toggle} style={{ float: "left", marginRight: "10px" }}>
      <i className="bx bxs-trash"></i>
    </Button>
  );

  const dispatch = useDispatch();

  const editLevelConfig = (formValue) => {
    dispatch(editLevelConfigs(formValue));
    toggle(modal);
  };

  const deleteLevelConfig = (formValue) => {
    dispatch(deleteLevelConfigs(formValue));
    toggle(modal);
  };

  if (label === "Edit") {
    button = editButton;
    title = "Modifier la configuration";
    form = <EditLevelConfigForm item={item} modalConfig={setModal} editLevelConfig={editLevelConfig} label={label}></EditLevelConfigForm>;
  } else {
    button = deleteButton;
    title = "Supprimer le niveau";
    form = <EditLevelConfigForm item={item} modalConfig={setModal} deleteLevelConfig={deleteLevelConfig} label={label}></EditLevelConfigForm>;
  }

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>{form}</ModalBody>
      </Modal>
    </div>
  );
};

export default EditLevelConfigModal;
